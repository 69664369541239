import axios from "axios"
import { apiService } from "./APIServices"

export const getTerms = () => {
  return axios.get(apiService.terms)
}

export const acceptTerms = ({ userId, tcId }) => {
  return axios.put(apiService.user + `/${userId}/terms?termsId=${tcId}`)
}
