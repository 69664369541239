import React, { useEffect, useState } from "react"
import "./SidePanel.scss"
import Icon from "./Icon"
import { OpaqueOverlay } from "./OpaqueOverlay"

export const SidePanel = ({ show, onClose, children, overlay, collapsible }) => {
  const sidebarWidth = 350
  const [right, setRight] = useState(-sidebarWidth)
  useEffect(() => {
    setRight(show ? 0 : -sidebarWidth)
    if (show) {
      document.body.style.overflow = "hidden"
    }
    return () => (document.body.style.overflow = null)
  }, [show])

  function handleClose() {
    setRight(-400)
    onClose()
  }

  function toggleCollapse() {
    setRight(right === 0 ? -sidebarWidth : 0)
  }

  return (
    <>
      {show && (overlay || <OpaqueOverlay />)}
      <div className="sidepanel" style={{ right, width: sidebarWidth }}>
        {show && (
          <div className="sidepanel-content">
            <div className="sidepanel-btn-close" onClick={handleClose}>
              <Icon iconRelativePath="monade/preallocation/close" />
            </div>
            {collapsible && (
              <div className="sidepanel-btn-collapse" onClick={toggleCollapse}>
                <Icon
                  iconRelativePath="monade/arrow-down"
                  svgStyles={{
                    transform: `rotate(${right === 0 ? -90 : 90}deg)`
                  }}
                />
              </div>
            )}
            {right === 0 ? children : null}
          </div>
        )}
      </div>
    </>
  )
}
