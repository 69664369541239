import { useEffect, useRef, useState } from "react"

export function useExcludeOverlay() {
  const overlayExcludeRef = useRef(null)
  const [overlayBoundingRect, setOverlayBoundingRect] = useState(null)

  useEffect(() => {
    const listener = () => {
      const rect = overlayExcludeRef.current?.getBoundingClientRect()
      setOverlayBoundingRect(rect)
    }
    window.addEventListener("scroll", listener)
    window.addEventListener("resize", listener)

    return () => {
      window.removeEventListener("scroll", listener)
      window.removeEventListener("resize", listener)
    }
  }, [])

  function updateExcludeRef(ref) {
    overlayExcludeRef.current = ref
    const rect = ref?.getBoundingClientRect()
    setOverlayBoundingRect(rect)
  }

  function clearOverlayBoundingRect() {
    overlayExcludeRef.current = null
    setOverlayBoundingRect(null)
  }

  return {
    overlayBoundingRect,
    updateExcludeRef,
    clearOverlayBoundingRect
  }
}
