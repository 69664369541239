const formatter = new Intl.NumberFormat("it-IT", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 2
})

export const formatPrice = (price) => {
  if (typeof price !== "number" || isNaN(price)) {
    return "-"
  }

  return formatter.format(price)
}

export function priceStringToFloat(stringPrice) {
  return parseFloat(stringPrice.replace(",", ".")) || 0
}
