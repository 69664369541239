import moment from "moment"

export const SET_CURRENT_PERIOD = "SET_CURRENT_PERIOD"
export const SET_TIMESHEET_PERIOD = "SET_TIMESHEET_PERIOD"

export const setTimesheetPeriod = (periods) => ({
  type: SET_TIMESHEET_PERIOD,
  payload: periods
})

export const setSelectedPeriod = (selectedPeriod) => ({
  type: SET_CURRENT_PERIOD,
  payload: selectedPeriod
})

const initialState = {
  selectedPeriod: { _id: moment().format("YYYYMM"), label: moment().format("MMMM") },
  timesheetPeriod: []
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_CURRENT_PERIOD":
      return {
        ...state,
        selectedPeriod: action.payload
      }
    case "SET_TIMESHEET_PERIOD":
      return {
        ...state,
        timesheetPeriod: action.payload
      }
    default:
      return state
  }
}
