import { formatMessage } from "devextreme/localization"
import moment from "moment"
import { useState } from "react"
import { exportExcel } from "../../../../services"
import { showToastError } from "../../Headcount/Main/Utils/ErrorHandling"
import Tooltip from "./common/Tooltip"
import Icon from "./Icon"
import { Spinner } from "./Spinner"

export default function ExportWbsButtonAsXLSX() {
  const [exportLoading, setExportLoading] = useState(false)

  return (
    <Tooltip
      content={<div className="text-nowrap">{formatMessage("jw-Excel export")}</div>}
    >
      <button
        className={`btn btn-outline-secondary ml-2`}
        style={{ backgroundColor: "white" }}
        disabled={exportLoading}
        onClick={() => {
          setExportLoading(true)
          exportExcel()
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement("a")
              link.href = url
              link.download =
                "wbs_export_" + moment().format("YYYYMMDD HH-mm") + ".xlsx"
              link.click()
              setExportLoading(false)
            })
            .catch(() => {
              showToastError(formatMessage("jw-Oops. Something went wrong"))
              setExportLoading(false)
            })
        }}
      >
        {exportLoading ? (
          <Spinner />
        ) : (
          <Icon
            svgStyles={{ width: "16px", height: "16px" }}
            iconRelativePath="monade/download"
          />
        )}
      </button>
    </Tooltip>
  )
}
