import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useMemo } from "react"
import { CustomInput } from "reactstrap"
import { getTerms, acceptTerms } from "../../services"

export default function TC() {
  const user = useSelector((state) => state.Auth.user)
  const [tc, setTc] = useState("")
  const [checked, setChecked] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)

  useMemo(() => {
    getTerms().then(({ data }) => {
      setTc(data)
    })
  }, [])

  const acceptTc = (e) => {
    if (checked) {
      acceptTerms({ userId: user._id, tcId: tc._id }).then((r) => {
        window.location.reload(false)
      })
    } else {
      e.preventDefault()
      setIsInvalid(true)
      return
    }
  }

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-md-offset-2">
            <form name="tandc">
              <div className="panel panel-default" style={{ marginTop: "20px" }}>
                {tc._id && (
                  <div className="panel-body">
                    <div style={{ textAlign: "center" }}>
                      <h1>Termini e Condizioni</h1>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: tc.text }} />
                  </div>
                )}
              </div>
              <div style={{ textAlign: "center" }}>
                <CustomInput
                  type="checkbox"
                  id="tcCheckBox"
                  invalid={isInvalid}
                  label="Dichiaro di aver letto e compreso l’informativa sul trattamento dei dati personali"
                  onChange={(e) => {
                    setChecked(e.target.checked)
                    setIsInvalid(false)
                  }}
                />
                <button
                  style={{ margin: "20px" }}
                  className="btn btn-primary"
                  onClick={acceptTc}
                >
                  Continua
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
