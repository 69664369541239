import { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"

export function useSelectStatuses() {
  const config = useSelector((state) => state.Auth.config)
  useEffect(() => {
    if (config.wbsStatus) {
      setStatuses(config.wbsStatus)
    }
  }, [config])

  const [statuses, setStatuses] = useState(config?.wbsStatus || [])
  const parseStatusCode = (statusCode) => {
    return statuses.find((status) => status.code === statusCode)?.name || "-"
  }

  const getStatusesAsOptions = () =>
    statuses.map((s) => ({ id: s.code, cell: s.name }))
  const [statusOptions, setStatusOptions] = useState(getStatusesAsOptions())
  useEffect(() => {
    setStatusOptions(getStatusesAsOptions())
  }, [statuses])

  return { statuses, parseStatusCode, statusOptions }
}
