import { apiService } from "./APIServices"

import axios from "axios"

export const createYear = async (year) => {
  return axios.post(apiService.fiscalYear, year)
}

export const getYear = async (yearId, includeWbs) => {
  return axios.get(`${apiService.fiscalYear}/${yearId}`, {
    params: { includeWbs: includeWbs }
  })
}

export const getAllYears = async (wbsId) => {
  if (!wbsId) {
    return axios.get(apiService.fiscalYear)
  }

  return axios.get(apiService.fiscalYear, {
    params: {
      wbsId
    }
  })
}

export const updateYear = async (year) => {
  return axios.put(`${apiService.fiscalYear}/${year._id}`, year)
}

export const deleteYear = async (yearId) => {
  return axios.delete(`${apiService.fiscalYear}/${yearId}`)
}
