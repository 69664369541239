import React, { Fragment, useEffect, useState } from "react"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import { useSelector } from "react-redux"
import { Card, CardBody, CardTitle, Col, Form, Row } from "reactstrap"
import ToggleSwitch from "../../../../assets/components/switch/ToggleSwitch"
import { JOTable } from "./table"
import { getJobsThumb } from "../../../../services/index"
import { useAppAbility } from "../../../../Context/ability"

export default function JOReview() {
  let selectedPeriod = useSelector((state) => state.Period.selectedPeriod)
  const [unitIsHours, setUnitIsHours] = useState(true)
  const [jobList, setJobList] = useState([])
  const config = useSelector((state) => state.Auth.config)

  const { can } = useAppAbility()
  const canManageAll = can("manage", "all")

  useEffect(() => {
    // get all jobs for superAdmins, get only owned jobs for reviewers
    let getOwned = canManageAll ? false : true

    getJobsThumb({ filterByUserOwner: getOwned }).then(({ data }) => {
      setJobList(data)
    })
  }, [])

  useEffect(() => {
    let unitCache = localStorage.getItem("@timesheetJobOwnerUnitIsHours")
    if (unitCache === "false") setUnitIsHours(false)
  }, [setUnitIsHours])

  const onToogleSwitch = () => {
    setUnitIsHours(!unitIsHours)
    localStorage.setItem("@timesheetJobOwnerUnitIsHours", !unitIsHours)
  }

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="main-card mb-3">
          <CardBody>
            <Form>
              <Row form>
                <Col md={12}>
                  <CardTitle>
                    <div className="d-flex justify-content-between">
                      <div />
                      <ToggleSwitch
                        id="joReview"
                        currentValue={!unitIsHours}
                        onChange={onToogleSwitch}
                      />
                    </div>
                  </CardTitle>
                </Col>
              </Row>
              <Row form>
                <Col md={12}>
                  <JOTable
                    currentPeriod={selectedPeriod}
                    unitIsHours={unitIsHours}
                    jobList={jobList}
                    config={config}
                  />
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
