import CustomStore from "devextreme/data/custom_store"
import merge from "deepmerge"
import { deleteTimesheetRow, getAnyReview } from "../../../../services"
import { saveRow } from "../../Utils/tsFlowConfig"
import { find, isEmpty } from "underscore"

export const InternalReviewCustomStore = ({
  currentPeriod,
  toReviewFilter,
  statusFilter,
  jobs,
  config,
  setDataLoaded,
}) => {
  return new CustomStore({
    load: async function () {
      setDataLoaded(false)
      return getAnyReview({
        period: currentPeriod._id,
        toReviewFilter: toReviewFilter,
        statusFilter: statusFilter,
        key: Math.random(),
      })
        .then(async ({ data }) => {
          data.forEach((row) => {
            let currentStatus = row.statusDetails.code

            if (!row.timesheetRow[currentStatus]) {
              currentStatus = row.currentStatus
            }

            row.timesheetRow.currentTsRowId = row.timesheetRow[currentStatus]._id
            // row.timesheetRow.currentLabel =
            //   row.timesheetRow[currentStatus].wbs +
            //   " - " +
            //   row.timesheetRow[currentStatus].activity

            const rowJob = jobs._array.find(
              (j) => j.id === row.timesheetRow[currentStatus].code
            )

            if (rowJob?.isTempPlaced) {
              row.timesheetRow.currentLabel = `${rowJob.wbs} (${config.wbsTempCode}) - ${rowJob.description}`
            } else {
              row.timesheetRow.currentLabel = rowJob?.label
            }

            //TODO workaround da sostituire (trovare una soluzione anche per la commessa temp)
            if (!row.timesheetRow.currentLabel) {
              row.timesheetRow.currentLabel =
                row.timesheetRow[currentStatus].wbs +
                " - " +
                row.timesheetRow[currentStatus].activity
            }

            row.timesheetRow.currentCode = row.timesheetRow[currentStatus].code
            row.timesheetRow.currentHours = row.timesheetRow[currentStatus].hours
            row.timesheetRow.currentDays = row.timesheetRow[currentStatus].days
            row.timesheetRow.currentDescription =
              row.timesheetRow[currentStatus].description || ""
            row.timesheetRow.currentNotes = row.timesheetRow[currentStatus].notes

            row.wbsAliasCode = rowJob?.wbsAliasCode
          })
          return {
            data: !isEmpty(data) ? data : [],
          }
        })
        .catch((e) => {
          throw e
        })
        .finally(() => setDataLoaded(true))
    },
    update: async (before, dataToUpdate) => {
      let isHoursCompiled =
        typeof dataToUpdate.timesheetRow.currentHours === "number"
      const objToSave = merge(before, dataToUpdate)
      const currentStatus = objToSave.currentStatus
      if (isHoursCompiled) {
        objToSave.timesheetRow.currentDays = objToSave.timesheetRow.currentHours / 8
      } else {
        objToSave.timesheetRow.currentHours = objToSave.timesheetRow.currentDays * 8
      }
      const jobRow = objToSave.timesheetRow

      const jobDetails = find(jobs._array, (j) => j.id === jobRow.currentCode)

      const objToSaveSanitized = {
        tsId: objToSave._id,
        _id: jobRow._id,
        code: jobRow.currentCode,
        wbs: jobDetails.wbs,
        activity: jobDetails.description,
        description: jobRow.currentDescription?.trim(),
        notes: jobRow.currentNotes,
        hours: jobRow.currentHours,
        days: jobRow.currentDays,
      }

      return saveRow({
        review: currentStatus,
        params: objToSaveSanitized,
      })
    },
    remove: (data) => {
      const currentStatus = data.currentStatus
      const objToSave = {
        tsId: data._id,
        _id: data.timesheetRow._id,
        code: data.timesheetRow.currentCode,
        description: data.timesheetRow.currentDescription,
        hours: 0,
        days: 0,
        notes: "cancelled",
      }

      switch (currentStatus) {
        case "preReview":
          if (!data.timesheetRow.code && !data.timesheetRow.userCompiling) {
            return deleteTimesheetRow({
              tsId: data._id,
              rowId: data.timesheetRow._id,
              currentStatus,
            })
          } else {
            return saveRow({ review: currentStatus, params: objToSave })
          }
        case "thirdReview":
          if (!data.timesheetRow.code && !data.timesheetRow.preReview) {
            return deleteTimesheetRow({
              tsId: data._id,
              rowId: data.timesheetRow._id,
              currentStatus,
            })
          } else {
            return saveRow({ review: currentStatus, params: objToSave })
          }
        case "completed":
          if (
            !data.timesheetRow.code &&
            !data.timesheetRow.preReview &&
            !data.timesheetRow.thirdReview
          ) {
            return deleteTimesheetRow({
              tsId: data._id,
              rowId: data.timesheetRow._id,
              currentStatus,
            })
          } else {
            return saveRow({ review: currentStatus, params: objToSave })
          }
        case "ppa":
          if (
            !data.timesheetRow.code &&
            !data.timesheetRow.preReview &&
            !data.timesheetRow.thirdReview
          ) {
            return deleteTimesheetRow({
              tsId: data._id,
              rowId: data.timesheetRow._id,
              currentStatus,
            })
          } else {
            return saveRow({ review: currentStatus, params: objToSave })
          }
        default:
          return null
      }
    },
  })
}
