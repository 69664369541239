import AddJobButton from "../AddJobButton"
import DetailsYearButton from "../DetailsYearButton"

export default function YearActionCell({
  wbsId,
  yearId,
  canManage = false,
  isWbsActive = false,
}) {
  return (
    <span className="d-flex flex-row pl-2">
      {canManage && (
        <AddJobButton
          disabled={!isWbsActive}
          yearId={yearId}
          className="my-2 mx-1"
        />
      )}
      <DetailsYearButton
        wbsId={wbsId}
        yearId={yearId}
        className="my-2 ml-1"
        canManage={canManage}
      />
    </span>
  )
}
