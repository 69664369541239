import { useEffect, useRef } from "react"
import "../InputDefaults.scss"
import { LabelWithError } from "../LabelWithError"
import "./NumericInput.scss"

export default function NumericInput({
  className,
  inputClassName,
  inputStyles,
  value,
  onChange,
  placeholder = "",
  label,
  disabled = false,
  readOnly = false,
  prefocus = false,
  error = "",
}) {
  const inputRef = useRef(null)
  const randomId = Math.random().toString()

  useEffect(() => {
    if (prefocus) {
      focus()
    }
  }, [])

  const focus = (e) => {
    inputRef.current?.focus()
  }

  const valueChanged = (event) => {
    if (readOnly) {
      return
    }
    if (onChange) {
      onChange(event)
    }
  }

  return (
    <div
      className={`${className} numeric-input-container d-flex flex-column ${
        readOnly ? "readonly" : disabled ? "disabled" : "cursor"
      }`}
    >
      {label ? (
        <LabelWithError
          label={label}
          error={error}
          htmlFor={randomId}
          labelClassName={`mb-2`}
          readOnly={readOnly}
          disabled={disabled}
        />
      ) : (
        ""
      )}
      <input
        type={readOnly ? "text" : "number"}
        disabled={disabled || readOnly}
        className={`w-100 ${
          readOnly ? "readonly" : disabled ? "disabled" : "cursor"
        } ${inputClassName} ${error !== "" ? "border-danger" : ""}`}
        id={randomId}
        style={inputStyles}
        placeholder={placeholder}
        value={value}
        onChange={valueChanged}
      />
    </div>
  )
}
