import { formatMessage } from "devextreme/localization"
import { useAppAbility } from "../../../Context/ability"
import PageTitle from "../../../Layout/AppMain/PageTitle"
import AccountableFilter from "./components/AccountableFilter"
import AddWbsButton from "./components/AddWbsButton"
import ClearFiltersButton from "./components/ClearFiltersButton"
import { ErrorBlock } from "./components/ErrorBlock"
import ExportWbsButtonAsXLSX from "./components/ExportWbsButtonAsXLSX"
import JobAndWbsTable from "./components/JobAndWbsTable"
import Paginator from "./components/Paginator"
import RefreshTableButton from "./components/RefreshTableButton"
import SearchFilter from "./components/SearchFilter"
import { Spinner } from "./components/Spinner"
import YearFilter from "./components/YearFilter"
import { useJobAndWbsData } from "./hooks/useJobAndWbsData"
import { useSelector } from "react-redux"
import constants from "../../../Common/constants"

export default function JobAndWbs() {
  const {
    pageSize,
    setPageSize,
    currentPage,
    lastPage,
    totalCount,
    amountCurrentlyVisualizing,
    onPageChange,
    getFilterValue,
    setFilterValue,
    orderBy,
    setOrderBy,
    sortingOrder,
    setSortingOrder,
    pinnedUntil,
    setPinnedUntil,
    resetAllFilters,
    refetch,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    data,
    error,
    queryWbsError,
  } = useJobAndWbsData()

  const user = useSelector((state) => state.Auth.user)

  const { can } = useAppAbility()
  
  const canManageWBS = can("manage", "Wbs") 

  return (
    <>
      <div className="job-and-wbs-container">
        <PageTitle
          heading={formatMessage("admin-job-wbs-title")}
          subheading=""
          icon="pe-7s-study text-success"
        />

        {!queryWbsError && (isSuccess || isLoading) ? (
          <div className="d-flex flex-row justify-content-between align-items-center mb-4">
            {canManageWBS && (
              <div className="d-flex align-items-end">
                <AddWbsButton extended={true} />
                {(isLoading || isFetching) && (
                  <span className="text-primary m-2">
                    <Spinner className={"mx-1"} /> {formatMessage("jw-Loading...")}
                  </span>
                )}
              </div>
            )}

            <div className="d-flex flex-row w-75" style={{ zIndex: 100000 }}>
              <SearchFilter
                placeholder={formatMessage("jw-Search...")}
                className="w-100 ml-2"
                termFilter={getFilterValue("term")}
                setTermFilter={(value) => {
                  setFilterValue("term", value)
                }}
              />
              <YearFilter
                className="ml-2 w-50"
                yearFilter={getFilterValue("fiscalYear")}
                setYearFilter={(value) => {
                  setFilterValue("fiscalYear", value)
                }}
              />
              <AccountableFilter
                className="ml-2 w-50"
                accountableFilter={getFilterValue("accountable")}
                setAccountableFilter={(value) => {
                  setFilterValue("accountable", value)
                }}
              />
              <ClearFiltersButton resetAllFilters={resetAllFilters} />
              <RefreshTableButton refetch={refetch} />
              <ExportWbsButtonAsXLSX />
            </div>
          </div>
        ) : (
          ""
        )}

        {queryWbsError ? (
          <div className="text-center my-4">
            <h5 className="mb-4 d-block">
              Error while loading data for Jobs and WBS
            </h5>
            <RefreshTableButton refetch={refetch} />
          </div>
        ) : (
          ""
        )}
        {!queryWbsError && (isSuccess || isLoading) ? (
          <div style={{ width: "calc(100vw - 140px)" }}>
            <JobAndWbsTable
              loading={isLoading}
              data={data || []}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              sortingOrder={sortingOrder}
              setSortingOrder={setSortingOrder}
              getFilterValue={getFilterValue}
              setFilterValue={setFilterValue}
              pinnedUntil={pinnedUntil}
              setPinnedUntil={setPinnedUntil}
              isFetching={isFetching}
            />
            <Paginator
              className="my-2 mr-3"
              currentPage={currentPage}
              onPageChange={onPageChange}
              pageSize={pageSize}
              setPageSize={setPageSize}
              lastPage={lastPage}
              totalCount={totalCount}
              amountCurrentlyVisualizing={amountCurrentlyVisualizing}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  )
}
