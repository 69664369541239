import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupItem,
  GroupPanel,
  Scrolling,
  SearchPanel,
  Summary,
  MasterDetail,
  Toolbar,
  Item
} from "devextreme-react/data-grid"
import CustomStore from "devextreme/data/custom_store"
import moment from "moment"
import React from "react"
import { getRows } from "../../Utils/tsFlowConfig"
import { MasterTemplate } from "../../Components/masterTemplate"
import { filter, head } from "underscore"
import { formatMessage } from "devextreme/localization"
import { Button } from "devextreme-react"

const reviewCode = "firstReview"

let exported = false

export const JOTable = React.memo(
  ({ currentPeriod, unitIsHours, jobList, config }) => {
    const DGRef = React.useRef(null)

    const dataSource = {
      store: new CustomStore({
        load: async function() {
          return getRows({
            params: {
              period: currentPeriod._id,
              filterByJobOwner: true
            },
            review: "closed"
          })
            .then(async ({ data }) => {
              return {
                data: data.length
                  ? data.map((j) => {
                      // console.log(j)
                      const rowJob = head(
                        filter(
                          jobList,
                          (job) => job._id === j.timesheetRow.closed.code
                        )
                      )

                      if (rowJob == null) {
                        j.label = `${j.timesheetRow.closed.wbs} - ${j.timesheetRow.closed.activity}`
                        return j
                      }

                      if (rowJob?.isTempPlaced) {
                        j.label = `${rowJob.wbs.code}${
                          rowJob?.wbs.aliasCode ? ` - ${rowJob?.wbs.aliasCode}` : ""
                        } (${config.wbsTempCode}) - ${rowJob.description}`
                      } else {
                        j.label = `${rowJob.wbs.code}${
                          rowJob?.wbs.aliasCode ? ` - ${rowJob?.wbs.aliasCode}` : ""
                        } - ${rowJob.description}`
                      }

                      return j
                    })
                  : []
              }
            })
            .catch((e) => {
              throw e
            })
        }
      })
    }

    const onExported = (e) => {
      e.component.columnOption(0, "groupIndex", 0)
    }

    const onExporting = (e) => {
      e.component.columnOption(0, "groupIndex", null)

      if (exported) return

      e.component.refresh().done(function() {
        exported = true
        e.component.exportToExcel()

        exported = false
      })

      e.cancel = true
    }

    return (
      <DataGrid
        dataSource={dataSource}
        showBorders={true}
        onExporting={onExporting}
        onExported={onExported}
        loadPanel={{
          enabled: true,
          indicatorSrc: `data:image/svg+xml;utf8,${require("./../../../../assets/rolling.svg")}`
        }}
        ref={DGRef}
      >
        <Export
          enabled={true}
          fileName={"jo_recap_" + moment().format("YYYYMMDD")}
        />
        <GroupPanel autoExpandAll={true} />
        <SearchPanel visible={true} />
        <Grouping autoExpandAll={false} />
        <Column
          dataField={`label`}
          caption={formatMessage("Project")}
          groupIndex={0}
        />
        <Column
          dataField={"owner.displayName"}
          caption={formatMessage("joreview-ts-owner")}
        />
        <Column
          dataField={
            unitIsHours ? `timesheetRow.closed.hours` : `timesheetRow.closed.days`
          }
          caption={unitIsHours ? formatMessage("Hours") : formatMessage("Days")}
          alignment={"center"}
          dataType={"number"}
          format={{}}
        />
        <Column
          dataField={`timesheetRow.closed.cost`}
          caption={formatMessage("Cost")}
          dataType={"number"}
          format={{ currency: "EUR", type: "currency" }}
          allowEditing={false}
          alignment={"center"}
          calculateCellValue={(timesheet) => {
            // this line fixes an issue with some timesheets that have more than 0 hours but €0.00 cost

            return timesheet.timesheetRow.closed.cost &&
              timesheet.timesheetRow.closed.cost > 0
              ? timesheet.timesheetRow.closed.cost
              : timesheet.timesheetRow.closed.hours * timesheet.owner.level.cost
          }}
        />
        <Summary>
          <GroupItem
            column="timesheetRow.owner"
            summaryType="count"
            displayFormat={"{0} timesheet"}
          />
          <GroupItem
            column={
              unitIsHours ? `timesheetRow.closed.hours` : `timesheetRow.closed.days`
            }
            summaryType="sum"
            showInGroupFooter={false}
            valueFormat={{}}
            displayFormat={"{0}"}
            alignByColumn={true}
          />
          <GroupItem
            column={`timesheetRow.closed.cost`}
            summaryType="sum"
            displayFormat={"{0}"}
            valueFormat={{ type: "currency", precision: 2, currency: "EUR" }}
            recalculateWhileEditing
            showInGroupFooter={false}
            alignByColumn={true}
          />
          <GroupPanel autoExpandAll={true} />
          <SearchPanel visible={true} />
          <Grouping autoExpandAll={false} />
          <Column
            dataField={`label`}
            caption={formatMessage("Project")}
            groupIndex={0}
          />
          <Column
            dataField={"owner.displayName"}
            caption={formatMessage("joreview-ts-owner")}
          />
          <Column
            dataField={
              unitIsHours ? `timesheetRow.closed.hours` : `timesheetRow.closed.days`
            }
            caption={unitIsHours ? formatMessage("Hours") : formatMessage("Days")}
            alignment={"center"}
            dataType={"number"}
            format={{}}
          />
          <Column
            dataField={`timesheetRow.closed.cost`}
            caption={formatMessage("Cost")}
            dataType={"number"}
            format={{ currency: "EUR", type: "currency" }}
            allowEditing={false}
            alignment={"center"}
          />
        </Summary>
        <Scrolling mode="virtual" />
        <MasterDetail
          enabled={true}
          component={(props) => (
            <MasterTemplate unitIsHours={unitIsHours} data={props.data} />
          )}
        />
        <Toolbar>
          <Item location="after" name="refreshButton">
            <Button
              icon="refresh"
              hint={formatMessage("dxDataGrid-commandRefresh")}
              onClick={() => DGRef.current.instance.refresh()}
            />
          </Item>
          <Item location="after" name="exportButton" />
          <Item location="after" name="searchPanel" />
        </Toolbar>
      </DataGrid>
    )
  }
)
