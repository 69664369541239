export const mapToOptions = (items, idFn, labelFn) => {
  return items
    .sort((a, b) =>
      labelFn(a).localeCompare(labelFn(b), undefined, {sensitivity: 'base'})
    )
    .map(item => ({
      id: idFn(item),
      cell: labelFn(item)
    }));
}
