import { useState } from "react"
import { useEffect } from "react"
import { formatPrice } from "../../../../../Utils/formatPrice"
import "./PriceCell.scss"

export default function PriceCell({ info }) {
  const [parsedAmount, setParsedAmount] = useState(formatPrice(info))
  useEffect(() => {
    setParsedAmount(formatPrice(info))
  }, [info])

  return <span>{parsedAmount}</span>
}
