import { useMemo, useState } from "react"
import "./ResourceSlotCell.scss"
import ResourceActivity from "../components/ResourceActivity"
import { formatPercentage } from "../../../../Utils/formatPercentage"
import { formatMessage } from "devextreme/localization"

export default function ResourceSlotCell({
  slot,
  highlightedActivityId,
  resource,
  maxRatio,
  showTotalBar = true,
  selected,
  selectable,
  onActivityClick
}) {
  const [activitiesEl, setActivitiesEl] = useState(null)

  const total = useMemo(() => {
    return (
      slot.activities.reduce(
        (sum, activity) => sum + Math.round(activity.percent * 1e2) / 1e2,
        0
      ) * 100
    )
  }, [slot])

  const totalHeight = useMemo(() => {
    if (!activitiesEl) {
      return 0
    }

    const { height } = activitiesEl.getBoundingClientRect()

    return height
  }, [activitiesEl])

  const minAllocationHeight = 25 //this value controls the size of everything else, and represents the height in pixels of the shortest job

  const fullHeight = minAllocationHeight * 10

  const calcScaledHeight = (percentage, allocationRatio) => {
    const ratioToScale = 1 + maxRatio - allocationRatio
    return percentage * fullHeight * ratioToScale < minAllocationHeight
      ? minAllocationHeight
      : percentage * fullHeight * ratioToScale
  }

  return (
    <div className="resource-slot-cell">
      {showTotalBar && slot.activities.length > 0 && (
        <div
          className={`resource-slot-cell-total ${
            total.toFixed(0) > 100 ? "total-overflow" : ""
          } ${totalHeight < 40 ? "resource-slot-cell-total-sm" : ""}`}
          style={{ height: totalHeight + "px" }}
        >
          <span>{formatPercentage(total, 0)}</span>
        </div>
      )}
      <div ref={setActivitiesEl} className="resource-slot-cell-activities">
        {slot.activities.map((activity, index) => (
          <ResourceActivity
            key={index}
            className={`${activity.id === highlightedActivityId &&
              "highlighted-activity"}`}
            activity={activity}
            height={calcScaledHeight(activity.percent, slot.ratio)}
            onClick={() => !activity?.readOnly && onActivityClick(activity)}
            selectable={selectable(activity)}
            selected={selected(activity)}
          />
        ))}
      </div>
      {!slot.activities.length && (
        <div className="text-center text-muted">
          <small>{formatMessage("preallocation-projects-slot-nodata")}</small>
        </div>
      )}
    </div>
  )
}
