import { useMutation, useQueryClient } from "@tanstack/react-query"
import { formatMessage } from "devextreme/localization"
import { useEffect } from "react"
import { useState } from "react"
import { createYear } from "../../../../services"
import {
  showSuccessToast,
  showToastError,
} from "../../Headcount/Main/Utils/ErrorHandling"
import { parseFiscalYearData } from "../utils/parseFiscalYearData"
import { parseFiscalYearToData } from "../utils/parseFiscalYearToData"

export function useCreateYear(wbs, onSuccess) {
  const [errors, setErrors] = useState([])
  const DEFAULT_FORCE_DC = "SC"

  const isChargeable = () => wbs?.type === "1"
  const [newYear, setNewYear] = useState({
    wbs: wbs?._id ? wbs._id : null,
    forceDc: isChargeable() ? DEFAULT_FORCE_DC : null,
    isDc: isChargeable() ? false : null,
    otherCosts: 0,
  })
  useEffect(() => {
    setNewYear((prev) => ({
      ...prev,
      wbs: wbs?._id ? wbs._id : null,
      forceDc: isChargeable() ? DEFAULT_FORCE_DC : null,
      isDc: isChargeable() ? false : null,
    }))
  }, [wbs])

  const onValueChanged = (field, value) => {
    const newData = { ...newYear }
    newData[field] = value
    setNewYear(newData)
  }

  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (year) => {
      if (wbs.type !== "1") {
        delete year.otherCosts
      }
      const payload = parseFiscalYearToData(year, isChargeable())
      return await createYear(payload)
    },
    {
      onSuccess: (data) => {
        const parsedYear = parseFiscalYearData(data.data)
        queryClient.invalidateQueries(["GET", "wbs"])
        queryClient.invalidateQueries(["GET", "wbs/", wbs._id])
        queryClient.setQueryData(["GET", "year/", parsedYear._id], parsedYear)
        showSuccessToast(formatMessage("jw-Successfully created Fiscal Year"))
        if (onSuccess) {
          onSuccess(parsedYear)
        }
      },
      onError: (err) => {
        if (err.response.status === 400) {
          showToastError(err.response)
          setErrors(err.response.data)
        } else {
          setErrors([])
          showToastError(err.response)
        }
      },
    },
  )

  return {
    mutation,
    onValueChanged,
    newYear,
    errors,
  }
}
