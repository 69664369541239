import Popup from "devextreme-react/popup"
import { formatMessage } from "devextreme/localization"
import React from "react"
import { Button } from "reactstrap"
import isExternal from "./IsExternal"
import "../../Styles/edit.css"

export default function ConfirmExitPopup({
  history,
  user,
  levels,
  confirmExitPopup,
  setConfirmExitPopup
}) {
  return (
    <Popup
      maxWidth={1000}
      height={"auto"}
      showTitle={true}
      onHiding={() => {
        setConfirmExitPopup(false)
      }}
      title={formatMessage("hc-are-you-sure-you-want-to-exit")}
      dragEnabled={true}
      closeOnOutsideClick={false}
      visible={confirmExitPopup}
      contentRender={() => {
        return (
          <div>
            <div className="mb-4" style={{ fontSize: "18px" }}>
              {formatMessage("hc-the-user-current-level-should-be")}
              {isExternal(user.currentLevel?.code, levels) ? formatMessage("hc-external") : formatMessage("hc-internal")}
              {formatMessage("hc-if-you-continue-the-user-will-be-set-back-to")}
              <b>
                {isExternal(user.currentLevel?.code, levels)
                  ? formatMessage("hc-external")
                  : formatMessage("hc-internal")}
              </b>
            </div>
            <div className="d-flex justify-content-between">
              <Button
                outline
                color="primary"
                d="button"
                width={100}
                height={40}
                onClick={() => {
                  setConfirmExitPopup(false)
                }}
              >
                <span className="dx-button-text">{formatMessage("Cancel")}</span>
              </Button>
              <Button
                color="primary"
                d="button"
                width={100}
                height={40}
                onClick={() => {
                  history.push("/admin/headcount")
                }}
              >
                <span className="dx-button-text">{formatMessage("hc-button-continue")}</span>
              </Button>
            </div>
          </div>
        )
      }}
    />
  )
}
