import { apiService } from "./APIServices"
import moment from "moment"

import axios from "axios"

export const exportExcel = (startDate, endDate) => {
  let params = {}

  if (startDate) {
    params.startDate = moment(startDate).format("YYYY-MM-DD")
  }

  if (endDate) {
    params.endDate = moment(endDate).format("YYYY-MM-DD")
  }

  return axios.get(apiService.export, {
    params,
    responseType: "arraybuffer"
  })
}
