import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { getJobsSuggestions } from "../../../../../services"

export function useSelectJobSuggestions() {
  const { isLoading, isError, isSuccess, data, error, refetch } = useQuery(
    ["GET", "job", "job-suggestions"],
    async () => {
      return await getJobsSuggestions()
    },
  )

  const getSuggestionsAsOptions = () => {
    if (!isSuccess) {
      return {}
    }
    const suggestionsAsOptions = {}
    Object.keys(data.data).forEach((suggestionKey) => {
      suggestionsAsOptions[suggestionKey] = data.data[suggestionKey].map(
        (suggestionString) => ({ id: suggestionString, cell: suggestionString }),
      )
    })
    return suggestionsAsOptions
  }
  const [suggestionsOptions, setSuggestionsOptions] = useState(
    getSuggestionsAsOptions(),
  )
  useEffect(() => {
    setSuggestionsOptions(getSuggestionsAsOptions())
  }, [data])

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    refetchSuggestions: refetch,
    suggestionsOptions,
  }
}
