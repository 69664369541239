import { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"

export function useSelectJobModes() {
  const config = useSelector((state) => state.Auth.config)
  useEffect(() => {
    setJobModes(config.jobModes || [])
  }, [config])

  const [jobModes, setJobModes] = useState(config?.jobModes || [])

  const getCustomersAsOptions = () => jobModes.map((jm) => ({ id: jm, cell: jm }))

  const [jobModeOptions, setJobModeOptions] = useState(getCustomersAsOptions())

  useEffect(() => {
    setJobModeOptions(getCustomersAsOptions())
  }, [jobModes])

  return { jobModes, jobModeOptions }
}
