import ResourceLegendItem from "./ResourceLegendItem";
import "./ResourceLegend.scss"

export default function ResourceLegend({
  seriesItems
}) {
  return (
    <div className='resource-legend'>
      {
        seriesItems.map(item => (
          <ResourceLegendItem key={item.id} item={item} />
        ))
      }
    </div>
  )
}
