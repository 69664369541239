import { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"

export function useCompetenceSections() {
  const config = useSelector((state) => state.Auth.config)
  useEffect(() => {
    if (config.competences) {
      setCompetences(config.competences)
    }
  }, [config])

  const [competences, setCompetences] = useState(config?.competences || [])

  return { competences }
}
