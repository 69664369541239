import { formatMessage } from "devextreme/localization"
import moment from "moment"
import React, { Fragment, useEffect, useReducer, useState } from "react"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import { useSelector } from "react-redux"
import { Bounce, toast } from "react-toastify"
import { Card, CardBody, CardTitle, Col, Form, Row } from "reactstrap"
import { head } from "underscore"
import ToggleSwitch from "../../../../assets/components/switch/ToggleSwitch"
import { useAppAbility } from "../../../../Context/ability"
import {
  getAllWbs,
  getJobsThumb,
  sendFirstReview,
  sendFirstReviewsByJob,
  sendFirstReviewsByPeriod
} from "../../../../services/index"
import AlertDialog from "../../Utils/AlertDialog"
import { sendInChargeReviews } from "../../Utils/tsFlowConfig"
import { EditRow } from "./editRow"
import { JOTable } from "./JOTable"

export default function JOReview({ sendAllJobAlert, setSendAllJobAlert }) {
  const { can } = useAppAbility()
  const canManageAll = can("manage", "all")

  let selectedPeriod = useSelector((state) => state.Period.selectedPeriod)
  const [openAlertAcceptAll, setOpenAlertAcceptAll] = useState(false)
  const [unitIsHours, setUnitIsHours] = useState(true)
  const [tsId, setTsId] = useState(false)
  const [tsJobId, setTsJobId] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [groupedRowsToApprove, setGroupedRowsToApprove] = useState([])
  const [editRow, setEditRow] = useState({ show: false, data: {} }) // edit row properties
  const [jobList, setJobList] = useState([])
  const [invalidJobs, setInvalidJobs] = useState([])
  const [update, forceUpdate] = useReducer((x) => x + 1, 0) // necessary to force component update
  const [invalidTsRows, setInvalidTsRows] = useState([]) // list of timesheets that contain invalid jobs
  const [sendAllJobError, setSendAllJobError] = useState(false) // bulk approve of jobs error
  const [assignedJobs, setAssignedJobs] = useState([]) // assigend jobs to send bulk approve
  const [invalidWbsCodes, setInvalidWbsCodes] = useState([]) // codes of tmp or non-final wbs
  const config = useSelector((state) => state.Auth.config)

  const DGRef = React.useRef(null)

  const handleSend = async () => {
    if (openAlert) {
      setOpenAlert(false)
      sendFirstReview({ tsId, jobId: tsJobId })
        .then(() => {
          DGRef.current.instance.refresh()
        })
        .catch((e) => {
          console.log("Err", e)
          DGRef.current.instance?.refresh()
          toast("Error approving current row", {
            transition: Bounce,
            closeButton: true,
            autoClose: 3000,
            position: "bottom-center",
            type: "error"
          })
        })
        .finally(forceUpdate())
    } else if (openAlertAcceptAll) {
      setOpenAlertAcceptAll(false)
      const timesheet = head(groupedRowsToApprove)

      sendFirstReviewsByJob({
        jobId: timesheet?.timesheetRow.firstReview.code,
        periodId: selectedPeriod._id
      })
        .catch((e) => {
          console.log("Err", e)
          toast("Error approving all rows of " + timesheet?.label, {
            transition: Bounce,
            closeButton: true,
            autoClose: 3000,
            position: "bottom-center",
            type: "error"
          })
        })
        .finally(() => {
          forceUpdate()
        })
    } else if (sendAllJobAlert) {
      setSendAllJobAlert(false)
      sendFirstReviewsByPeriod({ periodId: selectedPeriod._id })
        .catch((e) => {
          console.log("Err", e)
          toast("Error approving all incharge reviews", {
            transition: Bounce,
            closeButton: true,
            autoClose: 3000,
            position: "bottom-center",
            type: "error"
          })
        })
        .finally(() => {
          forceUpdate()
        })
    }
  }

  useEffect(() => {
    getAllWbs().then(({ data }) => {
      data = data
        .filter((wbs) => !wbs.isFinal || wbs.isTempPlaced)
        .map((wbs) => wbs.code)
      data.push(config.wbsTempCode)
      setInvalidWbsCodes(data)
    })

    // get all jobs for superAdmins, get only owned jobs for reviewers
    let getOwned = canManageAll ? false : true
    getJobsThumb({ filterByUserOwner: getOwned }).then(({ data }) => {
      data = data.map((job) => {
        job.isActive =
          job.startDate != null && job.endDate != null
            ? moment(selectedPeriod?.calendarStartDate).isBetween(
                job.startDate,
                job.endDate,
                "M",
                "[]"
              )
            : false
        job.disabled = !job.isActive || job.isDeleted
        return job
      })
      setJobList(data)
      setInvalidTsRows([])
      setGroupedRowsToApprove([])
      setInvalidJobs(data?.filter((job) => job.disabled).map((j) => j._id))
      setAssignedJobs([])
      setSendAllJobError(false)
      DGRef?.current?.instance?.refresh()
    })
  }, [update])

  useEffect(() => {
    forceUpdate()
  }, [selectedPeriod])

  useEffect(() => {
    let unitCache = localStorage.getItem("@timesheetJobOwnerUnitIsHours")
    if (unitCache === "false") setUnitIsHours(false)
  }, [setUnitIsHours])

  const onToogleSwitch = () => {
    setUnitIsHours(!unitIsHours)
    localStorage.setItem("@timesheetJobOwnerUnitIsHours", !unitIsHours)
  }

  const alertText = () => {
    if (
      (openAlert && invalidTsRows?.find((t) => t === tsJobId)) ||
      (openAlertAcceptAll &&
        groupedRowsToApprove.some(
          (element, index, array) =>
            invalidTsRows.find((t) => t === element.timesheetRow._id) != null
        ))
    ) {
      return {
        title: formatMessage("warning-jo-review-disabled-job-title"),
        message: formatMessage("warning-jo-review-disabled-job-body"),
        action: "",
        isAlert: true
      }
    }

    if (sendAllJobAlert) {
      const { error, alertText, isAlert } = sendInChargeReviews({
        containInvalidJobs: sendAllJobError,
        noReviewsToAprove: !sendAllJobError && assignedJobs.length === 0,
        selectedPeriod: selectedPeriod
      })

      return {
        title: alertText,
        isAlert: isAlert,
        action: error ? "" : formatMessage("SEND"),
        error: isAlert
      }
    }

    if (
      selectedPeriod._id ===
      moment()
        .add(1, "month")
        .format("YYYYMM")
    ) {
      return {
        title: formatMessage("future-review-send-confirm"),
        isAlert: true,
        action: formatMessage("SEND")
      }
    }

    const title = (function() {
      if (openAlertAcceptAll)
        return (
          formatMessage("warning-jo-review-approve-all-rows") +
          head(groupedRowsToApprove)?.label +
          "?"
        )
      else if (openAlert)
        return formatMessage("warning-jo-review-approve-selected-row")
    })()

    return {
      title: title,
      message: "",
      action: formatMessage("SEND")
    }
  }

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="main-card mb-3">
          <CardBody>
            <Form>
              <Row form>
                <Col md={12}>
                  <div>
                    <CardTitle>
                      <div className="d-flex justify-content-between">
                        <div />
                        <ToggleSwitch
                          id="joReview"
                          currentValue={!unitIsHours}
                          onChange={onToogleSwitch}
                        />
                      </div>
                    </CardTitle>
                  </div>
                </Col>
              </Row>
              <Row form>
                <Col md={12}>
                  <div>
                    <EditRow
                      setEditRow={setEditRow}
                      editRow={editRow}
                      unitIsHours={unitIsHours}
                      forceUpdate={forceUpdate}
                      jobs={jobList.filter((j) => !j.disabled)}
                    />
                    <AlertDialog
                      open={openAlert || openAlertAcceptAll || sendAllJobAlert}
                      alertText={alertText()}
                      handleSend={handleSend}
                      closeAlert={() => {
                        setOpenAlert(false)
                        setOpenAlertAcceptAll(false)
                        setSendAllJobAlert(false)
                      }}
                      isAlert={sendAllJobAlert}
                    />
                    <JOTable
                      currentPeriod={selectedPeriod}
                      unitIsHours={unitIsHours}
                      setOpenAlert={setOpenAlert}
                      setTsId={setTsId}
                      setTsJobId={setTsJobId}
                      DGRef={DGRef}
                      setOpenAlertAcceptAll={setOpenAlertAcceptAll}
                      setGroupedRowsToApprove={setGroupedRowsToApprove}
                      setEditRow={setEditRow}
                      jobList={jobList}
                      invalidJobs={invalidJobs}
                      forceUpdate={forceUpdate}
                      setInvalidTimesheets={setInvalidTsRows}
                      invalidTimesheets={invalidTsRows}
                      sendAllJobError={sendAllJobError}
                      setSendAllJobError={setSendAllJobError}
                      assignedJobs={assignedJobs}
                      setAssignedJobs={setAssignedJobs}
                      invalidWbsCodes={invalidWbsCodes}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
