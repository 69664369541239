import "bootstrap/dist/css/bootstrap.min.css"
import { Button as DevextremeButton } from "devextreme-react"
import "devextreme-react/check-box"
import DataGrid, {
  Button,
  Column,
  ColumnChooser,
  Editing,
  FilterRow,
  Item,
  LoadPanel,
  Scrolling,
  SearchPanel,
  StateStoring,
  Toolbar
} from "devextreme-react/data-grid"
import "devextreme-react/lookup"
import CustomStore from "devextreme/data/custom_store"
import { formatMessage } from "devextreme/localization"
import React, { Fragment, useRef, useState } from "react"
import { withRouter } from "react-router"
import {
  Button as RButton,
  Card,
  CardBody,
  Col,
  Form as RSForm,
  Row
} from "reactstrap"
import { filterRemove } from "../../../../assets/filter-remove"
import { addLevel, createUser, getUsers } from "../../../../services"
import "../../../../style/dx.material.bip-scheme.css"
import { calcExtJobTitle } from "./Utils/CalcExtJobTitle"
import clone from "./Utils/Clone"
import { showToastError } from "./Utils/ErrorHandling"
import { parseUserDates } from "./Utils/ParseUserDates"

export default withRouter(function UsersList({
  canManageUser,
  columns,
  genderValues,
  ...props
}) {
  const [loadPanelEnabled, setLoadPanel] = useState(true)
  const [createFormVisible, setCreateFormVisible] = useState(false)
  const [disableForm, setDisableForm] = useState(true)
  const [newUser, setNewUser] = useState({
    currentLevel: {},
    enabled: false
  })

  const formRef = useRef(null)

  const onContentReady = () => {
    setLoadPanel(false)
  }

  const clearFilter = () => {
    props.dataGridRef.current.instance.clearFilter()
  }

  const onDataErrorOccurred = (e) => {
    if (e.error.response?.status === 400) {
      e.error.message = formatMessage("hc-error-invalid-input-data-provided")
    } else if (e.error.response) {
      e.error.message = e.error.response.data
    }
  }

  const getLevelByCode = (c) => {
    return props.levels.find((l) => {
      return l.code === c
    })
  }

  const dataSource = {
    store: new CustomStore({
      load: () => {
        return getUsers(null, true, true)
          .then(({ data }) => {
            if (data) {
              let users = data
              users.forEach((user) => {
                if (user.currentLevel) {
                  user.currentLevel.extJobTitle = calcExtJobTitle(
                    user.currentLevel?.code,
                    user.competence,
                    props.levels
                  )
                  user.currentLevel.cost *= 8
                }

                if (user.isTeam == null) {
                  user.isTeam = false
                }
              })
              let parsedUsers = []
              users.forEach((user) => {
                parsedUsers.push(parseUserDates(user))
              })
              return {
                data: parsedUsers
              }
            } else {
              return {
                data: [],
                totalCount: 0
              }
            }
          })
          .catch((e) => {
            throw Object.assign(
              new Error(formatMessage("hc-error-data-loading-error")),
              { code: 404 }
            )
          })
      },
      insert: async (data) => {
        let user = clone(data)
        if (user.currentLevel) {
          delete user.currentLevel
        }
        try {
          let resp = await createUser(user)
          const userAdded = resp.data

          let currentLevel = null
          if (data.currentLevel) {
            currentLevel = { ...data.currentLevel }
            let level = getLevelByCode(currentLevel.code)
            currentLevel.name = level.name
            currentLevel.startDate = data.joiningDate
            currentLevel.cost = data.currentLevel.cost
              ? data.currentLevel.cost / 8
              : level.cost / 8
          }
          try {
            let response = await addLevel(userAdded._id, currentLevel)
            const currentLevelAdded = response.data
            return parseUserDates({ ...userAdded, currentLevel: currentLevelAdded })
          } catch (err) {
            showToastError(err.response)
            throw err
          }
        } catch (err) {
          showToastError(err.response)
          throw err
        }
      }
    })
  }

  const onExporting = (e) => {
    e.component.beginUpdate()
    columns.forEach((c) => {
      e.component.columnOption(c.field, "visible", true)
    })
  }

  const getSortedColumns = () => {
    return columns.sort(function(a, b) {
      var A = a.title.toUpperCase()
      var B = b.title.toUpperCase()
      return A < B ? -1 : A > B ? 1 : 0
    })
  }

  const hidePopup = () => {
    setCreateFormVisible(false)
    setDisableForm(true)
    formRef.current.instance.resetValues()
  }

  const showPopup = () => {
    setDisableForm(false)
    setCreateFormVisible(true)
  }

  const onCreateUser = () => {
    props.history.push(`headcount/create`)
  }

  return (
    <Fragment>
      <Card className="main-card mb-3">
        <CardBody>
          <RSForm>
            <Row form>
              <Col md={12}>
                <div>
                  {canManageUser ? (
                    <RButton
                      style={{
                        position: "absolute",
                        zIndex: "100"
                      }}
                      color="primary"
                      onClick={() => {
                        onCreateUser()
                      }}
                    >
                      {formatMessage("hc-add-user")}
                    </RButton>
                  ) : (
                    ""
                  )}

                  <div className="dx-swatch-dx-generic-compact">
                    <DataGrid
                      ref={props.dataGridRef}
                      id={"gridContainerHC"}
                      dataSource={dataSource}
                      onContentReady={onContentReady}
                      onEditingStart={(data) => {
                        props.history.push(`headcount/edit/${data.data._id}`)
                      }}
                      onDataErrorOccurred={onDataErrorOccurred}
                      onExporting={onExporting}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      repaintChangesOnly={true}
                      showRowLines
                      showColumnLines={false}
                      showBorders={true}
                    >
                      <StateStoring
                        enabled={true}
                        type="localStorage"
                        storageKey="storageHeadcount"
                      />
                      <FilterRow visible />
                      <Editing
                        mode="popup"
                        allowAdding={false}
                        allowUpdating={true}
                      />
                      {getSortedColumns().map((column) => {
                        return (
                          <Column
                            dataField={column.field}
                            key={column.field}
                            caption={column.title}
                            dataType={column.dataType}
                            format={column.format}
                            value={column.value}
                            visible={column.visible}
                            customizeText={column.customizeText}
                          >
                            {column.lookup}
                          </Column>
                        )
                      })}
                      <Column
                        type="buttons"
                        showInColumnChooser={false}
                        visible={true}
                        allowReordering={false}
                        allowSorting={false}
                        allowFiltering={true}
                      >
                        <Button
                          onClick={(e) => {
                            props.history.push(`headcount/show/${e.row.data._id}`)
                          }}
                          name="info"
                          icon="info"
                        />
                        {canManageUser ? <Button name="edit" icon="edit" /> : ""}
                      </Column>
                      <Scrolling mode="virtual" />
                      <Scrolling mode="infinite" rowRenderingMode="virtual" />
                      <LoadPanel enabled={loadPanelEnabled} />
                      <SearchPanel visible={true} />
                      <ColumnChooser enabled={true} mode="select" height="800" />
                      <Toolbar>
                        <Item
                          name="dxRemoveFilter"
                          widget="dxButton"
                          location="after"
                        >
                          <DevextremeButton
                            icon={filterRemove}
                            hint={formatMessage("clear-filters")}
                            onClick={clearFilter}
                          />
                        </Item>
                        <Item name="columnChooserButton" location="after" />
                        <Item name="searchPanel" location="after" />
                      </Toolbar>
                    </DataGrid>
                  </div>
                </div>
              </Col>
            </Row>
          </RSForm>
        </CardBody>
      </Card>
    </Fragment>
  )
})
