import DatePickerInput from "../components/input/DatePickerInput"
import ToggleButtonGroup from "../components/input/ToggleButtonGroup"
import "./Filters.scss"
import ToggleButton from "../components/input/ToggleButton"
import TextInput from "../components/input/TextInput"
import DropdownSelect from "../components/input/DropdownSelect"
import DropdownMultiSelect from "../components/input/DropdownMultipleSelect"
import { formatMessage } from "devextreme/localization"
import { useMemo } from "react"
import PercentageInput from "../components/input/PercentageInput"

export default function Filters({
  className,
  filters,
  onFiltersChange,
  areAllResourcesShown,
  onToggleShowAll,
  teams,
  showShowDetails,
  isLoading,
  searchPlaceholder,
}) {
  const aggregationOptions = [
    {
      id: "WEEKLY",
      label: formatMessage("preallocation-filters-aggregation-weeks"),
    },
    {
      id: "MONTHLY",
      label: formatMessage("preallocation-filters-aggregation-months"),
    },
  ]

  const orderOptions = [
    {
      id: "ASC",
      cell: formatMessage("preallocation-filters-order-asc"),
    },
    {
      id: "DESC",
      cell: formatMessage("preallocation-filters-order-desc"),
    },
  ]

  const teamOptions = useMemo(
    () =>
      (teams || []).map((team) => ({
        id: team._id,
        cell: team.name,
      })),
    [teams],
  )

  const userTypeOptions = [
    {
      id: "INTERNAL",
      cell: formatMessage("preallocation-filters-user-type-internal"),
    },
    {
      id: "EXTERNAL",
      cell: formatMessage("preallocation-filters-user-type-external"),
    },
  ]

  return (
    <div className={`resource-filters-wrapper ${className}`}>
      <div className="d-flex mb-4">
        <ToggleButtonGroup
          label={formatMessage("preallocation-filters-aggregation")}
          options={aggregationOptions}
          value={filters.aggregation}
          onChange={(aggregation) => !isLoading && onFiltersChange({ aggregation })}
          className="mr-3"
        />
        <DatePickerInput
          label={formatMessage("preallocation-filters-date")}
          value={filters.startDate}
          onChange={(startDate) => onFiltersChange({ startDate })}
          style={{ width: "154px" }}
        />
      </div>
      <div className="d-flex">
        <ToggleButton
          label={formatMessage("preallocation-filters-free-time")}
          text={
            filters.showFreeTime
              ? formatMessage("preallocation-filters-hide")
              : formatMessage("preallocation-filters-show")
          }
          icon={
            filters.showFreeTime
              ? "monade/preallocation/hide"
              : "monade/preallocation/show"
          }
          value={filters.showFreeTime}
          onChange={(showFreeTime) => onFiltersChange({ showFreeTime }, false)}
          className="mr-3"
          style={{ width: "76px" }}
        />
        {showShowDetails && (
          <ToggleButton
            label={formatMessage("preallocation-filters-details")}
            text={
              filters.showDetails
                ? formatMessage("preallocation-filters-hide")
                : formatMessage("preallocation-filters-show")
            }
            icon={
              filters.showDetails
                ? "monade/preallocation/details-hide"
                : "monade/preallocation/details"
            }
            value={filters.showDetails}
            onChange={(showDetails) =>
              !isLoading && onFiltersChange({ showDetails })
            }
            className="mr-3"
            style={{ width: "98px" }}
          />
        )}
        <ToggleButton
          label={formatMessage("preallocation-filters-resources")}
          text={
            areAllResourcesShown
              ? formatMessage("preallocation-filters-hide-all")
              : formatMessage("preallocation-filters-show-all")
          }
          icon={
            areAllResourcesShown
              ? "monade/preallocation/user-no"
              : "monade/preallocation/users"
          }
          value={areAllResourcesShown}
          onChange={() => !isLoading && onToggleShowAll()}
          style={{ width: "98px" }}
        />
        <TextInput
          label={formatMessage("preallocation-filters-search")}
          value={filters.search}
          onChange={(search) => onFiltersChange({ search })}
          className="mx-3"
          style={{ width: "154px" }}
          placeholder={searchPlaceholder}
        />

        <DropdownSelect
          label={formatMessage("preallocation-filters-user-type")}
          options={userTypeOptions}
          value={filters.userType?.[0]}
          onSelected={(e) => onFiltersChange({ userType: e ? [e.id] : null })}
          className="mr-3"
          style={{ width: "154px" }}
          searchable={false}
        />

        <DropdownSelect
          label={formatMessage("preallocation-filters-order")}
          options={orderOptions}
          value={filters.order}
          onSelected={({ id: order }) => onFiltersChange({ order })}
          className="mr-3"
          style={{ width: "80px" }}
          searchable={false}
        />

        <DropdownMultiSelect
          label={formatMessage("preallocation-filters-teams")}
          options={teamOptions}
          values={filters.teamId}
          onChange={(sel) => onFiltersChange({ teamId: sel.map((e) => e.id) })}
          style={{ width: "200px" }}
        />

        {/* // TODO: TEMPORARY HIDDEN, RE-ENABLE AFTER BACKEND FIX */}
        {/* <PercentageInput
          label={formatMessage("preallocation-filters-chargeability")}
          value={filters.chargeability}
          onChange={(e) => {
            onFiltersChange({
              chargeability: e.target.value && parseFloat(e.target.value)
            })
          }}
          className="ml-3"
          style={{ width: 100 }}
        /> */}
      </div>
    </div>
  )
}
