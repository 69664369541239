import {
  CustomRule,
  Form,
  GroupItem,
  Item,
  Label,
  SimpleItem
} from "devextreme-react/form"
import { formatMessage } from "devextreme/localization"
import { Fragment, useEffect } from "react"
import FormGroupTitle from "../Components/FormGroupTitle"
import { calcTotWorkedYears } from "../Utils/CalcTotWorkedYears"
import { FormValidator } from "../Utils/FormValidator"
import isExternal from "../Utils/IsExternal"
import isUserWithExternalPromotion from "../Utils/IsUserWithExternalPromotion"

export default function AdditionalInfoForm({ setErrors, ...props }) {
  const isExternalFieldDisabled = () => {
    return !(
      isExternal(props.user.currentLevel?.code, props.levels) ||
      isUserWithExternalPromotion(props.userLevels, props.levels)
    )
  }

  const errors = props.errors ?? []

  useEffect(() => {
    props.validateForms()
  }, [])
  
  const validator = new FormValidator()

  function configureForm(ref) {
    validator.validationGroup = ref
    props.registerForm("additional", ref)
  }

  const rule = (fieldName) => (
    <CustomRule
      validationCallback={validator.isValid(fieldName, errors)}
      message={validator.getErrorMessage(fieldName, errors)}
    ></CustomRule>
  )
  const updateTotWorkedYears = (workedYearsBeforeBip, workedYearsInBip) => {
    props.setUser((prevUser) => ({
      ...prevUser,
      workedYearsBeforeBip: workedYearsBeforeBip,
      workedYearsInBip: workedYearsInBip,
      totWorkedYears: calcTotWorkedYears(workedYearsBeforeBip, workedYearsInBip)
    }))
  }

  return (
    <Fragment>
      <div className="form-base" style={props.container}>
        <Form
          ref={configureForm}
          labelLocation="top"
          formData={props.user}
          validationGroup="AdditionalInfoForm"
        >
          <GroupItem cssClass="with-separator pb-4" colCount={12}>
            <GroupItem colSpan={3}>
              <Item>
                <FormGroupTitle
                  title={formatMessage("hc-header-additional-info")}
                  required={false}
                  requiredForEnabling={false}
                />
              </Item>
            </GroupItem>
            <GroupItem colSpan={9} colCount={2}>
              <GroupItem colSpan={2} colCount={3}>
                <SimpleItem
                  editorType="dxNumberBox"
                  dataField="workedYearsBeforeBip"
                  editorOptions={{
                    onValueChanged: (e) => {
                      setErrors([])
                      updateTotWorkedYears(e.value, props.user.workedYearsInBip)
                    }
                  }}
                >
                  <Label
                    showColon={false}
                    text={formatMessage("hc-field-worked-years-before-bip")}
                  />
                  {rule("workedYearsBeforeBip")}
                </SimpleItem>
                <SimpleItem
                  editorType="dxNumberBox"
                  dataField="workedYearsInBip"
                  editorOptions={{
                    onValueChanged: (e) => {
                      setErrors([])
                      updateTotWorkedYears(props.user.workedYearsBeforeBip, e.value)
                    }
                  }}
                >
                  <Label
                    showColon={false}
                    text={formatMessage("hc-field-worked-years-in-bip")}
                  />
                  {rule("workedYearsInBip")}
                </SimpleItem>
                <SimpleItem
                  editorType="dxNumberBox"
                  dataField="totWorkedYears"
                  editorOptions={{ readOnly: true }}
                >
                  <Label
                    showColon={false}
                    text={formatMessage("hc-field-total-worked-years")}
                  />
                  {rule("totWorkedyears")}
                </SimpleItem>
              </GroupItem>
              <SimpleItem dataField="sialCode" editorOptions={{ maxLength: 50 }}>
                <Label
                  showColon={false}
                  text={formatMessage("hc-field-sial-code")}
                />
                {rule("sialCode")}
              </SimpleItem>
              <SimpleItem
                dataField="reviewReader"
                editorType="dxLookup"
                editorOptions={{
                  readOnly: isExternalFieldDisabled(),
                  dataSource: [
                    {
                      name: formatMessage("hc-placeholder-review-reader"),
                      id: null
                    },
                    ...props.userNames
                  ],
                  valueExpr: "id",
                  displayExpr: "name",
                  onValueChanged: (e) => {
                    setErrors([])
                    props.setUser((prevUser) => ({
                      ...prevUser,
                      reviewReader: e.value
                    }))
                  },
                  value: props.user.reviewReader,
                  placeholder: formatMessage("hc-placeholder-review-reader"),
                  dropDownOptions: {
                    title: formatMessage("hc-field-review-readers"),
                    closeOnOutsideClick: true
                  },
                  searchEnabled: true,
                  showCancelButton: false
                }}
              >
                <Label
                  showColon={false}
                  text={formatMessage("hc-field-review-reader")}
                />
                {rule("reviewReader")}
              </SimpleItem>
              <SimpleItem
                dataField="chargeability"
                editorOptions={{ readOnly: true }}
              >
                <Label
                  showColon={false}
                  text={formatMessage("hc-field-chargeability")}
                />
                {rule("chargeability")}
              </SimpleItem>
              <SimpleItem
                dataField="currentLevel.extJobTitle"
                editorOptions={{ readOnly: true }}
              >
                <Label
                  showColon={false}
                  text={formatMessage("hc-field-ext-job-title")}
                />
                {rule("currentLevel.extJobTitle")}
              </SimpleItem>
              <SimpleItem
                dataField="created_at"
                editorOptions={{ readOnly: true }}
                editorType="dxDateBox"
              >
                <Label
                  showColon={false}
                  text={formatMessage("hc-field-created-at")}
                />
                {rule("created_at")}
              </SimpleItem>
              <SimpleItem
                dataField="updated_at"
                editorOptions={{ readOnly: true }}
                editorType="dxDateBox"
              >
                <Label
                  showColon={false}
                  text={formatMessage("hc-field-updated-at")}
                />
                {rule("updated_at")}
              </SimpleItem>
              <SimpleItem
                dataField="tags"
                editorType="dxTagBox"
                colSpan={2}
                editorOptions={{
                  dataSource: [...props.tags],
                  onValueChanged: (e) => {
                    // the follwoing condition is necessary to avoid fresh loop
                    if (e.previousValue !== e.value) {
                      setErrors([])
                      props.setUser((prevUser) => ({
                        ...prevUser,
                        tags: e.value
                      }))
                    }
                  },
                  value: props.user.tags,
                  placeholder: formatMessage("hc-placeholder-tag"),
                  dropDownOptions: {
                    title: formatMessage("hc-field-tags"),
                    closeOnOutsideClick: true
                  },
                  showCancelButton: false
                }}
              >
                <Label showColon={false} text={formatMessage("hc-field-tags")} />
                {rule("tags")}
              </SimpleItem>
              <SimpleItem
                dataField="notes"
                editorType="dxTextArea"
                colSpan={2}
                editorOptions={{ maxLength: 1024 }}
              >
                <Label showColon={false} text={formatMessage("hc-field-notes")} />
                {rule("notes")}
              </SimpleItem>
            </GroupItem>
          </GroupItem>
          <GroupItem colCount={12}>
            <GroupItem colSpan={3}>
              <Item>
                <FormGroupTitle
                  title={formatMessage("hc-header-external-users")}
                  required={false}
                  requiredForEnabling={true}
                />
              </Item>
            </GroupItem>

            <GroupItem colSpan={9} colCount={2}>
              <SimpleItem
                dataField="contract"
                editorOptions={{
                  readOnly: isExternalFieldDisabled(),
                  maxLength: 100
                }}
              >
                <Label
                  showColon={false}
                  text={`${formatMessage("hc-field-contract")} ${
                    isExternalFieldDisabled() ? "" : "■"
                  }`}
                />
                {rule("contract")}
              </SimpleItem>
              <SimpleItem
                dataField="contractType"
                editorType="dxLookup"
                editorOptions={{
                  readOnly: isExternalFieldDisabled(),
                  dataSource: [
                    {
                      display: formatMessage("hc-placeholder-contract-type"),
                      value: null
                    },
                    ...props.contractTypes.map((contractType) => {
                      return { display: contractType, value: contractType }
                    })
                  ],
                  displayExpr: "display",
                  valueExpr: "value",
                  onValueChanged: (e) => {
                    setErrors([])
                    props.setUser((prevUser) => ({
                      ...prevUser,
                      contractType: e.value
                    }))
                  },
                  value: props.user.contractType,
                  placeholder: formatMessage("hc-placeholder-contract-type"),
                  dropDownOptions: {
                    title: formatMessage("hc-field-contract-types"),
                    closeOnOutsideClick: true
                  },
                  searchEnabled: false,
                  showCancelButton: false
                }}
              >
                <Label
                  showColon={false}
                  text={formatMessage("hc-field-contract-type")}
                />
                {rule("contractType")}
              </SimpleItem>
              <SimpleItem
                dataField="skills"
                editorOptions={{
                  readOnly: isExternalFieldDisabled(),
                  maxLength: 1024
                }}
              >
                <Label showColon={false} text={formatMessage("hc-field-skills")} />
                {rule("skills")}
              </SimpleItem>
              <SimpleItem
                dataField="contractEnd"
                editorOptions={{ readOnly: isExternalFieldDisabled() }}
                editorType="dxDateBox"
              >
                <Label
                  showColon={false}
                  text={`${formatMessage("hc-field-contract-end")} ${
                    isExternalFieldDisabled() ? "" : "■"
                  }`}
                />
                {rule("contractEnd")}
              </SimpleItem>
              <SimpleItem
                dataField="orderEnd"
                editorOptions={{ readOnly: isExternalFieldDisabled() }}
                editorType="dxDateBox"
              >
                <Label
                  showColon={false}
                  text={`${formatMessage("hc-field-order-end")} ${
                    isExternalFieldDisabled() ? "" : "■"
                  }`}
                />
                {rule("orderEnd")}
              </SimpleItem>
              <SimpleItem
                dataField="xTechAccountable"
                editorType="dxLookup"
                editorOptions={{
                  readOnly: isExternalFieldDisabled(),
                  dataSource: [
                    {
                      name: formatMessage("hc-placeholder-xtech-accountable"),
                      id: null
                    },
                    ...props.userNames
                  ],
                  valueExpr: "id",
                  displayExpr: "name",
                  onValueChanged: (e) => {
                    setErrors([])
                    props.setUser((prevUser) => ({
                      ...prevUser,
                      xTechAccountable: e.value
                    }))
                  },
                  value: props.user.xTechAccountable,
                  placeholder: formatMessage("hc-placehodler-xtech-accountable"),
                  dropDownOptions: {
                    title: formatMessage("hc-field-xtech-accountable"),
                    closeOnOutsideClick: true
                  },
                  searchEnabled: true,
                  showCancelButton: false
                }}
              >
                <Label
                  showColon={false}
                  text={`${formatMessage("hc-field-xtech-accountable")} ${
                    isExternalFieldDisabled() ? "" : "■"
                  }`}
                />
                {rule("xTechAccountable")}
              </SimpleItem>
              <SimpleItem
                dataField="supplier"
                editorType="dxLookup"
                editorOptions={{
                  dataSource: [
                    {
                      display: formatMessage("hc-placeholder-supplier"),
                      value: null
                    },
                    ...props.suppliers.map((role) => {
                      return { display: role, value: role }
                    })
                  ],
                  displayExpr: "display",
                  valueExpr: "value",
                  onValueChanged: (e) => {
                    setErrors([])
                    props.setUser((prevUser) => ({ ...prevUser, supplier: e.value }))
                  },
                  value: props.user.supplier,
                  placeholder: formatMessage("hc-placeholder-supplier"),
                  readOnly: isExternalFieldDisabled(),
                  dropDownOptions: {
                    title: formatMessage("hc-field-suppliers"),
                    closeOnOutsideClick: true
                  },
                  searchEnabled: true,
                  showCancelButton: false
                }}
              >
                <Label
                  showColon={false}
                  text={`${formatMessage("hc-field-supplier")} ${
                    isExternalFieldDisabled() ? "" : "■"
                  }`}
                />
                {rule("supplier")}
              </SimpleItem>
              <SimpleItem
                dataField="equivalentBipLevel"
                editorType="dxLookup"
                editorOptions={{
                  readOnly: isExternalFieldDisabled(),
                  dataSource: [
                    {
                      displayName: formatMessage(
                        "hc-placeholder-equivalent-bip-level"
                      ),
                      code: null
                    },
                    ...props.levels
                      .filter((l) => {
                        return !l.isExternal
                      })
                      .map((l) => {
                        return {
                          displayName: l.name + " - " + l.code,
                          code: l.code
                        }
                      })
                  ],
                  displayExpr: "displayName",
                  valueExpr: "code",

                  placeholder: formatMessage("hc-placeholder-equivalent-bip-level"),
                  dropDownOptions: {
                    title: formatMessage("hc-field-bip-levels"),
                    closeOnOutsideClick: true
                  },
                  searchEnabled: true,
                  showCancelButton: false
                }}
              >
                <Label
                  showColon={false}
                  text={formatMessage("hc-field-equivalent-bip-level")}
                />
                {rule("equivalentBipLevel")}
              </SimpleItem>
            </GroupItem>
          </GroupItem>
        </Form>
      </div>
    </Fragment>
  )
}
