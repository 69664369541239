import { formatMessage } from "devextreme/localization"
import { withRouter } from "react-router-dom"
import Tooltip from "./common/Tooltip"
import Icon from "./Icon"
import "./Buttons.scss"

export default withRouter(function DetailsJobButton({
  className,
  yearId,
  jobShortCode,
  disabled = false,
  extended = false,
  history,
  canManage = false,
}) {
  return (
    <Tooltip
      disabled={extended}
      content={
        <div className="text-nowrap">
          {formatMessage("jw-DETAILS")} {formatMessage("jw-JOB")}
        </div>
      }
    >
      <button
        disabled={disabled}
        className={`btn btn-outline-primary square-btn text-nowrap ${className}`}
        onClick={() => {
          history.push(`/admin/${yearId}/job/edit/${jobShortCode}`)
        }}
      >
        <Icon iconRelativePath={canManage ? "monade/edit" : "monade/info"} />
        {extended ? (
          <span className="ml-1">{" " + formatMessage("jw-DETAILS")}</span>
        ) : (
          ""
        )}
      </button>{" "}
    </Tooltip>
  )
})
