export class FormValidator {
  validationGroup = null

  isValid(field, errors) {
    return () => {
      return !errors.find((e) => e.field === field)
    }
  }

  getErrorMessage(field, errors) {
    if (errors) {
      return errors.find((e) => e.field === field)?.message ?? ""
    }
  }
}
