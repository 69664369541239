/** Wbs types whose jobs are not mandatory to have a description in timesheet compiling:
 * Chargeable, Bip - Gestionale BIP, Formazione specialistica xTech, Bip - Recruiting, Ferie, Assenza, Ufficio, Altro
 */
const wbsTypesWithNoMandatoryDescription = [
  "1",
  "0",
  "8",
  "11",
  "20",
  "18",
  "19",
  "10"
]

export const getJobDescriptionMandatoriness = (wbsType) => {
  return !wbsTypesWithNoMandatoryDescription.includes(wbsType)
}
