import { useRef, useState } from "react"
import Checkbox from "../input/Checkbox"
import "./BooleanCell.scss"

export default function BooleanCell({ info, onChange, error, disabled = false }) {
  const [internalValue, setInternalValue] = useState(info || false)

  function onInternalValueChanged(value) {
    if (disabled) {
      return
    }
    setInternalValue(value)
    if (typeof onChange === "function") {
      onChange(value)
    }
  }

  return (
    <Checkbox
      disabled={disabled}
      value={internalValue}
      onChange={(event) => onInternalValueChanged(event.target.checked)}
      error={error || ""}
      className="d-flex justify-content-center"
    />
  )
}
