import { formatMessage } from "devextreme/localization"
import { useEffect, useState } from "react"
import { useTable } from "../hooks/useTable"
import { wbsColumns } from "./column/wbsColumns"
import { yearColumns } from "./column/yearColumns"
import "./JobAndWbsTable.scss"
import FooterRow from "./row/FooterRow"
import HeaderRow from "./row/HeaderRow"
import YearRow from "./row/YearRow"
import { Spinner } from "./Spinner"

export default function YearsTable({
  fiscalYears,
  footerData,
  isLoading,
  isChargeable,
  isActive,
  pinnedUntil,
  setPinnedUntil,
}) {
  const { headers } = useTable({
    tableColumns: wbsColumns,
    pinnedUntil,
    setPinnedUntil,
  })
  const [columnOffsets, setColumnOffsets] = useState(null)
  const [lastPinnedIndex, setLastPinnedIndex] = useState(null)
  const [yearsTableData, setYearsTableData] = useState(fiscalYears || [])
  useEffect(() => {
    setYearsTableData(fiscalYears || [])
  }, [fiscalYears])
  const { rows: yearRows } = useTable({
    tableColumns: yearColumns,
    tableData: yearsTableData,
  })

  useEffect(() => {
    setLastPinnedIndex(
      headers?.findIndex((column) => column.id === pinnedUntil) || null,
    )
  }, [headers, pinnedUntil])

  return (
    <div className="job-and-wbs-table-container">
      <table className="table-responsive">
        <thead>
          <HeaderRow
            headers={headers}
            filtrable={false}
            columnOffsets={columnOffsets}
            setColumnOffsets={setColumnOffsets}
            tableData={fiscalYears}
          />
        </thead>
        <tbody>
          {isLoading && (
            <tr className={"wbs-loading"}>
              <td colSpan={headers.length}>
                <div>
                  <Spinner spanContainerStyles={{ fontSize: "2rem" }} />
                </div>
              </td>
            </tr>
          )}
          {yearRows.map(({ info, cells }, index) => (
            <YearRow
              cells={cells}
              info={info}
              key={index}
              initialExpand={true}
              isWbsActive={isActive}
              isChargeable={isChargeable}
              columnOffsets={columnOffsets}
              lastPinnedIndex={lastPinnedIndex}
            />
          ))}
          {!isLoading && fiscalYears?.length === 0 && (
            <tr className={"wbs-empty"}>
              <td colSpan={headers.length}>
                <div className="p-5 d-flex flex-row justify-content-center align-items-center">
                  <span>{formatMessage("jw-No elements to show.")}</span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <FooterRow
            footerData={footerData}
            columnOffsets={columnOffsets}
            lastPinnedIndex={lastPinnedIndex}
          />
        </tfoot>
      </table>
    </div>
  )
}
