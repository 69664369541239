import { useMutation, useQueryClient } from "@tanstack/react-query"
import { formatMessage } from "devextreme/localization"
import { useState } from "react"
import { createWbs } from "../../../../services"
import {
  showSuccessToast,
  showToastError
} from "../../Headcount/Main/Utils/ErrorHandling"
import { parseWbsData } from "../utils/parseWbsData"
import { parseWbsToData } from "../utils/parseWbsToData"

export function useCreateWbs(wbsToClone, onSuccess) {
  const [errors, setErrors] = useState([])

  const [newWbs, setNewWbs] = useState(
    wbsToClone
      ? wbsToClone
      : {
          otherCosts: 0
        }
  )

  const onValueChanged = (field, value) => {
    const newData = {
      ...newWbs,
      isFinal: field === "isTempPlaced" && value ? false : newWbs.isFinal,
      isTempPlaced: field === "isFinal" && value ? false : newWbs.isTempPlaced
    }
    newData[field] = value
    setNewWbs(newData)
  }

  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (wbs) => {
      const payload = parseWbsToData(wbs)
      return await createWbs(payload)
    },
    {
      onSuccess: (data) => {
        const parsedWbs = parseWbsData(data.data)
        queryClient.invalidateQueries(["GET", "wbs"])
        queryClient.setQueryData(["GET", "wbs/", parsedWbs._id], parsedWbs)
        showSuccessToast(formatMessage("jw-Successfully created WBS"))
        if (onSuccess) {
          onSuccess(parsedWbs)
        }
      },
      onError: (err) => {
        if (err.response.status === 400) {
          showToastError(err.response)
          setErrors(err.response.data)
        } else {
          setErrors([])
          showToastError(err.response)
        }
      }
    }
  )

  return {
    mutation,
    onValueChanged,
    newWbs,
    errors
  }
}
