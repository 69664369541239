import CalendarSubrow from "./CalendarSubrow";

export default function CalendarRow({
  rowModel,
  mainCellComponentFactory,
  slotCellComponentsFactory,
  subrowMainCellComponentFactory,
  subrowSlotCellComponentsFactory,
  subrowModels,
}) {
  return (
    <>
      <tr className='calendar-row'>
        {mainCellComponentFactory && (
          <td>
            {mainCellComponentFactory(rowModel)}
          </td>
        )}
        {slotCellComponentsFactory && slotCellComponentsFactory(rowModel).map((slotCellComponent, index) => (
          <td key={index}>
            {slotCellComponent}
          </td>
        ))}
      </tr>
      {
        (subrowMainCellComponentFactory || subrowSlotCellComponentsFactory) && (subrowModels || []).map((subrowModel, index) => (
          <CalendarSubrow
            key={index}
            rowModel={rowModel}
            subrowModel={subrowModel}
            mainCellComponentFactory={subrowMainCellComponentFactory}
            slotCellComponentsFactory={subrowSlotCellComponentsFactory}
          />
        ))
      }
    </>
  )
}
