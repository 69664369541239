import cx from "classnames"
import React, { Component } from "react"
import { connect } from "react-redux"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const badgeByStatus = (status) => {
  switch (status) {
    case "userCompiling":
      return <div className="badge badge-warning">Pending</div>
    case "preReview":
      return <div className="badge badge-bip">Pre Review</div>
    case "firstReview":
      return <div className="badge badge-bip">First Review</div>
    case "thirdReview":
      return <div className="badge badge-bip">Third Review</div>
    case "ppa":
      return <div className="badge badge-bip">On PPA</div>
    case "onHold":
      return <div className="badge badge-bip">On Hold</div>
    case "closed":
      return <div className="badge badge-success">Processed</div>
    default:
      return <div />
  }
}

class PageTitle extends Component {
  render() {
    let {
      enablePageTitleIcon,
      enablePageTitleSubheading,
      actions,
      beforeActions,
      heading,
      icon,
      subheading,
      timesheetStatus,
      guideRef
    } = this.props

    return (
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className="page-title-before-actions">{beforeActions}</div>
            {icon ? (
              <div
                className={cx("page-title-icon", { "d-none": !enablePageTitleIcon })}
              >
                <i className={icon} color="red" style={{ color: "red" }} />
              </div>
            ) : (
              ""
            )}
            <div>
              {heading}
              <div
                className={cx("page-title-subheading", {
                  "d-none": !enablePageTitleSubheading
                })}
              >
                {subheading}
                {timesheetStatus && badgeByStatus(timesheetStatus)}
              </div>
            </div>
          </div>
          <div className="page-title-actions">{actions}</div>
        </div>
        {guideRef && (
          <div style={{ textAlign: "right", paddingRight: 10 }}>
            <a
              href={guideRef}
              target="_blank"
              rel="noopener noreferrer"
              title="Open Page Guide" // TODO: set translation
            >
              <FontAwesomeIcon icon={faQuestionCircle} size="lg"></FontAwesomeIcon>
            </a>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
  enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading
})

export default connect(mapStateToProps)(PageTitle)
