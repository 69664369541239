import DatePickerInput from "../components/input/DatePickerInput"
import ToggleButtonGroup from "../components/input/ToggleButtonGroup"
import "./Filters.scss"
import ToggleButton from "../components/input/ToggleButton"
import TextInput from "../components/input/TextInput"
import DropdownSelect from "../components/input/DropdownSelect"
import DropdownMultiSelect from "../components/input/DropdownMultipleSelect"
import { formatMessage } from "devextreme/localization"
import { useMemo } from "react"

export default function Filters({
  className,
  filters,
  onFiltersChange,
  areAllResourcesShown,
  onToggleShowAll,
  projects,
  showShowDetails,
  isLoading,
  searchPlaceholder
}) {
  const aggregationOptions = [
    {
      id: "WEEKLY",
      label: formatMessage("preallocation-filters-aggregation-weeks")
    },
    {
      id: "MONTHLY",
      label: formatMessage("preallocation-filters-aggregation-months")
    }
  ]

  const orderOptions = [
    {
      id: "ASC",
      cell: formatMessage("preallocation-filters-order-asc")
    },
    {
      id: "DESC",
      cell: formatMessage("preallocation-filters-order-desc")
    }
  ]

  const projectOptions = useMemo(
    () =>
      (projects || [])
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((project) => {
          const cell = project.fiscalYear?.wbs
            ? `${project.fiscalYear.wbs.code} ${project.name}`
            : project.name
          return {
            id: project.id,
            cell
          }
        }),
    [projects]
  )

  return (
    <div className={`filters-wrapper ${className}`}>
      <ToggleButtonGroup
        label={formatMessage("preallocation-filters-aggregation")}
        options={aggregationOptions}
        value={filters.aggregation}
        onChange={(aggregation) => !isLoading && onFiltersChange({ aggregation })}
        className="mr-3"
      />
      <DatePickerInput
        label={formatMessage("preallocation-filters-date")}
        value={filters.startDate}
        onChange={(startDate) => onFiltersChange({ startDate })}
        style={{ width: "154px" }}
      />
      <div className="flex-fill"></div>

      {showShowDetails && (
        <ToggleButton
          label={formatMessage("preallocation-filters-details")}
          text={
            filters.showDetails
              ? formatMessage("preallocation-filters-hide")
              : formatMessage("preallocation-filters-show")
          }
          icon={
            filters.showDetails
              ? "monade/preallocation/details-hide"
              : "monade/preallocation/details"
          }
          value={filters.showDetails}
          onChange={(showDetails) => !isLoading && onFiltersChange({ showDetails })}
          className="mr-3"
          style={{ width: "98px" }}
        />
      )}
      <ToggleButton
        label={formatMessage("preallocation-filters-resources")}
        text={
          areAllResourcesShown
            ? formatMessage("preallocation-filters-hide-all")
            : formatMessage("preallocation-filters-show-all")
        }
        icon={
          areAllResourcesShown
            ? "monade/preallocation/user-no"
            : "monade/preallocation/users"
        }
        value={areAllResourcesShown}
        onChange={() => !isLoading && onToggleShowAll()}
        className="mr-3"
        style={{ width: "98px" }}
      />
      <TextInput
        label={formatMessage("preallocation-filters-search")}
        value={filters.search}
        onChange={(search) => onFiltersChange({ search })}
        className="mr-3"
        style={{ width: "154px" }}
        placeholder={searchPlaceholder}
      />

      <DropdownSelect
        label={formatMessage("preallocation-filters-order")}
        options={orderOptions}
        value={filters.order}
        onSelected={({ id: order }) => onFiltersChange({ order })}
        className="mr-3"
        style={{ width: "80px" }}
        searchable={false}
      />

      <DropdownMultiSelect
        label={formatMessage("preallocation-filters-projects")}
        options={projectOptions}
        values={filters.jobId}
        onChange={(sel) => onFiltersChange({ jobId: sel.map((e) => e.id) })}
        style={{ width: "200px" }}
      />
    </div>
  )
}
