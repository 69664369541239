import { useMemo } from "react"
import "./ResourceTile.scss"

export const ResourceTile = ({ className, resource, color, showLevel = true }) => {
  const resourceName = useMemo(() => resource.fullName || resource.name, [resource])

  const resourceInitials = useMemo(() => {
    if (!resourceName) return
    const nameArray = resourceName.split(" ")
    const firstName = nameArray[0]
    const lastName = nameArray[nameArray.length - 1]
    return `${firstName[0] || ""}${lastName[0] || ""}`
  }, [resourceName])

  return (
    <div className={`${className} resource-tile text-truncate`}>
      <div className="d-flex align-items-center text-truncate">
        <div
          className="resource-hero"
          style={color ? { backgroundColor: color } : {}}
        >
          <span>{resourceInitials}</span>
        </div>
        <div className="resource-info pl-3 text-truncate flex-fill">
          <h2 className="resource-name text-truncate">{resourceName}</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: ".2rem"
            }}
          >
            {showLevel && (
              <p className="resource-level text-muted text-truncate">
                {resource?.level}
              </p>
            )}
            {new Date(resource.joiningDate) > new Date() ? (
              <span className="resource-hiring-label">[HIRING]</span>
            ) : (
              new Date(resource.leavingDate) < new Date() && (
                <span className="resource-resigned-label">[RESIGNED]</span>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
