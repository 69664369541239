import { faCheck, faFilter } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Chip from "@material-ui/core/Chip"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import merge from "deepmerge"
import { Template } from "devextreme-react/core/template"
import DataGrid, {
  Button,
  Column,
  Editing,
  Export,
  Grouping,
  GroupItem,
  GroupPanel,
  MasterDetail,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Summary,
  Toolbar,
  Item
} from "devextreme-react/data-grid"
import CustomStore from "devextreme/data/custom_store"
import { formatMessage } from "devextreme/localization"
import moment from "moment"
import React, { useState } from "react"
import ReactDOM from "react-dom"
import { useSelector } from "react-redux"
import { find, head } from "underscore"
import { MasterTemplate } from "../../Components/masterTemplate"
import {
  getBadgeStyle,
  getRows,
  isEditable,
  saveRow
} from "../../Utils/tsFlowConfig"
import { Button as DevextremeButton } from "devextreme-react"
import "./style/dx.joreview.table.custom.css"

const reviewCode = "firstReview"

const previousReviewCode = "preReview"

let exported = false

const theme = createMuiTheme({ palette: { primary: { main: "#0c2340" } } })

export const JOTable = React.memo(
  ({
    currentPeriod,
    unitIsHours,
    setOpenAlert,
    setTsId,
    setTsJobId,
    DGRef,
    setOpenAlertAcceptAll,
    setGroupedRowsToApprove,
    setEditRow,
    jobList,
    invalidJobs,
    invalidTimesheets,
    setInvalidTimesheets,
    forceUpdate,
    sendAllJobError,
    setSendAllJobError,
    assignedJobs,
    setAssignedJobs,
    invalidWbsCodes
  }) => {
    const user = useSelector((state) => state.Auth.user)

    const [showCosts, setShowCosts] = useState(() => {
      let unitCache = localStorage.getItem("@jobOwnerShowCost")
      if (unitCache === "true") {
        return true
      } else {
        return false
      }
    })
    const [showOnlyPending, setShowOnlyPending] = useState(false)
    const [showOnlySkipped, setShowOnlySkipped] = useState(false)
    const [showAssigned, setShowAssigned] = useState(true)
    const config = useSelector((state) => state.Auth.config)
    let rows

    const dataSource = {
      store: new CustomStore({
        load: async function() {
          return getRows({
            params: {
              period: currentPeriod._id,
              showAssigned: showAssigned,
              showOnlySkipped: showOnlySkipped,
              showOnlyPending: showOnlyPending
            },
            review: reviewCode
          })
            .then(async ({ data }) => {
              rows = {
                data: data.length
                  ? data.map((j) => {
                      const job = jobList.find(
                        (job) => job._id === j.timesheetRow[reviewCode].code
                      )

                      j.label = job?.isTempPlaced
                        ? `${j.timesheetRow[reviewCode].wbs} (${config.wbsTempCode}) - ${j.timesheetRow[reviewCode].activity}`
                        : `${j.timesheetRow[reviewCode].wbs} - ${j.timesheetRow[reviewCode].activity}`
                      return j
                    })
                  : [],
                totalCount: 0
              }
              return rows
            })
            .catch((e) => {
              throw e
            })
        },
        update: (before, dataToUpdate) => {
          const objToSave = merge(before, dataToUpdate)
          if (unitIsHours) {
            objToSave.timesheetRow[reviewCode].days =
              objToSave.timesheetRow[reviewCode].hours / 8
          } else {
            objToSave.timesheetRow[reviewCode].hours =
              objToSave.timesheetRow[reviewCode].days * 8
          }
          objToSave.timesheetRow[reviewCode].cost =
            objToSave.timesheetRow[reviewCode].hours * objToSave.owner.level.cost

          const { timesheetRow } = objToSave

          return saveRow({
            review: reviewCode,
            params: {
              tsId: objToSave._id,
              _id: timesheetRow._id,
              owner: user._id,
              wbs: timesheetRow[reviewCode].wbs,
              activity: timesheetRow[reviewCode].activity,
              code: timesheetRow[reviewCode].code,
              notes: timesheetRow[reviewCode].notes,
              hours: timesheetRow[reviewCode].hours,
              days: timesheetRow[reviewCode].days,
              description: timesheetRow[reviewCode].description
            }
          })
        }
      })
    }

    const onExported = (e) => {
      e.component.columnOption(0, "groupIndex", 0)
    }

    const onExporting = (e) => {
      e.component.columnOption(0, "groupIndex", null)

      if (exported) return

      e.component.refresh().done(function() {
        exported = true
        e.component.exportToExcel()

        exported = false
      })

      e.cancel = true
    }

    const renderReviewStatus = ({ data }) => {
      const badgeStyle = getBadgeStyle({
        status: data.timesheetRow[reviewCode].status,
        review: reviewCode
      })

      return (
        <div className={`${badgeStyle}`}>{data.timesheetRow[reviewCode].status}</div>
      )
    }

    const renderGroupButtons = (e) => {
      return (
        <div className="d-flex" style={{ alignItems: "center" }}>
          <div
            className="font-icon-wrapper-preter"
            data-toggle="tooltip"
            title={formatMessage("accept-all-hint")}
            onClick={() => {
              // get jobid of row from event data
              console.log(e);
              const jobId = rows.data.find(
                (row) =>
                  row.timesheetRow.firstReview.code ===
                  e.row.data.items[0].timesheetRow.firstReview.code
              ).timesheetRow[reviewCode].code

              // use jobid to filter rows to approve
              setGroupedRowsToApprove(
                rows?.data?.filter(
                  (row) => row.timesheetRow[reviewCode].code === jobId
                )
              )

              setOpenAlertAcceptAll(true)
            }}
          >
            <FontAwesomeIcon icon={faCheck} size="1x" />
          </div>
        </div>
      )
    }

    const onCellPrepared = (e) => {
      if (e.rowType === "group") {
        const isPending = find(
          e.data?.items || e.data?.collapsedItems,
          (row) => row.timesheetRow[reviewCode].status === "started"
        )

        // ADD ICON FOR ACCEPT ALL
        !e.column.caption &&
          head(e.column.buttons)?.icon === "check" &&
          isPending &&
          ReactDOM.render(renderGroupButtons(e), e.cellElement)

        if (e.column.caption === "Status") {
          e.cellElement.childNodes[0].textContent = isPending
            ? "pending"
            : "completed"
          const badgeStyle = getBadgeStyle({
            status: e.summaryItems[0].value,
            review: reviewCode
          })
          e.cellElement.childNodes[0].className = badgeStyle
        }
      } else if (e.rowType === "data") {
        if (
          e.data.timesheetRow[reviewCode].status === "started" &&
          (e.column.dataField === `timesheetRow.${previousReviewCode}.description` ||
            e.column.dataField === `timesheetRow.${previousReviewCode}.hours` ||
            e.column.dataField === `timesheetRow.${previousReviewCode}.days` ||
            e.column.dataField === `timesheetRow.${previousReviewCode}.cost` ||
            e.column.dataField === `timesheetRow.${reviewCode}.cost`)
        ) {
          e.cellElement.style.color = "grey"
        }
      }
    }

    const removeCostFilter = () => {
      setShowCosts(false)
    }

    const removePendingFilter = () => {
      setShowOnlyPending(false)
    }

    const removeSkippedFilter = () => {
      setShowOnlySkipped(false)
    }

    const removeAssignedFilter = () => {
      setShowAssigned(false)
    }

    const showCostCheckbox = () => {
      return (
        <ThemeProvider theme={theme}>
          <Select
            labelId="select-filter"
            id="required"
            value={""}
            displayEmpty
            disabled={
              showCosts && showOnlyPending && showOnlySkipped && showAssigned
            }
            onChange={(e) => {
              switch (e.target.value) {
                case "show-cost":
                  localStorage.setItem("@jobOwnerShowCost", true)
                  setShowCosts(true)
                  return
                case "show-only-pending":
                  setShowAssigned(false)
                  setShowOnlySkipped(false)
                  setShowOnlyPending(true)
                  return
                case "show-only-skipped":
                  setShowOnlySkipped(true)
                  setShowAssigned(false)
                  setShowOnlyPending(false)
                  return
                case "show-assigned":
                  setShowOnlySkipped(false)
                  setShowAssigned(true)
                  setShowOnlyPending(false)
                default:
                  return
              }
            }}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="" disabled>
              <FontAwesomeIcon icon={faFilter} size="1x" />
            </MenuItem>
            {!showAssigned && (
              <MenuItem value={"show-assigned"}>
                {formatMessage("assigned-to-me-filter-label")}
              </MenuItem>
            )}
            {!showCosts && (
              <MenuItem value={"show-cost"}>
                {formatMessage("show-costs-filter-label")}
              </MenuItem>
            )}
            {!showOnlyPending && (
              <MenuItem value={"show-only-pending"}>
                {formatMessage("Status") + ": " + "started"}
              </MenuItem>
            )}
            {!showOnlySkipped && (
              <MenuItem value={"show-only-skipped"}>
                {formatMessage("Status") + ": " + "skipped"}
              </MenuItem>
            )}
          </Select>

          {showAssigned && (
            <Chip
              style={{ marginLeft: 30 }}
              label={formatMessage("assigned-to-me-filter-label")}
              onDelete={removeAssignedFilter}
              color="primary"
              variant="outlined"
            />
          )}
          {showOnlyPending && (
            <Chip
              style={{ marginLeft: 10 }}
              label={formatMessage("Status") + ": " + "started"}
              onDelete={removePendingFilter}
              color="primary"
              variant="outlined"
            />
          )}

          {showCosts && (
            <Chip
              style={{ marginLeft: 30 }}
              label={formatMessage("show-costs-filter-label")}
              onDelete={removeCostFilter}
              color="primary"
              variant="outlined"
            />
          )}
          {showOnlySkipped && (
            <Chip
              style={{ marginLeft: 10 }}
              label={formatMessage("Status") + ": " + "skipped"}
              onDelete={removeSkippedFilter}
              color="primary"
              variant="outlined"
            />
          )}
        </ThemeProvider>
      )
    }

    const onToolbarPreparing = (e) => {
      e.toolbarOptions.items.unshift(
        {
          location: "before",
          template: "showCosts"
        },
        {
          location: "after",
          widget: "dxButton",
          options: {
            icon: "refresh",
            hint: formatMessage("dxDataGrid-commandRefresh"),
            onClick: () => forceUpdate()
          }
        }
      )
    }

    const onEditingStart = (e) => {
      const editable = isEditable({
        status: e.data?.timesheetRow[reviewCode].status,
        review: reviewCode
      })
      e.cancel = !editable
    }

    return (
      <div className="jo-review-table">
        <DataGrid
          dataSource={dataSource}
          showBorders={true}
          onExporting={onExporting}
          onExported={onExported}
          // onToolbarPreparing={onToolbarPreparing}
          onEditingStart={onEditingStart}
          onRowUpdated={() => forceUpdate()}
          onRowRemoved={() => forceUpdate()}
          loadPanel={{
            enabled: true,
            indicatorSrc: `data:image/svg+xml;utf8,${require("./../../../../assets/rolling.svg")}`
          }}
          onCellPrepared={onCellPrepared}
          ref={DGRef}
        >
          <Export
            enabled={true}
            fileName={"review_" + moment().format("YYYYMMDD")}
          />
          <Editing mode="cell" allowUpdating={true} />
          <GroupPanel autoExpandAll={true} />
          <SearchPanel visible={true} />
          <Grouping autoExpandAll={true} />
          <Column
            dataField={`timesheetRow.${reviewCode}.code`}
            caption={formatMessage("Project")}
            allowEditing={false}
            groupIndex={0}
            calculateDisplayValue={(e) => {
              let job = head(
                jobList.filter((j) => j._id == e.timesheetRow[reviewCode].code)
              )

              if (job == null) return e.label

              if (job?.isTempPlaced) {
                return `${job?.wbs.code}${
                  job?.wbs.aliasCode ? ` - ${job?.wbs.aliasCode}` : ""
                } (${config.wbsTempCode}) ${
                  job?.description ? `- ${job.description}` : ""
                }`
              } else {
                return `${job?.wbs.code}${
                  job?.wbs.aliasCode ? ` - ${job?.wbs.aliasCode}` : ""
                } ${job?.description ? `- ${job.description}` : ""}`
              }
            }}
          />
          <Column
            dataField={"owner.displayName"}
            width={180}
            caption={formatMessage("joreview-ts-owner")}
            allowEditing={false}
            calculateDisplayValue={(row) => {
              const isInvalid =
                invalidTimesheets.findIndex((i) => i === row.timesheetRow._id) > -1
              if (
                row.timesheetRow?.firstReview?.status === "started" &&
                invalidJobs?.includes(row.timesheetRow.firstReview.code) &&
                row.timesheetRow[reviewCode].hours > 0 &&
                row.timesheetRow[reviewCode].days > 0 &&
                !isInvalid
              ) {
                invalidTimesheets.push(row.timesheetRow._id)
                setInvalidTimesheets(invalidTimesheets)
                setSendAllJobError(true)
              }

              if (
                row.timesheetRow?.firstReview?.status === "started" &&
                row.timesheetRow?.firstReview?.owner === user.email
              ) {
                if (!assignedJobs.includes(row.timesheetRow.firstReview.code)) {
                  assignedJobs.push(row.timesheetRow.firstReview.code)
                  setAssignedJobs(assignedJobs)
                }
              }

              return row.owner.displayName
            }}
            onClick={(e) => {
              setTsId(e.row.data._id)
              setTsJobId(e.row.data.timesheetRow._id)
              setOpenAlert(true)
            }}
            cellRender={(data) => {
              if (
                data.data?.timesheetRow?.firstReview?.status === "started" &&
                invalidJobs?.includes(data.data.timesheetRow.firstReview.code) &&
                data.data.timesheetRow[reviewCode].hours > 0 &&
                data.data.timesheetRow[reviewCode].days > 0
              ) {
                return (
                  <div>
                    <span style={{ opacity: 0.6 }}>{data.displayValue}</span>
                    <span
                      className="text-danger ml-2 lnr lnr-warning"
                      title={formatMessage("job-disabled-tooltip")}
                    ></span>
                  </div>
                )
              } else {
                return <div>{data.displayValue}</div>
              }
            }}
          />
          <Column
            dataField={`timesheetRow.${reviewCode}.description`}
            caption={formatMessage("Description")}
            allowEditing={false}
          />
          <Column
            dataField={
              unitIsHours
                ? `timesheetRow.${previousReviewCode}.hours`
                : `timesheetRow.${previousReviewCode}.days`
            }
            width={110}
            caption={unitIsHours ? formatMessage("Hours") : formatMessage("Days")}
            alignment={"center"}
            dataType={"number"}
            format={{}}
            editorOptions={{
              showSpinButtons: true,
              format: { type: "fixedPoint", precision: 2 }
            }}
            allowEditing={false}
          />
          {showCosts && (
            <Column
              dataField={`timesheetRow.${previousReviewCode}.cost`}
              caption={formatMessage("Cost")}
              alignment={"center"}
              dataType={"number"}
              format={{ currency: "EUR", precision: 2, type: "currency" }}
              allowEditing={false}
              calculateCellValue={(rowData) => {
                if (rowData.timesheetRow[previousReviewCode])
                  return (
                    rowData.timesheetRow[previousReviewCode].hours * rowData.tsCost
                  )
              }}
            />
          )}
          <Column
            dataField={
              unitIsHours
                ? `timesheetRow.${reviewCode}.hours`
                : `timesheetRow.${reviewCode}.days`
            }
            width={110}
            allowEditing={true}
            caption={
              unitIsHours
                ? formatMessage("reviewed-hours")
                : formatMessage("reviewed-days")
            }
            alignment={"center"}
            dataType={"number"}
            format={{}}
            editorOptions={{
              showSpinButtons: true,
              format: { type: "fixedPoint", precision: 2 },
              min: 0
            }}
          />
          {showCosts && (
            <Column
              dataField={`timesheetRow.${reviewCode}.cost`}
              caption={formatMessage("reviewed-costs")}
              dataType={"number"}
              format={{ currency: "EUR", precision: 2, type: "currency" }}
              allowEditing={false}
              alignment={"center"}
              calculateCellValue={(rowData) =>
                rowData.timesheetRow[reviewCode].hours * rowData.tsCost
              }
            ></Column>
          )}
          <Column
            dataField={`timesheetRow.${reviewCode}.notes`}
            allowEditing={true}
            caption={formatMessage("Notes")}
            alignment={"center"}
            editorOptions={{ maxLength: 120 }}
          >
            <RequiredRule />
          </Column>
          <Column
            dataField={`timesheetRow.${reviewCode}.status`}
            caption={formatMessage("Status")}
            width={120}
            alignment={"center"}
            allowEditing={false}
            cellRender={renderReviewStatus}
          />
          <Column type="buttons" width={70}>
            <Button
              hint={formatMessage("Accept")}
              icon={"check"}
              visible={(e) => {
                return (
                  !e.row.isEditing &&
                  isEditable({
                    status: e.row.data.timesheetRow[reviewCode].status,
                    review: reviewCode
                  })
                )
              }}
              onClick={(e) => {
                setTsId(e.row.data._id)
                setTsJobId(e.row.data.timesheetRow._id)
                setOpenAlert(true)
              }}
            />
            <Button
              hint={formatMessage("edit-details-hint")}
              icon={"edit"}
              visible={(e) => {
                return (
                  !e.row.isEditing &&
                  isEditable({
                    status: e.row.data.timesheetRow[reviewCode].status,
                    review: reviewCode
                  })
                )
              }}
              onClick={(e) => {
                const row = e.row.data
                setEditRow({
                  show: true,
                  data: { label: row.label, ...row }
                })
              }}
            />
          </Column>
          <Summary>
            <GroupItem
              column="timesheetRow.owner"
              summaryType="count"
              displayFormat={"{0} timesheet"}
            />
            {unitIsHours ? (
              <GroupItem
                column={`timesheetRow.${previousReviewCode}.hours`}
                summaryType="sum"
                showInGroupFooter={false}
                valueFormat={{}}
                displayFormat={"{0}"}
                alignByColumn={true}
              />
            ) : (
              <GroupItem
                column={`timesheetRow.${previousReviewCode}.days`}
                summaryType="sum"
                displayFormat={"{0}"}
                valueFormat={{}}
                showInGroupFooter={false}
                alignByColumn={true}
              />
            )}
            {unitIsHours ? (
              <GroupItem
                column={`timesheetRow.${reviewCode}.hours`}
                summaryType="sum"
                showInGroupFooter={false}
                valueFormat={{}}
                displayFormat={"{0}"}
                alignByColumn={true}
              />
            ) : (
              <GroupItem
                column={`timesheetRow.${reviewCode}.days`}
                summaryType="sum"
                displayFormat={"{0}"}
                valueFormat={{}}
                showInGroupFooter={false}
                alignByColumn={true}
              />
            )}
            <GroupItem
              column={`timesheetRow.${reviewCode}.status`}
              summaryType="max"
              displayFormat={"{0}"}
              showInGroupFooter={false}
              name="statusGroup"
              alignByColumn={true}
            />
            {showCosts && (
              <GroupItem
                column={`timesheetRow.${previousReviewCode}.cost`}
                summaryType="sum"
                displayFormat={"{0}"}
                showInGroupFooter={false}
                valueFormat={{
                  type: "currency",
                  precision: 2,
                  currency: "EUR"
                }}
                alignByColumn={true}
              />
            )}
            {showCosts && (
              <GroupItem
                column={`timesheetRow.${reviewCode}.cost`}
                summaryType="sum"
                displayFormat={"{0}"}
                valueFormat={{
                  type: "currency",
                  precision: 2,
                  currency: "EUR"
                }}
                recalculateWhileEditing
                showInGroupFooter={false}
                alignByColumn={true}
              />
            )}
          </Summary>
          <Scrolling mode="virtual" />
          <Template name={"showCosts"} render={showCostCheckbox} />
          <MasterDetail
            enabled={true}
            component={(props) => (
              <MasterTemplate
                unitIsHours={unitIsHours}
                data={props.data}
                invalidWbsCodes={invalidWbsCodes}
              />
            )}
          />
          <Toolbar>
            <Item location="before" name="searchPanel" />
            <Item location="before" name="showCosts" template="showCosts" />
            <Item location="after" name="exportButton" />
            <Item location="after" name="refreshButton">
              <DevextremeButton
                icon="refresh"
                hint={formatMessage("dxDataGrid-commandRefresh")}
                onClick={() => forceUpdate()}
              />
            </Item>
          </Toolbar>
        </DataGrid>
      </div>
    )
  }
)
