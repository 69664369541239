import axios from "axios"
import { apiService } from "./APIServices"

export const exportAllPeriodsReview = () => {
  return axios.get(apiService.query + `/allTimesheet`, {
    responseType: "arraybuffer"
  })
}

export const exportCurrentPeriodReview = (periodId) => {
  console.log(periodId)
  return axios.get(apiService.query + `/timesheetByPeriod/${periodId}`, {
    responseType: "arraybuffer"
  })
}
