import { formatMessage } from "devextreme/localization"
import Icon from "./Icon"
import "./Calendar.scss"
import CalendarRow from "./CalendarRow"
import Button from "./Button"

export default function Calendar({
  className,
  style,
  rowModels,
  getSubrowModelsFn = (x) => x,
  headerMainCellComponentFactory,
  headerSlotCellComponentsFactory,
  mainCellComponentFactory,
  slotCellComponentsFactory,
  subrowMainCellComponentFactory,
  subrowSlotCellComponentsFactory,
  headerSpacing = 0,
  stickyHeader = true,
  isLoading,
  error,
  onReload
}) {
  return (
    <>
      {!isLoading && !error && (
        <table className={`${className}`} style={style}>
          <thead className={stickyHeader && "sticky-header"}>
            <tr>
              {headerMainCellComponentFactory && (
                <td>{headerMainCellComponentFactory(rowModels)}</td>
              )}
              {headerSlotCellComponentsFactory &&
                headerSlotCellComponentsFactory(rowModels)}
            </tr>
            <tr style={{ height: `${headerSpacing}px` }}></tr>
          </thead>
          <tbody>
            {rowModels.map((rowModel, index) => (
              <CalendarRow
                key={index}
                rowModel={rowModel}
                mainCellComponentFactory={mainCellComponentFactory}
                slotCellComponentsFactory={slotCellComponentsFactory}
                subrowMainCellComponentFactory={subrowMainCellComponentFactory}
                subrowSlotCellComponentsFactory={subrowSlotCellComponentsFactory}
                subrowModels={getSubrowModelsFn ? getSubrowModelsFn(rowModel) : []}
              />
            ))}
          </tbody>
        </table>
      )}
      {isLoading && (
        <div className="calendar-spinner-outer">
          <Icon iconRelativePath="monade/spinner" spin />
        </div>
      )}
      {error && (
        <div className="calendar-error-outer">
          <div className="text-center my-4">
            <h5 className="mb-4 d-block">
              {formatMessage("preallocation-projects-error")}
            </h5>
            <Button
              className="btn-primary"
              icon="monade/refresh"
              text={formatMessage("preallocation-projects-retry")}
              onClick={onReload}
            />
          </div>
        </div>
      )}
      {!isLoading && !error && !rowModels?.length && (
        <div className="text-center">
          {formatMessage("preallocation-projects-nodata")}
        </div>
      )}
    </>
  )
}
