import { useEffect, useState } from "react"
import { useAppAbility } from "../../../../../Context/ability"
import { getErrorByField } from "../../../Headcount/Main/Utils/ErrorHandling"
import { useEditWbs } from "../../hooks/useEditWbs"
import { useTable } from "../../hooks/useTable"
import Chevron from "../Chevron"
import { yearColumns } from "../column/yearColumns"
import YearRow from "./YearRow"
import { subject } from "@casl/ability"

export default function WbsRow({
  cells,
  info,
  columnOffsets,
  lastPinnedIndex,
  isFetching,
  initialExpand = false,
}) {

  const { can } = useAppAbility()

  const canManageWbs = can("manage", subject("Wbs", info))

  const [rowEl, setRowEl] = useState(null)

  const [editedWbs, setEditedWbs] = useState(info)
  useEffect(() => {
    setEditedWbs(info)
    resetErrors()
  }, [info])

  const { mutation, errors, resetErrors } = useEditWbs()

  function onWbsRowChanged(val, field) {
    const payload = { ...editedWbs, [field]: val }
    setEditedWbs(payload)

    mutation.mutate(payload)
  }

  const isChargeable = () => editedWbs.type === "1"
  const isActive = () => Boolean(editedWbs?.isActive)

  const [isExpanded, setIsExpanded] = useState(initialExpand)
  useEffect(() => {
    setIsExpanded(initialExpand)
  }, [initialExpand])

  // Year rows
  const [yearsTableData, setYearsTableData] = useState(editedWbs.fiscalYears || [])
  useEffect(() => {
    setYearsTableData(editedWbs.fiscalYears || [])
  }, [editedWbs.fiscalYears])
  const { rows: yearRows } = useTable({
    tableColumns: yearColumns,
    tableData: yearsTableData
  })

  useEffect(() => {
    if (!rowEl || !columnOffsets) {
      return
    }

    let timeoutId = setTimeout(() => {
      for (let index = 0; index < Array.from(rowEl.children).length; index++) {
        if (lastPinnedIndex != null && index <= lastPinnedIndex) {
          rowEl.children[index].className += " position-sticky overflow-none"
          rowEl.children[index].style.left = columnOffsets
            ? columnOffsets[index] + "px"
            : 0
          rowEl.children[index].style.zIndex = "1000"
        } else {
          rowEl.children[index].className = rowEl.children[index].className.replace(
            " position-sticky overflow-none",
            ""
          )
          rowEl.children[index].style.left = null
          rowEl.children[index].style.zIndex = null
        }
      }
    }, 0)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [rowEl, lastPinnedIndex, columnOffsets])

  useEffect(() => {
    if (isFetching) {
      resetErrors()
    }
  }, [isFetching])

  return (
    <>
      <tr
        ref={setRowEl}
        className={`wbs-row position-relative ${
          errors && errors?.length > 0 ? "error-row" : ""
        }`}
      >
        {cells.map((cell, index) => {
          return (
            <td
              className={`text-center text-nowrap px-3 bg-white wbs-row-${cell.id}
                ${index >= 8 && !isChargeable() ? "hide-cell" : ""}`}
              key={cell.id}
            >
              <div className="d-flex align-items-start">
                {cell.id === "code" && (
                  <Chevron
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                    className="mr-2"
                    disabled={!editedWbs.fiscalYears?.length}
                  />
                )}
                <cell.renderCell
                  onChange={(val, field) => onWbsRowChanged(val, field)}
                  error={getErrorByField(errors, cell.id)}
                  canManage={canManageWbs}
                />
              </div>
            </td>
          )
        })}
      </tr>
      {isExpanded &&
        yearRows.map(({ info, cells }, index) => {
          console.log(info)

          return (
            <YearRow
              cells={cells}
              info={info}
              key={index}
              initialExpand={initialExpand}
              isWbsActive={isActive()}
              isChargeable={isChargeable()}
              columnOffsets={columnOffsets}
              lastPinnedIndex={lastPinnedIndex}
            />
          )
        })}
    </>
  )
}
