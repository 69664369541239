import React, { Component } from "react"
import PropTypes from "prop-types"
import "./ToggleSwitch.scss"
import { formatMessage } from "devextreme/localization"

class ToggleSwitch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: props.defaultChecked,
      // Set text for rendering. Default props couldn't be used because they can't be assigned as function result (formatMessage in this case)
      Text:
        props.Text && props.Text.length > 0
          ? props.Text
          : [formatMessage("switch-label-Days"), formatMessage("switch-label-Hours")]
    }
  }

  onChange = (e) => {
    this.setState({
      checked: e.target.checked
    })
    if (typeof this.props.onChange === "function") this.props.onChange()
  }
  render() {
    return (
      <div className={"toggle-switch" + (this.props.Small ? " small-switch" : "")}>
        <input
          type="checkbox"
          name={this.props.Name}
          className="toggle-switch-checkbox"
          id={this.props.id}
          checked={this.props.currentValue}
          defaultChecked={this.props.defaultChecked}
          onChange={this.onChange}
          disabled={this.props.disabled}
        />
        {this.props.id ? (
          <label className="toggle-switch-label" htmlFor={this.props.id}>
            <span
              className={
                this.props.disabled
                  ? "toggle-switch-inner toggle-switch-disabled"
                  : "toggle-switch-inner"
              }
              data-yes={this.state.Text[0]}
              data-no={this.state.Text[1]}
            />
            <span
              className={
                this.props.disabled
                  ? "toggle-switch-switch toggle-switch-disabled"
                  : "toggle-switch-switch"
              }
            />
          </label>
        ) : null}
      </div>
    )
  }
}

ToggleSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  Text: PropTypes.array.isRequired,
  Name: PropTypes.string,
  onChange: PropTypes.func,
  defaultChecked: PropTypes.bool,
  Small: PropTypes.bool,
  currentValue: PropTypes.bool,
  disabled: PropTypes.bool
}

export default ToggleSwitch
