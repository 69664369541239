import moment from "moment"

export const parseJobToData = (job, isChargeable) => {
  const parsedJob = {
    _id: job._id,
    shortCode: job.shortCode,
    description: job.description,
    startDate: moment.utc(moment(job.startDate).format("YYYY-MM-DD")).toDate(),
    endDate: moment.utc(moment(job.endDate).format("YYYY-MM-DD")).toDate(),
    jobOwner: job.jobOwner,
    revenueXtechManagerial: job.revenueXtechManagerial,
    rfpXTech: job.rfpXTech,
    standardCostsXtech: job.standardCostsXtech,
    xTechMargin: job.xTechMargin,
    status: job.status,
    accountable: job.accountable,
    isDescriptionMandatory: job.isDescriptionMandatory,
    specificCompetence: job.specificCompetence,
    subComp: job.subComp,
    mode: job.mode,
    salesResponsible: job.salesResponsible,
    deliveryManager: job.deliveryManager,
    customerRef: job.customerRef,
    customerBusinessFunction: job.customerBusinessFunction,
    fiscalYear: job.fiscalYear,
    jobSpecialties: job.jobSpecialties || [],
    datwavePurchaseOrder: job.datwavePurchaseOrder,
    datwavePurchaseOrderValue: job.datwavePurchaseOrderValue,
    digitalAssets: job.digitalAssets,
    technologyProviders: job.technologyProviders,
    technologyProducts: job.technologyProducts,
    staffingNotes: job.staffingNotes,
    competenceRevenuesPerc: (() => {
      let ret = undefined
      if (job.competenceRevenuesPerc) {
        ret = { ...job.competenceRevenuesPerc }
        Object.keys(ret)?.forEach(
          (competenceRevenue) =>
            (ret[competenceRevenue] = ret[competenceRevenue] / 100)
        )
      }

      return ret
    })()
  }

  if (!isChargeable) {
    delete parsedJob.adaMonetized
    delete parsedJob.contractValue
    delete parsedJob.standardCostsTotal
    delete parsedJob.otherCosts
    delete parsedJob.targetMarginPerc
    delete parsedJob.revenueXtechManagerial
    delete parsedJob.xTechMargin
    delete parsedJob.standardCostsXtech
    delete parsedJob.rfpXTech
    delete parsedJob.subType
    delete parsedJob.finalCustomer
    delete parsedJob.billToCustomer
    delete parsedJob.xTechEffortPerc
    delete parsedJob.isNegative
    delete parsedJob.jobSpecialties
  }

  return parsedJob
}
