import { useEffect, useRef, useState } from "react"
import { formatPrice } from "../../../../../Utils/formatPrice"
import { useOnClickOutside } from "../../hooks/useOnClickOutside"
import { LabelWithError } from "../LabelWithError"
import "./PriceInput.scss"
import "../InputDefaults.scss"
import { FieldErrorIcon } from "../FieldErrorIcon"

export default function PriceInput({
  className,
  inputClassName,
  inputStyles,
  value,
  onChange,
  placeholder = "",
  label,
  readOnly = false,
  disabled = false,
  prefocus = false,
  error = "",
}) {
  const [parsedAmount, setParsedAmount] = useState(formatPrice(value))
  useEffect(() => {
    setParsedAmount(formatPrice(value))
  }, [value])

  const [isEditing, setIsEditing] = useState(false)

  const inputRef = useRef(null)
  const randomId = Math.random().toString()
  const containerRef = useRef(null)

  useOnClickOutside(containerRef, () => {
    setIsEditing(false)
  })

  useEffect(() => {
    if (prefocus) {
      focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isEditing) {
      focus()
    }
  }, [isEditing])

  const focus = () => {
    inputRef.current?.focus()
  }

  const valueChanged = (e) => {
    let input = e.target.value
    if (onChange) {
      if (isNaN(parseFloat(input))) {
        onChange(input)
      } else {
        onChange(parseFloat(input))
      }
    }
  }

  const onBlur = () => {
    setIsEditing(false)
  }

  const onKeyDown = (e) => {
    if (e.key === "-" && e.target.value === "0") e.preventDefault()
    if (!e.target.value) return
    if (e.key === "-") {
      valueChanged({ target: { value: value * -1 } })
    }
  }

  return (
    <div
      onClick={() => {
        if (isEditing) {
          return
        }
        setIsEditing(true)
      }}
      ref={containerRef}
      className={`${className ? className : ""} price-input-container d-flex ${
        label ? "flex-column" : "flex-row align-items-center"
      } ${readOnly ? "readonly" : disabled ? "disabled" : "cursor"}`}
    >
      {label ? (
        <LabelWithError
          label={label}
          error={error}
          htmlFor={randomId}
          labelClassName={`mb-2`}
          readOnly={readOnly}
          disabled={disabled}
        />
      ) : (
        <FieldErrorIcon className="mr-1" message={error} />
      )}
      {isEditing && !readOnly && !disabled ? (
        <input
          className={`w-100 ${inputClassName} ${
            error !== "" ? "border-danger" : ""
          } ${readOnly ? "readonly" : disabled ? "disabled" : "cursor"}`}
          id={randomId}
          style={inputStyles}
          placeholder={placeholder}
          value={value}
          onKeyDown={onKeyDown}
          onChange={valueChanged}
          type="number"
          disabled={disabled || readOnly}
          onBlur={onBlur}
        />
      ) : (
        <input
          className={`w-100 ${inputClassName} ${
            readOnly ? "readonly" : disabled ? "disabled" : "cursor"
          } ${error !== "" ? "border-danger" : ""}`}
          id={randomId}
          style={inputStyles}
          placeholder={placeholder}
          value={parsedAmount}
          onChange={() => {}}
          disabled={disabled || readOnly}
          onFocus={() => {
            setIsEditing(true)
          }}
        />
      )}
    </div>
  )
}
