import { useEffect } from "react"
import { useState } from "react"
import { formatPercentage } from "../../../../../Utils/formatPercentage"

export default function PercentageCell({ info }) {
  const [parsedPercentage, setParsedPercentage] = useState(info)
  useEffect(() => {
    setParsedPercentage(formatPercentage(info))
  }, [info])

  return <span>{parsedPercentage}</span>
}
