import CloneJobButton from "../CloneJobButton"
import DetailsJobButton from "../DetailsJobButton"

export default function JobActionCell({ yearId, job, canManage = false }) {
  return (
    <span className="d-flex flex-row pl-4 ml-3 my-2">
      {canManage && (
        <CloneJobButton yearId={yearId} job={job} className="my-2 mr-2" />
      )}
      <DetailsJobButton
        yearId={yearId}
        jobShortCode={job.shortCode}
        className="my-2"
        canManage={canManage}
      />
    </span>
  )
}
