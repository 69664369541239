import { formatMessage } from "devextreme/localization"
import { useSelectAdbs } from "../hooks/selectHooks/useSelectAdbs"
import "./Filter.scss"
import DropdownSelect from "./input/DropdownSelect"

export default function AdbFilter({ value, setValue, className }) {
  const { adbOptions, isLoading: adbOptionsLoading } = useSelectAdbs()

  return (
    <DropdownSelect
      className={`${className} filter-select`}
      innerLabel={
        <span className="text-muted pr-1">{formatMessage("jw-adb")}:</span>
      }
      value={value || "all"}
      disabled={adbOptionsLoading}
      options={adbOptions}
      placeholder="all"
      onSelected={(option) => {
        setValue(option?.id || null)
      }}
    />
  )
}
