import { formatMessage } from "devextreme/localization"
import { useEffect, useState } from "react"
import { useTable } from "../hooks/useTable"
import { wbsColumns } from "./column/wbsColumns"
import "./JobAndWbsTable.scss"
import FooterRow from "./row/FooterRow"
import HeaderRow from "./row/HeaderRow"
import WbsRow from "./row/WbsRow"
import { Spinner } from "./Spinner"

const parseFooterData = (data) => {
  return data.reduce(
    (reducedFooterData, rowData) => {
      return {
        contractValue:
          (reducedFooterData?.contractValue | 0) + (rowData?.contractValue | 0),
        standardCostsTotal:
          (reducedFooterData?.standardCostsTotal | 0) +
          (rowData?.standardCostsTotal | 0),
        otherCosts: (reducedFooterData?.otherCosts | 0) + (rowData?.otherCosts | 0),
        targetMargin:
          (reducedFooterData?.targetMargin | 0) + (rowData?.targetMargin | 0),
        revenueXtechManagerial:
          (reducedFooterData?.revenueXtechManagerial | 0) +
          (rowData?.revenueXtechManagerial | 0),
        rfpXTech: (reducedFooterData?.rfpXTech | 0) + (rowData?.rfpXTech | 0),
        standardCostsXtech:
          (reducedFooterData?.standardCostsXtech | 0) +
          (rowData?.standardCostsXtech | 0),
        xTechMargin:
          (reducedFooterData?.xTechMargin | 0) + (rowData?.xTechMargin | 0)
      }
    },
    {
      contractValue: 0,
      standardCostsTotal: 0,
      otherCosts: 0,
      targetMargin: 0,
      revenueXtechManagerial: 0,
      rfpXTech: 0,
      standardCostsXtech: 0,
      xTechMargin: 0
    }
  )
}

export default function JobAndWbsTable({
  data,
  loading,
  orderBy,
  setOrderBy,
  sortingOrder,
  setSortingOrder,
  getFilterValue,
  setFilterValue,
  pinnedUntil,
  setPinnedUntil,
  isFetching
}) {
  const [initialExpand, setInitialExpand] = useState(false)
  const [columnOffsets, setColumnOffsets] = useState(null)
  const [lastPinnedIndex, setLastPinnedIndex] = useState(null)

  const { headers, rows } = useTable({
    tableColumns: wbsColumns,
    tableData: data,
    getFilterValue,
    setFilterValue,
    orderBy,
    setOrderBy,
    sortingOrder,
    setSortingOrder,
    initialExpand,
    setInitialExpand,
    pinnedUntil,
    setPinnedUntil
  })

  useEffect(() => {
    const newIndex = headers?.findIndex((column) => column.id === pinnedUntil)
    setLastPinnedIndex(newIndex !== -1 ? newIndex : null)
  }, [headers, pinnedUntil])

  return (
    <div className="job-and-wbs-table-container">
      <table
        className="table-responsive"
        style={{ maxHeight: "calc(100vh - 300px)" }}
      >
        <thead>
          <HeaderRow
            headers={headers}
            columnOffsets={columnOffsets}
            setColumnOffsets={setColumnOffsets}
            tableData={data}
          />
        </thead>
        <tbody>
          {loading && (
            <tr className={"wbs-loading"}>
              <td colSpan={headers.length}>
                <div>
                  <Spinner spanContainerStyles={{ fontSize: "2rem" }} />
                </div>
              </td>
            </tr>
          )}
          {rows.map(({ info, cells }, index) => (
            <WbsRow
              cells={cells}
              info={info}
              key={index}
              initialExpand={initialExpand}
              columnOffsets={columnOffsets}
              lastPinnedIndex={lastPinnedIndex}
              isFetching={isFetching}
            />
          ))}
          {!loading && rows?.length === 0 && (
            <tr className={"wbs-empty"}>
              <td colSpan={headers.length}>
                <div className="p-5 d-flex flex-row justify-content-center align-items-center">
                  <span>{formatMessage("jw-No elements to show.")}</span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <FooterRow
            footerData={parseFooterData(data)}
            columnOffsets={columnOffsets}
            lastPinnedIndex={lastPinnedIndex}
          />
        </tfoot>
      </table>
    </div>
  )
}
