import React, { Fragment, useEffect, useState } from "react"
import { useSelector } from "react-redux"

export default function UserBox() {
  const user = useSelector((state) => state.Auth.user)
  const [photo, setPhoto] = useState(null)

  useEffect(() => {
    const headers = new Headers()
    const bearer = `Bearer ${JSON.parse(sessionStorage.getItem("graphBearer"))}`
    headers.append("Authorization", bearer)

    const options = {
      method: "GET",
      headers: headers
    }

    fetch("https://graph.microsoft.com/v1.0/me/photo/$value", options)
      .then((response) => response.blob())
      .then((r) => {
        setPhoto(URL.createObjectURL(r))
      })
      .catch((error) => console.log(error))
  }, [])

  return (
    <Fragment>
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <img width={42} className="rounded-circle" src={photo} alt="" />
            </div>
            <div className="widget-content-left  ml-3 header-user-info">
              <div className="widget-heading">
                {user.lastName} {user.firstName}
              </div>
              <div className="widget-subheading">{user.currentLevel.name}</div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
