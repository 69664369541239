import moment from "moment";

export const formatDateInterval = (startDate, endDate) => {
  const isMonthSame =
    moment(startDate)
      .utc()
      .month() ===
    moment(endDate)
      .utc()
      .month()
  const formattedStartDate = !startDate ? 'N/A' :
    isMonthSame
      ? moment(startDate).format("D")
      : moment(startDate)
        .format("D MMM")
        .toUpperCase()
  const formattedEndDate = !endDate ? 'N/A' :
    moment(endDate)
      .format("D MMM")
      .toUpperCase()

  return `${formattedStartDate} - ${formattedEndDate}`
}
