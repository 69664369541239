import { roundSecondDecimal } from "./RoundSecondDecimal"

export const calcChargeability = (user) => {
  const currentYear = new Date().getFullYear()

  let W = new Date(currentYear, 12, 31) // 31 december of the current year
  if (user.leavingDate && new Date(user.leavingDate) < W) {
    W = new Date(user.leavingDate)
  }

  let V = new Date(currentYear, 1, 1) // 1 genuary of the current year
  if (user.joiningDate && new Date(user.joiningDate) > V) {
    V = new Date(user.joiningDate)
  }

  let chargeability = (W - V) / (1000 * 3600 * 24) // days between V and W
  chargeability = (chargeability + 1) / 365 // sum one day and divide by 365
  chargeability = roundSecondDecimal(chargeability) // round to second decimal

  return chargeability < 0 ? 0 : chargeability
}

// formula:
// W = [min tra leavingDate e 31 dicembre dell'anno in corso]
// V = [max tra joiningDate e 1 gennaio dell'anno in corso]
// IF  ((W-V+1)/365)<0 ) --> 0
// ELSE --> (W-V+1)/365)
