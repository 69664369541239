import { formatMessage } from "devextreme/localization"
import { withRouter } from "react-router-dom"
import Tooltip from "./common/Tooltip"
import Icon from "./Icon"
import "./InputDefaults.scss"

export default withRouter(function AddYearButton({
  wbs,
  className,
  history,
  disabled = false,
  extended = false,
}) {
  return (
    <Tooltip
      disabled={extended}
      content={
        <div className="text-nowrap">
          + {formatMessage("jw-ADD")} {formatMessage("jw-YEAR")}
        </div>
      }
    >
      <button
        disabled={disabled}
        className={`${
          disabled ? "disabled" : ""
        } btn btn-primary square-btn text-nowrap font-weight-bold ${className}`}
        onClick={() =>
          history.push({
            pathname: `/admin/${wbs._id}/year/create`,
            state: { wbs },
          })
        }
      >
        <Icon iconRelativePath="monade/plus" />{" "}
        {extended ? (
          <span className="ml-1">
            {formatMessage("jw-ADD") + " " + formatMessage("jw-YEAR")}
          </span>
        ) : (
          ""
        )}
      </button>
    </Tooltip>
  )
})
