import "./Filters.scss"
import Button from "../components/Button"
import { formatMessage } from "devextreme/localization"
import DropdownSelect from "../components/input/DropdownSelect"
import { useEffect, useState } from "react"
import DatePickerInput from "../components/input/DatePickerInput"
import NumericInput from "../components/input/NumericInput"
import PercentageInput from "../components/input/PercentageInput"
import { mapToOptions } from "../utils/mapToOptions"
import { getErrorByField } from "../../../Admin/Headcount/Main/Utils/ErrorHandling"
import { useSelector } from "react-redux"
import TextInput from "../components/input/TextInput"

export default function Filters({
  projects,
  filters,
  workingDays,
  levels,
  jobTitles,
  teamLeaders,
  onFiltersChange,
  onResetFilters,
  errors
}) {
  const [projectsOptions, setProjectsOptions] = useState([])
  const [levelsOptions, setLevelsOptions] = useState([])
  const [jobTitlesOptions, setJobTitlesOptions] = useState([])
  const [teamLeadersOptions, setTeamLeadersOptions] = useState([])

  useEffect(() => {
    setProjectsOptions(
      mapToOptions(
        projects,
        (x) => x.id,
        (x) => x.name
      )
    )
  }, [projects])

  useEffect(() => {
    setLevelsOptions(
      mapToOptions(
        levels,
        (x) => x.code,
        (x) => x.name
      )
    )
  }, [levels])

  useEffect(() => {
    setJobTitlesOptions(
      mapToOptions(
        jobTitles,
        (x) => x,
        (x) => x
      )
    )
  }, [jobTitles])

  useEffect(() => {
    setTeamLeadersOptions(
      mapToOptions(
        teamLeaders,
        (x) => x._id,
        (x) => x.name
      )
    )
  }, [teamLeaders])

  const config = useSelector((state) => state.Auth.config)
  const competencesOptions = mapToOptions(
    config.competences,
    (x) => x,
    (x) => x
  )
  const userTypeOptions = [
    {
      id: "INTERNAL",
      cell: formatMessage("preallocation-filters-user-type-internal")
    },
    {
      id: "EXTERNAL",
      cell: formatMessage("preallocation-filters-user-type-external")
    }
  ]

  const calcWorkingDays =
    ((workingDays || 0) * (filters?.timeAllocationPercentage || 0)) / 100

  return (
    <div className="add-resource-filters">
      <div className="add-resource-filters-content">
        <h6>{formatMessage("preallocation-filters-period")}</h6>
        <div className="d-flex mb-3">
          <DatePickerInput
            label={formatMessage("preallocation-filters-start-date")}
            value={filters.startDate}
            onChange={(startDate) => {
              onFiltersChange({ startDate })
            }}
            className="flex-fill"
            error={getErrorByField(errors, "startDate")}
          />
          <NumericInput
            label={formatMessage("preallocation-filters-tolerance")}
            value={filters.startDateTolerance}
            onChange={(e) => onFiltersChange({ startDateTolerance: e.target.value })}
            className="add-resource-filters-input-sm ml-3"
          />
        </div>
        <div className="d-flex mb-3">
          <DatePickerInput
            label={formatMessage("preallocation-filters-end-date")}
            value={filters.endDate}
            onChange={(endDate) => onFiltersChange({ endDate })}
            className="flex-fill"
            error={getErrorByField(errors, "endDate")}
          />
          <NumericInput
            label={formatMessage("preallocation-filters-tolerance")}
            value={filters.endDateTolerance}
            onChange={(e) => onFiltersChange({ endDateTolerance: e.target.value })}
            className="add-resource-filters-input-sm ml-3"
          />
        </div>
        <div className="d-flex">
          <NumericInput
            className="add-resource-filters-input-sm mr-3"
            label={formatMessage("preallocation-filters-working-days")}
            value={calcWorkingDays}
            disabled={true}
          />
          <PercentageInput
            label={formatMessage("preallocation-filters-time-allocation")}
            value={filters.timeAllocationPercentage}
            onChange={(e) =>
              onFiltersChange({
                timeAllocationPercentage: parseFloat(e.target.value)
              })
            }
            className="flex-fill"
            error={getErrorByField(errors, "allocationPercentage")}
          />
        </div>
        <hr />
        <h6>{formatMessage("preallocation-filters-resource-characteristic")}</h6>
        <TextInput
          label={formatMessage("preallocation-filters-search")}
          value={filters.search}
          onChange={(search) => onFiltersChange({ search })}
          className="w-100 mb-3"
          placeholder={formatMessage(
            "preallocation-filters-resources-search-placeholder"
          )}
        />
        <DropdownSelect
          label={formatMessage("preallocation-filters-level")}
          options={levelsOptions}
          value={filters.level}
          onSelected={(e) => onFiltersChange({ level: e?.id })}
          className="w-100 mb-3"
          searchable={false}
        />
        <DropdownSelect
          label={formatMessage("preallocation-filters-job-title")}
          options={jobTitlesOptions}
          value={filters.jobTitle}
          onSelected={(e) => onFiltersChange({ jobTitle: e?.id })}
          className="w-100 mb-3"
          searchable={false}
        />
        <DropdownSelect
          label={formatMessage("preallocation-filters-team-leader")}
          options={teamLeadersOptions}
          value={filters.teamLeader}
          onSelected={(e) => onFiltersChange({ teamLeader: e?.id })}
          className="w-100 mb-3"
          searchable={false}
        />
        <DropdownSelect
          label={formatMessage("preallocation-filters-competence")}
          options={competencesOptions}
          value={filters.competence}
          onSelected={(e) => onFiltersChange({ competence: e?.id })}
          className="w-100 mb-3"
          searchable={false}
        />
        <DropdownSelect
          label={formatMessage("preallocation-filters-user-type")}
          options={userTypeOptions}
          value={filters.userType?.[0]}
          onSelected={(e) => onFiltersChange({ userType: e ? [e?.id] : null })}
          className="w-100"
          searchable={false}
        />
      </div>
      <div className="add-resource-filters-footer mt-4">
        <Button
          className="btn btn-outline-primary"
          text={formatMessage("preallocation-filters-reset")}
          onClick={onResetFilters}
        />
      </div>
    </div>
  )
}
