import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { parseWbsData } from "../../utils/parseWbsData"
import { getAllWbs } from "../../../../../services/WbsService"

export function useSelectWbs() {
  const [expand] = useState(false)
  const [enablePagination] = useState(false)
  const { isLoading, isError, isSuccess, data, error } = useQuery(
    ["GET", "wbs", { expand, enablePagination }],
    async () => {
      const filters = {}
      const sorting = { orderBy: "code", sortingOrder: "DESC" }
      return await getAllWbs(
        expand,
        enablePagination,
        null,
        null,
        filters,
        sorting,
      ).then((data) => {
        return data.data.map((wbs) => parseWbsData(wbs))
      })
    },
  )

  const parseWbsId = (wbsId) => {
    if (isError) {
      return "error"
    }

    if (isLoading) {
      return "-"
    }

    return data.find((wbs) => wbs._id === wbsId)?.code || "-"
  }

  const getWbsAsOptions = () => {
    if (!isSuccess) {
      return []
    }
    return data.map((wbs) => ({
      id: wbs._id,
      cell: wbs.code,
    }))
  }
  const [wbsOptions, setWbsOptions] = useState(getWbsAsOptions())
  useEffect(() => {
    setWbsOptions(getWbsAsOptions())
  }, [data])

  const getActiveWbsAsOptions = () => {
    if (!isSuccess) {
      return []
    }
    return data.map((wbs) => ({
      id: wbs._id,
      cell: wbs.code,
      disabled: !Boolean(wbs.isActive),
      accountable: wbs.accountable,
      chargeable: wbs.chargeable,
      type: wbs.type,
      isFinal: wbs.isFinal,
    }))
  }
  const [wbsOptionsActive, setWbsOptionsActive] = useState([])
  useEffect(() => {
    setWbsOptionsActive(getActiveWbsAsOptions())
  }, [data, isSuccess])

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    parseWbsId,
    wbsOptions,
    wbsOptionsActive,
  }
}
