import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { LabelWithError } from "./LabelWithError"
import "./DatePickerInput.scss"
import "./InputDefaults.scss"
import moment from "moment"

export default function DatePickerInput({
  className,
  style,
  value,
  onChange,
  label,
  inputClassName,
  minDate,
  maxDate,
  error = "",
  readOnly = false,
  disabled = false
}) {
  const randomId = Math.random().toString()

  const change = (event) => {
    if (onChange) {
      const date = event
        ? moment.utc(moment(event).format("YYYY-MM-DD")).toDate()
        : event
      onChange(date)
    }
  }

  return (
    <div
      style={style}
      className={`${className} date-picker-input-container ${
        readOnly ? "readonly" : disabled ? "disabled" : "cursor"
      }`}
    >
      {label ? (
        <LabelWithError
          disabled={disabled}
          readOnly={readOnly}
          label={label}
          error={error}
          labelClassName={`label mb-1`}
          htmlFor={randomId}
        />
      ) : (
        ""
      )}
      <DatePicker
        id={randomId}
        disabled={disabled}
        readOnly={readOnly}
        className={`w-100 ${inputClassName} ${error !== "" ? "border-danger" : ""} ${
          readOnly ? "readonly" : disabled ? "disabled" : "cursor"
        }`}
        dateFormat={"dd/MM/yyyy"}
        selected={value}
        onChange={(date) => change(date)}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  )
}
