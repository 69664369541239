import { formatMessage } from "devextreme/localization"
import Tabs from "react-responsive-tabs"
import PageTitle from "../../../Layout/AppMain/PageTitle"
import ProjectsView from "./ProjectsView"
import ResourcesView from "./ResourcesView"
import { useAppAbility } from "../../../Context/ability"
import "./index.scss"
import AddResource from "./AddResource"
import { exportExcel } from "./services/ExportService"
import { useState } from "react"
import moment from "moment"
import {ExportModal} from "./components/ExportModal";

export default function Preallocation() {
  const { can } = useAppAbility()
  const canReadProjectsView = can("read", "ProjectAllocations")
  const canReadResourcesView = can("read", "ResourceAllocations")
  const canReadAddResourceView = can("read", "AddResources")
  const canExport = can("export", "Allocation")

  const [isExportModalShown, setIsExportModalShown] = useState(false)
  const [loadingExcel, setLoadingExcel] = useState(false)

  const getTabs = () => {
    const tabs = []

    if (canReadProjectsView) {
      tabs.push({
        key: "projectsViewTab",
        title: formatMessage("preallocation-projects-view").toUpperCase(),
        getContent: () => <ProjectsView />
      })
    }

    if (canReadResourcesView) {
      tabs.push({
        key: "resourcesViewTab",
        title: formatMessage("preallocation-resources-view").toUpperCase(),
        getContent: () => <ResourcesView />
      })
    }

    if (canReadAddResourceView) {
      tabs.push({
        key: "addResourcesViewTab",
        title: formatMessage("preallocation-projects-add-resource").toUpperCase(),
        getContent: () => <AddResource />
      })
    }

    return tabs
  }

  const onExportExcel = async (startDate, endDate) => {
    setLoadingExcel(true)
    const response = await exportExcel(startDate, endDate)
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.download =
      "preallocation_export_" + moment().format("YYYYMMDD HH-mm") + ".xlsx"
    link.click()
    setLoadingExcel(false)
  }

  return (
    <div className="app-main__inner">
      <PageTitle
        heading={formatMessage("preallocation-title")}
        subheading=""
        icon="pe-7s-network text-success"
        actions={
          canExport && (
            <button
              className="btn btn-primary"
              onClick={() => setIsExportModalShown(true)}
              disabled={loadingExcel}
            >
              {formatMessage("preallocation-projects-export-allocations")}
            </button>
          )
        }
      />
      <Tabs
        tabsWrapperClass="body-tabs body-tabs-layout"
        panelClass="preallocation-tab-panel"
        transform={false}
        showInkBar={true}
        items={getTabs()}
      />
      <ExportModal
        show={isExportModalShown}
        close={() => setIsExportModalShown(false)}
        onExport={onExportExcel}
      />
    </div>
  )
}
