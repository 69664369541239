import { useEffect, useRef, useState } from "react"
import { formatPrice, priceStringToFloat } from "../../../../../Utils/formatPrice"
import { useOnClickOutside } from "../../hooks/useOnClickOutside"
import Tooltip from "../common/Tooltip"
import "./EditCell.scss"

export default function PriceCellEdit({ info, onChange, width = 70, error }) {
  const [price, setPrice] = useState((info || 0).toString())
  const [parsedAmount, setParsedAmount] = useState(
    formatPrice(parseFloat(info) || 0),
  )

  useEffect(() => {
    setParsedAmount(formatPrice(priceStringToFloat(price || "")))
  }, [price])

  const [isEditing, setIsEditing] = useState(false)
  const containerRef = useRef(null)
  const inputRef = useRef(null)

  useOnClickOutside(containerRef, () => {
    exitEditMode()
  })

  const focus = () => {
    inputRef?.current?.focus()
  }

  useEffect(() => {
    if (isEditing) {
      focus()
    }
  }, [isEditing])

  function onValueChanged(e) {
    setPrice(e.target.value || "")
  }

  const onKeyDown = (e) => {
    if (["tab", "enter"].includes(e.key.toLowerCase())) {
      exitEditMode()
    }

    if (
      e.key.match(/^[^0-9.,]$/) ||
      (e.key.match(/^[.,]$/) && price.toString().match(/[.,]/))
    ) {
      e.preventDefault()
    }
    if (e.key === "-") onValueChanged({ target: { value: (price * -1).toString() } })
  }

  const exitEditMode = () => {
    if (isEditing && price.toString() !== info.toString()) {
      onChange(priceStringToFloat(price))
    }
    setIsEditing(false)
  }

  return (
    <span
      style={{ width: `${width}px` }}
      onClick={() => {
        if (isEditing) {
          return
        }
        setIsEditing(true)
      }}
      ref={containerRef}
    >
      {isEditing ? (
        <input
          ref={inputRef}
          style={{ width: `${width}px` }}
          className="text-center"
          value={price}
          onChange={onValueChanged}
          onKeyDown={onKeyDown}
        />
      ) : error ? (
        <Tooltip content={<div className="text-nowrap">{error}</div>}>
          <span
            style={{ width: `${width}px` }}
            className={`
                text-center
                p-1
                inline-edit-cell__error
              `}
          >
            {parsedAmount}
          </span>
        </Tooltip>
      ) : (
        <span
          style={{ width: `${width}px` }}
          className={`
              text-center
              p-1
              inline-edit-cell
            `}
        >
          {parsedAmount}
        </span>
      )}
    </span>
  )
}
