import { parseFiscalYearData } from "./parseFiscalYearData"

export const parseWbsData = (wbsData) => {
  return {
    _id: wbsData._id,
    code: wbsData.code,
    aliasCode: wbsData.aliasCode,
    status: wbsData.status,
    isActive: wbsData.isActive,
    adb: wbsData.adb,
    type: wbsData.type,
    contractValue: wbsData.contractValue,
    standardCostsTotal: wbsData.standardCostsTotal,
    otherCosts: wbsData.otherCosts,
    targetMargin: wbsData.targetMargin,
    targetMarginPerc: wbsData.targetMarginPerc * 100,
    revenueXtechManagerial: wbsData.revenueXtechManagerial,
    rfpXTech: wbsData.rfpXTech,
    standardCostsXtech: wbsData.standardCostsXtech,
    xTechEffortPerc: wbsData.xTechEffortPerc * 100,
    xTechMargin: wbsData.xTechMargin,
    isFinal: wbsData.isFinal,
    isTempPlaced: wbsData.isTempPlaced,
    description: wbsData.description,
    subType: wbsData.subType,
    accountable: wbsData.accountable,
    area: wbsData.area,
    billToCustomer: wbsData.billToCustomer,
    finalCustomer: wbsData.finalCustomer,
    deputyAccountables: wbsData.deputyAccountables,
    internalSupporters: wbsData.internalSupporters,
    externalSupporters: wbsData.externalSupporters,
    hasExternalSupport: wbsData.hasExternalSupport,
    fiscalYears:
      wbsData.fiscalYears?.map((fiscalYear) => parseFiscalYearData(fiscalYear)) || []
  }
}
