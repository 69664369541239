import React, { version } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

const changelogList = [
  {
    version: "3.5.0",
    release_date: "09/08/2024",
    logs: [
      "Added in Job Admin section datwave purchasing order and purchasing value fields",
      "Added in Job Admin section internal supporters can now create wbs",
      "Changed completed timesheets missing review reminder recipient"
    ]
  },
  {
    version: "3.4.0",
    release_date: "19/06/2024",
    logs: [
      "Removed possibility to manually add P-XTCHG in a timesheet",
      "Added Area supporters for the management of the Job Admin Section",
      "Added export button in Missing Timesheet section"
    ]
  },
  {
    version: "3.3.0",
    release_date: "08/04/2024",
    logs: [
      "Added new Area field for WBS",
      "Fixed row approval in Job Review section",
      "Fixed error on user edit in Headcount section due to closed period",
      "Changed in Headcount section the user'email is now optional for team users",
      "Changed in Headcount section the user's review reader is now allowed only for external users"
    ]
  },
  {
    version: "3.2.0",
    release_date: "12/03/2024",
    logs: [
      "Changed Job Admin section to increase Wbs Alias code maximum length",
      "Fixed minor bugs in PreTer Review process",
      "Changed report email format"
    ]
  },
  {
    version: "3.1.0",
    release_date: "30/01/2024",
    logs: [
      "Changed Job Admin section to adopt new wbs code and keep an alias code for old wbs",
      "Changed Review section to adopt new wbs code and keep an alias code for old wbs",
      "Changed Preallocation section to adopt new wbs code and keep an alias code for old wbs"
    ]
  },
  {
    version: "3.0",
    release_date: "13/11/2023",
    logs: [
      "Added PreAllocation module",
      "Changed in Job Admin section Delivery Manager is now mandatory for jobs"
    ]
  },
  {
    version: "2.23",
    release_date: "19/09/2023",
    logs: [
      "Added new Appraisal section",
      "Added in Job Admin section Job Specialties field",
      "Fixed permission issues on review pages",
      "Fixed in Review section SEND ALL button not working",
      "Changed in Fiscal Year Admin section revenues managerial calculation logics",
      "Changed in Fiscal Year Admin section forceDC value type",
      "Changed in Review section in order to have a pre-closed stage for timesheets",
      "Removed in Job Admin section ADA Enabled, ADA Monetized and Ada Biz Dev Partners fields"
    ]
  },
  {
    version: "2.22",
    release_date: "29/08/2023",
    logs: [
      "Added in Organization Admin section Deputy Area Mangers for organizations of type Area",
      "Added in Missing Timesheet section the possibility to compile timesheets for future periods",
      "Added in Profile section organizational informations about logged in user",
      "Added in Headcount section the Team Account flag for users",
      "Added in Review section a red color to highlight a wbs causing the status onHold on a timesheet",
      "Fixed in Job Admin section year's standard costs can be lower than job's standard costs",
      "Fixed in Review section area Managers cannot see a deputy team member's timesheet"
    ]
  },
  {
    version: "2.21",
    release_date: "22/05/2023",
    logs: [
      "Fixed in Review section the switch hours/days not properly working in certain scenarios.",
      "Added in Review section the PPA step on row's details.",
      "Remvoed in Review section the Second Review stage.",
      "Added a daily reminder for line managers with the list of users who have not submitted their timesheet."
    ]
  },
  {
    version: "2.20",
    release_date: "12/04/2023",
    logs: [
      "Changed Job Admin in order to have revenues and costs separated by fiscal year",
      "Changed onHold logic in order to close automatically all the pending timesheets at the end of the review period",
      "Changed in Job Admin non-chargeable wbs must be always final",
      "Added in Job Admin non-chargeable wbs must be always final",
      "Added in Timesheet section a link to the user guide",
      "Added in Review section a link to the user guide",
      "Added in Review section links to each user Teams account",
      "Added in Job Review section a link to the user guide"
    ]
  },
  {
    version: "2.19",
    release_date: "18/07/2022",
    logs: [
      "Changed in Review in order to have acronyms for job types instead of full names",
      "Changed Job model in order to consider a job active using the start and end dates",
      "Changed in Headcount section users whose email domain is different from mail-bip.com can be registered",
      "Created new Missing Timesheet Manager role",
      "Fixed Job Admin required field validation"
    ]
  },
  {
    version: "2.18",
    release_date: "20/06/2022",
    logs: [
      "Added management of Job and Wbs with negative values",
      "Added automatic permission assignment for job owners",
      "Added description and notes in Timesheet Export All",
      "Changed Organization model in order to have different Areas for Teams with the same Team Leader",
      "Changed text and label color for the feature period in the period menu",
      "Fixed timesheet remaining on hold after PPA"
    ]
  },
  {
    version: "2.17",
    release_date: "19/05/2022",
    logs: [
      "Added in Timesheet section the feature to send a timesheet for the upcoming period",
      "Added in Review section the feature to approve reviews for the upcoming period",
      "Changed in Headcount section now users can be directly created enabled",
      "Added Missing Timesheet section to send timesheets in place of team members",
      "Changed in Headcount section permissions are now assigned automatically to user based on their role",
      "Fixed in Job Review section some jobs are not visible in recap for job owners",
      "Fixed in Job Review section some prereview costs were not shown",
      "Fixed in Review section time unit switch not properly working right after table refresh",
      "Changed in Job Admin job owner filter is now a text field",
      "Changed in Job Admin only enabled users can be set as job owner"
    ]
  },
  {
    version: "2.16",
    release_date: "19/04/2022",
    logs: [
      "Added in Job Review section Area Manager view",
      "Added in Job Review section bulk approval for all assigned reviews",
      "Added in Review section bulk approval for all assigned reviews",
      "Added in Timesheet Export All full name, competence, team leader and sial code fields ",
      "Changed in Timesheet Export All organization, level and area set to timesheet compilation time values",
      "Changed in Review section label Waiting for Wbs showing only for relevant rows",
      "Changed Job Review section UI style",
      "Changed Review section UI style",
      "Changed in Timesheet and Review sections periods limited to 12 months",
      "Changed in Review and Timesheet sections Periods sorting to descending order",
      "Fixed in Job Review Section hours label not showing if hours are set to 0",
      "Fixed in Job Admin default order not being by wbs code",
      "Fixed in Headcount Sial Code always empty for all users",
      "Fixed auth token not being refreshed after expiration time"
    ]
  },
  {
    version: "2.15.1",
    release_date: "22/03/2022",
    logs: [
      "Added in Headcount read-only user Team field",
      "Added in Headcount Circle and Global Competence fields",
      "Changed in Headcount renamed Community field to Competence",
      "Changed in Job & Wbs renamed Community Revenues to Competence Revenues"
    ]
  },
  {
    version: "2.15.0",
    release_date: "14/03/2022",
    logs: [
      "Added wbs TMP flag management",
      "Added in Timesheet section current job status validation before sending the timesheet",
      "Added in Review and Job Review sections current job status validation before sending the review",
      "Added in Timesheet section refresh button",
      "Added in Review section spinner for timesheet Export All",
      "Added in timesheet Export All wbs flags data",
      "Changed onHold logic according to the new TMP flag",
      "Changed in Job & Wbs job owner is now mandatory for chargable jobs",
      "Fixed in Review Section refresh button not updating job data",
      "Fixed in Job Review Section Recap data erroneously showing cost as 0 in some records",
      "Fixed in Job Review Section reviewed cost value miscalculated in some cases"
    ]
  },
  {
    version: "2.14.0",
    release_date: "1/03/2022",
    logs: [
      "Added in admin sections clear filters button",
      "Added wbs final status management",
      "Added in Job & Wbs job read-only final flag that indicates whether the related wbs is final or not",
      "Changed TBD and onHold logic according to the new wbs final status",
      "Changed in Job & Wbs wbs code is now always editable",
      "Changed in Job & Wbs job cannot be recreated with the same wbs and description after its removal",
      "Fixed in Headcount user form not being cleaned after closing",
      "Fixed in Job & Wbs table footer rendering defect",
      "Fixed Headcount warning bug on deleting current level of a disabled user",
      "Fixed in Review Section job labels are showed correctly after job removal",
      "Fixed in Job & Wbs missing visibility permission control for clone button"
    ]
  },
  {
    version: "2.13.0",
    release_date: "15/02/2022",
    logs: [
      "Added Language internationalization",
      "Added Headcount viewer capability",
      "Added FAQs and Support section",
      "Added in Headcount level promotion management",
      "Added in Headcount filter by column",
      "Added in Job & Wbs accounting data for TBD jobs",
      "Added in Job & Wbs job and wbs clone feature",
      "Added in Job & Wbs job validation start date and end date must have the same year",
      "Added in Organization the possibility to remove the organization owner for empty organizations",
      "Added in Job & Wbs code is editable for inactive wbs",
      "Added jobs mandatory description property can be defined for jobs not in 'P' and 'I' category",
      "Changed in Headcount from hourly cost to daily cost",
      "Changed timespan for timesheet Export All is now configurable",
      "Changed timesheet Export All columns",
      "Fixed in Job & Wbs ordering by wbs not working correctly",
      "Fixed in Organization the owner not being displayed in edit mode",
      "Fixed in Review Section job labels are now consistent with the updated job info",
      "Fixed in Review Section job labels are showed correctly after job deactivation",
      "Fixed in all Admin sections dates ISO alignment",
      "Fixed in Headcount review reader not being nullable"
    ]
  },
  {
    version: "2.12.0",
    release_date: "28/12/2021",
    logs: [
      "Added Master Data management",
      "Added job admin access to Area Managers",
      "Fixed review filters refresh loop"
    ]
  },
  {
    version: "2.11.0",
    release_date: "18/10/2021",
    logs: [
      "Added review status filters in review section",
      "Added inCharge and status filters in Job Review section",
      "Changed export all limiting data to an year and added cost's info",
      "Changed job or organization reassignment behaviour: in charge filter and notifications will work accordingly",
      "Fixed description mandatory check when changing job during review",
      "Fixed job type chips max length shortened and excluded 0 hours",
      "Fixed second review erroneously skipped when changes occurred in first review",
      "Fixed skip third review when no changes occurred in second review",
      "Fixed Period working hours not editable"
    ]
  },
  {
    version: "2.10.0",
    release_date: "02/08/2021",
    logs: [
      "Changed cancelling row during prereview sets hours to 0",
      "Added AM can do PPA of its own timesheet",
      "Added Job Owners can change job during first review",
      "Fixed Recap section reflects current job description"
    ]
  },
  {
    version: "2.9.0",
    release_date: "08/07/2021",
    logs: [
      "Exclude 0h row",
      "Fixed Timesheet not updated on submitter view",
      "Fixed Not updating actual reviewer when locking review",
      "Fixed Temp hours not updating when deleting rows"
    ]
  },
  {
    version: "2.8.0",
    release_date: "24/05/2021",
    logs: ["Added externals review reading"]
  },
  {
    version: "2.7.0",
    release_date: "10/05/2021",
    logs: [
      "Added deputy Team Leaders management",
      "Changed Job model adding WBS entity",
      "Fixed row deletion in timesheet compilation",
      "Added organizations deletion in Organization admin page",
      "Fixed job owner review current period was not listed",
      "Fixed export all data 'teams' field not filled"
    ]
  },
  {
    version: "2.6.0",
    release_date: "12/04/2021",
    logs: [
      "Added ADMIN role management",
      "Added Job view for AM and Job Owner",
      "Added display review job hours grouped by job type",
      "Added Recap View for job owner"
    ]
  },
  {
    version: "2.5.0",
    release_date: "18/03/2021",
    logs: [
      "Added PPA feature for AM and COEL",
      "Added filter skipped in JO review for skipped jobs",
      "Added search job by customer in timesheet compiling",
      "Added bulk approval Job Owner Review",
      "Added pending reviews and missing timesheet report email"
    ]
  },
  {
    version: "2.4.0",
    release_date: "17/02/2021",
    logs: [
      "Changed Job data model to integrate projects",
      "Added jobs grouped by type/customer in timesheet compiling section",
      "Added 'On Hold' Timesheet status until all jobs wbs are defined",
      "Changed Timesheet User Report email grouping rows by jobs wbs",
      "Added skip JO Review for jobs whose owners are the ts owner themselves and there were no changes in Pre-review",
      "Changed label 'Sent' instead of 'Skipped' in Job Owner Review Period drop-down",
      "Fixed Job Onwer Review keeps track also of skipped jobs",
      "Fixed skip Second Review if the timesheet only has jobs owned by the same person that did the Pre-Review"
    ]
  },
  {
    version: "2.3.0",
    release_date: "14/12/2020",
    logs: [
      "Added skip Pre-Review for COE Leader",
      "Added skip JO Review for jobs whose owners are the same that did the Pre-Review",
      "Added skip JO Review for jobs whose owner is the timesheet owner itself",
      "Added skip Second Review if no changes have been made in Pre-Review",
      "Added skip Second Review if the timesheet only has jobs owned by the same person that did the Pre-Review"
    ]
  },
  {
    version: "2.2.1",
    release_date: "26/11/2020",
    logs: ["Fixed job update in timesheet"]
  },
  {
    version: "2.2.0",
    release_date: "18/11/2020",
    logs: [
      "Added link to support in footer",
      "Fixed owner field incorrectly editable in JO Review phase",
      "Added timesheet status check for TM in timesheet section",
      "Added data export function in admin section for headcount/organization/job tables",
      "Fixed float point numbers of day/hour",
      "Fixed scrollable navigation bar",
      "Added highlight job when edited in Pre-Review phase"
    ]
  },
  {
    version: "2.1.0",
    release_date: "13/10/2020",
    logs: [
      "Changed jobs drop-down visualization: the list is now sorted alphabetically",
      "Fixed uncorrect management of days/hour field with more than 3 decimal places",
      "Changed usernames format: they are now displayed as surname + first name",
      "Changed review section visualization: the rows are now sorted alphabetically by the user's last name",
      "Changed JO Review section visualization: the rows are now sorted alphabetically by job code",
      'Added "Export all data" button, in the review section, to export all PreTER historical data',
      'Added direct link to period details in "missing review report" email',
      "Added automatic leaving date management in admin section",
      "Fixed current period not displayed in the drop-down list"
    ]
  },
  {
    version: "2.0.1",
    release_date: "25/09/2020",
    logs: ["Fixed hours and days in some rare circumstances not aligned"]
  }
]

export const Changelog = ({ showCl, closeCl }) => {
  return (
    <span className="d-inline-block mb-2 mr-2">
      <Modal isOpen={showCl} toggle={closeCl}>
        <ModalHeader>Changelog</ModalHeader>
        {changelogList.map((i, index) => {
          return (
            <ModalBody key={index}>
              <b>
                {i.version} ({i.release_date})
              </b>
              <br />
              {i.logs.map((log, lIndex) => {
                return <li key={lIndex}>{log}</li>
              })}
            </ModalBody>
          )
        })}
        <ModalFooter>
          <Button color="primary" onClick={closeCl}>
            Close
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </span>
  )
}
