const questions = [
  {
    id: 1,
    title: "Q.1 Has my timesheet been processed?",
    text:
      'You can check the status of your timsheet in the Timesheet section, the label on the top describes the current status.\
      The label "PROCESSED" means that your timesheet has been reviewed and closed. "PRE REVIEW", "FIRST REVIEW", "SECOND REVIEW" and "THIRD REVIEW" labels indicates that the timesheet is being evaluated in one of the corresponding review phase.\
      If your timesheet has been reopened after the review process, the label "ON PPA" will be displayed until its closure. Lastly, if your timesheet has one ore more jobs with no WBS defined, then it will be put "ON HOLD" until their resolution.\
      Once the review process is completed, you will receive a report email.'
  },
  {
    id: 2,
    title: "Q.2 I need to apply changes on my timesheet but I have already sent it",
    text:
      'If the review process is still ongoing (status is not "PROCESSED")(Q.1), your leader can change your timesheet. If the review process is already finished (status is "PROCESSED"), you can ask your Area Manager to apply the change in PPA.'
  },
  {
    id: 3,
    title: "Q.3 I have not recevied the report email",
    text:
      "Make sure that the review process is actually completed (Q.1). Check your spam inbox folder."
  },
  {
    id: 4,
    title: "Q.4 I can't find/select a job",
    text:
      "WBS/Job can be created/enabled only by Area Managers. Please, ask them directly to resolve this issue.",
  },
  {
    id: 5,
    title: "Q.5 I don't have access to the Timesheet section",
    text: "You can report this kind of issue on the Teams channel.",
    link:
      "https://teams.microsoft.com/l/channel/19%3a4b8496285d7a4ee9bf3172842d2c8dab%40thread.tacv2/PreTER%2520live%2520support?groupId=21ea7a0f-4722-4007-bec0-f2418d4a49d7&tenantId=bb1a63eb-eb09-471a-a005-37b07792a5b5"
  },
  {
    id: 6,
    title: "Q.6 A member of my team can't access Preter/Timesheet Section",
    text: "See Q.5"
  },
  {
    id: 7,
    title: "Q.7 Can I send my timesheet with more/less hours than are indicated?",
    text:
      "Yes, you can always send the timesheet. However, if the hours/days indicator doesn't match with the working days of your location, you can report this issue on the Teams channel.",
    link:
      "https://teams.microsoft.com/l/channel/19%3a4b8496285d7a4ee9bf3172842d2c8dab%40thread.tacv2/PreTER%2520live%2520support?groupId=21ea7a0f-4722-4007-bec0-f2418d4a49d7&tenantId=bb1a63eb-eb09-471a-a005-37b07792a5b5"
  },
  {
    id: 8,
    title: "Q.8 Hours/Days indicator doesn't match working days amount",
    text:
      "Working days total depends on your working location. Check your data in your profile section and if there is any inaccuracy, please report it on the Teams channel. (Q.7)"
  }
]
export default questions
