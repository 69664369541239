import { Tooltip } from "@material-ui/core"
import { formatMessage } from "devextreme/localization"
import moment from "moment"
import queryString from "query-string"
import React, { Fragment, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import Tabs from "react-responsive-tabs"
import { Button } from "reactstrap"
import { useAppAbility } from "../../../Context/ability"
import PageTitle from "../../../Layout/AppMain/PageTitle"
import { setSelectedPeriod } from "../../../reducers/Period"
import { PeriodDropDown } from "../Components/periodDropDown"
import AMView from "./amView"
import JoRecap from "./recap/"
import JOR from "./review/"
import { ExternalGuideRefs } from "../../../Common/guide/guideExternalRefs"

export default function JOReview(props) {
  const dispatch = useDispatch()

  const [sendAllJobAlert, setSendAllJobAlert] = useState(false)
  const [sendAllVisible, setSendAllVisible] = useState(true)

  const { can } = useAppAbility()
  const canReadJobOwnerReview = can("read", "JobOwnerReview")
  const canReadAreaManagerView = can("read", "AreaManagerView")

  useEffect(() => {
    const parsed = queryString.parse(props.location.search)
    if (parsed.period) {
      dispatch(
        setSelectedPeriod({
          _id: parsed.period,
          label: moment(parsed.period, "YYYYMM").format("MMMM YY"),
          calendarStartDate: parsed.period.calendarStartDate,
          calendarEndDate: parsed.period.calendarEndDate
        })
      )
    }
  }, [])

  const getTabs = () => {
    const tabs = []

    if (canReadJobOwnerReview) {
      tabs.push(
        {
          key: "reviewTab",
          title: formatMessage("joreview-review"),
          getContent: () => (
            <JOR
              sendAllJobAlert={sendAllJobAlert}
              setSendAllJobAlert={setSendAllJobAlert}
            />
          )
        },
        {
          key: "recapTab",
          title: formatMessage("joreview-recap"),
          getContent: () => <JoRecap />
        }
      )
    } else {
      setSendAllVisible(false)
    }

    if (canReadAreaManagerView) {
      tabs.push({
        key: "amViewTab",
        title: formatMessage("area-manager-view"),
        getContent: () => <AMView />
      })
    }

    return tabs
  }

  return (
    <Fragment>
      <PageTitle
        heading={formatMessage("Job-Owner")}
        subheading=""
        icon="pe-7s-cash text-success"
        actions={
          <Fragment>
            <PeriodDropDown review="anyReview" />

            {sendAllVisible && (
              <>
                <Tooltip
                  title={formatMessage("send-all-job-review-hint")}
                  arrow={true}
                >
                  <Button
                    id="sendAllButton"
                    color="dark"
                    onClick={() => setSendAllJobAlert(true)}
                  >
                    {formatMessage("send-all-job-review").toUpperCase()}
                  </Button>
                </Tooltip>
              </>
            )}
          </Fragment>
        }
        guideRef={ExternalGuideRefs.JOB_REVIEW}
      />
      <Tabs
        tabsWrapperClass="body-tabs body-tabs-layout"
        transform={false}
        showInkBar={true}
        onChange={(e) => {
          setSendAllVisible(e === "reviewTab")
        }}
        items={getTabs()}
      />
    </Fragment>
  )
}
