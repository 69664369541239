import { useEffect, useState, useCallback } from "react"

export const useInfiniteScroll = ({ onLoadMore, scrollableRef, yOffset = 50 }) => {
  const [isLoading, setIsLoading] = useState(false)

  const loadMoreIfNeeded = useCallback(
    async (ref) => {
      if (isLoading) {
        return
      }

      const { scrollTop, scrollHeight, clientHeight } = ref
      if (scrollTop + clientHeight > scrollHeight - yOffset) {
        setIsLoading(true)
        await onLoadMore()
        setIsLoading(false)
      }
    },
    [yOffset, onLoadMore, isLoading]
  )

  useEffect(() => {
    const ref = scrollableRef?.current || document
    const onScroll = async (e) => {
      loadMoreIfNeeded(scrollableRef?.current || e.target.scrollingElement)
    }
    ref.addEventListener("scroll", onScroll)

    loadMoreIfNeeded(ref)

    return () => {
      ref.removeEventListener("scroll", onScroll)
    }
  }, [scrollableRef, loadMoreIfNeeded])

  return isLoading
}
