import {LabelWithError} from "./LabelWithError";
import Icon from "../Icon"
import "./ToggleButton.scss"
import Button from "../Button";

export default function ToggleButton({
  className,
  style,
  value,
  onChange,
  label,
  text,
  icon,
  readOnly,
  disabled
}) {
  const randomId = Math.random().toString();

  return (
    <div className={`toggle-btn-wrapper ${className}`} style={style}>
      {label ? (
        <LabelWithError
          disabled={disabled}
          readOnly={readOnly}
          label={label}
          labelClassName={`mb-1`}
          error={''}
          htmlFor={randomId}
        />
      ) : (
        ""
      )}
      <Button
        className={`w-100 toggle-btn ${ value ? 'btn-primary' : 'btn-secondary' }`}
        text={text}
        icon={icon}
        id={randomId}
        disabled={disabled}
        onClick={ () => onChange(!value) }
      />
    </div>
  )
}
