import React from "react"
import Dino from "./../Dino"

export default function ServerError({ textMessage }) {
  return (
    <div>
      <h2 style={{ textAlign: "center", marginTop: 100, whiteSpace: "pre-line" }}>
        {textMessage ? textMessage : "Oops. Something went wrong"}
      </h2>
      <Dino />
    </div>
  )
}
