import "bootstrap/dist/css/bootstrap.min.css"
import { formatMessage } from "devextreme/localization"
import { Fragment } from "react"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import { Card, CardBody, Row, Col } from "reactstrap"

function WildCardTable(data) {
  return (
    <Fragment>
      <div className="mb-3">
        {data.lineManagerGoal && Object.keys(data.lineManagerGoal).length > 0 ? (
          <Row>
            <Col className="d-flex flex-row justify-content-start col-2">
              <p className="mb-0">
                <em>{formatMessage("appraisal-manager-goal-label")}</em>
              </p>
            </Col>
            <Col className="d-flex flex-row justify-content-start col-7 text-break">
              <p className="mb-0">
                <em>{data.lineManagerGoal.description}</em>
              </p>
            </Col>
            <Col className="d-flex flex-row justify-content-start col-1">
              <p className="mb-0">{data.lineManagerGoal.KPO}</p>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {data.userGoal && Object.keys(data.userGoal).length > 0 ? (
          <Row>
            <Col className="d-flex flex-row justify-content-start col-2">
              <p className="mb-0">{formatMessage("appraisal-user-goal-label")}</p>
            </Col>
            <Col className="d-flex flex-row justify-content-start col-7 text-break">
              <p className="mb-0">{data.userGoal.description}</p>
            </Col>
            <Col className="d-flex flex-row justify-content-start col-1">
              <p className="mb-0">{data.userGoal.KPO}</p>
            </Col>{" "}
            <Col className="d-flex flex-row justify-content-start col-1">
              <p className="mb-0">
                {data.userGoal.KPI ? `${data.userGoal.KPI}%` : ""}
              </p>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </div>
    </Fragment>
  )
}

function WildCard(data, index) {
  return (
    <Fragment>
      <Card className="main-card mb-3 table-responsive">
        <CardBody>
          <h5 style={{ color: "#033c73" }}>
            <strong>{data.challenge}</strong>
          </h5>
          {data.goals && data.goals.length > 0 ? (
            <Row className="pb-1">
              <Col className="d-flex flex-row justify-content-start col-2">
                <h6 className="mb-1">
                  <strong>{formatMessage("appraisal-th-goal")}</strong>
                </h6>
              </Col>
              <Col className="d-flex flex-row justify-content-start col-7"></Col>
              <Col className="d-flex flex-row justify-content-start col-1">
                <h6 className="mb-1">
                  <strong>{formatMessage("appraisal-th-KPO")}</strong>
                </h6>
              </Col>
              <Col className="d-flex flex-row justify-content-start col-1">
                <h6 className="mb-1">
                  <strong>{formatMessage("appraisal-th-KPI")}</strong>
                </h6>
              </Col>
            </Row>
          ) : (
            <Row className="pb-1">
              <Col className="d-flex flex-row justify-content-center col-12">
                <h6>
                  <strong>{formatMessage("appraisal-no-goal-alert")}</strong>
                </h6>
              </Col>
            </Row>
          )}
          {data.goals && data.goals.length > 0 ? (
            data.goals.map((goal) => WildCardTable(goal))
          ) : (
            <></>
          )}
        </CardBody>
      </Card>
    </Fragment>
  )
}

export default function TabContent(props) {
  const { data, tabName, index } = props

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component={"div"}
        transitionName={"TabsAnimation"}
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        {data.map((data) => WildCard(data))}
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
