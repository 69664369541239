import React, { Fragment, useState, useEffect } from "react"
import { Card, CardBody, CardTitle, Col, Form, Row } from "reactstrap"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import ToggleSwitch from "../../../assets/components/switch/ToggleSwitch"
import { ExternalReviewTable } from "./reviewTable"
import { useSelector } from "react-redux"
import { getAllWbs } from "../../../services"

export default function ExternalReview({ currentPeriod }) {
  const [unitIsHours, setUnitIsHours] = useState(true)

  const config = useSelector((state) => state.Auth.config)

  const [invalidWbsCodes, setInvalidWbsCodes] = useState([]) // codes of tmp or non-final wbs

  useEffect(() => {
    getAllWbs().then(({ data }) => {
      data = data
        .filter((wbs) => !wbs.isFinal || wbs.isTempPlaced)
        .map((wbs) => wbs.code)
      data.push(config.wbsTempCode)
      setInvalidWbsCodes(data)
    })
  }, [])

  useEffect(() => {
    let unitCache = localStorage.getItem("@timesheetExternalReviewUnitIsHours")
    if (unitCache === "false") setUnitIsHours(false)
  }, [setUnitIsHours])

  const onToogleSwitch = () => {
    setUnitIsHours(!unitIsHours)
    localStorage.setItem("@timesheetExternalReviewUnitIsHours", !unitIsHours)
  }

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="main-card mb-3">
          <CardBody>
            <Form>
              <Row form>
                <Col md={12}>
                  <div>
                    <CardTitle>
                      <div className="d-flex justify-content-between">
                        <div />
                        <ToggleSwitch
                          id="thirdReview"
                          currentValue={!unitIsHours}
                          onChange={onToogleSwitch}
                        />
                      </div>
                    </CardTitle>
                  </div>
                </Col>
              </Row>
              <Row form>
                <Col md={12}>
                  <ExternalReviewTable
                    unitIsHours={unitIsHours}
                    currentPeriod={currentPeriod}
                    invalidWbsCodes={invalidWbsCodes}
                  ></ExternalReviewTable>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
