import Icon from "../Icon"
import "./HeaderCell.scss"

export default function HeaderCell({
  isSortable,
  sortingOrder,
  onSortClick = () => {},
  isPinned,
  onPinClick = () => {},
  children,
}) {
  const svgStyles = {
    width: "11px",
    height: "11px",
  }

  const renderSortIcon = () =>
    isSortable && sortingOrder ? (
      <div className="pl-2">
        {sortingOrder === "ASC" || sortingOrder === "DESC" ? (
          <Icon
            iconRelativePath={
              sortingOrder === "ASC" ? "monade/arrow-up" : "monade/arrow-down"
            }
            svgStyles={svgStyles}
          />
        ) : (
          ""
        )}
      </div>
    ) : (
      <></>
    )

  const renderPinIcon = () => (
    <div className="pl-2">
      <Icon
        className={`pin-icon ${isPinned ? "pinned" : ""}`}
        iconRelativePath="monade/pin"
        svgStyles={svgStyles}
        onClick={(e) => {
          e.stopPropagation()
          onPinClick()
        }}
      />
    </div>
  )

  return (
    <div
      style={{ cursor: isSortable ? "pointer" : null }}
      className="d-flex flex-rows justify-content-center align-items-center"
      onClick={isSortable ? onSortClick : null}
    >
      <div>{children}</div>
      {renderSortIcon()}
      {renderPinIcon()}
    </div>
  )
}
