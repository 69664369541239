import merge from "deepmerge"
import { Template } from "devextreme-react/core/template"
import DataGrid, {
  Button,
  Column,
  Editing,
  Item,
  LoadPanel,
  Popup,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Toolbar
} from "devextreme-react/data-grid"
import CustomStore from "devextreme/data/custom_store"
import { formatMessage } from "devextreme/localization"
import moment from "moment"
import React, { Fragment, useEffect, useState } from "react"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import { useSelector } from "react-redux"
import { Card, CardBody, Col, Form as RSForm, Row } from "reactstrap"
import {
  createPeriod,
  deletePeriod,
  getAllPeriod,
  updatePeriod
} from "../../../services/index"
import { getAllOffices } from "../../../services/index"
import { formatDateNoTimezone } from "../../../Utils/formatDateNoTimezone"
import PageTitle from "./../../../Layout/AppMain/PageTitle"
import { AddRow } from "./addRow"
import { EditRow } from "./editRow"
import { Button as DevextremeButton } from "devextreme-react/button"

let editData = null

export default function Period() {
  const config = useSelector((state) => state.Auth.config)
  const [toggleAddPopUp, setToggleAddPopUp] = useState(false)
  const [toggleEditPopUp, setToggleEditPopUp] = useState(false)
  const [offices, setOffices] = useState([])

  useEffect(() => {
    getAllOffices().then(({ data }) => {
      setOffices(
        data.map((j) => ({
          id: j._id,
          name: j.name,
          code: j.code
        }))
      )
    })
  }, [])

  const onContentReady = () => {
    setLoadPanel(false)
  }

  const [loadPanelEnabled, setLoadPanel] = useState(true)
  const onEditorPreparing = function(e) {
    // console.log(e)
    if (e.dataField === "_id") {
      //console.log(e)
      e.visible = true
      if (!e.row.isNewRow) {
        e.allowEditing = false
        e.editorOptions.readOnly = true
        e.readOnly = true
      }
    }
  }

  const fields = [
    { dataField: "_id" },
    { dataField: "name" },
    { dataField: "startDate" },
    { dataField: "endDate" }
  ]

  const dataSource = {
    store: new CustomStore({
      load: async function() {
        return getAllPeriod()
          .then(async ({ data }) => {
            data.map((i) => {
              if (i.startDate) i.startDate = formatDateNoTimezone(i.startDate)
              if (i.endDate) i.endDate = formatDateNoTimezone(i.endDate)
              return i
            })

            if (data) {
              return {
                data: data,
                totalCount: 10
              }
            } else {
              return {
                data: [],
                totalCount: 0
              }
            }
          })
          .catch((e) => {
            throw e
          })
      },
      update: async (before, dataToUpdate) => {
        const objToSave = merge(before, dataToUpdate)
        // console.log(objToSave)
        return updatePeriod(objToSave)
      },
      insert: async (data) => {
        // console.log(data)

        data._id = moment(data.startDate).format("YYYYMM")
        data.calendarStartDate = moment(data.startDate)
          .startOf("month")
          .toDate()
        data.calendarEndDate = moment(data.startDate)
          .endOf("month")
          .toDate()
        data.name = moment(data.startDate).format("MMMM YYYY")
        // console.log(data)
        return createPeriod(data)
      },
      remove: async (data) => {
        // console.log(data)
        return deletePeriod(data._id)
      }
    })
  }
  let columns = [
    {
      title: "Id",
      field: "_id",
      requiredRule: <RequiredRule />,
      allowEditing: true,
      visible: false
    },
    {
      title: formatMessage("name"),
      field: "name",
      requiredRule: <RequiredRule />,
      allowEditing: true
    },
    {
      title: formatMessage("column-title-startDate"),
      field: "startDate",
      requiredRule: <RequiredRule />,
      allowEditing: true,
      dataType: "date",
      format: "dd-MM-yyyy"
    },
    {
      title: formatMessage("column-title-endDate"),
      field: "endDate",
      requiredRule: <RequiredRule />,
      allowEditing: true,
      dataType: "date",
      format: "dd-MM-yyyy"
    }
  ]

  offices.forEach(function(item) {
    fields.push({ dataField: "workingDays." + item.code })
    columns.push({
      title: "workingDays" + item.code,
      field: "workingDays." + item.code,
      allowEditing: true,
      visible: false,
      dataType: "number"
    })
  })

  return (
    <Fragment>
      <div>
        <PageTitle
          heading={formatMessage("period-admin")}
          subheading=""
          icon="pe-7s-study text-success"
        />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Card className="main-card mb-3">
            <CardBody>
              <RSForm>
                <Row form>
                  <Col md={12}>
                    <div className="dx-swatch-dx-generic-compact">
                      <AddRow
                        togglePopUp={toggleAddPopUp}
                        setTogglePopUp={setToggleAddPopUp}
                        offices={offices}
                      />
                      <EditRow
                        togglePopUp={toggleEditPopUp}
                        setTogglePopUp={setToggleEditPopUp}
                        offices={offices}
                        data={editData}
                      />
                      <DataGrid
                        //id={'gridContainer'}
                        dataSource={dataSource}
                        showBorders={true}
                        //keyExpr="_id"
                        onContentReady={onContentReady}
                        onEditorPreparing={onEditorPreparing}
                      >
                        <SearchPanel visible={true} />
                        <Editing
                          mode="popup"
                          allowAdding={false}
                          allowUpdating={true}
                          allowDeleting={true}
                        >
                          <Popup
                            title="Timesheet Row Details"
                            showTitle={true}
                            closeOnOutsideClick
                          ></Popup>
                        </Editing>
                        <SearchPanel visible={true} />

                        {columns.map((column) => {
                          return (
                            <Column
                              dataField={column.field}
                              key={column.field}
                              //width={column.width}
                              caption={column.title}
                              allowEditing={column.allowEditing}
                              placeholder={column.placeholder}
                              dataType={column.dataType}
                              format={column.format}
                              alignment={"left"}
                              cellRender={column.cellRender}
                              visible={column.visible}
                              editCellComponent={column.editCellComponent}
                            >
                              {column.requiredRule}
                              {column.lookup}
                            </Column>
                          )
                        })}
                        <Column type="buttons" width={80}>
                          <Button
                            name="edit"
                            icon="edit"
                            onClick={(data) => {
                              editData = data
                              setToggleEditPopUp(true)
                            }}
                          />
                          <Button name="delete" icon="trash" />
                        </Column>
                        <Template name={"groupRowTemplate"} />
                        <Scrolling mode="virtual" />
                        <LoadPanel enabled={loadPanelEnabled} />
                        <Toolbar>
                          <Item location="after">
                            <DevextremeButton
                              icon="plus"
                              onClick={() => setToggleAddPopUp(true)}
                            />
                          </Item>
                          <Item location="after" name="searchPanel" />
                        </Toolbar>
                      </DataGrid>
                    </div>
                  </Col>
                </Row>
              </RSForm>
            </CardBody>
          </Card>
        </ReactCSSTransitionGroup>
      </div>
    </Fragment>
  )
}
