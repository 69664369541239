import { Card } from "@material-ui/core"
import { formatMessage } from "devextreme/localization"
import moment from "moment"
import { Fragment, React, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Bounce, toast } from "react-toastify"
import { Button, CardBody, CardTitle, Col, Form, Progress, Row } from "reactstrap"
import ToggleSwitch from "../../../../assets/components/switch/ToggleSwitch"
import localization from "../../../../config/localization.json"
import PageTitle from "../../../../Layout/AppMain/PageTitle"
import {
  getJobsThumb,
  getPeriodById,
  getUser,
  sendMissingTimesheet
} from "../../../../services"
import { round } from "../../../../Utils/utils"
import Alert from "./alert"
import CreateTimesheetTable from "./table"

const ContainerHeadLine = ({ selectedPeriod, unitIsHours, onToogleSwitch }) => {
  return (
    <CardTitle>
      <div
        className="d-flex justify-content-between"
        style={{ alignItems: "center" }}
      >
        <div>
          {formatMessage(moment(selectedPeriod._id, "YYYYMM").format("MMMM")).concat(
            moment(selectedPeriod._id, "YYYYMM").format(" YYYY")
          )}
        </div>
        <ToggleSwitch
          id="id"
          defaultChecked={!unitIsHours}
          onChange={onToogleSwitch}
        />
      </div>
    </CardTitle>
  )
}

const ProgressBar = ({ unitIsHours, totalHoursCompiled, workingDays }) => {
  const unit = unitIsHours
    ? formatMessage("Hours") + ": "
    : formatMessage("Days") + ": "
  const compiled = unitIsHours ? totalHoursCompiled : totalHoursCompiled / 8
  const toBeCompiled = unitIsHours ? workingDays * 8 : workingDays
  const label =
    unit +
    round(compiled)?.toLocaleString(localization.lang) +
    "/" +
    toBeCompiled?.toLocaleString(localization.lang)

  let barColor

  if (compiled < toBeCompiled) {
    barColor = ""
  } else if (compiled === toBeCompiled) {
    barColor = "success"
  } else if (compiled > toBeCompiled) {
    barColor = "danger"
  }

  return (
    <Progress
      className="mb-3"
      value={compiled}
      color={barColor}
      max={unitIsHours ? workingDays * 8 : workingDays}
      animated={true}
    >
      {!compiled ? "" : label}
    </Progress>
  )
}

export default function CreateMissingTimesheet(props) {
  const [unitIsHours, setUnitIsHours] = useState(true)
  const [totalHoursCompiled, setTotalHoursCompiled] = useState()
  const [jobs, setJobs] = useState()
  const [user, setUser] = useState()
  const [workingDays, setWorkingDays] = useState([])
  const [period, setPeriod] = useState()
  const [openAlert, setOpenAlert] = useState(false)

  const [datasource, setDatasource] = useState([])

  useEffect(() => {
    // get user data
    getUser(props.match.params.userId).then(({ data }) => setUser(data))

    // get period data
    getPeriodById({
      periodId: props.match.params.periodId
    }).then(({ data }) => setPeriod(data))
  }, [])

  useEffect(() => {
    // populate jobs list
    getJobsThumb().then(({ data }) => {
      data = data
        .map((job) => ({
          ...job,
          isActive:
            job.startDate != null && job.endDate != null
              ? moment(period?.calendarStartDate).isBetween(
                  job.startDate,
                  job.endDate,
                  "M",
                  "[]"
                )
              : false
        }))
        .filter((job) => job.isActive && !job.isDeleted)
        .map((job) => ({
          ...job,
          label: job.wbs + " - " + job.description,
          suggestionLabel:
            job.wbs.code +
            (job.wbs.aliasCode ? " - " + job.wbs.aliasCode : "") +
            " - " +
            job.description
        }))

      setJobs(data)
    })
  }, [period])

  useEffect(() => {
    if (user != null && period != null) {
      const workingDays = period.workingDays.find(
        (workingDay) => workingDay.officeId === user.office
      ).days

      setWorkingDays(workingDays)
    }
  }, [user, period])

  const onToogleSwitch = () => {
    setUnitIsHours(!unitIsHours)
  }

  const onCancel = () => {
    props.history.push({ pathname: "/preter/management/missing-timesheet" })
  }

  const sendHandler = () => {
    sendMissingTimesheet({
      userId: user._id,
      period: props.match.params.periodId,
      timesheetRows: datasource
    })
      .then(() => {
        toast(formatMessage("success-sending-timesheet"), {
          transition: Bounce,
          closeButton: true,
          autoClose: 3000,
          position: "bottom-center",
          type: "success"
        })
        props.history.push("/preter/management/missing-timesheet")
      })
      .catch(() => {
        toast(formatMessage("error-sending-timesheet"), {
          transition: Bounce,
          closeButton: true,
          autoClose: 3000,
          position: "bottom-center",
          type: "error"
        })
      })
      .finally(() => {
        setOpenAlert(false)
      })
  }

  return (
    <Fragment>
      <PageTitle
        heading={formatMessage("missing-timesheet")}
        icon={"pe-7s-way text-success"}
        actions={
          <Fragment>
            <Button
              outline
              className="mr-2"
              color="primary"
              d="button"
              width={100}
              height={40}
              onClick={onCancel}
            >
              <span className="dx-button-text">
                {formatMessage("hc-button-cancel").toUpperCase()}
              </span>
            </Button>
            <Button
              className="btn-shadow mr-3"
              onClick={() => {
                setOpenAlert(true)
              }}
              color="dark"
              id="Tooltip-123"
              disabled={datasource.length === 0}
            >
              {formatMessage("SEND")}
            </Button>
          </Fragment>
        }
      />

      <Card>
        <CardBody>
          <Form>
            <Row>
              <Col md={12}>
                <h4
                  style={{ paddingBottom: 10 }}
                >{`${user?.firstName} ${user?.lastName}`}</h4>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {period && (
                  <ContainerHeadLine
                    selectedPeriod={period}
                    unitIsHours={unitIsHours}
                    onToogleSwitch={onToogleSwitch}
                  />
                )}
                <ProgressBar
                  unitIsHours={unitIsHours}
                  totalHoursCompiled={totalHoursCompiled}
                  workingDays={workingDays}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Alert
                  openAlert={openAlert}
                  setOpenAlert={setOpenAlert}
                  onSend={sendHandler}
                />
                <CreateTimesheetTable
                  jobs={jobs}
                  datasource={datasource}
                  unitIsHours={unitIsHours}
                  setTotalHoursCompiled={setTotalHoursCompiled}
                />
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  )
}
