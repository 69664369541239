import {useMemo} from "react";
import {formatMessage} from "devextreme/localization";
import {ResourceTile} from "../components/ResourceTile";
import {formatPercentage} from "../../../../Utils/formatPercentage";
import Button from "../components/Button";
import "./ResourceCardHeader.scss";

export default function ResourceCardHeader({
  resource,
  onToggleIsCalendarShown,
  onAddResource
}) {
  const calendarButtonText = useMemo(() => {
    return resource.isOpen ?
      formatMessage('preallocation-add-resource-hide-calendar') :
      formatMessage('preallocation-add-resource-show-calendar');
  }, [resource.isOpen]);

  return (
    <div className={`resource-card-header ${resource.isOpen ? 'calendar-shown' : ''}`}>
      <ResourceTile className='resource-card-header-tile' resource={resource} color={'#CBDCEA'} showLevel={false} />
      <div className='resource-card-header-info'>
        <div className='resource-card-header-info-field'>
          <h6>{formatMessage('preallocation-add-resource-card-level')}</h6>
          <span>{resource.level}</span>
        </div>
        <div className='resource-card-header-info-field'>
          <h6>{formatMessage('preallocation-add-resource-card-job-title')}</h6>
          <span>{resource.bipRole}</span>
        </div>
        <div className='resource-card-header-info-field text-truncate'>
          <h6>{formatMessage('preallocation-add-resource-card-team-leader')}</h6>
          <span>{resource.teamLeader}</span>
        </div>
        <div className='resource-card-header-info-field'>
          <h6>{formatMessage('preallocation-add-resource-card-free-time')}</h6>
          <span>{formatPercentage(resource.averageFreePercent * 100, 0)}</span>
        </div>
      </div>
      <Button
        className='btn-outline-primary ml-3'
        text={calendarButtonText}
        icon='monade/preallocation/calendar'
        onClick={() => onToggleIsCalendarShown(resource)}
      />
      <Button
        className='btn-primary ml-3'
        text={formatMessage('preallocation-add-resource-add')}
        icon='monade/preallocation/plus'
        onClick={() => onAddResource(resource)}
      />
    </div>
  );
}
