import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { getAllYears } from "../../../../../services"
import { parseFiscalYearData } from "../../utils/parseFiscalYearData"

export function useSelectYears(wbsId) {
  const { isLoading, isError, isSuccess, data, error } = useQuery(
    ["GET", "year", wbsId],
    async () => {
      return await getAllYears(wbsId).then((data) => {
        return data.data.map((year) => parseFiscalYearData(year))
      })
    },
  )

  const parseYearId = (yearId) => {
    if (isError) {
      return "error"
    }

    if (isLoading) {
      return "-"
    }

    return data.find((year) => year._id === yearId)?.year || "-"
  }

  const getYearsAsOptions = () => {
    if (!isSuccess) {
      return []
    }
    return data.map((year) => ({ id: year._id, cell: year.year }))
  }
  const [yearsOptions, setYearsOptions] = useState(getYearsAsOptions())
  useEffect(() => {
    setYearsOptions(getYearsAsOptions())
  }, [data])

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    parseYearId,
    yearsOptions,
  }
}
