import { FieldErrorIcon } from "../FieldErrorIcon"
import "../InputDefaults.scss"
import "./Checkbox.scss"

export default function Checkbox({
  disabled = false,
  readOnly = false,
  value,
  onChange,
  label,
  error = "",
  style,
  className,
}) {
  return (
    <div className={className} style={style}>
      <label
        className={`checkbox-container mb-0 d-flex flex-row align-items-center ${
          error !== "" ? "text-danger" : ""
        } ${readOnly ? "readonly" : disabled ? "disabled" : "cursor"}`}
        style={{ minWidth: "1.5rem", minHeight: "1.5rem" }}
      >
        <input
          disabled={disabled || readOnly}
          type="checkbox"
          checked={value}
          onChange={(e) => {
            if (disabled || readOnly || typeof onChange !== "function") {
              return
            }
            onChange(e)
          }}
          className={`${value ? "checked" : ""} ${
            readOnly ? "readonly" : disabled ? "disabled" : "cursor"
          }`}
        />
        <span
          className={`checkmark ${
            readOnly
              ? "readonly"
              : disabled
              ? "checkmark-disabled disabled"
              : "cursor"
          }`}
        ></span>
        {label && label !== "" ? (
          <>
            <span className="label-padding" />
            {label}
          </>
        ) : (
          ""
        )}
        <FieldErrorIcon className="pl-1 mb-2" message={error} />
      </label>
    </div>
  )
}
