import React, { Component, Fragment, useEffect } from "react"
import { connect } from "react-redux"
import cx from "classnames"
import { AbilityContext } from "./../../Context/ability"

import Nav from "../AppNav/VerticalNavWrapper"

import ReactCSSTransitionGroup from "react-addons-css-transition-group"

import PerfectScrollbar from "react-perfect-scrollbar"
import HeaderLogo from "../AppLogo"

import { setEnableMobileMenu } from "../../reducers/ThemeOptions"
import { formatMessage } from "devextreme/localization"

class AppSidebar extends Component {
  state = {}

  toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = this.props
    setEnableMobileMenu(!enableMobileMenu)
  }

  render() {
    let {
      backgroundColor,
      enableBackgroundImage,
      enableSidebarShadow,
      backgroundImage,
      backgroundImageOpacity,
    } = this.props

    const canReadIamAdmin = this.context.can("read", "IamAdmin")
    const canReadJobAdmin = this.context.can("read", "JobAdmin")
    const canReadUserAdmin = this.context.can("read", "UserAdmin")
    const canReadPeriodAdmin = this.context.can("read", "PeriodAdmin")
    const canReadOrganizationAdmin = this.context.can("read", "OrganizationAdmin")
    const canReadReviews = this.context.can("read", "Review")
    const canReadPreTer = this.context.can("read", "PreTer")
    const canReadJobReview = this.context.can("read", "JobReview")
    const canReadExternalReview = this.context.can("read", "ExternalReview")
    const canManageMissingTimesheet = this.context.can("manage", "MissingTimesheet")
    const canReadPreallocation = this.context.can("read", "Preallocation")
    const canReadAppraisal = this.context.can("read", "Appraisal")

    const MenuNav = [
      {
        icon: "pe-7s-id",
        label: formatMessage("Profile"),
        to: "#/main/profile",
      },
    ]

    let adminNav = []

    if (canReadIamAdmin) {
      adminNav.push({
        icon: "pe-7s-shield",
        label: "IAM",
        to: "#/admin/iam",
      })
    }

    if (canReadUserAdmin) {
      adminNav.push({
        icon: "pe-7s-users",
        label: formatMessage("hc-index-title"),
        to: "#/admin/headcount",
      })
    }

    if (canReadJobAdmin) {
      // adminNav.push({
      //   icon: "pe-7s-wallet",
      //   label: formatMessage("admin-job-wbs-title"),
      //   to: "#/admin/Job"
      // })
      adminNav.push({
        icon: "pe-7s-wallet",
        label: formatMessage("admin-job-wbs-title"),
        to: "#/admin/job-and-wbs",
      })
    }

    if (canReadPeriodAdmin) {
      adminNav.push({
        icon: "pe-7s-stopwatch",
        label: formatMessage("period-admin"),
        to: "#/admin/period",
      })
    }

    if (canReadOrganizationAdmin) {
      adminNav.push({
        icon: "pe-7s-network",
        label: formatMessage("Organization"),
        to: "#/admin/organization",
      })
    }

    let preallocationNavBar = []

    if (canReadPreallocation) {
      preallocationNavBar.push({
        icon: "pe-7s-date",
        label: formatMessage("preallocation-title"),
        to: "#/preallocation",
      })
    }

    let preterNavBar = []

    if (canReadPreTer) {
      preterNavBar.push({
        icon: "pe-7s-calculator",
        label: formatMessage("Timesheet"),
        to: "#/preter/timesheet",
      })
    }

    if (canReadJobReview) {
      preterNavBar.unshift({
        icon: "pe-7s-portfolio",
        label: formatMessage("Management"),
        content: [],
      })
      preterNavBar[0].content.push({
        label: formatMessage("Job-Owner"),
        to: "#/preter/management/jo-review",
      })
    }

    if (canReadReviews) {
      if (!canReadJobReview) {
        preterNavBar.unshift({
          icon: "pe-7s-portfolio",
          label: formatMessage("Management"),
          content: [],
        })
      }
      preterNavBar[0].content.push({
        label: formatMessage("Review"),
        to: "#/preter/management/review",
      })
    }

    if (canManageMissingTimesheet) {
      if (!canReadJobReview && !canReadReviews) {
        preterNavBar.unshift({
          icon: "pe-7s-portfolio",
          label: formatMessage("Management"),
          content: [],
        })
      }
      preterNavBar[0].content.push({
        label: formatMessage("missing-timesheet"),
        to: "#/preter/management/missing-timesheet",
      })
    }

    if (canReadExternalReview) {
      if (!canReadReviews && !canReadJobReview && !canManageMissingTimesheet) {
        // if Management section doesn't already exist
        preterNavBar.unshift({
          icon: "pe-7s-portfolio",
          label: formatMessage("Management"),
          content: [],
        })
      }
      preterNavBar[0].content.push({
        label: formatMessage("Externals"),
        to: "#/preter/management/externals-review",
      })
    }

    let performanceMgmtNavBar = []

    if (canReadAppraisal) {
      performanceMgmtNavBar.push({
        icon: "pe-7s-target",
        label: formatMessage("Appraisal"),
        to: "#/performance-mgmt/appraisal",
      })
    }

    return (
      <Fragment>
        <div className="sidebar-mobile-overlay" onClick={this.toggleMobileSidebar} />
        <ReactCSSTransitionGroup
          component="div"
          className={cx("app-sidebar", backgroundColor, {
            "sidebar-shadow": enableSidebarShadow,
          })}
          transitionName="SidebarAnimation"
          transitionAppear={true}
          transitionAppearTimeout={1500}
          transitionEnter={false}
          transitionLeave={false}
        >
          <HeaderLogo />
          <PerfectScrollbar>
            <div className="app-sidebar__inner">
              <Nav
                userRole={this.props.userRole}
                adminNavLabel={adminNav}
                preallocationNavBar={preallocationNavBar}
                preterNavBar={preterNavBar}
                performanceMgmtNavBar={performanceMgmtNavBar}
                MenuNav={MenuNav}
              />
            </div>
          </PerfectScrollbar>
          <div
            className={cx("app-sidebar-bg", backgroundImageOpacity)}
            style={{
              backgroundImage: enableBackgroundImage
                ? "url(" + backgroundImage + ")"
                : null,
            }}
          ></div>
        </ReactCSSTransitionGroup>
      </Fragment>
    )
  }
}

AppSidebar.contextType = AbilityContext

const mapStateToProps = (state) => ({
  enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
  enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  backgroundColor: state.ThemeOptions.backgroundColor,
  backgroundImage: state.ThemeOptions.backgroundImage,
  backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity,
  userRole: state.Auth.user.bipRole,
})

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebar)
