import { apiService } from "./APIServices"

import axios from "axios"

export const getAllOrganizations = async () => {
  return axios.get(apiService.organization, {
    params: {
      no_empty_owner: false,
      no_deleted: false
    }
  })
}

export const getOrganizations = async (params = {}) => {
  return axios.get(apiService.organization, { params })
}

export const createOrganization = async (organization) => {
  return axios.post(apiService.organization, organization)
}

export const updateOrganization = async (organization) => {
  return axios.put(apiService.organization + `/${organization._id}`, organization)
}

export const deleteOrganization = (organizationId) => {
  return axios.delete(apiService.organization + `/${organizationId}`)
}
