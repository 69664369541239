import { Template } from "devextreme-react/core/template"
import DataGrid, {
  Button,
  Column,
  Editing,
  Export,
  Item,
  LoadPanel,
  Lookup,
  Popup,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Toolbar
} from "devextreme-react/data-grid"
import { formatMessage } from "devextreme/localization"
import moment from "moment"
import React, { Fragment, useEffect, useRef, useState } from "react"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import { Card, CardBody, Col, Form as RSForm, Row } from "reactstrap"
import PageTitle from "../../../Layout/AppMain/PageTitle"
import { getUsers } from "../../../services/index"
import { AddRow } from "./addRow"
import { EditRow } from "./editRow"
import { Bounce, toast } from "react-toastify"
import { getOrganizationDatasource } from "./datasource"
import { exportDataGrid } from "devextreme/excel_exporter"
import { Workbook } from "exceljs"
import { saveAs } from "file-saver"

let editData = null
let userMap = {}

export default function Organization() {
  const [toggleAddPopUp, setToggleAddPopUp] = useState(false)
  const [toggleEditPopUp, setToggleEditPopUp] = useState(false)
  const types = ["Team", "Area"]
  const [teamLeaders, setTeamLeaders] = useState([])
  const [areaManagers, setAreaManagers] = useState([])
  const [supportersList, setSupportersList] = useState([])
  const [possibleOwners, setPossibleOwners] = useState([])
  const [organizationList, setOrganizationList] = useState([])
  const [dataSource, setDatasource] = useState(
    getOrganizationDatasource(setOrganizationList)
  )

  let _datagrid = React.useRef(null)

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "plus",
        onClick: () => setToggleAddPopUp(true)
      }
    })
  }

  useEffect(() => {
    getUsers(["GCL", "DAM", "AM", "COEL", "CM"]).then(({ data }) => {
      setAreaManagers(
        data.map((j) => ({
          id: j._id,
          name: j.firstName + " " + j.lastName
        }))
      )
      data.forEach(function(j) {
        userMap[j._id] = j.firstName + " " + j.lastName
      })
    })

    getUsers(["STL", "TL", "DTL", "GCL", "DAM", "AM", "COEL", "CM"]).then(
      ({ data }) => {
        setTeamLeaders(
          data.map((j) => ({
            id: j._id,
            name: j.firstName + " " + j.lastName
          }))
        )
        data.forEach(function(j) {
          userMap[j._id] = j.firstName + " " + j.lastName
        })
      }
    )

    getUsers(false, false, true, false).then(({ data }) => {
      setPossibleOwners(
        data.map((j) => ({
          id: j._id,
          name: j.firstName + " " + j.lastName
        }))
      )

      setSupportersList(
        data.map((j) => ({
          id: j._id,
          name: j.firstName + " " + j.lastName,
          area: j.area
        }))
      )

      data.forEach(function(j) {
        userMap[j._id] = j.firstName + " " + j.lastName
      })
    })
  }, [])

  const onContentReady = () => {
    setLoadPanel(false)
  }

  useEffect(() => {
    if (!toggleAddPopUp && !toggleEditPopUp) _datagrid.current.instance.refresh()
  }, [toggleAddPopUp, toggleEditPopUp])

  const [loadPanelEnabled, setLoadPanel] = useState(true)
  const onEditorPreparing = function(e) {
    // console.log(e)
    if (e.dataField === "_id") {
      e.visible = true
      if (!e.row.isNewRow) {
        e.allowEditing = false
        e.editorOptions.readOnly = true
        e.readOnly = true
      }
    }
  }

  const columns = [
    { title: "id", field: "_id", requiredRule: <RequiredRule />, visible: false },
    {
      title: formatMessage("name"),
      field: "name",
      requiredRule: <RequiredRule />,
      allowEditing: true
    },
    {
      title: formatMessage("Type"),
      field: "type",
      requiredRule: <RequiredRule />,
      allowEditing: true,
      lookup: <Lookup dataSource={types} />
    },
    {
      title: formatMessage("organization-owner"),
      field: "owner",
      requiredRule: <RequiredRule />,
      allowEditing: true,
      lookup: (
        <Lookup dataSource={possibleOwners} displayExpr="name" valueExpr="id" />
      )
    },
    {
      title: "Parent Organization",
      field: "parent",
      allowEditing: true,
      lookup: (
        <Lookup dataSource={organizationList} displayExpr="name" valueExpr="id" />
      )
    },
    {
      title: "Competence Area",
      field: "isCompetence",
      visible: true,
      value: false,
      dataType: "boolean",
      align: "center"
    },
    {
      title: "Shortcode",
      field: "shortcode",
      requiredRule: <RequiredRule />,
      allowEditing: true
    },
    {
      title: "Deputies",
      field: "deputies",
      allowEditing: true,
      cellTemplate: (container, options) => {
        const noBreakSpace = "\u00A0"
        const text = (options.value || [])
          .map((element) => options.column.lookup.calculateCellValue(element))
          .join(", ")
        container.textContent = text || noBreakSpace
        container.title = text
        container.style.whiteSpace = "pre-wrap"
      },
      lookup: (
        <Lookup dataSource={possibleOwners} displayExpr={"name"} valueExpr="id" />
      )
    },
    {
      title: "Internal Supporters",
      field: "internalSupporters",
      allowEditing: true,
      cellTemplate: (container, options) => {
        const noBreakSpace = "\u00A0"
        const text = (options.value || [])
          .map((element) => options.column.lookup.calculateCellValue(element))
          .join(", ")
        container.textContent = text || noBreakSpace
        container.title = text
        container.style.whiteSpace = "pre-wrap"
      },
      lookup: (
        <Lookup dataSource={supportersList} displayExpr={"name"} valueExpr="id" />
      )
    },
    {
      title: "External Supporters",
      field: "externalSupporters",
      allowEditing: true,
      cellTemplate: (container, options) => {
        const noBreakSpace = "\u00A0"
        const text = (options.value || [])
          .map((element) => options.column.lookup.calculateCellValue(element))
          .join(", ")
        container.textContent = text || noBreakSpace
        container.title = text
        container.style.whiteSpace = "pre-wrap"
      },
      lookup: (
        <Lookup dataSource={supportersList} displayExpr={"name"} valueExpr="id" />
      )
    }
  ]

  const onDataErrorOccurred = (e) => {
    let errorResp = e.error.response

    if (errorResp?.status === 409) {
      if (errorResp.data.errors) {
        const paths = []
        e.error.response.data.errors.forEach((error) => paths.push(error.path))
        e.error.message =
          "Organization already exists: verify " + paths.join(", ") + " to be unique"
      } else {
        console.log(errorResp)
        e.error.message = formatMessage(errorResp.data)
      }
    } else if (errorResp?.status === 400) {
      if (e.error.message instanceof Array) {
        e.error.message = formatMessage("invalid-input-data")
      } else {
        e.error.message = errorResp.data.message
      }
    } else {
      e.error.message = formatMessage("error-updating-organization")
    }

    toast(e.error.message, {
      transition: Bounce,
      closeButton: true,
      autoClose: 3000,
      position: "bottom-center",
      type: "error"
    })
  }

  const onExporting = (e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet("Sheet")

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      customizeCell: function(options) {
        const { excelCell, gridCell } = options
        if (
          gridCell.rowType === "data" &&
          gridCell.value?.constructor == Array &&
          gridCell.value.length > 0
        ) {
          const values = []
          gridCell.value.forEach((value) => {
            values.push(gridCell.column.lookup.calculateCellValue(value))
          })
          // wrap in excelCell
          excelCell.alignment.wrapText = true
          excelCell.value = values.join(", ")
        }
      }
    }).then(function() {
      workbook.xlsx.writeBuffer().then(function(buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          e.fileName + ".xlsx"
        )
      })
    })
  }

  return (
    <Fragment>
      <div>
        <PageTitle
          heading={formatMessage("Organization")}
          subheading=""
          icon="pe-7s-study text-success"
        />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Card className="main-card mb-3">
            <CardBody>
              <RSForm>
                <Row form>
                  <Col md={12}>
                    <div className="dx-swatch-dx-generic-compact">
                      <AddRow
                        togglePopUp={toggleAddPopUp}
                        setTogglePopUp={setToggleAddPopUp}
                        types={types}
                        areaManagers={areaManagers}
                        teamLeaders={teamLeaders}
                        supportersList={supportersList}
                        organizationList={organizationList}
                      />
                      <EditRow
                        togglePopUp={toggleEditPopUp}
                        setTogglePopUp={setToggleEditPopUp}
                        types={types}
                        areaManagers={areaManagers}
                        teamLeaders={teamLeaders}
                        internalSupportersList={supportersList.filter(
                          (s) => s.area == editData?.name
                        )}
                        externalSupportersList={supportersList.filter(
                          (s) => s.area !== editData?.name
                        )}
                        data={editData}
                        organizationList={organizationList}
                      />
                      <div>
                        <DataGrid
                          ref={_datagrid}
                          id={"gridContainer"}
                          dataSource={dataSource}
                          showBorders={true}
                          onExporting={onExporting}
                          onContentReady={onContentReady}
                          onEditorPreparing={onEditorPreparing}
                          onToolbarPreparing={onToolbarPreparing}
                          onDataErrorOccurred={onDataErrorOccurred}
                          errorRowEnabled={false} // disabled the error row because errors are shown in a toast message
                        >
                          <SearchPanel visible={true} />
                          {/* <ColumnChooser enabled={true} mode="select" height="800" /> */}
                          <Editing
                            mode="popup"
                            allowAdding={false}
                            allowUpdating={true}
                            allowDeleting={true}
                          >
                            <Popup
                              title=""
                              showTitle={true}
                              closeOnOutsideClick
                            ></Popup>
                          </Editing>
                          <SearchPanel visible={true} />

                          {columns.map((column) => {
                            return (
                              <Column
                                dataField={column.field}
                                key={column.field}
                                width={column.width}
                                caption={column.title}
                                allowEditing={column.allowEditing}
                                placeholder={column.placeholder}
                                dataType={column.dataType}
                                format={column.format}
                                alignment={column.align ?? "left"}
                                cellRender={column.cellRender}
                                visible={column.visible}
                                editCellComponent={column.editCellComponent}
                                cellTemplate={column.cellTemplate}
                              >
                                {column.requiredRule}
                                {column.lookup}
                              </Column>
                            )
                          })}
                          <Column type="buttons" width={80}>
                            <Button
                              name="edit"
                              icon="edit"
                              onClick={(data) => {
                                editData = Object.create(data.row.data)
                                let owner = {
                                  id: data.row.data.owner,
                                  name: userMap[data.row.data.owner]
                                }
                                editData.owner = owner
                                editData.parent = {
                                  id: data.row.data.parent,
                                  name: organizationList.find(
                                    (org) => org.id === data.row.data.parent
                                  )?.name
                                }
                                editData.deputies = data.row.data.deputies?.map(
                                  (deputyId) => ({
                                    id: deputyId,
                                    name: userMap[deputyId]
                                  })
                                )
                                editData.internalSupporters = data.row.data.internalSupporters?.map(
                                  (supporterId) => ({
                                    id: supporterId,
                                    name: userMap[supporterId]
                                  })
                                )
                                editData.externalSupporters = data.row.data.externalSupporters?.map(
                                  (supporterId) => ({
                                    id: supporterId,
                                    name: userMap[supporterId]
                                  })
                                )
                                setToggleEditPopUp(true)
                              }}
                            />
                            <Button name="delete" icon="trash" />
                          </Column>
                          <Template name={"groupRowTemplate"} />
                          <Scrolling mode="virtual" />
                          <LoadPanel enabled={loadPanelEnabled} />
                          <Export
                            enabled={true}
                            fileName={"ORGANIZATIONS_" + moment().format("YYYYMMDD")}
                          />
                          <Toolbar>
                            <Item location="after">
                              <Button
                                icon="plus"
                                onClick={() => setToggleAddPopUp(true)}
                              />
                            </Item>
                            <Item location="after" name="exportButton" />
                            <Item location="after" name="searchPanel" />
                          </Toolbar>
                        </DataGrid>
                      </div>
                    </div>
                  </Col>
                </Row>
              </RSForm>
            </CardBody>
          </Card>
        </ReactCSSTransitionGroup>
      </div>
    </Fragment>
  )
}
