export const calcExtJobTitle = (currentLevelCode, competence, levels) => {
  if (!currentLevelCode || !competence) {
    return null
  }
  const referenceLevel = levels.find((l) => {
    return l.code === currentLevelCode
  })
  if (!referenceLevel || !referenceLevel.extJobTitle) {
    return null
  }
  let extJobTitle = null
  for (const c in referenceLevel.extJobTitle) {
    if (c === competence) {
      extJobTitle = referenceLevel.extJobTitle[c]
      break
    }
  }
  return extJobTitle // may be null
}
