import { Button, Dialog, Paper, TextField } from "@material-ui/core"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import Autocomplete from "@material-ui/lab/Autocomplete"
import merge from "deepmerge"
import { formatMessage } from "devextreme/localization"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import Draggable from "react-draggable"
import NumberFormat from "react-number-format"
import { useSelector } from "react-redux"
import { saveRow } from "../../Utils/tsFlowConfig"

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value
          }
        })
      }}
      isNumericString
      decimalScale={2}
      decimalSeparator=","
      thousandSeparator={false}
      allowNegative={false}
      displayType={"number"}
    />
  )
}

export const EditRow = ({ setEditRow, editRow, unitIsHours, forceUpdate, jobs }) => {
  // const [jobs, setJobs] = useState([{}])
  const [tsValueError, setTsValueError] = useState("")
  const [tsValue, setTsValue] = useState("")
  const [notes, setNotes] = useState("")
  const [description, setDescription] = useState("")
  const [notesError, setNotesError] = useState("")
  const [descError, setDescError] = useState("")
  const [jobError, setJobError] = useState("")
  const [isDescriptionMandatory, setIsDescriptionMandatory] = useState(false)
  const [job, setJob] = useState(undefined)
  const user = useSelector((state) => state.Auth.user)
  const config = useSelector((state) => state.Auth.config)

  useEffect(() => {
    // ignore the function if dialog is closed
    if (!editRow.show) return

    setNotesError("")

    setTsValue(
      unitIsHours
        ? editRow.data.timesheetRow?.firstReview.hours
        : editRow.data.timesheetRow?.firstReview.days
    )
    const job = jobs.find(
      (item) => item._id === editRow.data.timesheetRow?.firstReview.code
    )
    setNotes(editRow.data.timesheetRow?.firstReview.notes)
    setJob({
      ...editRow.data.timesheetRow?.firstReview,
      label: editRow.data.label,
      isTempPlaced: job?.isTempPlaced,
      description: job?.description
    })

    setDescError("")
    setDescription(editRow.data.timesheetRow?.firstReview.description)
    setIsDescriptionMandatory(job?.isDescriptionMandatory)

    return () => {}
  }, [editRow])

  function onSave() {
    if (!job.code) return setJobError(formatMessage("required-field"))
    if (!notes || notes.length === 0)
      return setNotesError(formatMessage("required-field"))
    if (descError.trim().length !== 0)
      return setDescError(formatMessage("required-field"))

    if (unitIsHours) {
      editRow.data.timesheetRow.firstReview.hours = tsValue
      editRow.data.timesheetRow.firstReview.days =
        editRow.data.timesheetRow.firstReview.hours / 8
    } else {
      editRow.data.timesheetRow.firstReview.days = tsValue
      editRow.data.timesheetRow.firstReview.hours =
        editRow.data.timesheetRow.firstReview.days * 8
    }

    editRow.data.timesheetRow.firstReview.notes = notes
    editRow.data.timesheetRow.firstReview.description = description

    const reviewCode = editRow.data.currentStatus

    const objToSave = {
      review: reviewCode,
      params: merge(job, {
        tsId: editRow.data._id,
        _id: editRow.data.timesheetRow._id,
        owner: user._id,
        wbs: job.wbs,
        activity: job.activity,
        code: job.code,
        notes: editRow.data.timesheetRow[reviewCode].notes,
        hours: editRow.data.timesheetRow[reviewCode].hours,
        days: editRow.data.timesheetRow[reviewCode].days,
        description: editRow.data.timesheetRow[reviewCode].description
      })
    }

    saveRow(objToSave).then((e) => {
      setEditRow({ show: false, data: {} })
      forceUpdate()
    })
  }

  return (
    <Dialog
      open={editRow.show}
      onClose={() => setEditRow({ show: false, data: {} })}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      disableBackdropClick
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {formatMessage("edit-job-review-details-popup-title")}
      </DialogTitle>

      <Autocomplete
        id="combo-box-demo"
        onChange={(e, e1) => {
          setNotesError(formatMessage("required-field"))
          if (job != null && e1 != null) {
            let newJob = job
            Object.assign(newJob, {
              code: e1?._id,
              description: e1?.description,
              wbs: e1?.wbs,
              label: e1?.label
            })
            setJob(newJob)
            setIsDescriptionMandatory(e1?.isDescriptionMandatory)
            setDescError(
              e1?.isDescriptionMandatory && description.trim().length === 0
                ? formatMessage("required-field")
                : ""
            )
          }
        }}
        options={jobs}
        value={job}
        getOptionSelected={(option, value) => {
          return option._id === value.code
        }}
        getOptionLabel={(option) => {
          const label = typeof option.wbs === "object" ? option.wbs.code : option.wbs

          console.log("option", option)

          const aliasCode = option?.wbs?.aliasCode
            ? ` - ${option?.wbs.aliasCode}`
            : ""

          const description = option.description
            ? option.description
            : option.activity

          return option.isTempPlaced
            ? `${label}${aliasCode} (${config.wbsTempCode}) - ${option.description}`
            : `${label}${aliasCode} - ${description}`
        }}
        style={{
          width: 500,
          marginLeft: 20,
          marginRight: 20
        }}
        renderInput={(params) => (
          <TextField
            error={jobError.length !== 0}
            required
            {...params}
            label={formatMessage("Project")}
          />
        )}
      />

      <TextField
        required
        error={tsValueError.length !== 0}
        label={unitIsHours ? formatMessage("Hours") : formatMessage("Days")}
        onChange={(e) => {
          setNotesError(formatMessage("required-field"))
          setTsValueError("")
          const n = Number(e.target.value)
          setTsValue(n ? n : 0)
        }}
        value={tsValue}
        helperText={tsValueError}
        variant="outlined"
        style={{
          width: 150,
          marginLeft: 20,
          marginRight: 20,
          marginTop: 20
        }}
        InputProps={{
          inputComponent: NumberFormatCustom
        }}
      />

      <TextField
        required={isDescriptionMandatory}
        id="outlined-required"
        label={formatMessage("Description")}
        value={description}
        error={descError.trim().length !== 0}
        onChange={(e) => {
          setDescError("")
          setDescription(e.target.value)
          setNotesError(formatMessage("required-field"))
          setDescError(
            isDescriptionMandatory && e.target.value.trim().length === 0
              ? formatMessage("required-field")
              : ""
          )
        }}
        variant="outlined"
        style={{ width: 500, marginTop: 20, marginLeft: 20, marginBottom: 20 }}
        inputProps={{ maxLength: 120 }}
      />

      <TextField
        required
        id="outlined-required"
        label={formatMessage("Notes")}
        value={notes}
        error={notesError.length !== 0}
        onChange={(e) => {
          setNotesError("")
          setNotes(e.target.value)
        }}
        variant="outlined"
        style={{ width: 500, marginTop: 20, marginLeft: 20, marginBottom: 20 }}
        inputProps={{ maxLength: 120 }}
      />

      <DialogActions>
        <Button
          onClick={() => {
            setEditRow({ show: false, data: {} })
          }}
          color="primary"
        >
          {formatMessage("dxDataGrid-editingCancelRowChanges")}
        </Button>
        <Button
          onClick={() => {
            onSave()
          }}
          color="primary"
          disabled={notesError.trim().length !== 0 || descError.trim().length !== 0}
        >
          {formatMessage("dxDataGrid-editingSaveRowChanges")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
