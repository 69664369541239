import { useState } from "react"
import { getAllProjects, getProject } from "../services/ProjectsService"
import { parseProjectsFiltersToParams } from "../utils/parseProjectsFiltersToParams"
import { PREALLOCATION_PALETTE } from "../utils/preallocationPalette"

export const useProjects = () => {
  const [projects, setProjects] = useState([])
  const [isLoadingProjects, setIsLoadingProjects] = useState(false)
  const [loadProjectsError, setLoadProjectsError] = useState(null)
  const [paginationMeta, setPaginationMeta] = useState({})

  const loadProjects = async (filters) => {
    setIsLoadingProjects(true)
    setLoadProjectsError(null)

    try {
      const openedProjectsIds = projects
        .filter((project) => project.isOpen)
        .map((p) => p.id)
      const pinned = projects.filter((project) => project.isPinned)
      const pinnedProjectsIds = pinned.map((p) => p.id)
      const params = parseProjectsFiltersToParams(filters)
      if (paginationMeta.take) {
        params.take = paginationMeta.take
      }
      let {
        data: { data: rawProjects, ...meta }
      } = await getAllProjects(params)
      const newProjects = mapProjectsAddDetails(
        rawProjects,
        pinnedProjectsIds
      ).filter((p) => !pinnedProjectsIds.includes(p.id))
      const showedProjects = [...pinned, ...newProjects]
      setProjects(showedProjects)
      setPaginationMeta(meta)

      const projectIdsToOpen = showedProjects
        .filter((p) => openedProjectsIds.includes(p.id))
        .map((p) => p.id)

      await loadProjectDetails(projectIdsToOpen, filters)

      setIsLoadingProjects(false)
    } catch (e) {
      setLoadProjectsError(e)
      setIsLoadingProjects(false)
    }
  }

  const mapProjectsAddDetails = (projects, pinnedProjectsIds = []) =>
    projects.map((project, index) => ({
      ...project,
      color: PREALLOCATION_PALETTE[index % PREALLOCATION_PALETTE.length],
      isOpen: false,
      isPinned: pinnedProjectsIds.includes(project.id),
      details: null,
      isLoadingDetails: false,
      loadDetailsError: null
    }))

  const loadProjectDetails = async (projectIds, filters) => {
    if (!projectIds.length) {
      return
    }

    updateProjects(projectIds, () => ({
      isLoadingDetails: true,
      loadDetailsError: null,
      isOpen: true
    }))

    try {
      const params = parseProjectsFiltersToParams(filters)
      const { data: details } = await getProject({ ...params, jobIds: projectIds })
      updateProjects(projectIds, (p) => ({
        isLoadingDetails: false,
        details: details.find((d) => d.id === p.id) || []
      }))
    } catch (e) {
      updateProjects(projectIds, () => ({
        isLoadingDetails: false,
        loadDetailsError: e
      }))
    }
  }

  const updateProjects = (projectIds, fn) => {
    setProjects((p) =>
      p.map((p) => (projectIds.includes(p.id) ? { ...p, ...fn(p) } : p))
    )
  }

  const setProject = (project) => {
    setProjects((p) => p.map((p) => (p.id !== project.id ? p : project)))
  }
  const setIsProjectOpen = (project, isOpen, filters) => {
    const newProject = { ...project, isOpen }
    if (isOpen) {
      loadProjectDetails([newProject.id], filters)
    } else {
      setProject(newProject)
    }
  }

  const setIsProjectPinned = (project, isPinned) =>
    setProject({ ...project, isPinned })

  const loadMore = async (filters) => {
    try {
      if (!paginationMeta.hasNextPage) {
        return
      }
      setLoadProjectsError(null)
      const params = parseProjectsFiltersToParams(filters)
      params.page = paginationMeta.page + 1
      if (paginationMeta.take) {
        params.take = paginationMeta.take
      }
      let {
        data: { data: rawProjects, ...meta }
      } = await getAllProjects(params)
      const newProjects = mapProjectsAddDetails(rawProjects)
      setProjects([...projects, ...newProjects])
      setPaginationMeta(meta)
    } catch (e) {
      setLoadProjectsError(e)
    }
  }

  const loadPage = async (filters, page, pageSize) => {
    try {
      if (page < 0) return
      page = page || 0
      pageSize = pageSize || paginationMeta.take
      const currentPage = (paginationMeta.page || 0) - 1
      if (
        !paginationMeta.page ||
        (currentPage === page && paginationMeta.take === pageSize)
      ) {
        return
      }
      setLoadProjectsError(null)
      const params = parseProjectsFiltersToParams(filters)
      params.page = page + 1
      params.take = pageSize
      let {
        data: { data: rawProjects, ...meta }
      } = await getAllProjects(params)
      const pinned = projects.filter((p) => p.isPinned)
      const newProjects = mapProjectsAddDetails(rawProjects).filter(
        (p) => !pinned.some((pin) => pin.id === p.id)
      )
      setProjects([...pinned, ...newProjects])
      setPaginationMeta(meta)
    } catch (e) {
      setLoadProjectsError(e)
    }
  }

  return {
    projects,
    isLoadingProjects,
    loadProjectsError,
    loadProjects,
    loadProjectDetails,
    setIsProjectOpen,
    setIsProjectPinned,
    loadMore,
    loadPage,
    paginationMeta
  }
}
