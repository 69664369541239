import { Fragment, useState, useEffect } from "react"
import PageTitle from "../../../Layout/AppMain/PageTitle"
import Tabs from "react-responsive-tabs"
import { getAllGoals } from "../../../services"
import TabContent from "./TabContent"
import { formatMessage } from "devextreme/localization"

export default function Appraisal() {
  const [tabs, setTabs] = useState([])

  useEffect(() => {
    getAllGoals().then(({ data }) => {
      setTabs(getTabs(data))
    })
  }, [])

  const getTabs = (data) => {
    const tabs = []

    Object.keys(data).forEach((tabName, index) => {
      tabs.push({
        key: `${tabName.toLocaleLowerCase()}Tab`,
        title: tabName,
        getContent: () => (
          <TabContent data={data[tabName]} tabName={tabName} index={index} />
        )
      })
    })

    return tabs
  }

  return (
    <Fragment>
      <div>
        <PageTitle
          heading={formatMessage("appraisal-page-title")}
          subheading=""
          icon="pe-7s-target text-primary"
        />
        <Tabs
          tabsWrapperClass="body-tabs body-tabs-layout"
          transform={false}
          showInkBar={true}
          selectedTabKey={tabs.length > 0 ? tabs[0].key : undefined}
          items={tabs}
        />
      </div>
    </Fragment>
  )
}
