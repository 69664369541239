import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Paper from "@material-ui/core/Paper"
import Slide from "@material-ui/core/Slide"
import { formatMessage } from "devextreme/localization"
import React, { useState } from "react"
import Draggable from "react-draggable"
import { Alert } from "reactstrap"

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function SendMissingTimesheetAlert({
  openAlert,
  setOpenAlert,
  onSend
}) {
  const [disabled, setDisabled] = useState(false)

  return (
    <>
      <Dialog
        open={openAlert}
        TransitionComponent={Transition}
        aria-labelledby="draggable-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperComponent={PaperComponent}
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          <Alert
            color="danger"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {`${formatMessage("ATTENTION")}!`}
          </Alert>
          {formatMessage("send-missing-timesheet-alert")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color={"initial"}>
            {formatMessage("warning-missing-ts-compiling-sending-confirmation")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAlert(false)} color="primary">
            {formatMessage("Cancel")}
          </Button>

          <Button
            disabled={disabled}
            onClick={() => {
              setDisabled(true)
              onSend()
            }}
            color="primary"
          >
            {formatMessage("SEND")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
