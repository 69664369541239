import { setLocale, getLocale } from "../Common/localization/localization"

export const CHANGE_LOCALE = "CHANGE_LOCALE"

export const changeLocale = (locale) => ({
  type: CHANGE_LOCALE,
  payload: locale
})

const initialState = getLocale()

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_LOCALE":
      setLocale(action.payload)
      return action.payload

    default:
      setLocale(state)
      return state
  }
}
