import { formatDateNoTimezone } from "../../../../../Utils/formatDateNoTimezone"
import clone from "./Clone"
import { parseUserLevelDate } from "./ParseUserLevelDate"

export const parseUserDates = (user) => {
  let parsedUser = clone(user)

  if (parsedUser.contractEnd)
    parsedUser.contractEnd = formatDateNoTimezone(parsedUser.contractEnd)
  if (parsedUser.joiningDate)
    parsedUser.joiningDate = formatDateNoTimezone(parsedUser.joiningDate)
  if (parsedUser.leavingDate)
    parsedUser.leavingDate = formatDateNoTimezone(parsedUser.leavingDate)
  if (parsedUser.orderEnd)
    parsedUser.orderEnd = formatDateNoTimezone(parsedUser.orderEnd)

  if (parsedUser.currentLevel) {
    parsedUser.currentLevel = parseUserLevelDate(parsedUser.currentLevel)
  }

  return parsedUser
}
