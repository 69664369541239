import Icon from "./Icon";
import "./Button.scss";

export default function Button({
  style,
  className,
  id,
  text,
  icon,
  disabled,
  onClick
}) {
  return (
    <button
      id={ id }
      disabled={ disabled }
      className={`preallocation-btn btn ${className}`}
      onClick={ onClick }
    >
      { icon && <Icon iconRelativePath={icon} /> }
      <span className={ icon && `ml-1` }>
        { text }
      </span>
    </button>
  )
}
