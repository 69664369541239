import { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"

export function useSelectTypes() {
  const config = useSelector((state) => state.Auth.config)
  useEffect(() => {
    if (config.wbsTypes) {
      setTypes(config.wbsTypes)
    }
  }, [config])

  const [types, setTypes] = useState(config?.wbsTypes || [])
  const parseType = (typeCode) => {
    return types.find((type) => type.code === typeCode)?.name || "-"
  }

  const getTypesAsOption = () => types.map((t) => ({ id: t.code, cell: t.name }))
  const [typeOptions, setTypeOptions] = useState(getTypesAsOption())
  useEffect(() => {
    setTypeOptions(getTypesAsOption())
  }, [types])

  return { types, parseType, typeOptions }
}
