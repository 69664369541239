import { formatMessage } from "devextreme/localization"
import React, { Fragment } from "react"
import { useSelector } from "react-redux"
import { withRouter } from "react-router"
import { Card, CardBody } from "reactstrap"
import PageTitle from "../../../Layout/AppMain/PageTitle"
import { PeriodDropDown } from "../Components/periodDropDown"
import MissingTimesheetsTable from "./table"

export default withRouter(function MissingTimesheets(props) {
  const selectedPeriod = useSelector((state) => state.Period.selectedPeriod)

  return (
    <Fragment>
      <PageTitle
        heading={formatMessage("missing-timesheet")}
        icon={"pe-7s-way text-success"}
        actions={
          <Fragment>
            <PeriodDropDown review="anyReview" />
          </Fragment>
        }
      />
      <Card>
        <CardBody>
          <MissingTimesheetsTable
            history={props.history}
            selectedPeriod={selectedPeriod}
          />
        </CardBody>
      </Card>
    </Fragment>
  )
})
