import {useState} from "react";

export const useDebounce = (debounceTime) => {
  const [debounceTimerId, setDebounceTimerId] = useState(null);

  const debounce = (callback) => {
    if (debounceTimerId) {
      clearTimeout(debounceTimerId)
    }

    const timerId = setTimeout(callback, debounceTime);
    setDebounceTimerId(timerId)
  };

  return {
    debounce
  };
}
