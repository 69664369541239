import { useEffect, useState } from "react"
import moment from "moment"
import { useTeams } from "./useTeams"
import { getOrganizations } from "../../../../services"
import { useAppAbility } from "../../../../Context/ability"

export const useResourceView = () => {
  const [filters, setFilters] = useState({
    startDate: moment().startOf("day").toDate(),
    aggregation: "WEEKLY",
    showFreeTime: false,
    showDetails: false,
    search: "",
    order: "ASC",
    team: "all",
  })
  const [areAllResourcesShown, setAreAllResourcesShown] = useState(false)
  const [sortedTeams, setSortedTeams] = useState([])

  const {
    teams: rawTeams,
    isLoading,
    loadError,
    loadTeams,
    loadTeamDetails,
    loadMore,
    loadTeam,
    setIsTeamOpen,
    setIsTeamPinned,
    paginationMeta,
    loadPage,
  } = useTeams()

  const [teamList, setTeamList] = useState([])

  const { can } = useAppAbility()
  const canManageTeams = can("manage", "ResourceAllocations")

  useEffect(() => {
    if (!rawTeams) {
      return
    }

    setSortedTeams(sortTeams(rawTeams))
  }, [rawTeams, filters.order])

  useEffect(() => {
    updateAreAllResourcesShown()
    updateStartDate()
  }, [rawTeams])

  const sortTeams = (teams) => {
    const { pinnedTeams, unpinnedTeams } = teams.reduce(
      ({ pinnedTeams, unpinnedTeams }, current) => {
        if (current.isPinned) {
          return {
            pinnedTeams: [...pinnedTeams, current],
            unpinnedTeams,
          }
        } else {
          return {
            pinnedTeams,
            unpinnedTeams: [...unpinnedTeams, current],
          }
        }
      },
      { pinnedTeams: [], unpinnedTeams: [] }
    )

    return [...pinnedTeams, ...unpinnedTeams]
  }

  const toggleIsOpen = (team) => setIsTeamOpen(team, !team.isOpen, filters)
  const toggleIsPinned = (team) => setIsTeamPinned(team, !team.isPinned)
  const toggleAreAllResourcesShown = () => {
    if (!rawTeams) {
      return
    }
    if (areAllResourcesShown) {
      rawTeams.forEach((team) => setIsTeamOpen(team, false, filters))
    } else {
      const teamIds = rawTeams.map((team) => team.id)
      loadTeamDetails(teamIds, filters)
    }
  }

  const updateStartDate = () => {
    if (rawTeams.length === 0) {
      return
    }

    setFilters({
      ...filters,
      startDate: moment(rawTeams[0].teamTimeSlots[0].startDate).toDate(),
    })
  }

  const updateAreAllResourcesShown = () => {
    if (rawTeams.length === 0) {
      return
    }

    const areAllTeamsOpen = !rawTeams.some((team) => !team.isOpen)

    if (areAllTeamsOpen !== areAllResourcesShown) {
      setAreAllResourcesShown(areAllTeamsOpen)
    }
  }

  const loadTeamList = async () => {
    const params = canManageTeams
      ? {}
      : { user_is_owner: true, include_childs: true, user_is_area_deputy: true }
    const { data } = await getOrganizations(params)
    setTeamList(data)
  }

  return {
    filters,
    setFilters,
    areAllResourcesShown,
    setAreAllResourcesShown,
    teams: sortedTeams,
    isLoading,
    loadError,
    loadTeams,
    loadMore,
    loadTeam,
    toggleIsOpen,
    toggleIsPinned,
    toggleAreAllResourcesShown,
    teamList,
    loadTeamList,
    paginationMeta,
    loadPage,
  }
}
