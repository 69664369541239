export const formatPercentage = (percentage, fractionDigits = 2) => {
  if (
    (percentage !== 0 && !percentage) ||
    typeof percentage !== "number" ||
    isNaN(percentage)
  ) {
    return "-"
  }

  return `${percentage.toFixed(fractionDigits)} %`
}
