import React from "react"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import Stepper from "@material-ui/core/Stepper"
import { makeStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import localization from "../../../config/localization.json"
import {
  faPauseCircle,
  faCircle,
  faCheckCircle,
  faPlayCircle
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import clsx from "clsx"
import { getTeamsChatUrl } from "../Utils/utils.js"
import { formatMessage } from "devextreme/localization"

const FONTSIZE = 13

const useColorlibStepIconStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  active: {
    color: "rgb(12.35.64)"
  },
  completed: {
    color: "#73a839"
  }
})

function ColorlibStepIcon(props, status, row) {
  const classes = useColorlibStepIconStyles()
  const { active, completed, icon } = props

  const getIcon = () => {
    if (status === "onHold" && icon === 6) {
      return <FontAwesomeIcon icon={faPauseCircle} color="grey" size="2x" />
    } else if (status === "ppa" && icon === 7) {
      return <FontAwesomeIcon icon={faPlayCircle} size="2x" color="rgb(221,86,0)" />
    } else if (status === "closed" && icon === 6) {
      return <FontAwesomeIcon icon={faCheckCircle} size="2x" />
    } else if (completed) {
      return <FontAwesomeIcon icon={faCheckCircle} size="2x" />
    } else if (active) {
      return <FontAwesomeIcon icon={faPlayCircle} size="2x" color="rgb(221,86,0)" />
    } else {
      return <FontAwesomeIcon icon={faCircle} size="1x" style={{ marginTop: 5 }} />
    }
  }

  const icons = {
    1: getIcon(),
    2: getIcon(),
    3: getIcon(),
    4: getIcon(),
    5: getIcon(),
    6: getIcon(),
    7: getIcon()
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

const preview = window.innerWidth / 60

const setPreview = (text) => {
  if (!text || text === " ") return ""

  if (text.length > preview) {
    return text.substring(0, preview) + "..."
  } else {
    return text.substring(0, preview)
  }
}

const setDescription = (descr) => {
  switch (descr) {
    case "":
      return ""
    case " ":
      return ""
    default:
      return descr
  }
}

const generateStep = ({ key, reviewLabel, reviewCode, timesheetRow, row }) => {
  return {
    key,
    label: reviewLabel,
    owner:
      reviewCode === "userCompiling"
        ? row.owner.firstName + " " + row.owner.lastName
        : timesheetRow[reviewCode]?.owner?.split("@")[0] || "",
    code: row.currentStatus === reviewCode ? "" : timesheetRow[reviewCode]?.code,
    wbs: row.currentStatus === reviewCode ? "" : timesheetRow[reviewCode]?.wbs,
    activity:
      row.currentStatus === reviewCode ? "" : timesheetRow[reviewCode]?.activity,
    shortActivity:
      row.currentStatus === reviewCode
        ? ""
        : setPreview(timesheetRow[reviewCode].activity),
    hours: row.currentStatus === reviewCode ? "" : timesheetRow[reviewCode].hours,
    days: row.currentStatus === reviewCode ? "" : timesheetRow[reviewCode].days,
    description:
      row.currentStatus === reviewCode
        ? ""
        : setDescription(timesheetRow[reviewCode].description),
    shortDescription:
      row.currentStatus === reviewCode
        ? ""
        : setPreview(timesheetRow[reviewCode].description),
    notes: row.currentStatus === reviewCode ? "" : timesheetRow[reviewCode].notes,
    shortNotes:
      row.currentStatus === reviewCode
        ? ""
        : setPreview(timesheetRow[reviewCode].notes),
    ownerEmail:
      reviewCode === "userCompiling"
        ? row.owner.email
        : timesheetRow[reviewCode]?.owner // used to generate mircrosoft teams chat url
  }
}

const getSteps = ({ row, invalidWbsCodes }) => {
  const { timesheetRow } = row

  let steps = []

  !Object.keys(timesheetRow.userCompiling || []).length
    ? steps.push({ key: 1, label: "Original" })
    : steps.push(
        generateStep({
          key: 1,
          reviewLabel: "Original",
          reviewCode: "userCompiling",
          timesheetRow,
          row
        })
      )

  !Object.keys(timesheetRow.preReview || []).length
    ? steps.push({ key: 2, label: "Pre Review" })
    : steps.push(
        generateStep({
          key: 2,
          reviewLabel: "Pre Review",
          reviewCode: "preReview",
          timesheetRow,
          row
        })
      )

  !Object.keys(timesheetRow.firstReview || []).length
    ? steps.push({ key: 3, label: "Job Review" })
    : steps.push(
        generateStep({
          key: 3,
          reviewLabel: "Job Review",
          reviewCode: "firstReview",
          timesheetRow,
          row
        })
      )

  !Object.keys(timesheetRow.thirdReview || []).length
    ? steps.push({ key: 4, label: "Third Review" })
    : steps.push(
        generateStep({
          key: 4,
          reviewLabel: "Third Review",
          reviewCode: "thirdReview",
          timesheetRow,
          row
        })
      )

  !Object.keys(timesheetRow.completed || []).length
    ? steps.push({ key: 5, label: "Completed" })
    : steps.push(
        generateStep({
          key: 5,
          reviewLabel: "Completed",
          reviewCode: "completed",
          timesheetRow,
          row
        })
      )

  if (
    !Object.keys(timesheetRow.closed || []).length ||
    row.currentStatus === "onHold"
  ) {
    steps.push({
      key: 6,
      label: row.currentStatus === "onHold" ? "On Hold" : "Closed",
      wbs:
        row.currentStatus === "onHold" &&
        invalidWbsCodes?.find((wbs) => wbs === timesheetRow?.onHold?.wbs) != null &&
        "Waiting for WBS"
    })
  } else {
    steps.push({
      key: 6,
      label: "Closed",
      code: timesheetRow.closed.code,
      hours: timesheetRow.closed.hours,
      days: timesheetRow.closed.days,
      wbs: timesheetRow.closed.wbs,
      activity: timesheetRow.closed.activity,
      shortActivity: setPreview(timesheetRow.closed.activity),
      description: setDescription(timesheetRow.closed.description),
      shortDescription: setPreview(timesheetRow.closed.description),
      notes: timesheetRow.closed.notes,
      shortNotes: setPreview(timesheetRow.closed.notes)
    })
  }

  if (Object.keys(timesheetRow.ppa || []).length) {
    steps.push(
      generateStep({
        key: 7,
        reviewLabel: row.currentStatus === "ppa" ? "ON PPA" : "PPA",
        reviewCode: "ppa",
        timesheetRow,
        row
      })
    )
  }

  return steps
}

const getCurrentStep = ({ row }) => {
  if (row.currentStatus === "onHold" || row.currentStatus === "closed") return 7

  if (row.currentStatus === "ppa") return 6

  switch (row.statusDetails.code) {
    case "userCompiling":
      return 0
    case "preReview":
      return 1
    case "firstReview":
      return row.timesheetRow.firstReview.status !== "skipped" &&
        row.timesheetRow.firstReview.status !== "completed"
        ? 2
        : 3
    case "thirdReview":
      return 3
    case "completed":
      return 4
    case "closed":
      return 5
    case "ppa":
      return 6
    default:
      return 0
  }
}

export const MasterTemplate = React.memo(
  ({ unitIsHours, data, invalidWbsCodes }) => {
    const row = data.data
    const steps = getSteps({ row, unitIsHours, invalidWbsCodes })
    const currentStep = getCurrentStep({ row })

    return (
      <div>
        <Stepper activeStep={currentStep} alternativeLabel>
          {steps.map((item) => {
            return (
              <Step style={{ minWidth: "10%" }} key={item.key}>
                <StepLabel
                  StepIconComponent={(props) =>
                    ColorlibStepIcon(props, row.currentStatus, row)
                  }
                >
                  <Typography style={{ fontSize: FONTSIZE }}>
                    <strong>{item.label.toUpperCase()}</strong>
                  </Typography>
                  {item.owner && (
                    <Typography style={{ fontSize: FONTSIZE }}>
                      <Tooltip title={"Owner"}>
                        <i className={"pe-7s-user"} style={{ marginRight: 5 }} />
                      </Tooltip>
                      <a
                        title={formatMessage("tooltip-open-teams-chat")}
                        href={getTeamsChatUrl(item.ownerEmail)}
                        target="_blank"
                      >
                        {item.owner}
                      </a>
                    </Typography>
                  )}
                  {item.wbs && (
                    <Typography style={{ fontSize: FONTSIZE }}>
                      <Tooltip title={"Job"}>
                        <i
                          className={"pe-7s-portfolio"}
                          style={{ marginRight: 5 }}
                        />
                      </Tooltip>
                      {item.wbs}
                    </Typography>
                  )}
                  {item.activity && (
                    <Tooltip title={item.activity}>
                      <Typography
                        display={"block"}
                        noWrap={true}
                        style={{ fontSize: FONTSIZE }}
                      >
                        <Tooltip title={"Project"}>
                          <i className={"pe-7s-graph3"} style={{ marginRight: 5 }} />
                        </Tooltip>
                        {item.shortActivity}
                      </Typography>
                    </Tooltip>
                  )}
                  {item.description && (
                    <Tooltip title={item.description}>
                      <Typography
                        align="center"
                        noWrap={true}
                        style={{ fontSize: FONTSIZE }}
                      >
                        <Tooltip title={"Description"}>
                          <i
                            className={"pe-7s-comment"}
                            style={{ marginRight: 5 }}
                          />
                        </Tooltip>
                        {item.shortDescription}
                      </Typography>
                    </Tooltip>
                  )}
                  {item.hours != null ? (
                    <Typography style={{ fontSize: FONTSIZE }}>
                      <Tooltip title={unitIsHours ? "Hours" : "Days"}>
                        <i className={"pe-7s-clock"} style={{ marginRight: 5 }} />
                      </Tooltip>
                      {unitIsHours
                        ? item.hours.toLocaleString(localization.lang)
                        : item.days.toLocaleString(localization.lang)}
                    </Typography>
                  ) : (
                    <div></div>
                  )}
                  {item.notes && (
                    <Tooltip title={item.notes}>
                      <Typography
                        display={"block"}
                        noWrap={true}
                        style={{ fontSize: FONTSIZE }}
                      >
                        <Tooltip title={"Notes"}>
                          <i className={"pe-7s-chat"} style={{ marginRight: 5 }} />
                        </Tooltip>
                        {item.shortNotes}
                      </Typography>
                    </Tooltip>
                  )}
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </div>
    )
  }
)
