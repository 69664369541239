import "./ButtonGroup.scss"

export default function ButtonGroup({
  style,
  className,
  children,
}) {
  return (
    <div className={`preallocation-btn-group btn-group ${className}`} style={style}>
      {
        children?.map((child, index) => (
          <button
            type="button"
            key={index}
            className='btn btn-outline-primary'
            onClick={() => child.onClick && child.onClick()}
          >
            { child }
          </button>
        ))
      }
    </div>
  )
}
