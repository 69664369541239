import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "../InputDefaults.scss"
import { LabelWithError } from "../LabelWithError"
import "./DatePickerInput.scss"

export default function DatePickerInput({
  className,
  value,
  onChange,
  label,
  inputClassName,
  wrapperStyle,
  error = "",
  readOnly = false,
  disabled = false,
}) {
  const randomId = Math.random().toString()

  const change = (event) => {
    if (onChange) {
      onChange(event)
    }
  }

  return (
    <div
      style={wrapperStyle}
      className={`${className} date-picker-input-container d-flex flex-column ${
        readOnly ? "readonly" : disabled ? "disabled" : "cursor"
      }`}
    >
      {label ? (
        <LabelWithError
          disabled={disabled}
          readOnly={readOnly}
          label={label}
          error={error}
          labelClassName={`label mb-2`}
          htmlFor={randomId}
        />
      ) : (
        ""
      )}
      <DatePicker
        id={randomId}
        disabled={disabled}
        readOnly={readOnly}
        className={`w-100 ${inputClassName} ${error !== "" ? "border-danger" : ""} ${
          readOnly ? "readonly" : disabled ? "disabled" : "cursor"
        }`}
        selected={value}
        onChange={(date) => change(date)}
      />
    </div>
  )
}
