export const PREALLOCATION_PALETTE = [
  '#BB9AFF',
  '#78CDD9',
  '#FB9C9C',
  '#91BDFF',
  '#F7F5B7',
  '#B2EBF2',
  '#E1BEE7',
  '#9AF8A9',
  '#FFCCBC',
  '#C5CAE9',
]
