import { find } from "underscore"
import CustomStore from "devextreme/data/custom_store"
import { saveTimesheet } from "../../../services"

const reviewCode = "userCompiling"

const updateRow = (before, after) => {
  switch (typeof after) {
    case "string":
      return after
    case "number":
      return after
    default:
      return before
  }
}

export const getDataSource = ({ getTimesheet, tsId, jobs }) => {
  return {
    store: new CustomStore({
      load: async () => {
        const data = await getTimesheet()
        return data.filter(
          (tsr) =>
            (tsr.userCompiling?.code || tsr.ppa?.code || tsr.closed?.code) &&
            // Exclude cancelled rows during review
            (!tsr.closed || tsr.closed?.hours)
        )
      },
      update: (before, dataToUpdate) => {
        let newHours = 0
        let newDays = 0

        if (typeof dataToUpdate[reviewCode].hours === "number") {
          newHours = dataToUpdate[reviewCode].hours
          newDays = dataToUpdate[reviewCode].hours / 8
        }

        if (typeof dataToUpdate[reviewCode].days === "number") {
          newHours = dataToUpdate[reviewCode].days * 8
          newDays = dataToUpdate[reviewCode].days
        }

        const newCode = updateRow(
          before[reviewCode].code,
          dataToUpdate[reviewCode].code
        )

        const jobDetails = find(jobs._array, (j) => j.id === newCode)

        return saveTimesheet({
          tsId: tsId,
          rowId: before._id,
          code: newCode,
          wbs: jobDetails.wbs,
          activity: jobDetails.description,
          description:
            updateRow(
              before[reviewCode].description,
              dataToUpdate[reviewCode].description
            ) || "",
          hours: newHours || before[reviewCode].hours || 0,
          days: newDays || before[reviewCode].days || 0
        })
      },
      insert: (data) => {
        const { code, description, hours, days } = data[reviewCode]
        if (!code && !description && !hours && !days) {
          return
        } else {
          const jobDetails = find(jobs._array, (j) => j.id === code)

          return saveTimesheet({
            tsId: tsId,
            code: code,
            description: description || "",
            wbs: jobDetails.wbs,
            activity: jobDetails.description,
            hours: hours ? hours : days * 8,
            days: days ? days : hours / 8
          })
        }
      },
      remove: async (data) => {
        /* return deleteTimesheetRow({ not being used. Check button delete onClick
          tsId: tsId,
          rowId: data._id 
        }) */
      }
    })
  }
}
