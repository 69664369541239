import {formatDateNoTime} from "../../../../Utils/formatDateNoTime";

export const parseSingleResourceFiltersToParams = (filters, resourceFilters) => {
  let params = {
    aggregation: resourceFilters.aggregation,
    startDate: formatDateNoTime(resourceFilters.startDate),
    filterStartDate: formatDateNoTime(filters.startDate),
    filterEndDate: formatDateNoTime(filters.endDate),
    toleranceStartDate: filters.startDateTolerance,
    toleranceEndDate: filters.endDateTolerance
  };

  return params;
}
