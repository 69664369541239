import { formatMessage } from "devextreme/localization"
import { useEffect, useState } from "react"
import { useAppAbility } from "../../../../../Context/ability"
import PageTitle from "../../../../../Layout/AppMain/PageTitle"
import AddYearButton from "../../components/AddYearButton"
import BackButton from "../../components/BackButton"
import CloneWbsButton from "../../components/CloneWbsButton"
import ConfirmModal from "../../components/common/ConfirmModal"
import DeleteWbsButton from "../../components/DeleteWbsButton"
import { ErrorBlock } from "../../components/ErrorBlock"
import { Spinner } from "../../components/Spinner"
import WbsDetailsForm from "../../components/WbsDetailsForm"
import WhiteBoxContainer from "../../components/WhiteBoxContainer"
import YearsTable from "../../components/YearsTable"
import { useEditWbs } from "../../hooks/useEditWbs"
import { useWbsData } from "../../hooks/useWbsData"
import { subject } from "@casl/ability"

const parseFooterData = (data) => {
  data?.fiscalYears?.reduce(
    (reducedFooterData, rowData) => {
      return {
        contractValue: reducedFooterData.contractValue + rowData.contractValue,
        standardCostsTotal:
          reducedFooterData.standardCostsTotal + rowData.standardCostsTotal,
        otherCosts: reducedFooterData.otherCosts + rowData.otherCosts,
        targetMargin: reducedFooterData.targetMargin + rowData.targetMargin,
        revenueXtechManagerial:
          reducedFooterData.revenueXtechManagerial + rowData.revenueXtechManagerial,
        rfpXTech: reducedFooterData.rfpXTech + rowData.rfpXTech,
        standardCostsXtech:
          reducedFooterData.standardCostsXtech + rowData.standardCostsXtech,
        xTechMargin: reducedFooterData.xTechMargin + rowData.xTechMargin
      }
    },
    {
      contractValue: 0,
      standardCostsTotal: 0,
      otherCosts: 0,
      targetMargin: 0,
      revenueXtechManagerial: 0,
      rfpXTech: 0,
      standardCostsXtech: 0,
      xTechMargin: 0
    }
  )
}

export default function WbsEdit({ match }) {
  const localStorageKey = "wbs-table-filters-and-sorting"

  const { can } = useAppAbility()
  const [canManageWbs, setCanManageWbs] = useState(false)

  const wbsQuery = useWbsData(match.params.id)

  const [pinnedUntil, setPinnedUntil] = useState(
    JSON.parse(localStorage.getItem(localStorageKey))?.pinnedUntil || null
  )

  const [wbs, setWbs] = useState(wbsQuery.isSuccess ? wbsQuery.data : {})

  useEffect(() => {
    if (!wbsQuery.isSuccess) {
      return
    }
    setWbs(wbsQuery.data)
  }, [wbsQuery.isSuccess, wbsQuery.data])

  const [fiscalYears, setFiscalYears] = useState(
    wbsQuery.isSuccess ? wbsQuery.data.fiscalYears : []
  )
  useEffect(() => {
    setFiscalYears(wbs.fiscalYears)
    const canManage =
      can("manage", subject("Wbs", wbs))
    setCanManageWbs(canManage)
  }, [wbs])

  const { mutation, errors } = useEditWbs()

  const onValueChanged = (field, value) => {
    const newData = {
      ...wbs,
      isFinal: field === "isTempPlaced" && value ? false : wbs.isFinal,
      isTempPlaced: field === "isFinal" && value ? false : wbs.isTempPlaced
    }
    newData[field] = value
    setWbs(newData)
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalBody, setModalBody] = useState("")

  const openModal = () => setIsModalOpen(true)
  const save = () => mutation.mutate(wbs)
  const saveOrOpenModal = () => {
    if (
      !wbsQuery.data.isFinal &&
      !wbsQuery.data.isTempPlaced &&
      (wbs.isFinal || wbs.isTempPlaced)
    ) {
      setModalBody(formatMessage("warning-wbs-admin-final-affects-onhold-ts"))
      openModal()
      return
    }

    if (
      (wbsQuery.data.contractValue === 0 ||
        wbsQuery.data.contractValue * wbs.contractValue < 0) &&
      (wbsQuery.data.standardCostsTotal === 0 ||
        wbsQuery.data.standardCostsTotal * wbs.standardCostsTotal < 0) &&
      (wbsQuery.data.otherCosts === 0 ||
        wbsQuery.data.otherCosts * wbs.otherCosts < 0)
    ) {
      setModalBody(formatMessage("warning-wbs-admin-sign-change"))
      openModal()
      return
    }

    save()
  }

  const isActive = () => Boolean(wbsQuery.data?.isActive)
  const isChargeable = () => Boolean(wbsQuery.data?.type === "1")

  return (
    <>
      {wbsQuery.isError ? (
        <ErrorBlock error={wbsQuery.error} />
      ) : (
        <>
          <PageTitle
            heading={formatMessage("jw-Details") + " " + formatMessage("jw-WBS")}
            subheading={`${formatMessage("admin-job-wbs-title")} / ${
              wbsQuery.isLoading ? "-" : wbs.code
            }`}
            beforeActions={<BackButton to="/admin/job-and-wbs" className="mr-4" />}
            actions={
              canManageWbs ? (
                <>
                  <DeleteWbsButton
                    disabled={wbsQuery.isLoading}
                    wbs={wbsQuery.data}
                  />
                  <CloneWbsButton
                    disabled={wbsQuery.isLoading}
                    className="ml-2"
                    extended={true}
                    wbs={wbsQuery.data}
                  />
                  <ConfirmModal
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                    onConfirm={() => {
                      setIsModalOpen(false)
                      save()
                    }}
                    onCancel={() => {
                      setIsModalOpen(false)
                    }}
                    title={formatMessage("jw-Confirmation")}
                    body={modalBody}
                    confirmButtonText={formatMessage("jw-Save")}
                    cancelButtonText={formatMessage("jw-Cancel")}
                  />
                  <button
                    disabled={wbsQuery.isLoading}
                    className={`btn btn-primary mx-4`}
                    onClick={saveOrOpenModal}
                  >
                    {mutation.isLoading && <Spinner className={"mx-1"} />}{" "}
                    {formatMessage("jw-Save")}
                  </button>
                </>
              ) : (
                ""
              )
            }
          />
          <div className="mt-4 mb-3">
            {formatMessage("admin-job-wbs-title")} /{" "}
            {wbsQuery.isLoading ? "-" : wbsQuery.data.code}
            <span className="mx-3" />
            {mutation.isLoading ? (
              <span className="text-warning">
                <Spinner className={"mx-1"} />
                {formatMessage("jw-Saving...")}
              </span>
            ) : (
              ""
            )}
            {mutation.isSuccess ? (
              <span className="text-success">{formatMessage("jw-Saved")}</span>
            ) : (
              ""
            )}
            {mutation.isError ? (
              <span className="text-danger">{formatMessage("jw-Error")}</span>
            ) : (
              ""
            )}
          </div>
          <WhiteBoxContainer>
            {wbsQuery.isLoading ? (
              <div className="text-center">
                <Spinner className={"lg"} />
              </div>
            ) : (
              <WbsDetailsForm
                wbs={wbs}
                onValueChanged={onValueChanged}
                errors={errors}
                canManage={canManageWbs}
              />
            )}
          </WhiteBoxContainer>
          <div className="mt-5 mb-3">
            {wbsQuery.isLoading ? "-" : wbsQuery.data.code} /{" "}
            {formatMessage("jw-Fiscal Years")}
          </div>
          {canManageWbs && (
            <AddYearButton
              className="mb-3"
              wbs={wbs}
              disabled={wbsQuery.isLoading || !isActive()}
              extended={true}
            />
          )}
          {wbsQuery.isLoading ? (
            <WhiteBoxContainer>
              <div className="text-center">
                <Spinner className={"lg"} />
              </div>
            </WhiteBoxContainer>
          ) : (
            <div style={{ width: "calc(100vw - 140px)" }}>
              <YearsTable
                isLoading={wbsQuery.isLoading}
                fiscalYears={fiscalYears}
                footerData={parseFooterData(wbs)}
                isChargeable={isChargeable()}
                isActive={isActive()}
                pinnedUntil={pinnedUntil}
                setPinnedUntil={setPinnedUntil}
              />
            </div>
          )}
        </>
      )}
    </>
  )
}
