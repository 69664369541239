import { formatMessage } from "devextreme/localization"
import Dino from "../../../Dino"

export function ErrorBlock({ error }) {
  const message =
    error?.response?.data?.message || error?.message || error?.textMessage

  return (
    <>
      <h2 style={{ textAlign: "center", marginTop: 100, whiteSpace: "pre-line" }}>
        {message ? message : formatMessage("jw-Oops. Something went wrong")}
      </h2>
      <Dino />
    </>
  )
}
