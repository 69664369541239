import "./OpaqueOverlay.scss"

export const OpaqueOverlay = ({ exclude }) => {
  return exclude ? (
    <>
      <div
        className="opaque-overlay"
        style={{
          height: exclude?.top,
          top: 0
        }}
      />
      <div
        className="opaque-overlay"
        style={{
          top: exclude?.bottom
        }}
      />
    </>
  ) : (
    <div className="opaque-overlay" />
  )
}
