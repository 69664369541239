import { formatDateNoTime } from "../../../../Utils/formatDateNoTime"

export const parseProjectsFiltersToParams = (filters) => {
  let params = {
    ...filters,
    startDate: formatDateNoTime(filters.startDate)
  }

  if (params.team === "all") {
    delete params.team
  }

  for (const key of Object.keys(params)) {
    if (params[key] === null || params[key] === undefined || params[key] === "") {
      delete params[key]
    }
  }

  return params
}
