import { formatMessage } from "devextreme/localization"
import { useEffect, useRef, useState } from "react"
import { useAppAbility } from "../../../../../Context/ability"
import PageTitle from "../../../../../Layout/AppMain/PageTitle"
import AddJobButton from "../../components/AddJobButton"
import BackButton from "../../components/BackButton"
import DeleteYearButton from "../../components/DeleteYearButton"
import { ErrorBlock } from "../../components/ErrorBlock"
import { Spinner } from "../../components/Spinner"
import WhiteBoxContainer from "../../components/WhiteBoxContainer"
import YearDetailsForm from "../../components/YearDetailsForm"
import JobsTable from "../../components/JobsTable"
import { useEditYear } from "../../hooks/useEditYear"
import { useWbsData } from "../../hooks/useWbsData"
import { useYearData } from "../../hooks/useYearData"
import { subject } from "@casl/ability"

const parseFooterData = (data) => {
  data?.fiscalYears?.reduce(
    (reducedFooterData, rowData) => {
      return {
        contractValue: reducedFooterData.contractValue + rowData.contractValue,
        standardCostsTotal:
          reducedFooterData.standardCostsTotal + rowData.standardCostsTotal,
        otherCosts: reducedFooterData.otherCosts + rowData.otherCosts,
        targetMargin: reducedFooterData.targetMargin + rowData.targetMargin,
        revenueXtechManagerial:
          reducedFooterData.revenueXtechManagerial + rowData.revenueXtechManagerial,
        rfpXTech: reducedFooterData.rfpXTech + rowData.rfpXTech,
        standardCostsXtech:
          reducedFooterData.standardCostsXtech + rowData.standardCostsXtech,
        xTechMargin: reducedFooterData.xTechMargin + rowData.xTechMargin,
      }
    },
    {
      contractValue: 0,
      standardCostsTotal: 0,
      otherCosts: 0,
      targetMargin: 0,
      revenueXtechManagerial: 0,
      rfpXTech: 0,
      standardCostsXtech: 0,
      xTechMargin: 0,
    }
  )
}

export default function YearEdit({ match }) {
  const localStorageKey = "year-table-filters-and-sorting"

  const { can } = useAppAbility()
  const [canManageFiscalYear, setCanManageFiscalYear] = useState(false)

  const wbsQuery = useWbsData(match.params.wbsId)
  const yearQuery = useYearData(match.params.id, true)

  const [pinnedUntil, setPinnedUntil] = useState(
    JSON.parse(localStorage.getItem(localStorageKey))?.pinnedUntil || null
  )

  const [year, setYear] = useState(yearQuery.isSuccess ? yearQuery.data : {})
  useEffect(() => {
    if (!yearQuery.isSuccess) {
      return
    }
    setYear(yearQuery.data)
    setCanManageFiscalYear(can("manage", subject("FiscalYear", yearQuery.data)))
  }, [yearQuery.isSuccess, yearQuery.data])

  const [jobs, setJobs] = useState([])
  useEffect(() => {
    if (!(wbsQuery.isSuccess && yearQuery.isSuccess)) {
      return
    }
    setJobs(
      wbsQuery.data.fiscalYears?.find((year) => year.year === yearQuery.data.year)
        ?.jobs || []
    )
  }, [wbsQuery.isSuccess, wbsQuery.data, yearQuery.isSuccess, yearQuery.data])

  const { mutation, errors } = useEditYear()

  const onValueChanged = (field, value) => {
    const newData = { ...year }
    newData[field] = value
    setYear(newData)
  }

  const save = () => {
    mutation.mutate({ year, isChargeable: isChargeable() })
  }

  const isActive = () => Boolean(wbsQuery.data?.isActive)
  const isChargeable = () => Boolean(wbsQuery.data?.type === "1")

  return (
    <>
      {yearQuery.isError || wbsQuery.isError ? (
        <ErrorBlock error={yearQuery.error ?? wbsQuery.error} />
      ) : (
        <>
          <PageTitle
            heading={
              formatMessage("jw-Details") + " " + formatMessage("jw-Fiscal Year")
            }
            subheading={`${formatMessage("admin-job-wbs-title")} / ${
              wbsQuery.isLoading ? "-" : `${wbsQuery.data.code}`
            } / ${yearQuery.isLoading ? "-" : `${year.year}`}`}
            beforeActions={<BackButton to="/admin/job-and-wbs" className="mr-4" />}
            actions={
              canManageFiscalYear ? (
                <>
                  <DeleteYearButton
                    disabled={yearQuery.isLoading}
                    year={yearQuery.data}
                  />
                  <button
                    disabled={wbsQuery.isLoading || mutation.isLoading}
                    className={`btn btn-primary ml-4`}
                    onClick={save}
                  >
                    {mutation.isLoading && <Spinner className={"mx-1"} />}{" "}
                    {formatMessage("jw-Save")}
                  </button>
                </>
              ) : (
                ""
              )
            }
          />
          <div className="mt-4 mb-3">
            {formatMessage("admin-job-wbs-title")} /{" "}
            {wbsQuery.isLoading ? "-" : `${wbsQuery.data.code}`} /
            {yearQuery.isLoading ? "-" : `${year.year}`}
            <span className="mx-3" />
            {mutation.isLoading ? (
              <span className="text-warning">
                <Spinner className={"mx-1"} /> {formatMessage("jw-Saving...")}
              </span>
            ) : (
              ""
            )}
            {mutation.isSuccess ? (
              <span className="text-success">{formatMessage("jw-Saved")}</span>
            ) : (
              ""
            )}
            {mutation.isError ? (
              <span className="text-danger">{formatMessage("jw-Error")}</span>
            ) : (
              ""
            )}
          </div>
          <WhiteBoxContainer>
            {yearQuery.isLoading || wbsQuery.isLoading ? (
              <div className="text-center">
                <Spinner className={"lg"} />
              </div>
            ) : (
              <YearDetailsForm
                errors={errors}
                year={year}
                wbs={wbsQuery.data}
                onValueChanged={onValueChanged}
                canManage={canManageFiscalYear}
              />
            )}
          </WhiteBoxContainer>
          <div className="mt-5 mb-3">
            {wbsQuery.isLoading ? "-" : wbsQuery.data.code} /{" "}
            {yearQuery.isLoading ? "-" : yearQuery.data.year} /{" "}
            {formatMessage("jw-Jobs")}
          </div>
          {canManageFiscalYear && (
            <AddJobButton
              className="mb-3"
              yearId={year?._id}
              disabled={yearQuery.isLoading || !isActive()}
              extended={true}
            />
          )}
          {yearQuery.isLoading || wbsQuery.isLoading ? (
            <WhiteBoxContainer>
              <div className="text-center">
                <Spinner className={"lg"} />
              </div>
            </WhiteBoxContainer>
          ) : (
            <div style={{ width: "calc(100vw - 140px)" }}>
              <JobsTable
                isLoading={yearQuery.isLoading || wbsQuery.isLoading}
                jobs={jobs}
                footerData={parseFooterData(yearQuery.data)}
                isChargeable={isChargeable()}
                pinnedUntil={pinnedUntil}
                setPinnedUntil={setPinnedUntil}
              />
            </div>
          )}
        </>
      )}
    </>
  )
}
