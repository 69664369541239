import { formatMessage } from "devextreme/localization"
import { withRouter } from "react-router-dom"
import Tooltip from "./common/Tooltip"
import Icon from "./Icon"

export default withRouter(function AddWbsButton({
  className,
  disabled = false,
  extended = false,
  history,
}) {
  return (
    <Tooltip
      disabled={extended}
      content={
        <div className="text-nowrap">
          + {formatMessage("jw-ADD")} {formatMessage("jw-WBS")}
        </div>
      }
    >
      <button
        disabled={disabled}
        className={`btn btn-primary square-btn ${className}`}
        onClick={() => history.push(`/admin/wbs/create`)}
      >
        <Icon iconRelativePath="monade/plus" />{" "}
        {extended ? (
          <span className="ml-1">
            {formatMessage("jw-ADD") + " " + formatMessage("jw-WBS")}
          </span>
        ) : (
          ""
        )}
      </button>
    </Tooltip>
  )
})
