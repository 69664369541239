import { formatMessage } from "devextreme/localization"
import { useEffect, useState } from "react"
import { useAppAbility } from "../../../../../Context/ability"
import PageTitle from "../../../../../Layout/AppMain/PageTitle"
import BackButton from "../../components/BackButton"
import CloneJobButton from "../../components/CloneJobButton"
import ConfirmModal from "../../components/common/ConfirmModal"
import DeleteJobButton from "../../components/DeleteJobButton"
import { ErrorBlock } from "../../components/ErrorBlock"
import JobAccountingDataForm from "../../components/JobAccountingDataForm"
import JobCompetenceRevenueForm from "../../components/JobCompetenceRevenueForm"
import JobDetailsForm from "../../components/JobDetailsForm"
import { Spinner } from "../../components/Spinner"
import WhiteBoxContainer from "../../components/WhiteBoxContainer"
import { useEditJob } from "../../hooks/useEditJob"
import { useJobData } from "../../hooks/useJobData"
import { useWbsData } from "../../hooks/useWbsData"
import { useYearData } from "../../hooks/useYearData"
import { subject } from "@casl/ability"
import { getJobDescriptionMandatoriness } from "../../utils/jobDescriptionMandatoriness"
import constants from "../../../../../Common/constants"

export default function JobEdit({ match, history }) {
  const { can } = useAppAbility()
  const [canManageJob, setCanManageJob] = useState(false)

  const [wbsId, setWbsId] = useState("")
  const [yearId, setYearId] = useState(match.params.yearId)

  const wbsQuery = useWbsData(wbsId)
  const yearQuery = useYearData(yearId)
  useEffect(() => {
    if (!yearQuery.isSuccess) {
      return
    }

    setWbsId(yearQuery.data.wbs)
  }, [yearQuery.isSuccess, yearQuery.data])

  const jobQuery = useJobData(match.params.shortCode, true, true)
  const [job, setJob] = useState(jobQuery.isSuccess ? jobQuery.data : {})
  useEffect(() => {
    if (!jobQuery.isSuccess) {
      return
    }

    setJob(jobQuery.data)
  }, [jobQuery.isSuccess, jobQuery.data])

  useEffect(() => {
    if (jobQuery.isSuccess && wbsQuery.isSuccess && yearQuery.isSuccess) {
      const tempJob = {
        ...jobQuery.data,
        wbs: wbsQuery.data,
        fiscalYear: yearQuery.data,
        internalSupporters: wbsQuery.data.internalSupporters,
        externalSupporters: wbsQuery.data.externalSupporters,
        hasExternalSupport: wbsQuery.data.hasExternalSupport
      }
  
      setCanManageJob(can("manage", subject("Job", tempJob)))
    }
  }, [
    jobQuery.isSuccess,
    jobQuery.data,
    yearQuery.isSuccess,
    yearQuery.data,
    wbsQuery.isSuccess,
    wbsQuery.data
  ])

  const onSaveSuccess = (result) =>
    history.push(`/admin/${yearQuery.data._id}/job/edit/${result.shortCode}`)
  const { mutation, errors } = useEditJob(onSaveSuccess)

  const needsNonChargeableJobParsing = (jobToParse) => {
    return jobToParse.hasOwnProperty("mode")
  }

  const parseNonDatwaveJob = (jobToParse) => {
    const { datwavePurchaseOrder, datwavePurchaseOrderValue, ...result } = jobToParse
    return result
  }

  const parseNonChargeableJob = (jobToParse) => {
    const {
      technologyProducts,
      technologyProviders,
      digitalAssets,
      standardCostsXtech,
      mode,
      jobSpecialties,
      xTechMargin,
      rfpXTech,
      revenueXtechManagerial,
      ...result
    } = jobToParse

    return result
  }

  const onValueChanged = (field, value) => {
    let newData = null
    if (!field) {
      // update more than one field
      newData = { ...job, ...value?.fieldsToAdd }
      value?.fieldsToDelete?.forEach((fieldToDelete) => {
        delete newData[fieldToDelete]
      })
    } else {
      newData = { ...job, [field]: value }
    }
    setJob(newData)
  }

  useEffect(() => {
    const wbs = wbsQuery?.data

    if (!job || !wbs) {
      return
    }

    const wbsType = wbsQuery.data.type
    const isJobDescriptionMandatory = getJobDescriptionMandatoriness(wbsType)

    let jobToSave = job
    let needsSaving = false

    if (isChargeable() && jobToSave.adaMonetized == null) {
      jobToSave = {
        ...jobToSave,
        adaMonetized: false
      }
      needsSaving = true
    }

    if (!isChargeable() && needsNonChargeableJobParsing(jobToSave)) {
      jobToSave = parseNonChargeableJob(jobToSave)
      needsSaving = true
    }

    setIsDescriptionMandatoryReadonly(isJobDescriptionMandatory)

    if (isJobDescriptionMandatory && !jobToSave.isDescriptionMandatory) {
      jobToSave = {
        ...jobToSave,
        isDescriptionMandatory: true
      }
      needsSaving = true
    }

    if (needsSaving) {
      setJob(jobToSave)
    }
  }, [job, wbsQuery.data])

  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = () => setIsModalOpen(true)
  const save = () => {
    let jobToSave = job

    if (!isChargeable()) {
      jobToSave = parseNonChargeableJob(job)
      setJob(jobToSave)
    }

    if (!jobToSave.jobSpecialties?.includes(constants.JobSpecialties.DATWAVE)) {
      jobToSave = parseNonDatwaveJob(job)
      setJob(jobToSave)
    }

    mutation.mutate({ job: jobToSave, isChargeable: isChargeable() })
  }

  const saveOrOpenModal = () => {
    if (confirmOnSave) {
      openModal()
    } else {
      save()
    }
  }

  const isChargeable = () => wbsQuery.data?.type === "1"

  const [confirmOnSave, setConfirmOnSave] = useState(false)
  const [
    isDescriptionMandatoryReadonly,
    setIsDescriptionMandatoryReadonly
  ] = useState(false)

  return (
    <>
      {yearQuery.isError || wbsQuery.isError || jobQuery.isError ? (
        <ErrorBlock error={yearQuery.error ?? wbsQuery.error ?? jobQuery.error} />
      ) : (
        <>
          <PageTitle
            heading={formatMessage("jw-Details") + " " + formatMessage("jw-Job")}
            subheading={`${formatMessage("admin-job-wbs-title")} / ${
              wbsQuery.isLoading ? "-" : `${wbsQuery.data.code}`
            } / ${yearQuery.isLoading ? "-" : `${yearQuery.data.year}`} / ${
              jobQuery.isLoading ? "-" : `${job.shortCode}`
            }`}
            beforeActions={<BackButton to="/admin/job-and-wbs" className="mr-4" />}
            actions={
              <>
                <DeleteJobButton disabled={jobQuery.isLoading} job={jobQuery.data} />
                <CloneJobButton
                  disabled={jobQuery.isLoading}
                  className="ml-2"
                  extended={true}
                  job={job}
                  yearId={job?.fiscalYear}
                />
                <ConfirmModal
                  isOpen={isModalOpen}
                  setIsOpen={setIsModalOpen}
                  onConfirm={() => {
                    setIsModalOpen(false)
                    save()
                  }}
                  onCancel={() => {
                    setIsModalOpen(false)
                  }}
                  title={formatMessage("jw-Confirmation")}
                  body={formatMessage("warning-wbs-admin-final-affects-onhold-ts")}
                  confirmButtonText={formatMessage("jw-Save")}
                  cancelButtonText={formatMessage("jw-Cancel")}
                />
                <button
                  disabled={wbsQuery.isLoading || mutation.isLoading}
                  className={`btn btn-primary ml-4`}
                  onClick={saveOrOpenModal}
                >
                  {mutation.isLoading && <Spinner className={"mx-1"} />}{" "}
                  {formatMessage("jw-Save")}
                </button>
              </>
            }
          />
          <div className="mt-4 mb-3">
            {formatMessage("admin-job-wbs-title")} /{" "}
            {wbsQuery.isLoading ? "-" : wbsQuery.data.code} /{" "}
            {yearQuery.isLoading ? "-" : `${yearQuery.data.year}`} /{" "}
            {jobQuery.isLoading ? "-" : `${jobQuery.data.shortCode}`}
            <span className="mx-3" />
            {mutation.isLoading ? (
              <span className="text-warning">
                <Spinner className={"mx-1"} />
                {formatMessage("jw-Saving...")}
              </span>
            ) : (
              ""
            )}
            {mutation.isSuccess ? (
              <span className="text-success">{formatMessage("jw-Saved")}</span>
            ) : (
              ""
            )}
            {mutation.isError ? (
              <span className="text-danger">{formatMessage("jw-Error")}</span>
            ) : (
              ""
            )}
          </div>
          {jobQuery.isLoading || wbsQuery.isLoading || yearQuery.isLoading ? (
            <div className="text-center">
              <Spinner className={"lg"} />
            </div>
          ) : (
            <>
              <WhiteBoxContainer>
                <JobDetailsForm
                  job={job}
                  year={yearQuery.data}
                  wbs={wbsQuery.data}
                  onValueChanged={onValueChanged}
                  isCreating={false}
                  setYearId={setYearId}
                  wbsId={wbsId}
                  setWbsId={setWbsId}
                  errors={errors}
                  canManage={canManageJob}
                  setConfirmOnSave={setConfirmOnSave}
                  isDescriptionMandatoryReadonly={isDescriptionMandatoryReadonly}
                />
              </WhiteBoxContainer>
              {isChargeable() ? (
                <WhiteBoxContainer>
                  <JobAccountingDataForm
                    job={job}
                    year={yearQuery.data}
                    wbs={wbsQuery.data}
                    onValueChanged={onValueChanged}
                    errors={errors}
                    canManage={canManageJob}
                  />
                </WhiteBoxContainer>
              ) : (
                ""
              )}
              <WhiteBoxContainer>
                <JobCompetenceRevenueForm
                  job={job}
                  year={yearQuery.data}
                  wbs={wbsQuery.data}
                  onValueChanged={onValueChanged}
                  errors={errors}
                  canManage={canManageJob}
                />
              </WhiteBoxContainer>
            </>
          )}
        </>
      )}
    </>
  )
}
