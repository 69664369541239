import { FieldErrorIcon } from "./FieldErrorIcon"
import "./InputDefaults.scss"

export function LabelWithError({
  label,
  error,
  className,
  labelClassName,
  htmlFor,
  onClick,
  disabled = false,
  readOnly = false,
}) {
  return (
    <span
      onClick={onClick}
      className={`d-flex flex-row align-items-center mb-0 ${className} ${
        readOnly ? "readonly" : disabled ? "disabled" : "cursor"
      }`}
    >
      <label
        className={`${
          readOnly ? "readonly" : disabled ? "disabled" : "cursor"
        } text-nowrap label ${error !== "" ? "text-danger" : ""} ${labelClassName}`}
        htmlFor={htmlFor}
      >
        {label}
      </label>
      <FieldErrorIcon className="pl-1 mb-2" message={error} />
    </span>
  )
}
