import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { MsalProvider } from "@azure/msal-react"
import { PublicClientApplication, EventType } from "@azure/msal-browser"
import { msalConfig } from "./authConfig"
import config from "devextreme/core/config"
import "@fortawesome/fontawesome-free/css/all.css"

config({
  editorStylingMode: "outlined",
  forceIsoDateParsing: false
})

export const msalInstance = new PublicClientApplication(msalConfig)

const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
  }
})

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <App pca={msalInstance} />
  </MsalProvider>,
  document.getElementById("root")
)
