import LinearProgress from "@material-ui/core/LinearProgress"
import ArrayStore from "devextreme/data/array_store"
import { formatMessage } from "devextreme/localization"
import moment from "moment"
import React, { Fragment, useEffect, useReducer, useState } from "react"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import { useDispatch, useSelector } from "react-redux"
import { Bounce, toast } from "react-toastify"
import {
  Button as BootButton,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Progress,
  Row
} from "reactstrap"
import { isEmpty } from "underscore"
import localization from "../../../config/localization.json"
import { setAllJobs } from "../../../reducers/Jobs"
import { getJobsThumb, getUserTsByPeriod, initTimesheet } from "../../../services"
import { sendTimesheet } from "../../../services/index"
import { round } from "../../../Utils/utils"
import { sendOriginalTimesheetText } from "../Utils/tsFlowConfig"
import ToggleSwitch from "./../../../assets/components/switch/ToggleSwitch"
import PageTitle from "./../../../Layout/AppMain/PageTitle"
import { PeriodDropDown } from "./../Components/periodDropDown"
import AlertDialog from "./../Utils/AlertDialog"
import { TimesheetTable } from "./TimesheetTable"
import { ExternalGuideRefs } from "../../../Common/guide/guideExternalRefs"

const reviewCode = "userCompiling"

const SendButton = ({ currentStatus, setOpenAlert }) => {
  return (
    currentStatus === "userCompiling" && (
      <BootButton
        className="btn-shadow mr-3"
        onClick={() => {
          setOpenAlert(true)
        }}
        color="dark"
        id="Tooltip-123"
      >
        {formatMessage("SEND")}
      </BootButton>
    )
  )
}

const ContainerHeadLine = ({ selectedPeriod, unitIsHours, onToogleSwitch }) => {
  return (
    <CardTitle>
      <div
        className="d-flex justify-content-between"
        style={{ alignItems: "center" }}
      >
        <div>
          {formatMessage(moment(selectedPeriod._id, "YYYYMM").format("MMMM")).concat(
            moment(selectedPeriod._id, "YYYYMM").format(" YYYY")
          )}
        </div>
        <ToggleSwitch
          id="id"
          defaultChecked={!unitIsHours}
          onChange={onToogleSwitch}
        />
      </div>
    </CardTitle>
  )
}

const ProgressBar = ({
  unitIsHours,
  totalHoursCompiled,
  workingDays,
  currentStatus
}) => {
  const unit = unitIsHours
    ? formatMessage("Hours") + ": "
    : formatMessage("Days") + ": "
  const compiled = unitIsHours ? totalHoursCompiled : totalHoursCompiled / 8
  const toBeCompiled = unitIsHours ? workingDays * 8 : workingDays
  const label =
    unit +
    round(compiled).toLocaleString(localization.lang) +
    "/" +
    toBeCompiled.toLocaleString(localization.lang)

  let barColor

  if (compiled < toBeCompiled) {
    barColor = ""
  } else if (compiled === toBeCompiled) {
    barColor = "success"
  } else if (compiled > toBeCompiled) {
    barColor = "danger"
  }

  return (
    <Progress
      className="mb-3"
      value={compiled}
      color={barColor}
      max={unitIsHours ? workingDays * 8 : workingDays}
      animated={currentStatus === "userCompiling"}
    >
      {!compiled ? "" : label}
    </Progress>
  )
}

export default function Timesheet() {
  const jobs = useSelector(
    (state) =>
      new ArrayStore({
        data: state.Jobs.forReview,
        key: "_id"
      })
  )
  const selectedPeriod = useSelector((state) => state.Period.selectedPeriod)
  const timesheetPeriods = useSelector((state) => state.Period.timesheetPeriod)
  const [unitIsHours, setUnitIsHours] = useState(true)
  const [totalHoursCompiled, setTotalHoursCompiled] = useState(0)
  const [currentStatus, setCurrentStatus] = useState("")
  const [openAlert, setOpenAlert] = useState(false)
  const [tsId, setTsId] = useState("")
  const [sendingTs, setSendingTs] = useState(false)
  const [workingDays, setWorkingDays] = useState(0)
  const [update, forceUpdate] = useReducer((x) => x + 1, 0) // necessary to force component update
  const [invalidJobs, setInvalidJobs] = useState([])
  const [isError, setIsError] = useState(false)
  const config = useSelector((state) => state.Auth.config)
  const dispatch = useDispatch()

  useEffect(() => {
    let unitCache = localStorage.getItem("@timesheetUnit")
    if (unitCache === "false") setUnitIsHours(false)
  }, [setUnitIsHours, update])

  useEffect(() => {
    updateJobThumb()
  }, [update, selectedPeriod])

  useEffect(() => {
    setIsError(false)
    setInvalidJobs(jobs._array.filter((j) => j.disabled).map((j) => j.id))
  }, [jobs._array, update])

  const updateJobThumb = () => {
    getJobsThumb().then(({ data }) => {
      dispatch(
        setAllJobs({
          jobs: data,
          wbsTypes: config.wbsTypes,
          selectedPeriod: selectedPeriod
        })
      )
    })
  }

  const setTimesheetData = (data) => {
    setTsId(data._id)
    let totalHours =
      round(
        data?.timesheetRow?.reduce(
          (accumulator, currentValue) =>
            (parseFloat(accumulator) || 0) +
            (parseFloat(
              data.currentStatus === "closed"
                ? currentValue.closed?.hours
                : currentValue[reviewCode]?.hours
            ) || 0),
          0
        )
      ) || 0
    setWorkingDays(data.workingDays)
    setTotalHoursCompiled(totalHours)
    setCurrentStatus(data.currentStatus)
  }

  const createTimesheet = async () => {
    try {
      const { data } = await initTimesheet({ period: selectedPeriod._id })
      setTimesheetData(data)
      return (
        data?.timesheetRow?.map((i) => {
          i[reviewCode].days = i[reviewCode]?.hours / 8
          return i
        }) || []
      )
    } catch (e) {
      console.log("Error on creating timesheet", e)
    }
  }

  const getTimesheet = async () => {
    const { data } = await getUserTsByPeriod({ period: selectedPeriod._id })

    if (isEmpty(data)) {
      await createTimesheet()
    } else {
      setTimesheetData(data)
      return (
        data?.timesheetRow?.map((i) => {
          i[data.currentStatus].days = i[data.currentStatus]?.hours / 8
          return i
        }) || []
      )
    }
  }

  useEffect(() => {
    ;(async () => {
      await getTimesheet()
    })()
  }, [selectedPeriod])

  const alertText = () => {
    return sendOriginalTimesheetText({
      totalDays: round(totalHoursCompiled / 8),
      workingDays,
      unitIsHours,
      containInvalidJobs: isError,
      selectedPeriod: selectedPeriod
    })
  }

  const onToogleSwitch = () => {
    setUnitIsHours(!unitIsHours)
    localStorage.setItem("@timesheetUnit", !unitIsHours)
  }

  const sendTs = () => {
    setSendingTs(true)
    setOpenAlert(false)
    sendTimesheet({ tsId })
      .then(() => {
        setSendingTs(false)
        setCurrentStatus("preReview")
        toast(formatMessage("success-sending-timesheet"), {
          transition: Bounce,
          closeButton: true,
          autoClose: 3000,
          position: "bottom-center",
          type: "success"
        })
      })
      .catch((e) => {
        setSendingTs(false)
        toast(formatMessage("error-sending-timesheet"), {
          transition: Bounce,
          closeButton: true,
          autoClose: 3000,
          position: "bottom-center",
          type: "error"
        })
      })
      .finally(() => {
        updateJobThumb()
      })
  }

  const iconColor = () => {
    switch (currentStatus) {
      case "userCompiling":
        return "text-warning"
      case "preReview":
        return "text-primary"
      case "firstReview":
        return "text-primary"
      case "onHold":
        return "text-primary"
      case "thirdReview":
        return "text-primary"
      case "ppa":
        return "text-primary"
      case "closed":
        return "text-success"
      default:
        return "text-primary"
    }
  }

  return (
    <Fragment>
      <div>
        <PageTitle
          heading={formatMessage("Timesheet")}
          subheading=""
          icon={"pe-7s-study " + iconColor()}
          timesheetStatus={currentStatus}
          actions={
            <Fragment>
              <PeriodDropDown review="original" updateComponent={currentStatus} />
              <SendButton
                currentStatus={currentStatus}
                setOpenAlert={setOpenAlert}
              />
            </Fragment>
          }
          guideRef={ExternalGuideRefs.TIMESHEET}
        />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Card className="main-card mb-3">
            <AlertDialog
              open={openAlert}
              alertText={alertText()}
              isAlert={alertText().isAlert}
              handleSend={sendTs}
              closeAlert={() => setOpenAlert(false)}
            />
            <CardBody>
              {isEmpty(jobs._array) || isEmpty(timesheetPeriods) ? (
                <LinearProgress />
              ) : (
                <Form>
                  <Row form>
                    <Col md={12}>
                      <ContainerHeadLine
                        selectedPeriod={selectedPeriod}
                        unitIsHours={unitIsHours}
                        onToogleSwitch={onToogleSwitch}
                      />
                      <ProgressBar
                        unitIsHours={unitIsHours}
                        totalHoursCompiled={totalHoursCompiled}
                        workingDays={workingDays}
                        currentStatus={currentStatus}
                      />
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={12}>
                      {sendingTs && <LinearProgress />}
                      {tsId && (
                        <TimesheetTable
                          jobs={jobs}
                          tsId={tsId}
                          unitIsHours={unitIsHours}
                          getTimesheet={getTimesheet}
                          currentStatus={currentStatus}
                          invalidJobs={invalidJobs}
                          forceUpdate={forceUpdate}
                          isError={isError}
                          setIsError={setIsError}
                          config={config}
                        />
                      )}
                    </Col>
                  </Row>
                </Form>
              )}
            </CardBody>
          </Card>
        </ReactCSSTransitionGroup>
      </div>
    </Fragment>
  )
}
