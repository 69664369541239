import { formatMessage } from "devextreme/localization"
import { useEffect } from "react"
import Icon from "./Icon"
import "./Paginator.scss"

export default function Paginator({
  className,
  currentPage,
  pageSize,
  setPageSize,
  lastPage,
  totalCount,
  amountCurrentlyVisualizing,
  onPageChange
}) {
  useEffect(() => {
    if (currentPage > lastPage) {
      onPageChange(lastPage)
    }
  }, [currentPage, lastPage, onPageChange])

  useEffect(() => {
    onPageChange(currentPage)
  }, [currentPage, onPageChange])

  const prev = () => {
    if (currentPage <= 0) {
      return
    }

    onPageChange(currentPage - 1)
  }

  const next = () => {
    if (currentPage >= lastPage) {
      return
    }

    onPageChange(currentPage + 1)
  }

  const first = () => {
    if (currentPage === 0) {
      return
    }

    onPageChange(0)
  }

  const last = () => {
    if (currentPage === lastPage) {
      return
    }

    onPageChange(lastPage)
  }

  const goTo = (pageNumber) => {
    if (pageNumber > lastPage || pageNumber < 0) {
      return
    }

    onPageChange(pageNumber)
  }

  const svgStyles = { width: "21px", height: "21px" }

  const AMOUNT_OF_BEFORE_OR_AFTER_PAGES = 2
  const getPagesToShow = () => {
    const pagesBefore = []
    const pagesAfter = []

    for (let i = 1; i <= AMOUNT_OF_BEFORE_OR_AFTER_PAGES; i++) {
      if (currentPage - i >= 0) {
        pagesBefore.unshift(currentPage - i)
      }
    }

    for (let i = 1; i <= AMOUNT_OF_BEFORE_OR_AFTER_PAGES; i++) {
      if (currentPage + i <= lastPage) {
        pagesAfter.push(currentPage + i)
      }
    }

    if (pagesBefore.length < AMOUNT_OF_BEFORE_OR_AFTER_PAGES) {
      for (
        let i = 1;
        i <= AMOUNT_OF_BEFORE_OR_AFTER_PAGES - pagesBefore.length;
        i++
      ) {
        const pageToAdd =
          (pagesAfter.length ? pagesAfter[pagesAfter.length - i] : currentPage) + i
        if (pageToAdd <= lastPage) {
          pagesAfter.push(pageToAdd)
        }
      }
    }

    if (pagesAfter.length < AMOUNT_OF_BEFORE_OR_AFTER_PAGES) {
      for (
        let i = 1;
        i <= AMOUNT_OF_BEFORE_OR_AFTER_PAGES - pagesAfter.length;
        i++
      ) {
        const pageToAdd = (pagesBefore.length ? pagesBefore[0] : currentPage) - i
        if (pageToAdd >= 0) {
          pagesBefore.unshift(pageToAdd)
        }
      }
    }

    return [...pagesBefore, currentPage, ...pagesAfter]
  }

  return (
    <div
      className={`${className} d-flex flex-row justify-content-between paginator`}
    >
      <div className="d-flex flex-row">
        <div className="clickable-page mr-2 text-muted" onClick={first}>
          <Icon
            svgStyles={svgStyles}
            iconRelativePath="monade/pag-double-arrow-left"
          />
        </div>
        <div className="clickable-page mr-3 text-muted" onClick={prev}>
          <Icon svgStyles={svgStyles} iconRelativePath="monade/pag-arrow-left" />
        </div>
        {getPagesToShow().map((page) => (
          <div
            key={page}
            className={`clickable-page ${
              page === currentPage ? "selected" : ""
            } mr-3 text-muted`}
            onClick={() => goTo(page)}
          >
            {page + 1}
          </div>
        ))}
        <div className="clickable-page mr-2 text-muted" onClick={next}>
          <Icon svgStyles={svgStyles} iconRelativePath="monade/pag-arrow-right" />
        </div>
        <div className="clickable-page text-muted" onClick={last}>
          <Icon
            svgStyles={svgStyles}
            iconRelativePath="monade/pag-double-arrow-right"
          />
        </div>
      </div>
      <div className="text-muted">
        {formatMessage("jw-You are currently visualizing")}{" "}
        {amountCurrentlyVisualizing} {formatMessage("jw-of")} {totalCount} |{" "}
        {formatMessage("jw-Page size")}:{" "}
        <div
          onClick={() => setPageSize(5)}
          className={`px-2 py-1 btn btn-outline-${
            pageSize === 5 ? "primary" : "secondary"
          }`}
        >
          5
        </div>
        <div
          onClick={() => setPageSize(10)}
          className={`p-1 ml-1 btn btn-outline-${
            pageSize === 10 ? "primary" : "secondary"
          }`}
        >
          10
        </div>
        <div
          onClick={() => setPageSize(20)}
          className={`p-1 ml-1 btn btn-outline-${
            pageSize === 20 ? "primary" : "secondary"
          }`}
        >
          20
        </div>
      </div>
    </div>
  )
}
