import { formatDateNoTime } from "../../../../Utils/formatDateNoTime"

export const parseResourcesFiltersToParams = (filters) => {
  let params = {
    startDate: filters.startDate && formatDateNoTime(filters.startDate),
    endDate: filters.endDate && formatDateNoTime(filters.endDate),
    toleranceStartDate: filters.startDateTolerance,
    toleranceEndDate: filters.endDateTolerance,
    allocationPercentage: filters.timeAllocationPercentage,
    resourceLevel: filters.level,
    bipRole: filters.jobTitle,
    teamLeaderId: filters.teamLeader,
    competence: filters.competence,
    userType: filters.userType,
    search: filters.search ? filters.search : undefined
  }

  for (const key of Object.keys(params)) {
    if (params[key] == null) {
      delete params[key]
    }
  }

  return params
}
