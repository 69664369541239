export const parseBipUserData = (bipUser) => {
  return {
    id: bipUser._id,
    firstName: bipUser.firstName,
    lastName: bipUser.lastName,
    fullName: `${bipUser.firstName} ${bipUser.lastName}`,
    disabled: !bipUser.enabled,
    organization: bipUser.organization,
  }
}
