import { formatMessage } from "devextreme/localization"
import { useState } from "react"
import { withRouter } from "react-router-dom"
import PageTitle from "../../../../../Layout/AppMain/PageTitle"
import BackButton from "../../components/BackButton"
import { Spinner } from "../../components/Spinner"
import WbsDetailsForm from "../../components/WbsDetailsForm"
import WhiteBoxContainer from "../../components/WhiteBoxContainer"
import ConfirmModal from "../../components/common/ConfirmModal"
import { useCreateWbs } from "../../hooks/useCreateWbs"

export default withRouter(function WbsCreate({ history, location }) {
  const { mutation, onValueChanged, newWbs, errors } = useCreateWbs(
    location?.state?.wbsToClone,
    (wbs) => history.push(`/admin/wbs/edit/${wbs._id}`),
  )

  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => setIsModalOpen(true)
  const save = () => mutation.mutate(newWbs)
  const saveOrOpenModal = () => {
    // if (newWbs.isFinal || newWbs.isTempPlaced) {
    //   openModal()
    // } else {
    //   save()
    // }
    save()
  }

  return (
    <>
      <PageTitle
        heading={formatMessage("jw-Create") + " " + formatMessage("jw-WBS")}
        subheading={`${formatMessage("jw-WBS")} / ${formatMessage("jw-Create")}`}
        beforeActions={<BackButton className="mr-4" />}
        actions={
          <>
            <ConfirmModal
              isOpen={isModalOpen}
              setIsOpen={setIsModalOpen}
              onConfirm={() => {
                setIsModalOpen(false)
                save()
              }}
              onCancel={() => {
                setIsModalOpen(false)
              }}
              title={formatMessage("jw-Confirmation")}
              body={formatMessage("warning-wbs-admin-final-affects-onhold-ts")}
              confirmButtonText={formatMessage("jw-Create")}
              cancelButtonText={formatMessage("jw-Cancel")}
            />
            <button
              disabled={mutation.isLoading}
              onClick={saveOrOpenModal}
              className={"btn btn-primary"}
            >
              {mutation.isLoading && <Spinner className={"mx-1"} />}{" "}
              {formatMessage("jw-Create")}
            </button>
          </>
        }
      />
      <div className="mt-4 mb-3">
        {formatMessage("jw-WBS")} / {formatMessage("jw-Create")}
        <span className="mx-3" />
        {mutation.isLoading ? (
          <span className="text-warning">
            <Spinner className={"mx-1"} /> {formatMessage("jw-Saving...")}
          </span>
        ) : (
          ""
        )}
        {mutation.isSuccess ? (
          <span className="text-success">{formatMessage("jw-Created")}</span>
        ) : (
          ""
        )}
        {mutation.isError ? (
          <span className="text-danger">{formatMessage("jw-Error")}</span>
        ) : (
          ""
        )}
      </div>
      <WhiteBoxContainer>
        <WbsDetailsForm
          errors={errors}
          wbs={newWbs}
          onValueChanged={onValueChanged}
          isCreating={true}
          canManage={true}
        />
      </WhiteBoxContainer>
    </>
  )
})
