import { useQuery } from "@tanstack/react-query"
import { getYear } from "../../../../services"
import { parseFiscalYearData } from "../utils/parseFiscalYearData"
import { parseJobData } from "../utils/parseJobData"

export function useYearData(yearId, includeWbs = false) {
  const { isLoading, isError, isSuccess, data, error } = useQuery(
    ["GET", "year/", yearId],
    async () => {
      return await getYear(yearId, includeWbs).then((data) => {
        return parseFiscalYearData(data.data)
      })
    }
  )

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
  }
}
