import React from "react"
import { Route } from "react-router-dom"
import AppHeader from "../../Layout/AppHeader/"
import AppSidebar from "../../Layout/AppSidebar/"
import AppFooter from "../../Layout/AppFooter/"
import { useAppAbility } from "./../../Context/ability"
import PreallocationIndex from "./Preallocation/index"
import { ConfirmProvider } from "./Preallocation/components/ConfirmModal"

const Preallocation = ({ match }) => {
  const { can } = useAppAbility()

  const canReadPreallocation = can("read", "Preallocation")

  return (
    <ConfirmProvider>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          {canReadPreallocation && (
            <Route path={`${match.url}`} component={PreallocationIndex} exact />
          )}
          <AppFooter />
        </div>
      </div>
    </ConfirmProvider>
  )
}

export default Preallocation
