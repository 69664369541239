import { makeStyles, MenuItem, Select } from "@material-ui/core"
import React, {useEffect} from "react"
import { useDispatch, useSelector } from "react-redux"
import { changeLocale } from "../../../reducers/Locale"
import moment from "moment";
import "moment/locale/it";

const useSelectStyles = makeStyles({
  select: {
    // this class refers to the select box's line css
    "&:before": {
      borderColor: "#ECEDEE"
    },
    "&:after": {
      borderColor: "#ECEDEE"
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "#ECEDEE"
    },
    width: "3.5rem"
  },
  icon: {
    // this class refers to the select box's arrow icon css
    fill: "#ECEDEE",
    fontSize: "1.3rem"
  },
  root: {
    // this class refers to the select box's text css
    color: "#ECEDEE",
    fontSize: ".9rem"
  }
})

const LanguageSelector = () => {
  const currentLocale = useSelector((state) => state.Locale)

  const localeDispatch = useDispatch()

  const onLangChange = (event) => {
    localeDispatch(changeLocale(event.target.value))
    document.location.reload()
  }

  const selectClasses = useSelectStyles()

  useEffect(() => {
    moment.locale(currentLocale);
  }, []);

  return (
    <div style={{ paddingLeft: "2rem" }}>
      <Select
        className={selectClasses.select}
        labelId="select-language"
        id="langSelect"
        inputProps={{
          classes: {
            icon: selectClasses.icon,
            root: selectClasses.root
          }
        }}
        defaultValue={currentLocale}
        onChange={onLangChange}
      >
        <MenuItem value={"en"}>ENG</MenuItem>
        <MenuItem value={"it"}>ITA</MenuItem>
      </Select>
    </div>
  )
}

export default LanguageSelector
