import { parseJobData } from "./parseJobData"

export const parseFiscalYearData = (fiscalYear, isChargeable = true) => {
  const parsedFiscalYear = {
    //table info
    _id: fiscalYear._id,
    year: fiscalYear.year,
    wbs: fiscalYear.wbs,
    wbsData: fiscalYear.wbsData ?? null,
    contractValue: fiscalYear.contractValue,
    standardCostsTotal: fiscalYear.standardCostsTotal,
    otherCosts: fiscalYear.otherCosts,
    targetMargin: fiscalYear.targetMargin,
    targetMarginPerc: fiscalYear.targetMarginPerc * 100,
    revenueXtechManagerial: fiscalYear.revenueXtechManagerial,
    rfpXTech: fiscalYear.rfpXTech,
    standardCostsXtech: fiscalYear.standardCostsXtech,
    xTechEffortPerc: fiscalYear.xTechEffortPerc * 100,
    isDc: fiscalYear.isDc,
    xTechMargin: fiscalYear.xTechMargin,
    forceDc: fiscalYear.forceDc,
    dcMultiply: fiscalYear.dcMultiply,
    jobs: fiscalYear.jobs?.map((job) => parseJobData(job)) || [],
  }

  return parsedFiscalYear
}
