import { formatMessage } from "devextreme/localization"
import { useState } from "react"
import { getErrorByField } from "../../Headcount/Main/Utils/ErrorHandling"
import { useSelectAccountableUsers } from "../hooks/selectHooks/useSelectAccountableUsers"
import { useSelectBipUsers } from "../hooks/selectHooks/useSelectBipUsers"
import { useSelectJobModes } from "../hooks/selectHooks/useSelectJobModes"
import { useSelectJobSuggestions } from "../hooks/selectHooks/useSelectJobSuggestions"
import { useSelectStatuses } from "../hooks/selectHooks/useSelectStatuses"
import { useSelectWbs } from "../hooks/selectHooks/useSelectWbs"
import { useSelectYears } from "../hooks/selectHooks/useSelectYears"
import ConfirmModal from "./common/ConfirmModal"
import Checkbox from "./input/Checkbox"
import DatePickerInput from "./input/DatePickerInput"
import DropdownMultipleSelect from "./input/DropdownMultipleSelect"
import DropdownSelect from "./input/DropdownSelect"
import TextArea from "./input/TextArea"
import TextInput from "./input/TextInput"
import { useSelectJobSpecialties } from "../hooks/selectHooks/useSelectJobSpecialtiesValues.js"
import constants from "../../../../Common/constants.js"

export default function JobDetailsForm({
  job,
  year,
  wbs,
  onValueChanged,
  isCreating,
  isCloning,
  setYearId,
  wbsId,
  setWbsId,
  errors,
  canManage = false,
  setConfirmOnSave,
  isDescriptionMandatoryReadonly
}) {
  const [unchargeableWbsToChange, setUnchargeableWbsToChange] = useState(null)
  const { wbsOptionsActive, isLoading: wbsOptionsLoading } = useSelectWbs()
  const { yearsOptions, isLoading: yearsOptionsLoading } = useSelectYears(wbsId)
  const { bipUserOptions, isLoading: bipUserOptionsLoading } = useSelectBipUsers()
  const { statusOptions } = useSelectStatuses()
  const { jobModeOptions } = useSelectJobModes()
  const { jobSpecialtiesValueOptions } = useSelectJobSpecialties()

  const {
    accountableUserOptions,
    isLoading: accountableUserOptionsLoading
  } = useSelectAccountableUsers()
  const {
    suggestionsOptions,
    isLoading: suggestionsOptionsLoading
  } = useSelectJobSuggestions()

  const isChargeable = () => wbs.type === "1"

  const isDatwaveJob = () => job.jobSpecialties?.includes("Datwave")

  const onWbsChange = (option, checkChargeable = true) => {
    const chargeableChanged =
      (wbs.chargeable && !option.chargeable) ||
      (option.type !== "1" && wbs.type === "1")

    if (chargeableChanged && checkChargeable) {
      setUnchargeableWbsToChange(option)
      return
    }
    if (isCloning) {
      if (!wbs.isFinal && option.isFinal) {
        setConfirmOnSave(true)
      } else {
        setConfirmOnSave(false)
      }
    }
    setWbsId(option?.id || "")
    const fieldsToAdd = {
      fiscalYear: null,
      accountable: option.accountable
    }
    let fieldsToDelete = []
    if (chargeableChanged) {
      fieldsToDelete = [
        "digitalAssets",
        "technologyProviders",
        "technologyProducts",
        "standardCostsXtech",
        "mode",
        "jobSpecialties"
      ]
    }
    onValueChanged(null, { fieldsToAdd, fieldsToDelete })
  }

  return (
    <div className="d-flex flex-column">
      <h4 className="mb-4">
        {wbs.code} / {year.year} /{" "}
        {!job.shortCode || job.shortCode === ""
          ? formatMessage("jw-Create") + " " + formatMessage("jw-Job")
          : job.shortCode}
      </h4>
      <div className="mb-4">
        <TextArea
          readOnly={!canManage}
          value={job.description}
          label={formatMessage("jw-Description") + " *"}
          placeholder={formatMessage("jw-Insert description")}
          onChange={(event) => onValueChanged("description", event.target.value)}
          error={getErrorByField(errors, "description")}
        />
      </div>
      {isCloning || !isCreating ? (
        <div className="w-100 d-flex flex-row mb-4">
          <DropdownSelect
            readOnly={!canManage}
            options={wbsOptionsActive}
            disabled={wbsOptionsLoading}
            label={`${formatMessage("jw-WBS")} *`}
            className="w-25"
            value={wbsId}
            onSelected={onWbsChange}
          />
          <ConfirmModal
            isOpen={unchargeableWbsToChange}
            setIsOpen={() => setUnchargeableWbsToChange(null)}
            onConfirm={() => {
              onWbsChange(unchargeableWbsToChange, false)
              setUnchargeableWbsToChange(null)
            }}
            onCancel={() => {
              setUnchargeableWbsToChange(null)
            }}
            title={formatMessage("jw-Confirmation")}
            body={formatMessage(
              "warning-job-admin-switching-charg-nonCharg",
              formatMessage("customerRef"),
              formatMessage("mode"),
              formatMessage("customerBusinessFunction"),
              "ADA"
            )}
            confirmButtonText={formatMessage("jw-Save")}
            cancelButtonText={formatMessage("jw-Cancel")}
          />
          <DropdownSelect
            readOnly={!canManage}
            options={yearsOptions}
            disabled={yearsOptionsLoading}
            label={`${formatMessage("jw-Fiscal Year")} *`}
            className="w-25 pl-3"
            value={job.fiscalYear}
            onSelected={(option) => {
              onValueChanged("fiscalYear", option?.id || null)

              if (option?.id) {
                setYearId(option?.id || "")
              }
            }}
            error={getErrorByField(errors, "fiscalYear")}
          />
        </div>
      ) : (
        ""
      )}
      <div className="d-flex flex-row mb-4">
        <DatePickerInput
          readOnly={!canManage}
          className="w-25"
          value={job.startDate}
          label={formatMessage("jw-Start date") + " *"}
          onChange={(value) => onValueChanged("startDate", value)}
          error={getErrorByField(errors, "startDate")}
        />
        <DatePickerInput
          readOnly={!canManage}
          className="w-25 pl-3"
          value={job.endDate}
          label={formatMessage("jw-End date") + " *"}
          onChange={(value) => onValueChanged("endDate", value)}
          error={getErrorByField(errors, "endDate")}
        />
        <Checkbox
          readOnly={!canManage || isDescriptionMandatoryReadonly}
          value={job.isDescriptionMandatory}
          onChange={(event) =>
            onValueChanged("isDescriptionMandatory", event.target.checked)
          }
          label={formatMessage("jw-Description mandatory")}
          className="pl-3 mt-4 pt-2"
          error={getErrorByField(errors, "isDescriptionMandatory")}
        />
      </div>
      <div className="d-flex flex-row mb-4">
        <DropdownSelect
          readOnly={!canManage}
          options={statusOptions}
          value={job.status}
          label={formatMessage("jw-Status") + " *"}
          className="w-25"
          onSelected={(option) => onValueChanged("status", option?.id || null)}
          error={getErrorByField(errors, "status")}
        />
        <DropdownSelect
          readOnly={!canManage || wbs.accountable}
          disabled={accountableUserOptionsLoading}
          options={accountableUserOptions}
          value={job.accountable}
          label={formatMessage("jw-Accountable") + " *"}
          className="w-25 pl-3"
          onSelected={(option) => onValueChanged("accountable", option?.id || null)}
          error={getErrorByField(errors, "accountable")}
        />
        {isChargeable() ? (
          <DropdownSelect
            readOnly={!canManage}
            options={jobModeOptions}
            label={formatMessage("jw-Mode") + " *"}
            className="w-25 pl-3"
            value={job.mode}
            onSelected={(option) => onValueChanged("mode", option?.id || null)}
            error={getErrorByField(errors, "mode")}
          />
        ) : (
          ""
        )}
      </div>
      <div className="d-flex flex-row mb-4">
        <TextInput
          readOnly={!canManage}
          label={formatMessage("jw-Specific competence")}
          value={job.specificCompetence}
          className="w-25"
          onChange={(event) =>
            onValueChanged("specificCompetence", event.target.value)
          }
          error={getErrorByField(errors, "specificCompetence")}
        />
        <TextInput
          readOnly={!canManage}
          label={formatMessage("jw-Sub competence")}
          value={job.subComp}
          className="w-25 pl-3"
          onChange={(event) => onValueChanged("subComp", event.target.value)}
          error={getErrorByField(errors, "subComp")}
        />
        {isChargeable() && (
          <DropdownMultipleSelect
            readOnly={!canManage}
            options={jobSpecialtiesValueOptions || []}
            showOnlySelectedButton={true}
            values={job.jobSpecialties}
            label={formatMessage("jw-Job Specialties")}
            className="w-25 pl-3"
            onChange={(selections) => {
              if (!selections.includes(constants.JobSpecialties.DATWAVE)) {
                let fieldsToAdd = []
                let fieldsToDelete = [
                  "datwavePurchaseOrder",
                  "datwavePurchaseOrderValue"
                ]
                onValueChanged(null, { fieldsToAdd, fieldsToDelete })
              }

              onValueChanged(
                "jobSpecialties",
                selections.map((s) => s.id)
              )
            }}
            error={getErrorByField(errors, "jobSpecialties")}
            searchable={false}
          />
        )}
      </div>
      <div className="d-flex flex-row mb-4">
        <DropdownSelect
          readOnly={!canManage}
          disabled={bipUserOptionsLoading}
          options={bipUserOptions}
          value={job.jobOwner}
          label={`${formatMessage("jw-Job owner")}${isChargeable() ? " *" : ""}`}
          className="w-25"
          onSelected={(option) => onValueChanged("jobOwner", option?.id || null)}
          error={getErrorByField(errors, "jobOwner")}
        />
        <DropdownSelect
          readOnly={!canManage}
          disabled={bipUserOptionsLoading}
          options={bipUserOptions}
          value={job.salesResponsible}
          label={formatMessage("jw-Sales responsible")}
          className="w-25 pl-3"
          onSelected={(option) =>
            onValueChanged("salesResponsible", option?.id || null)
          }
          error={getErrorByField(errors, "salesResponsible")}
        />
        <DropdownSelect
          readOnly={!canManage}
          disabled={bipUserOptionsLoading}
          options={bipUserOptions}
          value={job.deliveryManager}
          label={formatMessage("jw-Delivery manager") + " *"}
          className="w-25 pl-3"
          onSelected={(option) =>
            onValueChanged("deliveryManager", option?.id || null)
          }
          error={getErrorByField(errors, "deliveryManager")}
        />
      </div>
      {isChargeable() ? (
        <>
          <div className="d-flex flex-row mb-4">
            <DropdownMultipleSelect
              readOnly={!canManage}
              options={suggestionsOptions.digitalAssets || []}
              disabled={suggestionsOptionsLoading}
              showOnlySelectedButton={true}
              values={job.digitalAssets}
              label={formatMessage("jw-Digital assets") + " *"}
              className="w-25"
              onChange={(selections) => {
                onValueChanged(
                  "digitalAssets",
                  selections.map((s) => s.id)
                )
              }}
              error={getErrorByField(errors, "digitalAssets")}
            />
            <DropdownMultipleSelect
              readOnly={!canManage}
              options={suggestionsOptions.technologyProviders || []}
              disabled={suggestionsOptionsLoading}
              showOnlySelectedButton={true}
              values={job.technologyProviders}
              label={formatMessage("jw-Technology providers") + " *"}
              className="w-25 pl-3"
              onChange={(selections) => {
                onValueChanged(
                  "technologyProviders",
                  selections.map((s) => s.id)
                )
              }}
              error={getErrorByField(errors, "technologyProviders")}
            />
            <DropdownMultipleSelect
              readOnly={!canManage}
              options={suggestionsOptions.technologyProducts || []}
              disabled={suggestionsOptionsLoading}
              showOnlySelectedButton={true}
              values={job.technologyProducts}
              label={formatMessage("jw-Technology products") + " *"}
              className="w-25 pl-3"
              onChange={(selections) => {
                onValueChanged(
                  "technologyProducts",
                  selections.map((s) => s.id)
                )
              }}
              error={getErrorByField(errors, "technologyProducts")}
            />
          </div>
          <div className="d-flex flex-row mb-4">
            <TextInput
              readOnly={!canManage}
              label={formatMessage("jw-Customer ref")}
              value={job.customerRef}
              className="w-25"
              onChange={(event) => onValueChanged("customerRef", event.target.value)}
              error={getErrorByField(errors, "customerRef")}
            />
            <TextInput
              readOnly={!canManage}
              label={formatMessage("jw-Customer business function")}
              value={job.customerBusinessFunction}
              className="w-25 pl-3"
              onChange={(event) =>
                onValueChanged("customerBusinessFunction", event.target.value)
              }
              error={getErrorByField(errors, "customerBusinessFunction")}
            />
            {isDatwaveJob() && (
              <TextInput
                readOnly={!canManage}
                label={formatMessage("jw-Datwave purchase order")}
                value={job.datwavePurchaseOrder}
                className="w-25 pl-3"
                onChange={(event) =>
                  onValueChanged("datwavePurchaseOrder", event.target.value)
                }
                error={getErrorByField(errors, "datwavePurchaseOrder")}
              />
            )}
          </div>
        </>
      ) : (
        ""
      )}
      <div className="mb-4">
        <TextArea
          readOnly={!canManage}
          value={job.staffingNotes}
          label={formatMessage("jw-Staffing notes")}
          placeholder={formatMessage("jw-Insert staffing notes")}
          onChange={(event) => onValueChanged("staffingNotes", event.target.value)}
          error={getErrorByField(errors, "staffingNotes")}
        />
      </div>
    </div>
  )
}
