import axios from "axios"
import { apiService } from "./APIServices"

export const getJOReview = async ({
  period,
  showAssigned,
  showOnlySkipped,
  showOnlyPending
}) => {
  let filter = showAssigned ? `&filter=inCharge` : ""
  filter += showOnlySkipped ? `&status=skipped` : ""
  filter += showOnlyPending ? `&status=started` : ""
  return axios.get(apiService.timesheet + `/firstReview?period=${period}${filter}`)
}

export const saveFirstReviewRow = async ({
  tsId,
  _id,
  code,
  description,
  notes,
  hours,
  days,
  cost,
  wbs,
  activity
}) => {
  return axios.put(
    apiService.timesheet + `/${tsId}/firstReview/save`,
    _id
      ? { _id, code, description, notes, hours, days, cost, wbs, activity }
      : { code, description, notes, hours, days, cost, wbs, activity }
  )
}

export const sendFirstReview = async ({ tsId, jobId }) => {
  return axios.put(apiService.timesheet + `/${tsId}/firstReview/send?jobId=${jobId}`)
}

export const sendFirstReviewsByJob = async ({ periodId, jobId }) => {
  return axios.put(apiService.timesheet + `/firstReview/${periodId}/${jobId}`)
}

export const sendFirstReviewsByPeriod = async ({ periodId }) => {
  return axios.patch(apiService.timesheet + `/firstReview/${periodId}`)
}

export const getUserTsByPeriod = async ({ period }) => {
  return axios.get(apiService.timesheet + `?period=${period}`)
}

export const getPreReview = async ({ period }) => {
  return axios.get(apiService.timesheet + `/preReview?period=${period}`)
}

export const savePreReviewRow = async ({
  tsId,
  _id,
  code,
  description,
  notes,
  hours,
  days,
  cost,
  wbs,
  activity
}) => {
  return axios.put(
    apiService.timesheet + `/${tsId}/preReview/save`,
    _id
      ? { _id, code, description, notes, hours, days, cost, activity, wbs }
      : { code, description, notes, hours, days, cost, activity, wbs }
  )
}

export const sendPreReview = async ({ tsId }) => {
  return axios.put(apiService.timesheet + `/${tsId}/preReview/send`)
}

export const reopenTimesheet = async ({ tsId }) => {
  return axios.patch(apiService.timesheet + `/${tsId}/reopen`)
}

export const savePpaRow = async ({
  tsId,
  _id,
  code,
  description,
  notes,
  hours,
  days,
  cost,
  wbs,
  activity
}) => {
  return axios.patch(
    apiService.timesheet + `/${tsId}/edit`,
    _id
      ? { _id, code, description, notes, hours, days, cost, wbs, activity }
      : { code, description, notes, hours, days, cost, wbs, activity }
  )
}

export const sendPpa = async ({ tsId }) => {
  return axios.patch(apiService.timesheet + `/${tsId}/close`)
}

export const getAnyReview = async ({
  period,
  toReviewFilter,
  statusFilter,
  key
}) => {
  let filter = toReviewFilter ? `&filter=inCharge` : ""
  filter += statusFilter !== undefined ? `&status=${statusFilter}` : ""
  filter += `&key=${key}`
  return axios.get(apiService.timesheet + `/anyReview?period=${period}${filter}`)
}

export const saveThirdReviewRow = async ({
  tsId,
  _id,
  code,
  description,
  notes,
  hours,
  days,
  cost,
  wbs,
  activity
}) => {
  return axios.put(
    apiService.timesheet + `/${tsId}/thirdReview/save`,
    _id
      ? { _id, code, description, notes, hours, days, cost, wbs, activity }
      : { code, description, notes, hours, days, cost, wbs, activity }
  )
}

export const sendThirdReview = async ({ tsId }) => {
  return axios.put(apiService.timesheet + `/${tsId}/thirdReview/send`)
}

export const saveCompletedReviewRow = async ({
  tsId,
  _id,
  code,
  description,
  notes,
  hours,
  days,
  cost,
  wbs,
  activity
}) => {
  return axios.put(
    apiService.timesheet + `/${tsId}/completed/save`,
    _id
      ? { _id, code, description, notes, hours, days, cost, activity, wbs }
      : { code, description, notes, hours, days, cost, activity, wbs }
  )
}

export const initTimesheet = async ({ period }) => {
  return axios.post(apiService.timesheet, {
    period: period
  })
}

export const saveTimesheet = ({
  tsId,
  rowId,
  code,
  hours,
  days,
  description,
  wbs,
  activity
}) => {
  return axios.put(apiService.timesheet + `/${tsId}/row`, {
    _id: rowId,
    code,
    hours,
    days,
    description,
    wbs,
    activity
  })
}

export const sendTimesheet = async ({ tsId }) => {
  return axios.put(apiService.timesheet + `/${tsId}/send`)
}

export const deleteTimesheetRow = async ({ tsId, rowId, currentStatus }) => {
  return axios.delete(
    apiService.timesheet +
      `/${tsId}/row/${rowId}/${currentStatus ? currentStatus : ""}`
  )
}

export const getExternalReview = async ({ period }) => {
  return axios.get(apiService.timesheet + `/externals?period=${period}`)
}

export const sendInChargeReviews = async ({ periodId }) => {
  return axios.patch(apiService.timesheet + `/anyReview?period=${periodId}`)
}

export const closeReviews = async ({ periodId }) => {
  return axios.patch(apiService.timesheet + `/closeAll?period=${periodId}`)
}

export const getClosedReview = async ({
  period,
  filterByJobAccountable,
  filterByJobOwner
}) => {
  let params = { period }
  if (filterByJobAccountable) params.filter = "jobAccountable"
  else if (filterByJobOwner) params.filter = "jobOwner"

  return axios.get(apiService.timesheet + `/closed`, {
    params
  })
}

export const getMissingTimesheetByPeriod = async ({ period }) => {
  return axios.get(`${apiService.timesheet}/missing`, {
    params: { period }
  })
}

export const sendMissingTimesheet = async ({ userId, period, timesheetRows }) => {
  return axios.patch(
    `${apiService.timesheet}/missing/${userId}/send`,
    timesheetRows,
    {
      params: { period }
    }
  )
}
