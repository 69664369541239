import "bootstrap/dist/css/bootstrap.min.css"
import Popup from "devextreme-react/popup"
import { formatMessage } from "devextreme/localization"
import React from "react"
import { Button } from "reactstrap"
import clone from "../Utils/Clone"
import isExternal from "../Utils/IsExternal"
import isUserWithExternalPromotion from "../Utils/IsUserWithExternalPromotion"
import "./../../../../../style/dx.material.bip-scheme.css"

export default function SwitchToExternalOrInternalPopup({
  showConfirmChangePopup,
  setShowConfirmChangePopup,
  user,
  setUser,
  updateUserAndHandleResponse,
  levels,
  userLevels,
  setIsSelectingExternalLevel
}) {
  const onConfirmChange = () => {
    let isSwitchingToExternal = !(
      isExternal(user.currentLevel?.code, levels) ||
      isUserWithExternalPromotion(userLevels, levels)
    )
    let updatedUser = clone(user)
    updatedUser.enabled = false

    updateUserAndHandleResponse(updatedUser, (resp) => {
      setShowConfirmChangePopup(false)
      setUser((prev) => ({
        ...prev,
        enabled: resp.data.enabled
      }))
      setIsSelectingExternalLevel(isSwitchingToExternal)
    })
  }

  return (
    <Popup
      maxWidth={1000}
      height={"auto"}
      showTitle={true}
      onHiding={() => {
        setShowConfirmChangePopup(false)
      }}
      title={formatMessage("hc-are-you-sure-you-want-to-continue")}
      dragEnabled={true}
      closeOnOutsideClick={false}
      visible={showConfirmChangePopup}
      contentRender={() => {
        return (
          <div>
            <div className="mb-4" style={{ fontSize: "18px" }}>
              {formatMessage("hc-changing-the-user-from")}
              {isExternal(user.currentLevel?.code, levels) ||
              isUserWithExternalPromotion(userLevels, levels)
                ? formatMessage("hc-external")
                : formatMessage("hc-internal")}
              {" " + formatMessage("hc-to") + " "}
              {isExternal(user.currentLevel?.code, levels) ||
              isUserWithExternalPromotion(userLevels, levels)
                ? formatMessage("hc-internal")
                : formatMessage("hc-external")}
              {formatMessage("hc-will-automatically")}
              {user.enabled ? (
                <>
                  <b>{formatMessage("hc-disable-the-user")}</b>
                  {formatMessage("hc-and")}
                </>
              ) : (
                ""
              )}
              <b>{formatMessage("hc-save-the-current-sate-of-the-user")}</b>.
            </div>
            <div className="d-flex justify-content-between">
              <Button
                outline
                color="primary"
                d="button"
                width={100}
                height={40}
                onClick={() => {
                  setShowConfirmChangePopup(false)
                }}
              >
                <span className="dx-button-text">
                  {formatMessage("hc-button-cancel")}
                </span>
              </Button>
              <Button
                color="primary"
                d="button"
                width={100}
                height={40}
                onClick={() => {
                  onConfirmChange()
                }}
              >
                <span className="dx-button-text">
                  {formatMessage("hc-button-confirm")}
                </span>
              </Button>
            </div>
          </div>
        )
      }}
    />
  )
}
