import { useMemo, useState } from "react"
import { Link, withRouter } from "react-router-dom"
import { formatMessage } from "devextreme/localization"
import moment from "moment"
import Icon from "../components/Icon"
import "./ProjectCell.scss"
import Button from "../components/Button"

export default withRouter(function ProjectCell({
  history,
  project,
  filters,
  onToggleIsOpen,
  onToggleIsPinned,
  onAddResourceLight,
  canViewProjectDetails
}) {
  const startDate = useMemo(() => {
    return moment(project.jobStartDate).format("DD/MM/YYYY")
  }, [project.jobStartDate])
  const endDate = useMemo(() => {
    return moment(project.jobEndDate).format("DD/MM/YYYY")
  }, [project.jobEndDate])
  const projectUrl = useMemo(() => {
    return `/admin/${project.fiscalYearId}/job/edit/${project.shortCode}`
  }, [project.fiscalYearId, project.shortCode])

  const goToAddResource = () => {
    onAddResourceLight(project)
  }

  return (
    <div className="project-cell">
      <div
        className="project-cell-color"
        style={{ backgroundColor: project.color }}
      />
      <div className="project-cell-content text-truncate">
        <div className="d-flex align-items-center text-truncate">
          <span className="project-cell-name text-truncate">
            {canViewProjectDetails ? (
              <Link className="text-decoration-none" to={projectUrl}>
                <span style={{ display: "flex", flexDirection: "column" }}>
                  {project.contractNumber && <span>{project.contractNumber}</span>}
                  <span>{project.name}</span>
                </span>
              </Link>
            ) : (
              project.name
            )}
          </span>
          {/*<Icon className={`project-cell-icon ${isTooltipOpen ? 'bg-primary text-white' : 'bg-secondary'}`}*/}
          {/*      iconRelativePath='monade/preallocation/info' onClick={() => setIsTooltipOpen(!isTooltipOpen)}/>*/}
          <Icon
            className={`project-cell-icon rounded-circle ${
              project.isOpen ? "bg-primary text-white" : "bg-secondary"
            }`}
            iconRelativePath={
              project.isOpen
                ? "monade/preallocation/user-no"
                : "monade/preallocation/users"
            }
            onClick={() => onToggleIsOpen()}
          />
          <Icon
            className="project-cell-icon"
            iconRelativePath={
              project.isPinned
                ? "monade/preallocation/pin-active"
                : "monade/preallocation/pin"
            }
            onClick={() => onToggleIsPinned()}
          />
        </div>
        <div className="text-muted mt-1">
          {formatMessage(
            "preallocation-projects-start-end-date",
            startDate,
            endDate
          )}
        </div>
        <div className="d-flex justify-content-between align-items-center mt-2">
          <Button
            className="btn-primary"
            icon="monade/preallocation/plus"
            text={formatMessage("preallocation-projects-add-resource")}
            onClick={() => goToAddResource()}
          />
          {/*<b>{ `Total cost: ${formatPrice(project.cost || 100000)}` }</b>*/}
        </div>
      </div>
    </div>
  )
})
