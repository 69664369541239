import { formatMessage } from "devextreme/localization"
import { withRouter } from "react-router-dom"
import PageTitle from "../../../../../Layout/AppMain/PageTitle"
import BackButton from "../../components/BackButton"
import { Spinner } from "../../components/Spinner"
import WhiteBoxContainer from "../../components/WhiteBoxContainer"
import YearDetailsForm from "../../components/YearDetailsForm"
import { useCreateYear } from "../../hooks/useCreateYear"
import { useWbsData } from "../../hooks/useWbsData"

export default withRouter(function YearCreate({ history, match }) {
  const wbsQuery = useWbsData(match.params.wbsId)

  const { mutation, onValueChanged, newYear, errors } = useCreateYear(
    wbsQuery.data,
    (year) => {
      history.push(`/admin/${wbsQuery.data._id}/year/edit/${year._id}`)
    },
  )

  return (
    <>
      <PageTitle
        heading={formatMessage("jw-Create") + " " + formatMessage("jw-Fiscal Year")}
        subheading={`${formatMessage("jw-Fiscal Year")} / ${formatMessage(
          "jw-Create",
        )}`}
        beforeActions={<BackButton className="mr-4" />}
        actions={
          <button
            disabled={mutation.isLoading}
            onClick={() => {
              mutation.mutate(newYear)
            }}
            className="btn btn-primary"
          >
            {mutation.isLoading && <Spinner className={"mx-1"} />}{" "}
            {formatMessage("jw-Create")}
          </button>
        }
      />
      <div className="mt-4 mb-3">
        {formatMessage("jw-Fiscal Year")} / {formatMessage("jw-Create")}
        <span className="mx-3" />
        {mutation.isLoading ? (
          <span className="text-warning">
            <Spinner className={"mx-1"} /> {formatMessage("jw-Saving...")}
          </span>
        ) : (
          ""
        )}
        {mutation.isSuccess ? (
          <span className="text-success">{formatMessage("jw-Created")}</span>
        ) : (
          ""
        )}
        {mutation.isError ? (
          <span className="text-danger">{formatMessage("jw-Error")}</span>
        ) : (
          ""
        )}
      </div>
      {wbsQuery.isLoading ? (
        <div className="text-center">
          <Spinner className={"lg"} />
        </div>
      ) : (
        <>
          <WhiteBoxContainer>
            <YearDetailsForm
              year={newYear}
              wbs={wbsQuery.data}
              onValueChanged={onValueChanged}
              isCreating={true}
              errors={errors}
              canManage={true}
            />
          </WhiteBoxContainer>
        </>
      )}
    </>
  )
})
