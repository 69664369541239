import { formatMessage } from "devextreme/localization"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { getErrorByField } from "../../../Admin/Headcount/Main/Utils/ErrorHandling"
import Button from "../components/Button"
import DatePickerInput from "../components/input/DatePickerInput"
import DropdownSelect from "../components/input/DropdownSelect"
import PercentageInput from "../components/input/PercentageInput"
import { ResourceTile } from "../components/ResourceTile"
import { getWorkingDays } from "../services/WorkdayService"
import "./AllocationForm.scss"
import { getAllUsers, getJobs } from "../../../../services"
import { useAppAbility } from "../../../../Context/ability"

export const AllocationForm = ({
  project,
  resource,
  allocation,
  initAllocation,
  minDate,
  maxDate,
  onCancel,
  onSave,
  errors,
  showJobYear,
  lineManagerFilterProject = false,
  lineManagerAllowedAllocationJobs = [],
  activitySeparator = false
}) => {
  const { can } = useAppAbility()
  const canManageProjects = can("manage", "ProjectAllocations")

  const [resourceList, setResourceList] = useState([])
  const [projectList, setProjectList] = useState([])
  const [newAllocation, setNewAllocation] = useState({})
  const [workingDaysCounter, setWorkingDaysCounter] = useState(0)

  const loadResources = async (leavingDate_gte, joiningDate_lte) => {
    const { data } = await getAllUsers(false, leavingDate_gte, joiningDate_lte)
    setResourceList(data)
  }

  const loadProjects = async (startDate, endDate) => {
    if (lineManagerFilterProject) {
      setProjectList(
        lineManagerAllowedAllocationJobs.map((p) => ({
          ...p,
          id: p._id,
          name: p.description
        }))
      )
      return
    }

    const params =
      canManageProjects || lineManagerFilterProject
        ? {}
        : {
            user_is_delivery_manager: true,
            user_is_accountable: true,
            user_is_deputy_accountable: true
          }
    if (startDate) {
      params.valid_from_date = moment(startDate).format("YYYY-MM-DD")
    }
    if (endDate) {
      params.valid_to_date = moment(endDate).format("YYYY-MM-DD")
    }
    params.populate_wbs = true
    let { data: projects } = await getJobs(params)

    setProjectList(projects.map((p) => ({ ...p, id: p._id, name: p.description })))
  }

  useEffect(() => {
    setNewAllocation({
      jobId: allocation?.id || project?.id,
      userId: resource?.id || resource?.userId,
      startDate:
        (allocation && new Date(allocation.specificStartDate)) ||
        (initAllocation && new Date(initAllocation.specificStartDate)),
      endDate:
        (allocation && new Date(allocation.specificEndDate)) ||
        (initAllocation && new Date(initAllocation.specificEndDate)),
      allocationPercentage: (allocation?.specificPercent || 0) * 100
    })
  }, [allocation, project, resource, initAllocation])

  useEffect(() => {
    const selectedProject =
      projectList.find((p) => p.id === newAllocation.jobId) || {}
    loadResources(selectedProject.startDate, selectedProject.endDate)
  }, [newAllocation.jobId, projectList])

  useEffect(() => {
    if (resource) {
      const startDate = resource.joiningDate
      const endDate = resource.leavingDate
        ? resource.leavingDate
        : moment().endOf("year")

      loadProjects(startDate, endDate)
    } else {
      loadProjects()
    }
  }, [resource])

  useEffect(() => {
    if (newAllocation.startDate && newAllocation.endDate && newAllocation.userId) {
      getWorkingDays(
        newAllocation.startDate,
        newAllocation.endDate,
        newAllocation.userId
      )
        .then(({ data: { workdaysCount } }) => {
          setWorkingDaysCounter(workdaysCount)
        })
        .catch(() => setWorkingDaysCounter(0))
    } else {
      setWorkingDaysCounter(0)
    }
  }, [newAllocation.userId, newAllocation.startDate, newAllocation.endDate])

  function handleSave() {
    onSave({
      ...newAllocation,
      isNew: !allocation
    })
  }

  function updateFormValue(key, value) {
    setNewAllocation({
      ...newAllocation,
      [key]: value
    })
  }

  const activityOptions = useMemo(
    () =>
      projectList
        .sort((a, b) => {
          // sort by activity name which is made of wbs and name
          const a_activityName = `${
            a.fiscalYear?.wbs ? a.fiscalYear.wbs.code : ""
          } - ${a.name}`.toLowerCase()

          const b_activityName = `${
            b.fiscalYear?.wbs ? b.fiscalYear.wbs.code : ""
          } - ${b.name}`.toLowerCase()

          return a_activityName.localeCompare(b_activityName)
        })
        .map((p) => {
          return {
            id: p.id,
            cell: `${
              p.fiscalYear.wbs.aliasCode ? p.fiscalYear.wbs.aliasCode + "\n" : ""
            }${showJobYear ? ` ${p.fiscalYear.year}` : ""} ${
              p.fiscalYear?.wbs ? p.fiscalYear.wbs.code : ""
            } - ${p.name}`
          }
        }),
    [projectList]
  )

  const resourcesOptions = useMemo(
    () => (resourceList || []).map((r) => ({ id: r._id, cell: r.name })),
    [resourceList]
  )

  const dateLimits = useMemo(() => {
    const project = projectList.find((p) => p.id === newAllocation.jobId) || {}
    const res = resourceList.find((r) => r._id === newAllocation.userId) || {}
    let minDateCalc = project.startDate || minDate || allocation?.startDate
    let maxDateCalc = project.endDate || maxDate || allocation?.endDate
    if (res.joiningDate && moment(res.joiningDate).isAfter(minDateCalc)) {
      minDateCalc = res.joiningDate
    }
    if (res.leavingDate && moment(res.leavingDate).isBefore(maxDateCalc)) {
      maxDateCalc = res.leavingDate
    }

    return {
      minDate: minDateCalc && moment(minDateCalc).toDate(),
      maxDate: maxDateCalc && moment(maxDateCalc).toDate()
    }
  }, [
    newAllocation.jobId,
    allocation,
    minDate,
    maxDate,
    newAllocation.userId,
    resourceList,
    projectList
  ])

  const editableResources = !resource

  return (
    <div className="allocation-form">
      <h5 className="p-4">
        {formatMessage(
          allocation
            ? "preallocation-resource-allocation-edit"
            : "preallocation-resource-allocation-new"
        )}
      </h5>
      {!editableResources && resource && (
        <div className="allocation-tile px-4 py-3">
          <ResourceTile resource={resource} />
        </div>
      )}
      <div className="p-4">
        <div className="mb-3">
          <DropdownSelect
            label={formatMessage("preallocation-resource-allocation-activity")}
            options={activityOptions}
            value={newAllocation.jobId}
            onSelected={(val) => val && updateFormValue("jobId", val.id)}
            error={getErrorByField(errors, "jobId")}
            readOnly={editableResources}
            enableSeparator={activitySeparator}
          />
        </div>
        {editableResources && (
          <div className="mb-3">
            <DropdownSelect
              label={formatMessage("preallocation-resource-allocation-resource")}
              options={resourcesOptions}
              value={newAllocation.userId}
              onSelected={(val) => val && updateFormValue("userId", val.id)}
              error={getErrorByField(errors, "userId")}
            />
          </div>
        )}
        <div className="mb-3">
          <DatePickerInput
            label={formatMessage("preallocation-resource-allocation-start-date")}
            value={newAllocation.startDate}
            onChange={(date) => updateFormValue("startDate", date)}
            error={getErrorByField(errors, "startDate")}
            {...dateLimits}
          />
        </div>
        <div className="mb-3">
          <DatePickerInput
            label={formatMessage("preallocation-resource-allocation-end-date")}
            value={newAllocation.endDate}
            onChange={(date) => updateFormValue("endDate", date)}
            error={getErrorByField(errors, "endDate")}
            {...dateLimits}
          />
        </div>
        <div className="mb-3">
          <label className="d-block mb-1">
            {formatMessage("preallocation-resource-allocation-working-days")}
          </label>
          <input
            className="form-control input-disabled"
            disabled
            value={workingDaysCounter}
          />
        </div>
        <div className="mb-5">
          <PercentageInput
            label={formatMessage(
              "preallocation-resource-allocation-allocation-percentage"
            )}
            value={newAllocation.allocationPercentage}
            onChange={(e) =>
              updateFormValue("allocationPercentage", parseFloat(e.target.value))
            }
            error={getErrorByField(errors, "allocationPercentage")}
          />
        </div>
        <div className="d-flex justify-content-between">
          <Button
            className="btn-outline-primary"
            text={formatMessage("preallocation-cancel")}
            onClick={onCancel}
          />
          <Button
            className="btn-primary"
            text={formatMessage("preallocation-save")}
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  )
}
