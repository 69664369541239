import { useEffect, useRef } from "react"
import Tabs from "react-responsive-tabs"
import { formatMessage } from "devextreme/localization"
import "./index.scss"
import { useAddResource } from "../hooks/useAddResource"
import Filters from "./Filters"
import { useDebounce } from "../hooks/useDebounce"
import ResourcesList from "./ResourcesList"
import { useAllocations } from "../hooks/useAllocations"
import { SidePanel } from "../components/SidePanel"
import { AllocationForm } from "../EditAllocation/AllocationForm"
import moment from "moment"

export default function AddResource() {
  const {
    filters,
    setFilters,
    DEFAULT_FILTERS,
    workingDays,
    loadResources,
    // projects,
    levels,
    jobTitles,
    teamLeaders,
    resources,
    isLoadingResources,
    resourcesNoTolerance,
    loadResourcesNoTolerance,
    isCreatingAllocation,
    createAllocationsErrors,
    toggleIsCalendarShown,
    loadResource,
    loadMoreResources,
    loadMoreResourcesNoTolerance,
    paginationMeta,
    paginationNoToleranceMeta
  } = useAddResource()

  const {
    editAllocation,
    editingAllocation,
    errors: allocationErrors,
    resetErrors: resetAllocationErrors,
    createAllocation,
    projects,
    loadAllocationData,
    loadProjects: loadProjectList
  } = useAllocations()

  const { debounce: resourcesDebounce } = useDebounce(1000)

  const tabs = [
    {
      key: "allTab",
      title: formatMessage(
        "preallocation-add-resource-all-tab",
        paginationMeta.totalCount || "-"
      ).toUpperCase(),
      getContent: () => (
        <ResourcesList
          resources={resources}
          projectId={filters.project}
          onToggleIsCalendarShown={onToggleIsCalendarShown}
          onAddResource={onAddResource}
          onResourceFiltersChange={(resource, resourceFilters) =>
            loadResource(resource, filters, resourceFilters)
          }
          onLoadMore={() => loadMoreResources(filters)}
        />
      )
    },
    {
      key: "ignoreToleranceTab",
      title: formatMessage(
        "preallocation-add-resource-ignore-tolerance-tab",
        paginationNoToleranceMeta.totalCount || "-"
      ).toUpperCase(),
      getContent: () => (
        <ResourcesList
          resources={resourcesNoTolerance}
          projectId={filters.project}
          onToggleIsCalendarShown={onToggleIsCalendarShown}
          onAddResource={onAddResource}
          onResourceFiltersChange={(resource, resourceFilters) =>
            loadResource(resource, filters, resourceFilters)
          }
          onLoadMore={() => loadMoreResourcesNoTolerance(filters)}
        />
      )
    }
  ]

  const tabRef = useRef("allTab")

  useEffect(() => {
    loadAllocationData()
    loadResources(filters)
    loadResourcesNoTolerance(filters)
  }, [])

  const onFiltersChange = (filter) => {
    const changedFilterKeys = Object.keys(filter)

    if (
      !changedFilterKeys.some(
        (key) => JSON.stringify(filter[key]) !== JSON.stringify(filters[key])
      )
    ) {
      return
    }

    const newFilters = {
      ...filters,
      ...filter
    }

    setFilters(newFilters)

    resourcesDebounce(async () => {
      await loadResources(newFilters)
      await loadResourcesNoTolerance(newFilters)
    })
  }

  const onResetFilters = () => {
    setFilters({
      ...DEFAULT_FILTERS
    })
    loadResources(DEFAULT_FILTERS)
    loadResourcesNoTolerance(DEFAULT_FILTERS)
  }

  const onToggleIsCalendarShown = (resource) => {
    toggleIsCalendarShown(resource)
  }

  const onAddResource = async (resource) => {
    let startDate = filters.startDate
    let endDate = filters.endDate
    if (tabRef.current === "allTab") {
      const startDateTolerance = parseFloat(filters.startDateTolerance) || 0
      const endDateTolerance = parseFloat(filters.endDateTolerance) || 0
      startDate = moment(startDate)
        .subtract(startDateTolerance, "days")
        .toDate()
      endDate = moment(endDate)
        .add(endDateTolerance, "days")
        .toDate()
    }
    await loadProjectList(startDate, endDate)
    resetAllocationErrors()
    editAllocation({
      resource,
      project: { id: filters.project }
    })
  }

  const onSaveAllocation = async ({ isNew, ...allocation }) => {
    try {
      await createAllocation(allocation)
      editAllocation(null)
      loadResources(filters)
    } catch (error) {}
  }

  return (
    <div className="add-filters">
      <div className="add-filters-main">
        <SidePanel show={editingAllocation} onClose={() => editAllocation(null)}>
          <AllocationForm
            {...editingAllocation}
            onCancel={() => editAllocation(null)}
            onSave={onSaveAllocation}
            errors={allocationErrors}
            showJobYear={true}
            activitySeparator={true}
          />
        </SidePanel>
        <Filters
          projects={projects}
          filters={filters}
          workingDays={workingDays}
          levels={levels}
          jobTitles={jobTitles}
          teamLeaders={teamLeaders}
          onFiltersChange={onFiltersChange}
          onResetFilters={onResetFilters}
          errors={createAllocationsErrors}
        />
        <Tabs
          containerClass="add-filters-tab-container"
          tabsWrapperClass="body-tabs body-tabs-layout"
          panelClass="add-filters-tab-panel"
          transform={false}
          showInkBar={true}
          items={tabs}
          onChange={(tab) => (tabRef.current = tab)}
        />
      </div>
    </div>
  )
}
