export const parseFiscalYearToData = (fiscalYear, isChargeable) => {
  const parsedFiscalYear = {
    _id: fiscalYear._id,
    wbs: fiscalYear.wbs,
    year: fiscalYear.year,
    contractValue: fiscalYear.contractValue,
    standardCostsTotal: fiscalYear.standardCostsTotal,
    otherCosts: fiscalYear.otherCosts,
    targetMargin: fiscalYear.targetMargin,
    targetMarginPerc: fiscalYear.targetMarginPerc / 100,
    revenueXtechManagerial: fiscalYear.revenueXtechManagerial,
    rfpXTech: fiscalYear.rfpXTech,
    standardCostsXtech: fiscalYear.standardCostsXtech,
    xTechEffortPerc: fiscalYear.xTechEffortPerc / 100,
    isDc: fiscalYear.isDc,
    xTechMargin: fiscalYear.xTechMargin,
    forceDc: fiscalYear.forceDc,
    dcMultiply: fiscalYear.dcMultiply,
  }

  if (!isChargeable) {
    delete parsedFiscalYear.otherCosts
    delete parsedFiscalYear.targetMarginPerc
    delete parsedFiscalYear.xTechEffortPerc
    delete parsedFiscalYear.xTechMargin
  }

  return parsedFiscalYear
}
