import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { parseCustomerData } from "../../utils/parseCustomerData"
import { getAllCustomers } from "../../../../../services/CustomerService"

export function useSelectCustomer() {
  const { isLoading, isError, isSuccess, data, error } = useQuery(
    ["customer"],
    async () => {
      return await getAllCustomers().then((data) => {
        return data.data.map((customer) => parseCustomerData(customer))
      })
    },
    { staleTime: 1000 * 60 * 2 },
  )

  const parseCustomerId = (customerId) => {
    if (isError) {
      return "error"
    }

    if (isLoading) {
      return "-"
    }

    return data.find((customer) => customer.id === customerId)?.name || "-"
  }

  const getCustomersAsOptions = () => {
    if (!isSuccess) {
      return []
    }
    return data.map((customer) => ({ id: customer.id, cell: customer.name }))
  }
  const [customerOptions, setCustomerOptions] = useState(getCustomersAsOptions())
  useEffect(() => {
    setCustomerOptions(getCustomersAsOptions())
  }, [data])

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    parseCustomerId,
    customerOptions,
  }
}
