import { useEffect, useState } from "react"
import { formatMessage } from "devextreme/localization"
import PriceCell from "../cell/PriceCell"

export default function FooterRow({ footerData, columnOffsets, lastPinnedIndex }) {
  const [footerEl, setFooterEl] = useState(null)

  useEffect(() => {
    if (!footerEl || !columnOffsets) {
      return
    }

    let timeoutId = setTimeout(() => {
      for (let index = 0; index < Array.from(footerEl.children).length; index++) {
        if (lastPinnedIndex != null && index <= lastPinnedIndex) {
          footerEl.children[index].className +=
            " bg-white position-sticky overflow-none"
          footerEl.children[index].style.left = columnOffsets
            ? columnOffsets[index] + "px"
            : 0
          footerEl.children[index].style.zIndex = "999"
        } else {
          footerEl.children[index].className = footerEl.children[
            index
          ].className.replace(" position-sticky overflow-none", "")
          footerEl.children[index].style.left = null
          footerEl.children[index].style.zIndex = null
        }
      }
    }, 0)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [footerEl, lastPinnedIndex, columnOffsets])

  const tdClassName = "text-center text-nowrap px-3"

  return (
    <tr ref={setFooterEl} className="footer-row position-relative">
      <td className="no-right-border p-2">
        <b>{formatMessage("jw-Total")}</b>
      </td>
      <td className="no-right-border"></td>
      <td className="no-right-border"></td>
      <td className="no-right-border"></td>
      <td className="no-right-border"></td>
      <td className="no-right-border"></td>
      <td className="no-right-border"></td>
      <td></td>
      <td className={`${tdClassName}`}>
        <b>
          <PriceCell info={footerData?.contractValue} />
        </b>
      </td>
      <td className={`${tdClassName}`}>
        <b>
          <PriceCell info={footerData?.standardCostsTotal} />
        </b>
      </td>
      <td className={`${tdClassName}`}>
        <b>
          <PriceCell info={footerData?.otherCosts} />
        </b>
      </td>
      <td className={`${tdClassName}`}>
        <b>
          <PriceCell info={footerData?.targetMargin} />
        </b>
      </td>
      <td></td>
      <td className={`${tdClassName}`}>
        <b>
          <PriceCell info={footerData?.revenueXtechManagerial} />
        </b>
      </td>
      <td className={`${tdClassName}`}>
        <b>
          <PriceCell info={footerData?.rfpXTech} />
        </b>
      </td>
      <td className={`${tdClassName}`}>
        <b>
          <PriceCell info={footerData?.standardCostsXtech} />
        </b>
      </td>
      <td></td>
      <td></td>
      <td className={`${tdClassName}`}>
        <b>
          <PriceCell info={footerData?.xTechMargin} />
        </b>
      </td>
    </tr>
  )
}
