import JobActionCell from "../cell/JobActionCell"
import PriceCellEdit from "../cell/PriceCellEdit"
import PriceCell from "../cell/PriceCell"
import JobCodeCell from "../cell/JobCodeCell"
import { formatMessage } from "devextreme/localization"

export const jobColumns = [
  {
    id: "shortCode",
    Header: () => <span>{formatMessage("jw-Actions")}</span>,
    accessor: "shortCode",
    Cell: ({ info, row, canManage }) => (
      <JobActionCell yearId={row.fiscalYear} job={row} canManage={canManage} />
    ),
  },
  {
    id: "name",
    Header: () => <span>{formatMessage("jw-Name")}</span>,
    accessor: (info) => {
      return {
        name: info?.name,
        startDate: info?.startDate,
        endDate: info?.endDate,
        jobOwner: info?.jobOwner,
        description: info?.description,
      }
    },
    Cell: ({ info, onChange, startDateError, endDateError, canManage, width }) => (
      <JobCodeCell
        info={info}
        onChange={(val, field) => onChange(val, field)}
        startDateError={startDateError || ""}
        endDateError={endDateError || ""}
        canManage={canManage}
        width={width}
      />
    ),
  },
  {
    id: "revenueXtechManagerial",
    Header: () => <span>{formatMessage("jw-Managerial")}</span>,
    accessor: "revenueXtechManagerial",
    Cell: ({ info }) => <PriceCell info={info} />,
  },
  {
    id: "rfpXTech",
    Header: () => <span>{formatMessage("jw-RFP")}</span>,
    accessor: "rfpXTech",
    Cell: ({ info }) => <PriceCell info={info} />,
  },
  {
    id: "standardCostsXtech",
    Header: () => <span>{formatMessage("jw-STD cost xTech")}</span>,
    accessor: "standardCostsXtech",
    Cell: ({ info, onChange, error, canManage }) => {
      if (!canManage) {
        return <PriceCell info={info} />
      }
      return (
        <PriceCellEdit info={info} onChange={(val) => onChange(val)} error={error} />
      )
    },
  },
  {
    id: "xTechMargin",
    Header: () => <span>{formatMessage("jw-Margin xTech")}</span>,
    accessor: "xTechMargin",
    Cell: ({ info }) => <PriceCell info={info} />,
  },
]
