import { formatMessage } from "devextreme/localization"

export default function FormGroupTitle({ title, required, requiredForEnabling}) {
  return (
    <div className="px-2 py-4">
      <h6 className="font-weight-bold text-uppercase">{title}</h6>
      {required ? (
        <div className="text-muted py-1">{formatMessage("hc-required")}</div>
      ) : (
        ""
      )}
      {requiredForEnabling ? (
        <div className="text-muted py-1">
          {formatMessage("hc-required-for-enabling")}
        </div>
      ) : (
        ""
      )}
    </div>
  )
}
