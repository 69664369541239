export const parseJobData = (job) => {
  const parsedJob = {
    //table info
    _id: job._id,
    shortCode: job.shortCode,
    description: job.description,
    startDate: new Date(job.startDate),
    endDate: new Date(job.endDate),
    jobOwner: job.jobOwner,
    revenueXtechManagerial: job.revenueXtechManagerial,
    rfpXTech: job.rfpXTech,
    standardCostsXtech: job.standardCostsXtech,
    xTechMargin: job.xTechMargin,

    //details info
    status: job.status,
    accountable: job.accountable,
    deputyAccountables: job.deputyAccountables,
    internalSupporters: job.internalSupporters,
    externalSupporters: job.externalSupporters,
    hasExternalSupport: job.hasExternalSupport,
    wbs: job.wbs ?? null,
    isDescriptionMandatory: job.isDescriptionMandatory,
    specificCompetence: job.specificCompetence,
    subComp: job.subComp,
    mode: job.mode,
    salesResponsible: job.salesResponsible,
    deliveryManager: job.deliveryManager,
    customerRef: job.customerRef,
    customerBusinessFunction: job.customerBusinessFunction,
    fiscalYear: job.fiscalYear,
    jobSpecialties: job.jobSpecialties,
    datwavePurchaseOrder: job.datwavePurchaseOrder,
    datwavePurchaseOrderValue: job.datwavePurchaseOrderValue,
    digitalAssets: job.digitalAssets,
    technologyProviders: job.technologyProviders,
    technologyProducts: job.technologyProducts,
    staffingNotes: job.staffingNotes,
    competenceRevenuesPerc: (() => {
      if (job.competenceRevenuesPerc) {
        Object.keys(job.competenceRevenuesPerc)?.forEach(
          (competenceRevenue) =>
            (job.competenceRevenuesPerc[competenceRevenue] =
              job.competenceRevenuesPerc[competenceRevenue] * 100)
        )
      }

      return job.competenceRevenuesPerc
    })()
  }

  return parsedJob
}
