import { useMemo, useState } from "react"
import { usePopper } from "react-popper"
import "./ResourceCell.scss"
import { ResourceTile } from "../components/ResourceTile"
import Icon from "../components/Icon"
import ResourceDetailsModal from "../components/ResourceDetailsTooltip"
import { formatMessage } from "devextreme/localization"
import Button from "../components/Button"
import ResourceDoughnutChart from "../components/ResourceDoughnutChart"

export default function ResourceCell({
  project,
  resource,
  filters,
  onAddAllocation,
  onEditMultipleAllocations,
  onRemove
}) {
  const [referenceEl, setReferenceEl] = useState(null)
  const [popperEl, setPopperEl] = useState(null)
  const [isTooltipShown, setIsTooltipShown] = useState(false)
  const popper = usePopper(referenceEl, popperEl, {
    placement: "bottom-end",
    strategy: "fixed"
  })

  const seriesItems = useMemo(() => {
    return [
      {
        id: "busy",
        label: formatMessage(
          filters.aggregation === "WEEKLY"
            ? "preallocation-resource-weekly-busy-time"
            : "preallocation-resource-monthly-busy-time"
        ),
        value: resource.averageBusyPercent,
        color: "#5A6167",
        borderColor: "#5A6167"
      },
      {
        id: "free",
        label: formatMessage(
          filters.aggregation === "WEEKLY"
            ? "preallocation-resource-weekly-free-time"
            : "preallocation-resource-monthly-free-time"
        ),
        value: resource.averageFreePercent,
        color: "#F1F4F6",
        borderColor: "#D4D6D8"
      }
    ]
  }, [resource, filters.aggregation])

  return (
    <>
      <div className="resource-cell">
        <div
          className="resource-cell-color"
          style={{ backgroundColor: project.color }}
        />
        <div className="resource-cell-content text-truncate">
          <div className="resource-cell-content-top text-truncate">
            <ResourceTile resource={resource} color={project.color} />
            <div className="resource-cell-icons">
              <Icon
                className="resource-cell-icon bg-secondary"
                iconRelativePath="monade/preallocation/trash"
                onClick={() => onRemove(project, resource)}
              />
              <span ref={setReferenceEl}>
                <Icon
                  className={`resource-cell-icon ${
                    isTooltipShown ? "bg-primary text-white" : "bg-secondary"
                  }`}
                  iconRelativePath="monade/preallocation/info"
                  onMouseEnter={() => setIsTooltipShown(true)}
                  onMouseLeave={() => setIsTooltipShown(false)}
                />
              </span>
              <ResourceDetailsModal
                ref={setPopperEl}
                style={popper.styles.popper}
                resource={resource}
                isShown={isTooltipShown}
                {...popper.attributes.popper}
              />
            </div>
          </div>
          <div className="resource-cell-content-middle flex-fill">
            <ResourceDoughnutChart seriesItems={seriesItems} />
          </div>
          <div className="resource-cell-content-bottom">
            <Button
              className="btn-primary"
              // icon="monade/preallocation/plus"
              text={formatMessage("preallocation-projects-add-allocation")}
              onClick={() => onAddAllocation(project, resource)}
            />
            <Button
              className="btn-outline-primary"
              // icon="monade/preallocation/edit"
              text={formatMessage(
                "preallocation-projects-edit-multiple-allocations"
              )}
              onClick={() => onEditMultipleAllocations(project, resource)}
            />
          </div>
        </div>
      </div>
    </>
  )
}
