import { DataGrid } from "devextreme-react"
import {
  Button,
  Column,
  CustomRule,
  Editing,
  Lookup,
  RequiredRule,
  Texts
} from "devextreme-react/data-grid"
import { formatMessage } from "devextreme/localization"
import React from "react"
import { isEmpty } from "underscore"
import { round } from "../../../../Utils/utils"

export default function Table(props) {
  const alignDaysHorus = (event) => {
    if (event.newData && (event.newData.days || event.newData.hours)) {
      // Row UPDATE case
      if (props.unitIsHours) {
        // update days
        event.newData.days = event.newData.hours / 8
      } else {
        // update hours
        event.newData.hours = event.newData.days * 8
      }
    } else if (event.data) {
      // Row INSERT, DELETE cases
      if (props.unitIsHours) {
        // update days
        event.data.days = event.data.hours / 8
      } else {
        // update hours
        event.data.hours = event.data.days * 8
      }
    }
  }

  const updateTotalHours = (event) => {
    let totalHours =
      round(
        props.datasource?.reduce(
          (accumulator, currentValue) =>
            (parseFloat(accumulator) || 0) + (parseFloat(currentValue?.hours) || 0),
          0
        )
      ) || 0

    props.setTotalHoursCompiled(totalHours)
  }

  return (
    <>
      <DataGrid
        showBorders={true}
        dataSource={props.datasource}
        onRowUpdating={alignDaysHorus}
        onRowInserting={alignDaysHorus}
        onRowRemoving={alignDaysHorus}
        onRowUpdated={updateTotalHours}
        onRowInserted={updateTotalHours}
        onRowRemoved={updateTotalHours}
      >
        <Editing
          mode="cell"
          allowAdding={true}
          allowUpdating={true}
          allowDeleting={true}
        >
          <Texts confirmDeleteMessage="" />
        </Editing>
        <Column dataField={"code"} caption={formatMessage("Job")} alignment={"left"}>
          <Lookup
            dataSource={props.jobs}
            displayExpr="suggestionLabel"
            valueExpr="_id"
          />
          <RequiredRule />
        </Column>

        <Column
          dataField={"description"}
          caption={formatMessage("Description")}
          editorOptions={{ maxLength: 120 }}
          alignment={"left"}
        >
          <CustomRule
            validationCallback={(e) => {
              return e.data.code &&
                props.jobs.find((i) => i._id === e.data.code).isDescriptionMandatory
                ? !isEmpty(e.data.description)
                : true
            }}
            message="Description is required for this job"
          />
        </Column>
        <Column
          dataField={props.unitIsHours ? "hours" : "days"}
          caption={
            props.unitIsHours ? formatMessage("Hours") : formatMessage("Days")
          }
          dataType={"number"}
          alignment={"center"}
          width={"150"}
          format={{}} // Mandatory for localization
          editorOptions={{
            showSpinButtons: true,
            format: { type: "fixedPoint", precision: 2 }
          }}
        >
          <CustomRule
            validationCallback={(e) => {
              return e.data.hours > 0 || e.data.days > 0
            }}
            message={
              props.unitIsHours === true
                ? formatMessage("warning-ts-compiling-sending-greater-then-0-hours")
                : formatMessage("warning-ts-compiling-sending-greater-then-0-days")
            }
          />
        </Column>
        <Column type="buttons" width={50}>
          <Button name="delete" icon="trash" />
        </Column>
      </DataGrid>
    </>
  )
}
