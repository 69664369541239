import { Accordion } from "devextreme-react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Card } from "reactstrap"
import questionsIt from "./data-it"
import questionsEn from "./data-en"
import { formatMessage } from "devextreme/localization"
import { getLocale } from "../localization/localization"
import { useEffect, useState } from "react"

const CustomItem = (data) => {
  return (
    <div>
      <div>
        <span>{data.text}</span> &emsp;
        {data.link && <a class="dx-icon-link" href={data.link} />}
      </div>
    </div>
  )
}

export const SupportModal = ({ show, close }) => {
  const currentLocale = getLocale()
  const [questions, setQuestions] = useState(questionsEn)

  useEffect(() => {
    switch (currentLocale) {
      case "en":
        return setQuestions(questionsEn)
      case "it":
        return setQuestions(questionsIt)
      default:
        return setQuestions(questionsEn)
    }
  }, [currentLocale])

  const onContentReady = (e) => {
    for (let i = 0; i < questions.length; i++) {
      e.component.collapseItem(i)
    }
  }

  return (
    <span className="d-inline-block mb-2 mr-2">
      <Modal isOpen={show} toggle={close} size="lg" centered>
        <ModalHeader>{formatMessage("FAQs")}</ModalHeader>
        <ModalBody>
          <Card>
            <Accordion
              dataSource={questions}
              collapsible={true}
              itemRender={CustomItem}
              onContentReady={onContentReady}
            ></Accordion>
          </Card>
        </ModalBody>
        <ModalFooter style={{ display: "flex" }}>
          <div style={{ display: "inline" }}>
            <span>{formatMessage("FAQs-footer-message-1")} </span>
            <a
              href="https://bip-xtech.atlassian.net/servicedesk/customer/portal/2/group/9/create/32"
              target="_blank"
              rel="noopener noreferrer"
            >
              {formatMessage("FAQs-footer-message-2")}
            </a>
            <span> {formatMessage("or")} </span>
            <a
              href="https://bip-xtech.atlassian.net/servicedesk/customer/portal/2/group/9/create/32"
              target="_blank"
              rel="noopener noreferrer"
            >
              {formatMessage("FAQs-footer-message-3")}
            </a>
          </div>
        </ModalFooter>
      </Modal>
    </span>
  )
}
