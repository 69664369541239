import DropdownSelect from "./input/DropdownSelect"
import "./Filter.scss"
import { useSelectStatuses } from "../hooks/selectHooks/useSelectStatuses"
import { formatMessage } from "devextreme/localization"

export default function StatusFilter({ value, setValue, className }) {
  const { statusOptions } = useSelectStatuses()

  return (
    <DropdownSelect
      className={`${className} filter-select`}
      innerLabel={
        <span className="text-muted pr-1">{formatMessage("jw-status")}:</span>
      }
      value={value || "all"}
      options={statusOptions}
      placeholder={formatMessage("jw-all")}
      onSelected={(option) => {
        setValue(option?.id || null)
      }}
    />
  )
}
