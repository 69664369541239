import { LabelWithError } from "./LabelWithError"
import "./TextInput.scss"
import "./InputDefaults.scss"

export default function TextInput({
  className,
  style,
  value,
  onChange,
  label,
  inputClassName,
  placeholder = null,
  error = "",
  readOnly = false,
  disabled = false
}) {
  const randomId = Math.random().toString()

  return (
    <div
      style={style}
      className={`${className} date-picker-input-container ${
        readOnly ? "readonly" : disabled ? "disabled" : "cursor"
      }`}
    >
      {label ? (
        <LabelWithError
          disabled={disabled}
          readOnly={readOnly}
          label={label}
          error={error}
          labelClassName={`label mb-1`}
          htmlFor={randomId}
        />
      ) : (
        ""
      )}
      <input
        type="text"
        id={randomId}
        disabled={disabled}
        className={`w-100 ${inputClassName} ${error !== "" ? "border-danger" : ""} ${
          readOnly ? "readonly" : disabled ? "disabled" : ""
        }`}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  )
}
