import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { getAllUsers } from "../../../../../services"
import { parseBipUserData } from "../../utils/parseBipUserData"

export function useSelectBipUsers() {
  const includeRoles = true
  const isEnabled = false
  const { isLoading, isError, isSuccess, data, error } = useQuery(
    ["GET", "user", { includeRoles, isEnabled }],
    async () => {
      return await getAllUsers(isEnabled).then((data) => {
        return data.data.map((bipUser) => parseBipUserData(bipUser))
      })
    },
    { staleTime: 1000 * 60 * 2 },
  )

  const parseBipUserId = (bipUserId) => {
    if (isError) {
      return "error"
    }

    if (isLoading) {
      return "-"
    }

    return data.find((bipUser) => bipUser.id === bipUserId)?.fullName || "-"
  }

  const getBipUsersAsOptions = () => {
    if (!isSuccess) {
      return []
    }
    return data.map((u) => ({ id: u.id, cell: u.fullName }))
  }
  const [bipUserOptions, setBipUserOptions] = useState(getBipUsersAsOptions())
  useEffect(() => {
    setBipUserOptions(getBipUsersAsOptions())
  }, [data])

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    parseBipUserId,
    bipUserOptions,
  }
}
