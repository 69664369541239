const questions = [
  {
    id: 1,
    title: "Q.1 In che stato si trova il mio consuntivo?",
    text:
      'Puoi controllare lo stato del tuo consuntivo nella sezione Consuntivo, l\'etichetta in alto ne indica lo stato corrente. "PROCESSED" indica che il consuntivo ha terminato la fase di revisione. "PRE REVIEW", "FIRST REVIEW", "SECOND REVIEW" e\
      "THIRD REVIEW" indicano che il consuntivo si trova nella rispettiva fase di revisione."ON PPA" indica che il Consuntivo è stato riaperto dopo la sua chiusura ed è in attesa di ulteriori modifche.\
      Infine i Consuntivi legati ad uno o più progetti in attesa di commessa restano in stato "ON HOLD" fino alla definizione della stessa.\
      Una volta terminato il processo di revisione, riceverai una mail con il dettaglio delle ore e commesse confermate.'
  },
  {
    id: 2,
    title: "Q.2 Ho bisogno di modificare il consuntivo già inviato",
    text:
      'Se il consuntivo non ha ancora completato il processo di review, (non si trova quindi nello stato "PROCESSED") (vedi Q.1), la modifica può essere fatta direttamente dal tuo leader in una delle fasi di revisione. Se invece il consuntivo è stato chiuso (stato "PROCESSED"), puoi chiedere al tuo Area Manager di fare PPA.'
  },
  {
    id: 3,
    title: "Q.3 Non ho ricevuto la mail di conferma",
    text:
      "Assicurati che il tuo consuntivo è stato effettivamente processato (vedi Q.1). Controlla che la mail non sia nella posta indesiderata."
  },
  {
    id: 4,
    title:
      "Q.4 Devo consuntivare su una commessa ma non la trovo/ non è selezionabile",
    text:
      "Commesse/Job possono essere creati/abilitati solo dagli AM. Rivolgiti direttamente al tuo AM per procedere alla creazione/abilitazione."
  },
  {
    id: 5,
    title: "Q.5 Non accedo alla sezione Consuntivo",
    text:
      "E' possibile richiedere l'abilitazione di un utente al PreTer sul canale Teams.",
    link:
      "https://teams.microsoft.com/l/channel/19%3a4b8496285d7a4ee9bf3172842d2c8dab%40thread.tacv2/PreTER%2520live%2520support?groupId=21ea7a0f-4722-4007-bec0-f2418d4a49d7&tenantId=bb1a63eb-eb09-471a-a005-37b07792a5b5"
  },
  {
    id: 6,
    title:
      "Q.6 Un mio team member non accede al Preter/ non accede alla sezione Consuntivo",
    text: "vedi Q.5"
  },
  {
    id: 7,
    title: "Q.7 Devo consuntivare più/meno ore di quelle indicate in PreTer",
    text:
      "E' sempre possibile inviare un consuntivo con ore in meno o in più rispetto a quelle indicate. Tuttavia, se ti accorgi che il numero indicato non è coerente con i giorni lavorativi della tua sede effettiva, è opportuno segnalarlo. Puoi fare questo tipo di richiesta sul canale Teams.",
    link:
      "https://teams.microsoft.com/l/channel/19%3a4b8496285d7a4ee9bf3172842d2c8dab%40thread.tacv2/PreTER%2520live%2520support?groupId=21ea7a0f-4722-4007-bec0-f2418d4a49d7&tenantId=bb1a63eb-eb09-471a-a005-37b07792a5b5"
  },
  {
    id: 8,
    title:
      "Q.8 Le ore/giorni da consuntivare non corrispondono ai giorni lavorativi",
    text:
      "Il totale ore/giorni indicati su PreTer può dipendere dalla tua sede lavorativa. Controlla nel profilo che i tuoi dati siano corretti, in caso negativo segnalalo direttamente sul canale teams.",
    link:
      "https://teams.microsoft.com/l/channel/19%3a4b8496285d7a4ee9bf3172842d2c8dab%40thread.tacv2/PreTER%2520live%2520support?groupId=21ea7a0f-4722-4007-bec0-f2418d4a49d7&tenantId=bb1a63eb-eb09-471a-a005-37b07792a5b5"
  }
]
export default questions
