import { withRouter } from "react-router-dom"
import { formatMessage } from "devextreme/localization"
import Icon from "../components/Icon"
import "./TeamCell.scss"

export default withRouter(function TeamCell({
  team,
  onToggleIsOpen,
  onToggleIsPinned
}) {
  return (
    <div className="team-cell">
      <div className="team-cell-color" style={{ backgroundColor: team.color }} />
      <div className="team-cell-content text-truncate">
        <div className="d-flex align-items-center text-truncate">
          <span className="team-cell-name text-truncate">{team.name}</span>
          {/*<Icon className={`team-cell-icon ${isTooltipOpen ? 'bg-primary text-white' : 'bg-secondary'}`}*/}
          {/*      iconRelativePath='monade/preallocation/info' onClick={() => setIsTooltipOpen(!isTooltipOpen)}/>*/}
          <Icon
            className={`team-cell-icon rounded-circle ${
              team.isOpen ? "bg-primary text-white" : "bg-secondary"
            }`}
            iconRelativePath={
              team.isOpen
                ? "monade/preallocation/user-no"
                : "monade/preallocation/users"
            }
            onClick={() => onToggleIsOpen()}
          />
          <Icon
            className="team-cell-icon"
            iconRelativePath={ team.isPinned ? "monade/preallocation/pin-active" : "monade/preallocation/pin" }
            onClick={() => onToggleIsPinned()}
          />
        </div>
        <div className="text-muted mt-1">
          {formatMessage("preallocation-teams-info", team.resourceCount, team.owner)}
        </div>
        <div className="d-flex justify-content-between align-items-center mt-2">
          {/* <Button
            className="btn-primary"
            icon="monade/preallocation/plus"
            text={formatMessage("preallocation-teams-add-resource")}
            onClick={() => goToAddResource()}
          /> */}
          {/*<b>{ `Total cost: ${formatPrice(team.cost || 100000)}` }</b>*/}
        </div>
      </div>
    </div>
  )
})
