import { formatMessage } from "devextreme/localization"
import { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import PageTitle from "../../../../../Layout/AppMain/PageTitle"
import BackButton from "../../components/BackButton"
import JobAccountingDataForm from "../../components/JobAccountingDataForm"
import JobCompetenceRevenueForm from "../../components/JobCompetenceRevenueForm"
import JobDetailsForm from "../../components/JobDetailsForm"
import { Spinner } from "../../components/Spinner"
import WhiteBoxContainer from "../../components/WhiteBoxContainer"
import { useCreateJob } from "../../hooks/useCreateJob"
import { useWbsData } from "../../hooks/useWbsData"
import { useYearData } from "../../hooks/useYearData"
import { getJobDescriptionMandatoriness } from "../../utils/jobDescriptionMandatoriness"
import constants from "../../../../../Common/constants"

export default withRouter(function JobCreate({ match, history, location }) {
  const [wbsId, setWbsId] = useState("")
  const [yearId, setYearId] = useState(match.params.yearId)
  const [
    isDescriptionMandatoryReadonly,
    setIsDescriptionMandatoryReadonly
  ] = useState(false)
  const wbsQuery = useWbsData(wbsId)
  const yearQuery = useYearData(yearId)

  useEffect(() => {
    if (!yearQuery.isSuccess) {
      return
    }
    setWbsId(yearQuery.data.wbs)
  }, [yearQuery.isSuccess, yearQuery.data?.wbs])

  useEffect(() => {
    if (!wbsQuery?.data) {
      return
    }

    const wbsType = wbsQuery.data.type
    const isJobDescriptionMandatory = getJobDescriptionMandatoriness(wbsType)

    setIsDescriptionMandatoryReadonly(isJobDescriptionMandatory)

    if (isJobDescriptionMandatory && !newJob.isDescriptionMandatory) {
      setNewJob({
        ...newJob,
        isDescriptionMandatory: true
      })
    }
  }, [wbsQuery.isSuccess, wbsQuery.data])

  const { mutation, onValueChanged, newJob, setNewJob, errors } = useCreateJob(
    location?.state?.jobToClone || null,
    wbsQuery.data,
    yearQuery.data,
    (job) => {
      history.push(`/admin/${yearQuery.data._id}/job/edit/${job.shortCode}`)
    }
  )

  const isChargeable = () => wbsQuery.data?.type === "1"
  const [confirmOnSave, setConfirmOnSave] = useState(false)

  const parseNonDatwaveJob = (jobToParse) => {
    const { datwavePurchaseOrder, datwavePurchaseOrderValue, ...result } = jobToParse
    return result
  }

  useEffect(() => {
    if (!newJob.jobSpecialties.includes(constants.JobSpecialties.DATWAVE)) {
      let jobToSave = newJob
      let parsedJob = parseNonDatwaveJob(jobToSave)
      setNewJob(parsedJob)
    }
  }, [newJob.jobSpecialties])

  return (
    <>
      <PageTitle
        heading={formatMessage("jw-Create") + " " + formatMessage("jw-Job")}
        subheading={`${formatMessage("jw-Job")} / ${formatMessage("jw-Create")}`}
        beforeActions={<BackButton className="mr-4" />}
        actions={
          <button
            disabled={mutation.isLoading}
            onClick={() => {
              mutation.mutate({ job: newJob, isChargeable: isChargeable() })
            }}
            className="btn btn-primary"
          >
            {mutation.isLoading && <Spinner className={"mx-1"} />}{" "}
            {formatMessage("jw-Create")}
          </button>
        }
      />
      <div className="mt-4 mb-3">
        {formatMessage("jw-Job")} / {formatMessage("jw-Create")}
        <span className="mx-3" />
        {mutation.isLoading ? (
          <span className="text-warning">
            <Spinner className={"mx-1"} />
            {formatMessage("jw-Saving...")}
          </span>
        ) : (
          ""
        )}
        {mutation.isSuccess ? (
          <span className="text-success"> {formatMessage("jw-Created")}</span>
        ) : (
          ""
        )}
        {mutation.isError ? (
          <span className="text-danger"> {formatMessage("jw-Error")}</span>
        ) : (
          ""
        )}
      </div>
      {wbsQuery.isLoading || yearQuery.isLoading ? (
        <div className="text-center">
          <Spinner className={"lg"} />
        </div>
      ) : (
        <>
          <WhiteBoxContainer>
            <JobDetailsForm
              onValueChanged={onValueChanged}
              job={newJob}
              wbs={wbsQuery.data}
              year={yearQuery.data}
              isCreating={true}
              isCloning={location.state?.jobToClone != null}
              setYearId={setYearId}
              wbsId={wbsId}
              setWbsId={setWbsId}
              setConfirmOnSave={setConfirmOnSave}
              errors={errors}
              canManage={true}
              isDescriptionMandatoryReadonly={isDescriptionMandatoryReadonly}
            />
          </WhiteBoxContainer>
          {isChargeable() ? (
            <WhiteBoxContainer>
              <JobAccountingDataForm
                job={newJob}
                year={yearQuery.data}
                wbs={wbsQuery.data}
                onValueChanged={onValueChanged}
                errors={errors}
                canManage={true}
              />
            </WhiteBoxContainer>
          ) : (
            ""
          )}
          <WhiteBoxContainer>
            <JobCompetenceRevenueForm
              onValueChanged={onValueChanged}
              job={newJob}
              wbs={wbsQuery.data}
              year={yearQuery.data}
              errors={errors}
              canManage={true}
            />
          </WhiteBoxContainer>
        </>
      )}
    </>
  )
})
