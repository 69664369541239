import { useMutation, useQueryClient } from "@tanstack/react-query"
import { formatMessage } from "devextreme/localization"
import { useState } from "react"
import { updateYear } from "../../../../services"
import {
  showSuccessToast,
  showToastError,
} from "../../Headcount/Main/Utils/ErrorHandling"
import { parseFiscalYearData } from "../utils/parseFiscalYearData"
import { parseFiscalYearToData } from "../utils/parseFiscalYearToData"

export function useEditYear(onSuccess) {
  const [errors, setErrors] = useState([])
  const resetErrors = () => {
    setErrors([])
  }

  const queryClient = useQueryClient()

  const mutation = useMutation(
    async ({ year, isChargeable }) => {
      const payload = parseFiscalYearToData(year, isChargeable)

      return await updateYear(payload)
    },
    {
      onError: (err) => {
        if (err.response.status === 400) {
          showToastError(err.response)
          setErrors(err.response.data)
        } else {
          setErrors([{}])
          showToastError(err.response)
        }
      },
      onSuccess: (data) => {
        const parsedYear = parseFiscalYearData(data.data)
        queryClient.invalidateQueries(["GET", "wbs"])
        queryClient.invalidateQueries(["GET", "year/", parsedYear._id])
        setErrors([])
        showSuccessToast(formatMessage("jw-Successfully saved Fiscal Year"))

        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )

  return {
    mutation,
    errors,
    resetErrors,
  }
}
