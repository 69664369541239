import {Modal, ModalHeader, ModalBody, ModalFooter, Card} from "reactstrap"
import {formatMessage} from "devextreme/localization"
import DatePickerInput from "./input/DatePickerInput";
import {useState} from "react";

export const ExportModal = ({show, close, onExport}) => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const onExportClick = () => {
    onExport(startDate, endDate);
    onClose();
  }

  const onClose = () => {
    setStartDate(null);
    setEndDate(null);
    close();
  }

  return (
    <span className="d-inline-block mb-2 mr-2">
      <Modal isOpen={show} toggle={onClose} size="lg" centered>
        <ModalHeader>{formatMessage('preallocation-projects-export-allocations-select')}</ModalHeader>
        <ModalBody className='d-flex justify-content-around py-5'>
          <DatePickerInput
            label={formatMessage('preallocation-filters-start-date')}
            value={startDate}
            maxDate={endDate}
            onChange={setStartDate}
          />
          <DatePickerInput
            label={formatMessage('preallocation-filters-end-date')}
            value={endDate}
            minDate={startDate}
            onChange={setEndDate}
          />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end align-items-center">
          <button
            className="btn btn-secondary mr-2"
            onClick={onClose}>
            {formatMessage("preallocation-cancel")}
          </button>
          <button
            className="btn btn-primary"
            onClick={onExportClick}
          >
            {formatMessage("preallocation-projects-export-allocations")}
          </button>
        </ModalFooter>
      </Modal>
    </span>
  )
}
