import { useEffect, useState } from "react"
import TextInput from "./input/TextInput"
import { useDebouncedValue } from "../hooks/useDebouncedValue"

export default function SearchFilter({
  className,
  termFilter, // the real searched filter
  setTermFilter,
  placeholder,
}) {
  const [displayValue, setDisplayValue] = useState(termFilter) // the value the user sees and edits
  const debouncedDisplayValue = useDebouncedValue(displayValue, 700) // the value we want termFilter to be

  useEffect(() => {
    if (debouncedDisplayValue === undefined) {
      return
    }
    setTermFilter(debouncedDisplayValue)
  }, [debouncedDisplayValue])

  useEffect(() => {
    setDisplayValue(termFilter)
  }, [termFilter])

  return (
    <TextInput
      placeholder={placeholder}
      className={className}
      value={displayValue}
      onChange={(e) => {
        setDisplayValue(e.target.value)
      }}
    />
  )
}
