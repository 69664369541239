import React, { Fragment } from "react"
import { Changelog } from "../../Common/changelog/changelog"
import { SupportModal } from "../../Common/support/support"
import { formatMessage } from "devextreme/localization"

class AppFooter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showChangelog: false,
      showTroubleshooting: false
    }
  }

  showCl = () => {
    this.setState({ showChangelog: !this.state.showChangelog })
  }

  showTroubleshooting = () => {
    this.setState({ showTroubleshooting: !this.state.showTroubleshooting })
  }

  render() {
    return (
      <Fragment>
        <div className="app-footer">
          <div className="app-footer__inner">
            <div className="app-footer-left">
              <ul className="nav">
                <li className="nav-item">
                  <a
                    // href="https://bip-xtech.atlassian.net/servicedesk/customer/portal/2/group/9/create/32"
                    onClick={this.showTroubleshooting}
                    className="nav-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatMessage("FAQs")}
                  </a>
                </li>
              </ul>
            </div>
            <div className="app-footer-right">
              <ul className="nav">
                <li className="nav-item" onClick={this.showCl}>
                  {document.env.APP_VERSION}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Changelog showCl={this.state.showChangelog} closeCl={this.showCl} />
        <SupportModal
          show={this.state.showTroubleshooting}
          close={this.showTroubleshooting}
        />
      </Fragment>
    )
  }
}

export default AppFooter
