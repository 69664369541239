import moment from "moment"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink
} from "reactstrap"
import { useSelector } from "react-redux"
import { setSelectedPeriod, setTimesheetPeriod } from "../../../reducers/Period"
import { getPeriodsForUser } from "../../../services/index"
import { getPeriodsForReview } from "../../../services"
import { formatMessage } from "devextreme/localization"

export const PeriodDropDown = React.memo(
  ({
    review,
    updateComponent // when change trigger a rerender
  }) => {
    const dispatch = useDispatch()
    const [perioDropdownOpen, setPeriodDropdownOpen] = useState(false)
    const [periods, setPeriods] = useState([])
    const selectedPeriod = useSelector((state) => state.Period.selectedPeriod)

    const getPeriodsFor = async () => {
      switch (review) {
        case "original":
          const { data: original } = await getPeriodsForUser({
            limit: 12,
            sortDirection: "DESC"
          })
          dispatch(setTimesheetPeriod(original))
          setPeriods(original)
          break
        case "anyReview":
          const { data: anyReview } = await getPeriodsForReview({
            limit: 12,
            sortDirection: "DESC"
          })
          setPeriods(anyReview)
          break
      }
    }

    useEffect(() => {
      getPeriodsFor()
    }, [updateComponent])

    const isFuturePeriod = (periodId) => {
      return (
        periodId ===
        moment()
          .add(1, "month")
          .format("YYYYMM")
      )
    }

    const renderPeriodRow = (period) => {
      let badgeColor

      if (isFuturePeriod(period._id)) {
        badgeColor = "badge-grey"
      } else {
        badgeColor = "badge-warning"
      }

      switch (period.status) {
        case "missing":
          return (
            <div
              className={`ml-auto badge ${badgeColor} badge-pill badge-secondary`}
            >
              Pending
            </div>
          )
        case "pending":
          return (
            <div
              className={`ml-auto badge ${badgeColor} badge-pill badge-secondary`}
            >
              Pending
            </div>
          )
        case "userCompiling":
          return (
            <div
              className={`ml-auto badge ${badgeColor} badge-pill badge-secondary`}
            >
              Pending
            </div>
          )
        case "closed":
          return (
            <div className="ml-auto badge badge-success badge-pill badge-secondary">
              Processed
            </div>
          )
        case "completed":
          return (
            <div className="ml-auto badge badge-success badge-pill badge-secondary">
              Completed
            </div>
          )
        default:
          return (
            <div className="ml-auto badge badge-success badge-pill badge-secondary">
              Completed
            </div>
          )
      }
    }

    const onClickPeriod = (period) => {
      dispatch(
        setSelectedPeriod({
          _id: period._id,
          label: moment(period._id, "YYYYMM").format("MMMM YY"),
          calendarStartDate: period.calendarStartDate,
          calendarEndDate: period.calendarEndDate
        })
      )
    }

    const translatePeriod = (periodId) => {
      const date = moment(periodId, "YYYYMM")
      return [
        formatMessage(date.format("MMMM").toString()),
        date.format("YY").toString()
      ].join(" ")
    }

    return (
      <Dropdown
        direction="left"
        className="d-inline-block"
        onMouseOver={() => setPeriodDropdownOpen(true)}
        onMouseLeave={() => setPeriodDropdownOpen(false)}
        toggle={(prevState) => setPeriodDropdownOpen(!prevState.perioDropdownOpen)}
        isOpen={perioDropdownOpen}
      >
        <DropdownToggle className="btn-wide mr-2" caret color="primary">
          {periods.length && translatePeriod(selectedPeriod._id)}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>{formatMessage("Period")}</DropdownItem>
          <Nav vertical>
            {periods.length > 0 ? (
              periods.map((period) => (
                <NavItem key={period._id}>
                  <NavLink onClick={() => onClickPeriod(period)}>
                    <span
                      style={{
                        color: isFuturePeriod(period._id) ? "grey" : "black"
                      }}
                    >
                      {translatePeriod(period._id)}
                    </span>
                    {review === "original" && renderPeriodRow(period)}
                  </NavLink>
                </NavItem>
              ))
            ) : (
              <div style={{ paddingLeft: 10 }}>{formatMessage("not-available")}</div>
            )}
          </Nav>
        </DropdownMenu>
      </Dropdown>
    )
  }
)
