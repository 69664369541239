import React, { Component, Fragment } from "react"
import { withRouter } from "react-router-dom"
import { isEmpty } from "underscore"
import { AbilityContext } from "./../../Context/ability"

import MetisMenu from "react-metismenu"
import { formatMessage } from "devextreme/localization"

class Nav extends Component {
  render() {
    return (
      <Fragment>
        <h5 className="app-sidebar__heading">Menu</h5>
        <MetisMenu
          content={this.props.MenuNav}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
        {this.props.adminNavLabel && this.props.adminNavLabel.length > 0 && (
          <h5 className="app-sidebar__heading">{formatMessage("admin-head")}</h5>
        )}
        <MetisMenu
          content={this.props.adminNavLabel}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
        {this.props.preallocationNavBar && !isEmpty(this.props.preallocationNavBar) && (
          <h5 className="app-sidebar__heading">{formatMessage('preallocation-title')}</h5>
        )}
        <MetisMenu
          content={this.props.preallocationNavBar}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
        {this.props.preterNavBar && !isEmpty(this.props.preterNavBar) && (
          <h5 className="app-sidebar__heading">{formatMessage('preter-title')}</h5>
        )}
        <MetisMenu
          content={this.props.preterNavBar}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
        {this.props.performanceMgmtNavBar &&
          !isEmpty(this.props.performanceMgmtNavBar) && (
            <h5 className="app-sidebar__heading">Performance Mgmt</h5>
          )}
        <MetisMenu
          content={this.props.performanceMgmtNavBar}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
      </Fragment>
    )
  }
}

Nav.contextType = AbilityContext

export default withRouter(Nav)
