const baseUrl = `${document.env.BASE_URL}/preallocation/api/${document.env.API_VER}/`

export const apiService = {
  allocation: baseUrl + "allocation",
  project: baseUrl + "project",
  resource: baseUrl + "resource",
  team: baseUrl + "team",
  workday: baseUrl + "workday",
  export: baseUrl + "export"
}
