import { formatMessage } from "devextreme/localization"
import { useEffect, useState } from "react"
import { useTable } from "../hooks/useTable"
import { jobColumns } from "./column/jobColumns"
import { wbsColumns } from "./column/wbsColumns"
import "./JobAndWbsTable.scss"
import FooterRow from "./row/FooterRow"
import HeaderRow from "./row/HeaderRow"
import JobRow from "./row/JobRow"
import { Spinner } from "./Spinner"

export default function JobsTable({
  jobs,
  footerData,
  isLoading,
  isChargeable,
  pinnedUntil,
  setPinnedUntil,
}) {
  const { headers } = useTable({
    tableColumns: wbsColumns,
    pinnedUntil,
    setPinnedUntil,
  })

  const [columnOffsets, setColumnOffsets] = useState(null)
  const [lastPinnedIndex, setLastPinnedIndex] = useState(null)

  const [jobsTableData, setJobsTableData] = useState(jobs || [])

  useEffect(() => {
    setJobsTableData(jobs || [])
  }, [jobs])

  useEffect(() => {
    setLastPinnedIndex(
      headers?.findIndex((column) => column.id === pinnedUntil) || null,
    )
  }, [headers, pinnedUntil])

  const { rows: jobRows } = useTable({
    tableColumns: jobColumns,
    tableData: jobsTableData || [],
  })

  return (
    <div className="job-and-wbs-table-container">
      <table className="table-responsive">
        <thead>
          <HeaderRow
            headers={headers}
            filtrable={false}
            columnOffsets={columnOffsets}
            setColumnOffsets={setColumnOffsets}
            tableData={jobs}
          />
        </thead>
        <tbody>
          {isLoading && (
            <tr className={"wbs-loading"}>
              <td colSpan={headers.length}>
                <div>
                  <Spinner spanContainerStyles={{ fontSize: "2rem" }} />
                </div>
              </td>
            </tr>
          )}
          {jobRows.map(({ info, cells }, index) => (
            <JobRow
              cells={cells}
              info={info}
              key={index}
              isChargeable={isChargeable}
              columnOffsets={columnOffsets}
              lastPinnedIndex={lastPinnedIndex}
            />
          ))}
          {!isLoading && jobs?.length === 0 && (
            <tr className={"wbs-empty"}>
              <td colSpan={headers.length}>
                <div className="p-5 d-flex flex-row justify-content-center align-items-center">
                  <span>{formatMessage("jw-No elements to show.")}</span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <FooterRow
            footerData={footerData}
            columnOffsets={columnOffsets}
            lastPinnedIndex={lastPinnedIndex}
          />
        </tfoot>
      </table>
    </div>
  )
}
