import { formatMessage } from "devextreme/localization"
import { withRouter } from "react-router-dom"

export default withRouter(function BackButton({
  className,
  content = formatMessage("jw-BACK"),
  to,
  disabled = false,
  history,
}) {
  return (
    <button
      disabled={disabled}
      onClick={() => {
        if (to) {
          history.push(to)
          return
        }
        history.goBack()
      }}
      className={`${className} btn btn-outline-primary`}
    >
      {content}
    </button>
  )
})
