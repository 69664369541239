import { useResourceView } from "../hooks/useResourceView"
import { useEffect } from "react"
import Filters from "./Filters"
import { SidePanel } from "../components/SidePanel"
import { AllocationForm } from "../EditAllocation/AllocationForm"
import { EditMultipleAllocationsForm } from "../EditAllocation/EditMultipleAllocationsForm"
import moment from "moment"
import { useAllocations } from "../hooks/useAllocations"
import { OpaqueOverlay } from "../components/OpaqueOverlay"
import { useExcludeOverlay } from "../hooks/useExcludeOverlay"
import { useDebounce } from "../hooks/useDebounce"
import ResourcesCalendar from "./ResourcesCalendar"
import { formatMessage } from "devextreme/localization"
import { useConfirm } from "../components/ConfirmModal"
import Paginator from "../components/Paginator"
import { useAppAbility } from "../../../../Context/ability"

export default function ResourcesView() {
  const {
    filters,
    setFilters,
    areAllResourcesShown,
    teams,
    teamList,
    loadTeamList,
    isLoading,
    loadError,
    loadTeams,
    toggleIsOpen,
    toggleIsPinned,
    toggleAreAllResourcesShown,
    paginationMeta,
    loadPage
  } = useResourceView()

  const {
    createAllocation,
    updateAllocation,
    updateMultipleAllocation,
    deleteMultipleAllocations,
    errors: allocationErrors,
    resetErrors: resetAllocationErrors,
    editingAllocation,
    editAllocation,
    editMultiple,
    isEditingMultiple,
    toggleAllocation,
    selectedAllocations,
    lineManagerAllowedProjects,
    loadAllocationData,
    loadProjects: loadProjectList
  } = useAllocations(true)

  const {
    overlayBoundingRect,
    updateExcludeRef,
    clearOverlayBoundingRect
  } = useExcludeOverlay()

  const { debounce } = useDebounce(300)
  const confirmModal = useConfirm()

  const { can } = useAppAbility()
  const canShowDetails = can("read", "AllocationDetails")

  useEffect(() => {
    loadTeams(filters)
    loadTeamList()
    loadAllocationData()
  }, [])

  useEffect(() => {
    const slotCount = teams[0]?.timeslotNumber
    const startDate = teams[0]?.teamTimeSlots[0]?.startDate || filters.startDate
    const endDate =
      slotCount &&
      moment(startDate)
        .add(slotCount, filters.aggregation === "WEEKLY" ? "week" : "month")
        .subtract(1, "day")
        .toDate()
    loadProjectList(startDate, endDate)
  }, [teams])

  const onFilterChange = (filter, reload = true) => {
    const changedFilterKeys = Object.keys(filter)

    if (
      !changedFilterKeys.some(
        (key) => JSON.stringify(filter[key]) !== JSON.stringify(filters[key])
      )
    ) {
      return
    }

    const newFilters = {
      ...filters,
      ...filter
    }

    setFilters(newFilters)

    if (reload) debounce(async () => await loadTeams(newFilters))
  }

  const onPreviousClick = () => {
    onFilterChange({
      startDate: moment(filters.startDate)
        .subtract(1, filters.aggregation === "WEEKLY" ? "week" : "month")
        .toDate()
    })
  }

  const onNextClick = () => {
    onFilterChange({
      startDate: moment(filters.startDate)
        .add(1, filters.aggregation === "WEEKLY" ? "week" : "month")
        .toDate()
    })
  }

  const onRemoveResource = (team, resource) => {
    console.log("Remove: ", team, resource)
  }

  const onAddAllocation = (team, resource) => {
    resetAllocationErrors()
    editAllocation({ team, resource })
  }

  const onEditAllocation = (team, resource, rawAllocation, slot) => {
    const allocation = {
      ...rawAllocation,
      startDate: slot.startDate,
      endDate: slot.endDate
    }
    if (isEditingMultiple) {
      toggleAllocation({
        ...allocation,
        teamId: team.id,
        resourceId: resource.id
      })
      return
    }
    resetAllocationErrors()
    editAllocation({ team, resource, allocation })
  }

  const onEditMultipleAllocations = (team, resource, rowRef) => {
    if (isEditingMultiple) {
      return
    }
    if (filters.showFreeTime) {
      const newFilters = { ...filters, showFreeTime: false }
      setFilters(newFilters)
    }
    clearOverlayBoundingRect(null)
    editMultiple({ team, resource })
    updateExcludeRef(rowRef)
    rowRef?.scrollIntoView({ behavior: "smooth", block: "center" })
  }

  const onSaveAllocation = async ({ isNew, ...allocation }) => {
    if (isNew) {
      await createAllocation(allocation)
    } else {
      const oldAllocation = {
        userId: editingAllocation.resource.id,
        jobId: editingAllocation.allocation.id,
        startDate: editingAllocation.allocation.specificStartDate,
        endDate: editingAllocation.allocation.specificEndDate
      }
      await updateAllocation(oldAllocation, allocation)
    }
    editAllocation(null)
    loadTeams(filters)
  }

  const onSaveMultipleAllocations = async (editData) => {
    try {
      const allocations = {
        ...editData,
        selectedAllocations: selectedAllocations.map((a) => ({
          jobId: a.id,
          startDate: a.specificStartDate,
          endDate: a.specificEndDate
        }))
      }
      await updateMultipleAllocation(editData.userId, allocations)
      editAllocation(null)
      loadTeams(filters)
    } catch (error) {}
  }

  const onDeleteMultipleAllocations = async () => {
    if (
      await confirmModal.confirm({
        title: formatMessage("preallocation-confirm-delete-title"),
        body: formatMessage("preallocation-confirm-delete-multiple-resource"),
        confirmButtonText: formatMessage("preallocation-delete"),
        confirmButtonClassName: "btn-danger"
      })
    ) {
      await deleteMultipleAllocations(
        selectedAllocations.map((a) => ({
          jobId: a.id,
          userId: a.resourceId,
          startDate: a.specificStartDate,
          endDate: a.specificEndDate
        }))
      )
      editAllocation(null)
      loadTeams(filters)
    }
  }

  return (
    <>
      <SidePanel
        show={editingAllocation || isEditingMultiple}
        onClose={() => editAllocation(null)}
        overlay={
          <OpaqueOverlay exclude={isEditingMultiple && overlayBoundingRect} />
        }
        collapsible={isEditingMultiple}
      >
        {isEditingMultiple ? (
          <EditMultipleAllocationsForm
            {...editingAllocation}
            selected={selectedAllocations}
            onCancel={() => editAllocation(null)}
            onSave={onSaveMultipleAllocations}
            onDelete={onDeleteMultipleAllocations}
            errors={allocationErrors}
            showJobYear={true}
            lineManagerFilterProject={true}
            lineManagerAllowedAllocationJobs={lineManagerAllowedProjects}
          />
        ) : (
          <AllocationForm
            {...editingAllocation}
            onCancel={() => editAllocation(null)}
            onSave={onSaveAllocation}
            errors={allocationErrors}
            showJobYear={true}
            lineManagerFilterProject={true}
            lineManagerAllowedAllocationJobs={lineManagerAllowedProjects}
            activitySeparator={true}
          />
        )}
      </SidePanel>
      <Filters
        className="w-100 mb-4 pb-2"
        filters={filters}
        onFiltersChange={onFilterChange}
        areAllResourcesShown={areAllResourcesShown}
        onToggleShowAll={toggleAreAllResourcesShown}
        teams={teamList}
        showShowDetails={canShowDetails}
        isLoading={isLoading || teams.some((p) => p.isLoadingDetails)}
        searchPlaceholder={formatMessage(
          "preallocation-filters-teams-search-placeholder"
        )}
      />
      <ResourcesCalendar
        teams={teams}
        filters={filters}
        selectedAllocations={selectedAllocations}
        isEditingMultiple={isEditingMultiple}
        editingAllocation={editingAllocation}
        isLoading={isLoading}
        loadError={loadError}
        onReload={() => loadTeams(filters)}
        toggleIsOpen={toggleIsOpen}
        toggleIsPinned={toggleIsPinned}
        onAddAllocation={onAddAllocation}
        onEditAllocation={onEditAllocation}
        onEditMultipleAllocations={onEditMultipleAllocations}
        onRemoveResource={onRemoveResource}
        onPreviousClick={onPreviousClick}
        onNextClick={onNextClick}
      />
      <Paginator
        className="mt-4"
        currentPage={paginationMeta.page - 1}
        lastPage={paginationMeta.pageCount - 1}
        onPageChange={(page) => loadPage(filters, page)}
        pageSize={paginationMeta.take}
        setPageSize={(pageSize) => loadPage(filters, 0, pageSize)}
        totalCount={paginationMeta.totalCount}
        amountCurrentlyVisualizing={teams.length}
      />
    </>
  )
}
