import React, { useState, useEffect, createContext, useContext } from "react"
import { PublicClientApplication } from "@azure/msal-browser"
import { Ability } from "@casl/ability"
import { setUserDetails, setConfig as setConfigData } from "./../reducers/Auth"
import { useDispatch } from "react-redux"
import { getConfig, getUserData } from "../services"
import get from "lodash/get"

// Msal Configurations
const config = {
  auth: {
    authority:
      "https://login.microsoftonline.com/bb1a63eb-eb09-471a-a005-37b07792a5b5/",
    clientId: "9dbe9cf5-20a6-4f0d-907d-ffd0f9bf9bb2",
    redirectUri: document.env.REDIRECT_URI,
    audience: "9dbe9cf5-20a6-4f0d-907d-ffd0f9bf9bb2",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        console.log(message)
      },
      piiLoggingEnabled: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
  },
}

export function createAbility() {
  const ability = new Ability()
  ability.can = ability.can.bind(ability)
  ability.cannot = ability.cannot.bind(ability)

  return ability
}

function AuthProvider(props) {
  const [user, setUser] = useState()
  const [config, setConfig] = useState("")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")

  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        const [{ data: config }, { data: userData }] = await Promise.all([
          getConfig(),
          getUserData(),
        ])

        if (userData && config) {
          userData.capabilities = createAbility()
          userData.capabilities.update(
            convert(
              JSON.stringify(userData.permissions)
                .replaceAll("&", ".")
                .replaceAll("!", "$"),
              { user: userData }
            )
          )
          dispatch(setUserDetails(userData))
          dispatch(setConfigData(config))
          setUser(userData)
          setConfig(config)
        }
        setLoading(false)
      } catch (e) {
        console.log(e)
        setError(e.response?.status || 500)
        setLoading(false)
      }
    })()
  }, [])

  return <AuthContext.Provider value={{ user, loading, error, config }} {...props} />
}

const convert = (template, vars) => {
  return JSON.parse(template, (_, rawValue) => {
    if (rawValue[0] !== "$") {
      return rawValue
    }
    const name = rawValue.slice(2, -1)
    const value = get(vars, name)
    return value
  })
}

const generateRequest = (accountFromSession) => ({
  scopes: ["openid"],
  account: accountFromSession,
  forceRefresh: true,
})

const AuthContext = createContext({})
const useAuth = () => useContext(AuthContext)
const PCA = new PublicClientApplication(config)
export { AuthProvider, useAuth, PCA, generateRequest }
