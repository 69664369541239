import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import _ from "lodash"
import { formatMessage } from "devextreme/localization"
import { getAllRoles } from "../../../services"
import Tooltip from "@material-ui/core/Tooltip"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const useStyles = makeStyles({
  list: {
    width: 500
  },
  fullList: {
    width: "auto"
  },
  paper: {
    marginTop: 10,
    marginLeft: 10,
    padding: 2
  }
})

export default function EditIam({ open, close, data, onSaveRoles }) {
  const classes = useStyles()
  const [rolesCopy, setRolesCopy] = useState({})
  const [networkError, setNetworkError] = useState(false)
  const [roles, setRoles] = useState([])

  useEffect(() => {
    ;(async () => {
      const { data } = await getAllRoles().catch((e) => setNetworkError(e))
      if (data) setRoles(data)
    })()
  }, [])

  useEffect(() => {
    setRolesCopy(_.cloneDeep(data.roles || []))
  }, [data, open])

  const onAddAnotherRole = () => {
    const oldData = [...rolesCopy]
    // if the array does not contain null data
    if (!oldData.some((item) => item === null)) {
      oldData.push(null)
      setRolesCopy(oldData)
    }
  }

  const onChangeRole = (role, index) => {
    const oldData = [...rolesCopy]
    oldData[index] = role
    setRolesCopy(oldData)
  }

  const onDeleteRole = (index) => {
    if (rolesCopy.length === 1) {
      setRolesCopy([])
    } else {
      const oldData = [...rolesCopy]
      oldData.splice(index, 1)
      setRolesCopy(oldData)
    }
  }

  const list = () => (
    <div className={classes.list} role="presentation">
      <ListItem>
        <ListItemText primary={formatMessage("edit-permissions-title")} />
      </ListItem>
      <Divider />
      <ListItem>
        <div>
          <Typography variant="h6">{formatMessage("Member")}</Typography>
          <Typography variant="subtitle1">
            {data.firstName} {data.lastName}
          </Typography>
        </div>
      </ListItem>
      <List>
        {rolesCopy.map((role, index) => {
          return (
            <ListItem style={{ marginTop: 20 }} key={index}>
              <Autocomplete
                size="small"
                value={role === {} ? {} : role}
                disableClearable
                onChange={(event, newValue) => {
                  onChangeRole(newValue, index)
                }}
                key={index}
                options={_.sortBy(roles, "name")}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => {
                  return option._id === value._id
                }}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={formatMessage("role")}
                    variant="outlined"
                  />
                )}
                renderOption={(option) => (
                  <Tooltip title={option.description || ""}>
                    <Typography noWrap>{option.name}</Typography>
                  </Tooltip>
                )}
              />
              <ListItemIcon>
                <IconButton
                  onClick={() => onDeleteRole(index)}
                  style={{ marginLeft: 40 }}
                >
                  <FontAwesomeIcon icon={faTrash} size="1x" />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          )
        })}
        <ListItem style={{ marginTop: 20 }}>
          <Button
            style={{ marginLeft: 20 }}
            color="primary"
            onClick={onAddAnotherRole}
          >
            {formatMessage("add-role-label").toUpperCase()}
          </Button>
        </ListItem>
        <ListItem style={{ marginTop: 40 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              onSaveRoles({
                rolesToSave: rolesCopy.filter((item) => item !== null),
                userId: data._id
              })
            }
          >
            {formatMessage("dxDataGrid-editingSaveRowChanges")}
          </Button>
          <Button style={{ marginLeft: 20 }} color="primary" onClick={close}>
            {formatMessage("Cancel")}
          </Button>
        </ListItem>
      </List>
    </div>
  )

  return (
    <React.Fragment>
      <Drawer anchor={"right"} open={open} onClose={close}>
        {open && list()}
      </Drawer>
    </React.Fragment>
  )
}
