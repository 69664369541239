import { formatMessage } from "devextreme/localization"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { getErrorByField } from "../../../Admin/Headcount/Main/Utils/ErrorHandling"
import Button from "../components/Button"
import DatePickerInput from "../components/input/DatePickerInput"
import DropdownSelect from "../components/input/DropdownSelect"
import PercentageInput from "../components/input/PercentageInput"
import { ResourceTile } from "../components/ResourceTile"
import "./EditMultipleAllocationsForm.scss"
import { useAppAbility } from "../../../../Context/ability"
import { getJobs } from "../../../../services"

export const EditMultipleAllocationsForm = ({
  project,
  resource,
  selected,
  onCancel,
  onSave,
  onDelete,
  errors,
  lineManagerFilterProject = false,
  lineManagerAllowedAllocationJobs = []
}) => {
  const { can } = useAppAbility()
  const canManageProjects = can("manage", "ProjectAllocations")

  const [projectList, setProjectList] = useState([])
  const [editData, setEditData] = useState({})

  const loadProjects = async (startDate, endDate) => {
    if (lineManagerFilterProject) {
      setProjectList(
        lineManagerAllowedAllocationJobs.map((p) => ({
          ...p,
          id: p._id,
          name: p.description
        }))
      )
      return
    }

    const params =
      canManageProjects || lineManagerFilterProject
        ? {}
        : {
            user_is_delivery_manager: true,
            user_is_accountable: true,
            user_is_deputy_accountable: true
          }
    if (startDate) {
      params.valid_from_date = moment(startDate).format("YYYY-MM-DD")
    }
    if (endDate) {
      params.valid_to_date = moment(endDate).format("YYYY-MM-DD")
    }
    params.populate_wbs = true
    let { data: projects } = await getJobs(params)

    setProjectList(projects.map((p) => ({ ...p, id: p._id, name: p.description })))
  }

  useEffect(() => {
    setEditData({
      jobId: project?.id,
      userId: resource?.id,
      allocationPercentage: 100
    })
  }, [project, resource])

  function handleSave() {
    onSave({
      ...editData
    })
  }

  function updateFormValue(key, value) {
    setEditData({
      ...editData,
      [key]: value
    })
  }

  useEffect(() => {
    const startDate = selected[0]?.specificStartDate
    const endDate = selected[selected.length - 1]?.specificEndDate

    loadProjects(startDate, endDate)
  }, [resource, selected])

  const activityOptions = useMemo(
    () =>
      projectList.map((p) => ({
        id: p.id,
        cell: p.fiscalYear?.wbs ? `${p.fiscalYear.wbs.code} ${p.name}` : p.name
      })),
    [projectList]
  )
  return (
    <div className="allocation-form">
      <h5 className="p-4">
        {formatMessage("preallocation-projects-edit-multiple-allocations")}
      </h5>

      {resource && (
        <div className="allocation-tile px-4 py-3">
          <ResourceTile resource={resource} />
        </div>
      )}
      <div className="p-4">
        <div className="pb-3 mb-3 border-bottom">
          <h6 className="font-weight-bold">
            {formatMessage(
              "preallocation-resource-allocation-edit-items-selected",
              selected.length
            )}
          </h6>
        </div>
        <div className="mb-3">
          <p className="font-weight-bold">
            {formatMessage("preallocation-resource-allocation-edit-multiple")}
          </p>
        </div>
        <div className="mb-3">
          <DropdownSelect
            label={formatMessage("preallocation-resource-allocation-activity")}
            options={activityOptions}
            value={editData.jobId}
            onSelected={(val) => val && updateFormValue("jobId", val.id)}
            error={getErrorByField(errors, "jobId")}
            enableSeparator={true}
          />
        </div>

        <div className="mb-5">
          <PercentageInput
            label={formatMessage(
              "preallocation-resource-allocation-allocation-percentage"
            )}
            value={editData.allocationPercentage}
            onChange={(e) =>
              updateFormValue("allocationPercentage", parseFloat(e.target.value))
            }
            error={getErrorByField(errors, "allocationPercentage")}
          />
        </div>
        <div className="d-flex justify-content-between pb-3 border-bottom mb-4">
          <Button
            className="btn-outline-primary"
            text={formatMessage("preallocation-cancel")}
            onClick={onCancel}
          />
          <Button
            className="btn-primary"
            text={formatMessage("preallocation-allocation-form-edit")}
            onClick={handleSave}
          />
        </div>
        <div className="d-flex justify-content-between">
          <Button
            className="btn-outline-danger"
            icon="monade/preallocation/trash"
            text={formatMessage("preallocation-allocation-form-delete")}
            onClick={onDelete}
          />
        </div>
      </div>
    </div>
  )
}
