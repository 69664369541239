import React, { useEffect, useState } from "react"
import DataGrid, {
  Scrolling,
  Column,
  Editing,
  Lookup,
  Button,
  RequiredRule,
  Texts,
  Toolbar,
  Item
} from "devextreme-react/data-grid"
import { CustomRule } from "devextreme-react/validator"
import { getDataSource } from "./dataSource"
import { isEmpty } from "underscore"
import { deleteTimesheetRow } from "../../../services"
import { formatMessage } from "devextreme/localization"
import { Button as DevextremeButton } from "devextreme-react"

const reviewCode = "userCompiling"

export const TimesheetTable = React.memo(
  ({
    tsId,
    jobs,
    currentStatus,
    unitIsHours,
    getTimesheet,
    invalidJobs,
    forceUpdate,
    isError,
    setIsError,
    config
  }) => {
    const [statusToShow, setStatusToShow] = useState("userCompiling")
    const [allowChanges, setAllowChanges] = useState(true)

    useEffect(() => {
      if (currentStatus === "closed") {
        setStatusToShow("closed")
      } else if (currentStatus === "ppa") {
        setStatusToShow("ppa")
      } else {
        setStatusToShow("userCompiling")
      }

      setAllowChanges(currentStatus === "userCompiling")
    }, [currentStatus, tsId])

    let _datagrid = React.useRef(null)

    return (
      <DataGrid
        dataSource={getDataSource({ getTimesheet, tsId, jobs, unitIsHours })}
        showBorders={true}
        loadPanel={{ enabled: false }}
        onRowUpdating={() => {
          forceUpdate()
        }}
        onRowRemoved={() => {
          forceUpdate()
        }}
        ref={_datagrid}
        onEditorPreparing={(e) => {
          if (e.dataField === "userCompiling.code") {
            if (
              e.value != null &&
              !e.lookup.dataSource.find((j) => j.id === e.value)
            ) {
              e.lookup.dataSource.push(jobs._array.find((j) => j.id === e.value))
            }
          }

          if (e.parentType === "dataRow" && e.dataField === reviewCode + ".code") {
            e.editorOptions.grouped = true
            e.editorOptions.dataSource.group = "type"
            e.editorOptions.searchExpr = ["suggestionLabel", "type"]
            e.editorOptions.showClearButton = true
          }
        }}
      >
        <Editing
          mode="cell"
          allowAdding={true}
          allowUpdating={allowChanges}
          allowDeleting={allowChanges}
        >
          <Texts confirmDeleteMessage="" />
        </Editing>
        <Column dataField={"_id"} defaultSortOrder="desc" visible={false} />
        <Column dataField={statusToShow + ".wbs"} visible={false} />
        <Column
          dataField={statusToShow + ".code"}
          caption={formatMessage("Job")}
          alignment={"left"}
          calculateDisplayValue={(row) => {
            if (
              row[statusToShow] &&
              invalidJobs?.includes(row[statusToShow].code) &&
              !isError
            ) {
              setIsError(true)
            }

            const job = row[statusToShow]
              ? jobs._array.find((j) => j.id === row[statusToShow].code)
              : undefined

            if (job?.isTempPlaced && statusToShow === "closed") {
              return `${config.wbsTempCode} - ${job.description}`
            }

            return job ? job.label : ""
          }}
          cellRender={(data) => {
            if (
              invalidJobs?.includes(data.value) &&
              currentStatus === "userCompiling"
            ) {
              return (
                <div>
                  <span style={{ opacity: 0.6 }}>{data.displayValue}</span>
                  <span
                    className="text-danger ml-2 lnr lnr-warning"
                    title={formatMessage("job-disabled-tooltip")}
                  ></span>
                </div>
              )
            } else {
              return <div>{data.displayValue}</div>
            }
          }}
        >
          <Lookup
            dataSource={jobs._array.filter((j) => j.disabled === false)}
            displayExpr="suggestionLabel"
            valueExpr="id"
          />
          <RequiredRule />
        </Column>
        <Column
          dataField={statusToShow + ".description"}
          caption={formatMessage("Description")}
          editorOptions={{ maxLength: 120 }}
          alignment={"left"}
        >
          <CustomRule
            validationCallback={(e) => {
              return e.data[reviewCode]?.code &&
                jobs._array.find((i) => i.id === e.data[reviewCode].code)
                  .isDescriptionMandatory
                ? !isEmpty(e.data[reviewCode]?.description)
                : true
            }}
            message="Description is required for this job"
          />
        </Column>
        {
          <Column
            dataField={
              unitIsHours ? statusToShow + ".hours" : statusToShow + ".days"
            }
            caption={unitIsHours ? formatMessage("Hours") : formatMessage("Days")}
            dataType={"number"}
            alignment={"center"}
            width={"150"}
            format={{}} // Mandatory for localization
            editorOptions={{
              showSpinButtons: true,
              format: { type: "fixedPoint", precision: 2 }
            }}
            calculateCellValue={(rowData) => {
              if (
                !isEmpty(rowData) &&
                rowData[statusToShow] &&
                (rowData[statusToShow]["hours"] || rowData[statusToShow]["days"])
              ) {
                return unitIsHours
                  ? rowData[statusToShow]["hours"]
                  : rowData[statusToShow]["days"]
              }
            }}
          >
            <CustomRule
              validationCallback={(e) => {
                return e.data[reviewCode]?.hours > 0 || e.data[reviewCode]?.days > 0
              }}
              message={
                unitIsHours === true
                  ? formatMessage(
                      "warning-ts-compiling-sending-greater-then-0-hours"
                    )
                  : formatMessage("warning-ts-compiling-sending-greater-then-0-days")
              }
            />
          </Column>
        }
        <Column type="buttons" width={50}>
          <Button
            name="delete"
            icon="trash"
            onClick={async ({ row }) => {
              if (row.data._id) {
                await deleteTimesheetRow({
                  tsId: tsId,
                  rowId: row.data._id
                })
              } else {
                _datagrid.current.instance.deleteRow(row.rowIndex)
              }
              if (isError) setIsError(false)
              forceUpdate()
            }}
          />
        </Column>
        <Scrolling mode="virtual" />
        <Toolbar>
          <Item name="refreshButton" location="after" widget={"dxButton"}>
            <DevextremeButton
              icon="refresh"
              hint={formatMessage("dxDataGrid-commandRefresh")}
              onClick={() => forceUpdate()}
            />
          </Item>
          {allowChanges && <Item location="after" name="addRowButton" />}
        </Toolbar>
      </DataGrid>
    )
  }
)
