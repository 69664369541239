import {PieChart, Series, Animation, Legend, Tooltip} from "devextreme-react/pie-chart";
import "./ResourceDoughnutChart.scss"
import ResourceLegend from "./ResourceLegend";
import {formatPercentage} from "../../../../Utils/formatPercentage";

export default function ResourceDoughnutChart({
  seriesItems
}) {
  const contentTemplate = ({argument, value}) => {
    return `${argument}: ${formatPercentage(value * 100)}`;
  }

  return (
    <div className='resource-doughnut-chart'>
      <PieChart
        dataSource={seriesItems}
        type='doughnut'
        size={{ height: 70, width: 70 }}
        innerRadius={0.66}
        palette={seriesItems.map(item => item.color)}
        startAngle={90}
      >
        <Series
          argumentField="label"
          valueField="value"
        />
        <Tooltip enabled={true} contentTemplate={contentTemplate} />
        <Legend visible={false} />
        <Animation enabled={false} />
      </PieChart>
      <ResourceLegend seriesItems={seriesItems} />
    </div>
  )
}
