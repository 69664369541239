import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import { itIT } from "@material-ui/core/locale"
import Paper from "@material-ui/core/Paper"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import React, { useState } from "react"
import Draggable from "react-draggable"
import { useSelector } from "react-redux"
import { find } from "underscore"
import { Bounce, toast } from "react-toastify"
import NumberFormat from "react-number-format"
import PropTypes from "prop-types"

import {
  savePreReviewRow,
  saveThirdReviewRow,
  savePpaRow,
  saveCompletedReviewRow
} from "../../../services"
import { formatMessage } from "devextreme/localization"
const themeIT = createMuiTheme({}, itIT)

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value
          }
        })
      }}
      isNumericString
      decimalScale={2}
      decimalSeparator=","
      thousandSeparator={false}
      allowNegative={false}
      displayType={"number"}
    />
  )
}

export const AddRow = ({
  addRow,
  setAddRow,
  dataToUpdate,
  unitIsHours,
  forceUpdate
}) => {
  const user = useSelector((state) => state.Auth.user)
  const [open, setOpen] = useState(false)
  const [code, setCode] = useState("")
  const [codeError, setCodeError] = useState("")
  const [isDescriptionMandatory, setIsDescriptionMandatory] = useState(false)
  const [description, setDescription] = useState("")
  const [descriptionError, setDescriptionError] = useState("")
  const [tsValue, setTsValue] = useState("")
  const [tsValueError, setTsValueError] = useState("")
  const [notes, setNotes] = useState("")
  const [notesError, setNotesError] = useState("")
  const config = useSelector((state) => state.Auth.config)

  React.useEffect(() => {
    setOpen(addRow)
    setCode("")
    setCodeError("")
    setIsDescriptionMandatory(false)
    setDescription("")
    setDescriptionError("")
    setTsValue("")
    setTsValueError("")
    setNotes("")
    setNotesError("")
  }, [addRow])

  const onJobChange = (event, value) => {
    setCodeError("")
    if (value) {
      if (value.isDescriptionMandatory) {
        setIsDescriptionMandatory(true)
      } else {
        setIsDescriptionMandatory(false)
      }
      setCode(value.id)
    }
  }

  const onInsert = async () => {
    const { allRows } = dataToUpdate
    const currentStatus = allRows[0].statusDetails.code
    if (code === "") return setCodeError(formatMessage("required-field"))
    if (tsValue <= 0 || tsValue === "" || tsValue === null)
      return setTsValueError(formatMessage("required-field"))
    if (
      (notes === "" || notes === null) &&
      currentStatus !== "preReview" &&
      currentStatus !== "thirdReview" &&
      currentStatus !== "ppa"
    )
      return setNotesError(formatMessage("required-field"))
    if (isDescriptionMandatory && !description.trim())
      return setDescriptionError("Description is required for this job")

    const jobDetails = find(dataToUpdate?.jobs?._array || [], (j) => j.id === code)

    const objToInsert = {
      tsId: allRows[0]._id,
      owner: user._id,
      code: code,
      description: description,
      hours: unitIsHours ? tsValue : tsValue * 8,
      days: unitIsHours ? tsValue / 8 : tsValue,
      notes: notes,
      wbs: jobDetails.wbs,
      activity: jobDetails.description
    }

    try {
      switch (currentStatus) {
        case "preReview":
          await savePreReviewRow(objToInsert)
          break
        case "thirdReview":
          await saveThirdReviewRow(objToInsert)
          break
        case "completed":
          await saveCompletedReviewRow(objToInsert)
          break
        case "ppa":
          await savePpaRow(objToInsert)
          break
        default:
          break
      }
      setAddRow(false)

      forceUpdate()
    } catch (e) {
      console.log("Error on adding", e.toString())
      toast(
        e.response.status === 423
          ? formatMessage("review-timesheet-old-data")
          : "There was an error. Try again",
        {
          transition: Bounce,
          closeButton: true,
          autoClose: 3000,
          position: "bottom-center",
          type: "error"
        }
      )

      forceUpdate()
      setAddRow(false)
    }
  }

  const areNotesOptional = () => {
    return (
      dataToUpdate &&
      dataToUpdate.allRows.length &&
      (dataToUpdate.allRows[0].statusDetails.code === "preReview" ||
        dataToUpdate.allRows[0].statusDetails.code === "thirdReview" ||
        dataToUpdate.allRows[0].statusDetails.code === "ppa")
    )
  }

  const render = () => {
    if (setAddRow) {
      return (
        <Dialog
          open={open}
          onClose={() => setAddRow(false)}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          disableBackdropClick
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            {formatMessage("review-add-row-title")}
          </DialogTitle>
          <DialogContent>
            <FormControl
              disabled
              variant="outlined"
              style={{ width: 400, marginTop: 20 }}
            >
              <InputLabel htmlFor="component-disabled">Owner</InputLabel>
              <Input
                id="component-disabled"
                value={
                  dataToUpdate &&
                  dataToUpdate.allRows.length &&
                  dataToUpdate.allRows[0].owner.email
                }
                onChange={() => {}}
              />
            </FormControl>
            <Autocomplete
              id="combo-box-demo"
              onChange={onJobChange}
              options={
                dataToUpdate &&
                dataToUpdate.jobs?._array.filter((j) => j.disabled === false)
              }
              getOptionLabel={(option) => {
                if (option?.isTempPlaced) {
                  return `${option.wbs}${
                    option.wbsAliasCode ? ` - ${option.wbsAliasCode}` : ""
                  } (${config.wbsTempCode}) - ${option.description}`
                }
                return option.suggestionLabel
              }}
              style={{ width: 400, marginTop: 20 }}
              renderInput={(params) => (
                <TextField
                  error={codeError.length !== 0}
                  required
                  {...params}
                  label="Job"
                  variant="outlined"
                />
              )}
            />
            <TextField
              id="outlined-required"
              label={formatMessage("Description")}
              value={description}
              required={isDescriptionMandatory}
              error={descriptionError.length !== 0}
              onChange={(e) => {
                setDescriptionError("")
                setDescription(e.target.value)
              }}
              variant="outlined"
              helperText={descriptionError}
              style={{ width: 400, marginTop: 20 }}
              inputProps={{ maxLength: 120 }}
            />
            <ThemeProvider theme={themeIT}>
              {/* Reimplementa il NumberFormat, sti cavoli se spariscono i pulsanti di increment */}
              <TextField
                required
                id="outlined-required"
                // type="number"
                error={tsValueError.length !== 0}
                label={unitIsHours ? formatMessage("Hours") : formatMessage("Days")}
                onChange={(e) => {
                  setTsValueError("")
                  const n = Number(e.target.value)
                  setTsValue(n ? n : 0)
                }}
                value={tsValue}
                helperText={tsValueError}
                variant="outlined"
                style={{ width: 400, marginTop: 20 }}
                InputProps={{
                  inputComponent: NumberFormatCustom
                }}
              />
            </ThemeProvider>
            <TextField
              required={!areNotesOptional()}
              id="outlined-required"
              label={formatMessage("Notes")}
              value={notes}
              error={notesError.length !== 0}
              onChange={(e) => {
                setNotesError("")
                setNotes(e.target.value)
              }}
              variant="outlined"
              style={{ width: 400, marginTop: 20 }}
              inputProps={{ maxLength: 120 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddRow(false)} color="primary">
              {formatMessage("Cancel")}
            </Button>
            <Button onClick={onInsert} color="primary">
              {formatMessage("dxDataGrid-editingSaveRowChanges")}
            </Button>
          </DialogActions>
        </Dialog>
      )
    } else {
      return <div />
    }
  }

  return <div>{render()}</div>
}
