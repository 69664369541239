function MultiSwitchComponent({ items, onValueChanged, disabled, size, value }) {
  const activate = (selectedItem) => {
    onValueChanged(selectedItem.id)
  }

  const getActiveClass = (item) => {
    return item.activeClass || "btn-primary"
  }

  const getNonActiveClass = (item) => {
    return item.nonActiveClass || "btn-outline-primary"
  }

  const getSize = () => {
    if (size === "sm") {
      return "btn-group-sm"
    }
    if (size === "lg") {
      return "btn-group-lg"
    }
  }

  return (
    <div className={"btn-group " + getSize()} role="group">
      {items.map((item) => {
        return (
          <button
            key={item.id}
            style={{ ...item.customStyle, transition: ".2s", fontSize: "16px"}}
            className={
              (value === item.id ? getActiveClass(item) : getNonActiveClass(item)) +
              " multi-switch btn "
            }
            disabled={disabled}
            htmlFor={"btnradio" + item.id}
            onClick={(e) => {
              e.preventDefault()
              if (!disabled) {
                activate(item)
              }
            }}
          >
            {item.text}
          </button>
        )
      })}
    </div>
  )
}

export default MultiSwitchComponent
