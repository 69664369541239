import { apiService } from "./APIServices"

import axios from "axios"

/* params: {
     startDate, *
     endDate, *
     jobId, *
     toleranceStartDate, *
     toleranceEndDate, *
     allocationPercentage, *
     resourceLevel, *
     teamLeader, *
} */
export const getAllResources = async (params) => {
  return axios.get(apiService.resource, { params })
}

export const getResource = async (resourceId, params) => {
  return axios.get(`${apiService.resource}/${resourceId}`, { params })
}
