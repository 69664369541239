import "./ResourcesList.scss"
import ResourceCard from "./ResourceCard"
import Icon from "../components/Icon"
import { useInfiniteScroll } from "../hooks/useInfiniteScroll"
import { useRef } from "react"
import { formatMessage } from "devextreme/localization"

export default function ResourcesList({
  className,
  resources,
  projectId,
  onToggleIsCalendarShown,
  onAddResource,
  onResourceFiltersChange,
  onLoadMore
}) {
  const ref = useRef()
  const isLoadingMore = useInfiniteScroll({
    onLoadMore: onLoadMore,
    scrollableRef: ref
  })
  return (
    <div ref={ref} className={`resources-list ${className}`}>
      {resources.map((resource, index) => (
        <ResourceCard
          key={index}
          resource={resource}
          projectId={projectId}
          onToggleIsCalendarShown={onToggleIsCalendarShown}
          onAddResource={onAddResource}
          onResourceFiltersChange={onResourceFiltersChange}
        />
      ))}
      {isLoadingMore && (
        <div className="d-flex w-100 justify-content-center py-2 h2">
          <Icon iconRelativePath="monade/spinner" spin />
        </div>
      )}
      {!resources?.length && (
        <div className="text-center">
          {formatMessage("preallocation-projects-nodata")}
        </div>
      )}
    </div>
  )
}
