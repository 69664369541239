import { formatDateNoTimezone } from "../../../../../Utils/formatDateNoTimezone"
import clone from "./Clone"

export const parseUserLevelDate = (level) => {
  let parsedLevel = clone(level)

  if (parsedLevel.startDate)
    parsedLevel.startDate = formatDateNoTimezone(parsedLevel.startDate)

  return parsedLevel
}
