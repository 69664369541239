import ThemeOptions from "./ThemeOptions"
import Auth from "./Auth"
import Period from "./Period"
import Jobs from "./Jobs"
import Locale from "./Locale"

const reducers = {
  ThemeOptions,
  Auth,
  Period,
  Jobs,
  Locale
}

export default reducers
