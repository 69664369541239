import { useEffect, useState } from "react"
import "./ResourceActivity.scss"
import { usePopper } from "react-popper"
import moment from "moment"
import { formatMessage } from "devextreme/localization"
import { formatPercentage } from "../../../../Utils/formatPercentage"

export default function ResourceActivity({
  className,
  activity,
  height,
  selectable,
  selected,
  onClick
}) {
  const [showTooltip, setShowTooltip] = useState(false)
  const [referenceEl, setReferenceEl] = useState(null)
  const [popperEl, setPopperEl] = useState(null)

  const popper = usePopper(referenceEl, popperEl, {
    strategy: "fixed",
    placement: "auto"
  })

  const tooltipHasData =
    activity.specificStartDate ||
    activity.specificEndDate ||
    activity.specificPercent

  return (
    <>
      <div
        ref={setReferenceEl}
        className={`resource-activity ${className}`}
        style={{ height: height + "px" }}
        onClick={onClick}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <div>
          <b className="text-truncate pr-1">
            {(activity.percent * 100).toFixed(0) + "%"}
          </b>
        </div>
        <div className="text-truncate flex-fill">{activity.name}</div>
        {selectable && (
          <div
            className={`resource-activity-select ${selected && "selected"}`}
          ></div>
        )}
      </div>
      <div
        ref={setPopperEl}
        {...popper.attributes.popper}
        style={popper.styles.popper}
        className="resource-activity-tooltip"
      >
        {tooltipHasData && showTooltip && (
          <div className="resource-activity-tooltip-content bg-primary text-white">
            {activity.specificStartDate && (
              <div>
                {formatMessage(
                  "preallocation-projects-tooltip-start-date",
                  moment(activity.specificStartDate).format("DD/MM/YYYY")
                )}
              </div>
            )}
            {activity.specificEndDate && (
              <div>
                {formatMessage(
                  "preallocation-projects-tooltip-end-date",
                  moment(activity.specificEndDate).format("DD/MM/YYYY")
                )}
              </div>
            )}
            {activity.specificPercent && (
              <div>
                {formatMessage(
                  "preallocation-projects-tooltip-percent",
                  formatPercentage(activity.specificPercent * 100, 2)
                )}
              </div>
            )}
            <div>
              {formatMessage(
                "preallocation-projects-tooltip-delivery-manager",
                activity.deliveryManager ?? "-"
              )}
            </div>
            <div>
              {formatMessage(
                "preallocation-projects-tooltip-contract-number",
                activity.contractNumber ?? "-"
              )}
            </div>
            <div>
              {formatMessage(
                "preallocation-projects-tooltip-wbs-code",
                activity.aliasCode ?? "-"
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
