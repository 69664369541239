import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { getAllAdb } from "../../../../../services"
import { parseAdbData } from "../../utils/parseAdbData"

export function useSelectAdbs() {
  const { isLoading, isError, isSuccess, data, error } = useQuery(
    ["GET", "adb"],
    async () => {
      return await getAllAdb().then((data) => {
        return data.data.map((adb) => parseAdbData(adb))
      })
    },
    { staleTime: 1000 * 60 * 2 },
  )

  const parseAdbId = (adbId) => {
    if (isError) {
      return "error"
    }

    if (isLoading) {
      return "-"
    }

    return data.find((adb) => adb.id === adbId)?.name || "-"
  }

  const getAdbsAsOptions = () => {
    if (!isSuccess) {
      return []
    }
    return data.map((u) => ({ id: u.id, cell: u.name }))
  }
  const [adbOptions, setAdbOptions] = useState(getAdbsAsOptions())
  useEffect(() => {
    setAdbOptions(getAdbsAsOptions())
  }, [data])

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    parseAdbId,
    adbOptions,
  }
}
