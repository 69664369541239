import { formatMessage } from "devextreme/localization"
import { useEffect, useState } from "react"
import { useWbsTempCode } from "../../hooks/useWbsTempCode"
import AdbFilter from "../AdbFilter"
import AdbCell from "../cell/AdbCell"
import BooleanCell from "../cell/BooleanCell"
import PercentageCell from "../cell/PercentageCell"
import PriceCell from "../cell/PriceCell"
import PriceCellEdit from "../cell/PriceCellEdit"
import StatusCell from "../cell/StatusCell"
import TypeCell from "../cell/TypeCell"
import WbsActionCell from "../cell/WbsActionCell"
import Chevron from "../Chevron"
import Tooltip from "../common/Tooltip"
import IsActiveFilter from "../IsActiveFilter"
import IsFinalFilter from "../IsFinalFilter"
import IsTempPlacedFilter from "../IsTempPlacedFilter"
import StatusFilter from "../StatusFilter"
import TypeFilter from "../TypeFilter"

export const wbsColumns = [
  {
    id: "_id",
    Header: () => <span>{formatMessage("jw-Actions")}</span>,
    accessor: "_id",
    Cell: ({ info, row, canManage }) => (
      <WbsActionCell info={info} row={row} canManage={canManage} />
    )
  },
  {
    id: "code",
    Header: () => <span>{formatMessage("jw-Code")}</span>,
    sortable: true,
    accessor: "code",
    Filter: ({ initialExpand, setInitialExpand }) => {
      const [internalInitialExpand, setInternalInitialExpand] = useState(
        initialExpand
      )
      useEffect(() => {
        setInitialExpand(internalInitialExpand)
      }, [internalInitialExpand])

      return (
        <div className="w-100" style={{ paddingLeft: "12px" }}>
          <Tooltip
            content={
              internalInitialExpand
                ? formatMessage("jw-Collapse all rows")
                : formatMessage("jw-Expand all rows")
            }
          >
            <span>
              <Chevron
                className="mr-2"
                isExpanded={internalInitialExpand}
                setIsExpanded={setInternalInitialExpand}
              />
            </span>
          </Tooltip>
        </div>
      )
    },
    Cell: ({ info, row }) => {
      const wbsTempCode = useWbsTempCode()

      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          <span className="text-nowrap font-weight-bold">
            {row.isTempPlaced ? `${info} (${wbsTempCode})` : info}
            {row.aliasCode && (
              <>
                <br />
                {`[${row.aliasCode}]`}
              </>
            )}
          </span>
        </div>
      )
    }
  },
  {
    id: "isActive",
    Header: () => <span>{formatMessage("jw-Active")}</span>,
    accessor: "isActive",
    Filter: ({ value, setValue }) => (
      <IsActiveFilter value={value} setValue={setValue} />
    ),
    Cell: ({ info }) => <BooleanCell info={info} disabled={true} />
  },
  {
    id: "isFinal",
    Header: () => <span>{formatMessage("jw-Final")}</span>,
    accessor: "isFinal",
    Filter: ({ value, setValue }) => (
      <IsFinalFilter value={value} setValue={setValue} />
    ),
    Cell: ({ info }) => <BooleanCell info={info} disabled={true} />
  },
  {
    id: "isTempPlaced",
    Header: () => <span>{formatMessage("jw-TMP")}</span>,
    accessor: "isTempPlaced",
    Filter: ({ value, setValue }) => (
      <IsTempPlacedFilter value={value} setValue={setValue} />
    ),
    Cell: ({ info }) => <BooleanCell info={info} disabled={true} />
  },
  {
    id: "status",
    Header: () => <span>{formatMessage("jw-Status")}</span>,
    accessor: "status",
    Filter: ({ value, setValue }) => (
      <StatusFilter value={value} setValue={setValue} />
    ),
    Cell: ({ info }) => <StatusCell info={info} />
  },
  {
    id: "adb",
    Header: () => <span>{formatMessage("jw-ADB")}</span>,
    accessor: "adb",
    Filter: ({ value, setValue }) => <AdbFilter value={value} setValue={setValue} />,
    Cell: ({ info }) => <AdbCell info={info} />
  },
  {
    id: "type",
    Header: () => <span>{formatMessage("jw-Type")}</span>,
    sortable: true,
    Filter: ({ value, setValue }) => (
      <TypeFilter value={value} setValue={setValue} />
    ),
    accessor: "type",
    Cell: ({ info }) => <TypeCell info={info} />
  },
  {
    id: "contractValue",
    Header: () => <span>{formatMessage("jw-Contract value")}</span>,
    accessor: "contractValue",
    Cell: ({ info, row, onChange, error, canManage }) => {
      if (!canManage) {
        return <PriceCell info={info} />
      }
      return (
        <PriceCellEdit info={info} onChange={(val) => onChange(val)} error={error} />
      )
    }
  },
  {
    id: "standardCostsTotal",
    Header: () => <span>{formatMessage("jw-STD total costs")}</span>,
    accessor: "standardCostsTotal",
    Cell: ({ info, row, onChange, error, canManage }) => {
      if (!canManage) {
        return <PriceCell info={info} />
      }
      return (
        <PriceCellEdit info={info} onChange={(val) => onChange(val)} error={error} />
      )
    }
  },
  {
    id: "otherCosts",
    Header: () => <span>{formatMessage("jw-Exp & other costs")}</span>,
    accessor: "otherCosts",
    Cell: ({ info, row, onChange, error, canManage }) => {
      if (!canManage) {
        return <PriceCell info={info} />
      }
      return (
        <PriceCellEdit info={info} onChange={(val) => onChange(val)} error={error} />
      )
    }
  },
  {
    id: "targetMargin",
    Header: () => <span>{formatMessage("jw-Margin")}</span>,
    accessor: "targetMargin",
    Cell: ({ info }) => <PriceCell info={info} />
  },
  {
    id: "targetMarginPerc",
    Header: () => <span>{formatMessage("jw-Margin %")}</span>,
    accessor: "targetMarginPerc",
    Cell: ({ info }) => <PercentageCell info={info} />
  },
  {
    id: "revenueXtechManagerial",
    Header: () => <span>{formatMessage("jw-Managerial")}</span>,
    accessor: "revenueXtechManagerial",
    Cell: ({ info }) => <PriceCell info={info} />
  },
  {
    id: "rfpXTech",
    Header: () => <span>{formatMessage("jw-RFP")}</span>,
    accessor: "rfpXTech",
    Cell: ({ info }) => <PriceCell info={info} />
  },
  {
    id: "standardCostsXtech",
    Header: () => <span>{formatMessage("jw-STD cost xTech")}</span>,
    accessor: "standardCostsXtech",
    Cell: ({ info }) => <PriceCell info={info} />
  },
  {
    id: "xTechEffortPerc",
    Header: () => <span>{formatMessage("jw-STD effort xTech")}</span>,
    accessor: "xTechEffortPerc",
    Cell: ({ info }) => <PercentageCell info={info} />
  },
  {
    id: "isDc",
    Header: () => <span>{formatMessage("jw-IS DC")}</span>,
    accessor: "isDc"
  },
  {
    id: "xTechMargin",
    Header: () => <span>{formatMessage("jw-Margin xTech")}</span>,
    accessor: "xTechMargin",
    Cell: ({ info }) => <PriceCell info={info} />
  },
  {
    id: "forceDc",
    Header: () => <span>{formatMessage("jw-Force DC")}</span>,
    accessor: "forceDc"
  }
]
