import moment from "moment"
import { apiService } from "./APIServices"

import axios from "axios"

export const getWorkingDays = (startDate, endDate, userId) => {
  return axios.get(`${apiService.workday}/count`, {
    params: {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      userId
    }
  })
}
