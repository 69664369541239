import { formatMessage } from "devextreme/localization"
import Tooltip from "./common/Tooltip"
import Icon from "./Icon"

export default function RefreshTableButton({ refetch }) {
  return (
    <Tooltip
      content={
        <div className="text-nowrap">{formatMessage("jw-Refresh table")}</div>
      }
    >
      <button
        className={`btn btn-outline-secondary ml-2`}
        style={{ backgroundColor: "white" }}
        onClick={refetch}
      >
        <Icon
          svgStyles={{ width: "16px", height: "16px" }}
          iconRelativePath="monade/refresh"
        />
      </button>
    </Tooltip>
  )
}
