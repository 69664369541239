import { formatMessage } from "devextreme/localization"
import { Bounce, toast } from "react-toastify"

export const parseErrors = (err) => {
  const rawErrors = err.data.errors

  // [{field: "", message: ""}, ...]
  // field can be "status" or any of the fieldName of the form
  let parsedErrors = []

  //if there isn't any field-specific errors
  if (!rawErrors) {
    parsedErrors.push({ field: err.data.status, message: err.data })
  } else {
    if (Array.isArray(rawErrors)) {
      // if error is an Array:
      // [ {param: "", msg: ""} , ...]
      rawErrors.forEach((e) => {
        if (e.param) {
          parsedErrors.push({ field: e.param, message: e.msg })
        } else {
          parsedErrors.push({ field: e.path, message: e.message })
        }
      })
    } else {
      for (var prop in rawErrors) {
        // if error is an Object
        // { "fieldName": {message: ""}, ... }
        let e = {}
        e.field = prop
        e.message = rawErrors[prop].message
        parsedErrors.push(e)
      }
    }
  }

  if (parsedErrors.length > 0) {
    console.error(parsedErrors)
  }
  return parsedErrors
}

export function showToastError(response, message) {
  console.error(
    "ERROR: " + JSON.stringify(response, null, 2),
    JSON.stringify(message, null, 2)
  )
  if (!message) {
    switch (response?.status) {
      case 401:
        message = formatMessage("hc-error-session-expired")
        break
      case 400:
        message = formatMessage("hc-error-form-validation-failed")
        break
      case 409:
        message = response.data?.message
          ? response.data?.message
          : response.data?.errors?.map((e) => e.message)?.join(", ")
        break
      case 500:
        message =
          formatMessage(response.data) === ""
            ? response.data
            : formatMessage(response.data)
        break
      default:
        message = formatMessage("hc-error-failed-to-save-resource")
        break
    }
  }
  toast(message, {
    transition: Bounce,
    closeButton: true,
    autoClose: 2000,
    position: "bottom-center",
    type: "error"
  })
}

export function showSuccessToast(message = formatMessage("hc-success")) {
  toast(message, {
    transition: Bounce,
    closeButton: true,
    autoClose: 2000,
    position: "bottom-center",
    type: "success"
  })
}

export function getErrorByField(errors = [], field) {
  if(!errors || !errors.length) {
    return ""
  }
  return errors.find((error) => error.field === field)?.message || ""
}
