import Tippy from "@tippyjs/react"
import { hideAll } from "tippy.js"

import { forwardRef } from "react"
import "./Tooltip.scss"

export default function Tooltip({ children, disabled, content }) {
  const ChildrenWrapper = forwardRef((props, ref) => {
    return <span ref={ref}>{children}</span>
  })

  return (
    <Tippy
      delay={100}
      disabled={disabled}
      className="tippy-tooltip"
      content={content}
      onShow={(instance) => {
        hideAll({ exclude: instance })
      }}
      maxWidth="none"
    >
      <ChildrenWrapper />
    </Tippy>
  )
}
