import { apiService } from "./APIServices"

import axios from "axios"

export const getAllOffices = async () => {
  return axios.get(apiService.office)
}

export const getOffice = async (officeId) => {
  return axios.get(`${apiService.office}/${officeId}`)
}
