import { formatMessage } from "devextreme/localization"
import moment from "moment"
import { useState } from "react"
import { Button, Tooltip } from "reactstrap"
import {
  exportAllPeriodsReview,
  exportCurrentPeriodReview
} from "../../../../services"

export const exportReviewModes = {
  ALL_PERIODS: "ALL_PERIODS",
  CURRENT_PERIOD: "CURRENT_PERIOD"
}

function ExportAllButton({ mode, periodId }) {
  const [exporting, setExporting] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const downloadFile = (blob) => {
    const url = window.URL.createObjectURL(new Blob([blob]))
    const link = document.createElement("a")
    link.href = url
    link.download =
      `${mode === exportReviewModes.ALL_PERIODS ? "all" : "period"}_review_` +
      moment().format("YYYYMMDD HH-mm") +
      ".xlsx"
    link.click()
  }

  return (
    <span>
      <Button
        id={
          mode === exportReviewModes.ALL_PERIODS
            ? "exportAllReviewButton"
            : "exportCurrentReviewButton"
        }
        style={{
          zIndex: "100"
        }}
        color={"primary"}
        disabled={exporting}
        onClick={() => {
          setExporting(true)
          if (mode === exportReviewModes.ALL_PERIODS) {
            exportAllPeriodsReview().then((response) => {
              downloadFile(response.data)
              setExporting(false)
            })
          } else {
            exportCurrentPeriodReview(periodId).then((response) => {
              downloadFile(response.data)
              setExporting(false)
            })
          }
        }}
      >
        {exporting
          ? "EXPORTING DATA..."
          : (mode === exportReviewModes.ALL_PERIODS
              ? formatMessage("review-export-all-periods")
              : formatMessage("review-export-current-period")
            ).toUpperCase()}
      </Button>
      <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        target={
          mode === exportReviewModes.ALL_PERIODS
            ? "exportAllReviewButton"
            : "exportCurrentReviewButton"
        }
        toggle={() => setTooltipOpen(!tooltipOpen)}
        style={{ fontSize: "10px" }}
      >
        {mode === exportReviewModes.ALL_PERIODS
          ? formatMessage("dxDataGrid-exportAll")
          : formatMessage("tooltip-export-current-period")}
      </Tooltip>
    </span>
  )
}

export default ExportAllButton
