import "./ResourceCard.scss";
import ResourceCardHeader from "./ResourceCardHeader";
import ResourceCardBody from "./ResourceCardBody";

export default function ResourceCard({
  resource,
  projectId,
  onToggleIsCalendarShown,
  onAddResource,
  onResourceFiltersChange
}) {
  return (
    <div className='resource-card'>
      <ResourceCardHeader
        resource={resource}
        onToggleIsCalendarShown={onToggleIsCalendarShown}
        onAddResource={onAddResource}
      />
      {resource.isOpen &&
        <ResourceCardBody
          resource={resource}
          projectId={projectId}
          onResourceFiltersChange={onResourceFiltersChange}
        />
      }
    </div>
  );
}
