import "./ResourceLegendItem.scss"
import {formatPercentage} from "../../../../Utils/formatPercentage";

export default function ResourceLegendItem({
  item: {
    label,
    value,
    color,
    borderColor
  }
}) {
  return (
    <div className='resource-legend-item'>
      <div className='resource-legend-item-value'>
        <div className='resource-legend-item-circle' style={{ backgroundColor: color, borderColor }} />
        <span>{formatPercentage(value * 100, 1)}</span>
      </div>
      <div className='resource-legend-item-label'>{label}</div>
    </div>
  )
}
