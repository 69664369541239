export const SET_USER_DETAILS = "SET_USER_DETAILS"
export const SET_CONFIG = "SET_CONFIG"

export const setUserDetails = (userDetails) => ({
  type: SET_USER_DETAILS,
  userDetails
})

export const setConfig = (configData) => ({
  type: SET_CONFIG,
  configData
})

const initialState = {
  userEmail: null,
  user: null,
  config: null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "AAD_LOGIN_SUCCESS":
      return {
        ...state,
        userEmail: action.payload.account.userName
      }
    case "SET_USER_DETAILS":
      return {
        ...state,
        user: action.userDetails
      }
    case "SET_CONFIG":
      return {
        ...state,
        config: action.configData
      }
    default:
      return state
  }
}
