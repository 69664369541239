import { useEffect, useState } from "react"
import moment from "moment"
import { useProjects } from "./useProjects"

export const useProjectsView = () => {
  const [filters, setFilters] = useState({
    startDate: moment()
      .startOf("day")
      .toDate(),
    aggregation: "WEEKLY",
    showFreeTime: false,
    showDetails: false,
    search: "",
    order: "ASC",
    team: "all"
  })
  const [areAllResourcesShown, setAreAllResourcesShown] = useState(false)
  const [sortedProjects, setSortedProjects] = useState([])

  const {
    projects: rawProjects,
    isLoadingProjects,
    loadProjectsError,
    loadProjects,
    loadProjectDetails,
    loadMore,
    loadProject,
    setIsProjectOpen,
    setIsProjectPinned,
    loadPage,
    paginationMeta
  } = useProjects()

  useEffect(() => {
    if (!rawProjects) {
      return
    }

    setSortedProjects(sortProjects(rawProjects))
  }, [rawProjects, filters.order])

  useEffect(() => {
    updateAreAllResourcesShown()
    updateStartDate()
  }, [rawProjects])

  const sortProjects = (projects) => {
    const { pinnedProjects, unpinnedProjects } = projects.reduce(
      ({ pinnedProjects, unpinnedProjects }, current) => {
        if (current.isPinned) {
          return {
            pinnedProjects: [...pinnedProjects, current],
            unpinnedProjects
          }
        } else {
          return {
            pinnedProjects,
            unpinnedProjects: [...unpinnedProjects, current]
          }
        }
      },
      { pinnedProjects: [], unpinnedProjects: [] }
    )

    return [...pinnedProjects, ...unpinnedProjects]
  }

  const toggleIsOpen = (project) =>
    setIsProjectOpen(project, !project.isOpen, filters)
  const toggleIsPinned = (project) => setIsProjectPinned(project, !project.isPinned)
  const toggleAreAllResourcesShown = () => {
    if (!rawProjects) {
      return
    }
    if (areAllResourcesShown) {
      rawProjects.forEach((project) => setIsProjectOpen(project, false, filters))
    } else {
      const projectIds = rawProjects.map((project) => project.id)
      loadProjectDetails(projectIds, filters)
    }
  }

  const updateStartDate = () => {
    if (rawProjects.length === 0) {
      return
    }

    setFilters({
      ...filters,
      startDate: moment(rawProjects[0].projectTimeSlots[0].startDate).toDate()
    })
  }

  const updateAreAllResourcesShown = () => {
    if (rawProjects.length === 0) {
      return
    }

    const areAllProjectsOpen = !rawProjects.some((project) => !project.isOpen)

    if (areAllProjectsOpen !== areAllResourcesShown) {
      setAreAllResourcesShown(areAllProjectsOpen)
    }
  }

  return {
    filters,
    setFilters,
    areAllResourcesShown,
    setAreAllResourcesShown,
    projects: sortedProjects,
    isLoadingProjects,
    loadProjectsError,
    loadProjects,
    loadMore,
    loadProject,
    toggleIsOpen,
    toggleIsPinned,
    toggleAreAllResourcesShown,
    loadPage,
    paginationMeta
  }
}
