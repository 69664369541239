import { useEffect, useRef, useState } from "react"
import "../Buttons.scss"

export default function HeaderRow({
  headers,
  columnOffsets,
  setColumnOffsets,
  tableData,
  filtrable = true,
}) {
  const [headerEl, setHeaderEl] = useState(null)
  const [subheaderEl, setSubheaderEl] = useState(null)

  useEffect(() => {
    if (!headerEl || !setColumnOffsets) {
      return
    }

    let timeoutId = setTimeout(() => {
      const columnOffsets = Array.from(headerEl.children)
        .slice(0, headerEl.children.length - 1)
        .reduce(
          (result, current) => {
            const { width } = current.getBoundingClientRect()
            return [...result, result[result.length - 1] + width]
          },
          [0],
        )

      setColumnOffsets(columnOffsets)
    }, 0)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [headerEl, tableData])

  useEffect(() => {
    if (!headerEl || !subheaderEl || !columnOffsets) {
      return
    }

    const { height: headerHeight } = headerEl.getBoundingClientRect()

    let timeoutId = setTimeout(() => {
      for (let index = 0; index < Array.from(headerEl.children).length; index++) {
        subheaderEl.children[index].style.top = `${headerHeight}px`

        if (headers[index].isPinned) {
          headerEl.children[index].className += " overflow-none"
          subheaderEl.children[index].className += " overflow-none"
          headerEl.children[index].style.left = columnOffsets
            ? columnOffsets[index] + "px"
            : 0
          subheaderEl.children[index].style.left = columnOffsets
            ? columnOffsets[index] + "px"
            : 0
          headerEl.children[index].style.zIndex = "10000"
          subheaderEl.children[index].style.zIndex = "20000"
        } else {
          headerEl.children[index].className = headerEl.children[
            index
          ].className.replace(" overflow-none", "")
          subheaderEl.children[index].className = subheaderEl.children[
            index
          ].className.replace(" overflow-none", "")
          headerEl.children[index].style.left = null
          subheaderEl.children[index].style.left = null
          headerEl.children[index].style.zIndex = "9999"
          subheaderEl.children[index].style.zIndex = "19999"
        }
      }
    }, 0)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [headerEl, subheaderEl, headers, columnOffsets])

  return (
    <>
      <tr ref={setHeaderEl} className="header-row position-relative bg-white">
        {headers.map((header) => (
          <th
            className={`text-center text-nowrap px-3 py-2 bg-white position-sticky header-${header.id}`}
            key={header.id}
          >
            {header.content}
          </th>
        ))}
      </tr>
      {filtrable && (
        <tr
          ref={setSubheaderEl}
          className="filter-row font-weight-normal position-relative"
        >
          {headers.map((header) => (
            <th className="p-1 position-sticky" key={header.id}>
              <span className="d-flex flex-row justify-content-center align-items-center">
                {header.filter}
              </span>
            </th>
          ))}
        </tr>
      )}
    </>
  )
}
