import { formatMessage } from "devextreme/localization"
import { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import Tooltip from "./common/Tooltip"
import Icon from "./Icon"
import "./Buttons.scss"

export default withRouter(function CloneJobButton({
  history,
  className,
  job,
  yearId,
  extended = false,
  disabled,
}) {
  const getWbsWithoutShortCodeAndDescription = () => {
    const { _id, shortCode, ...newJob } = job
    return newJob
  }

  const [jobToClone, setJobToClone] = useState(
    getWbsWithoutShortCodeAndDescription(),
  )
  useEffect(() => {
    setJobToClone(getWbsWithoutShortCodeAndDescription())
  }, [job])

  return (
    <Tooltip
      disabled={extended}
      content={
        <div className="text-nowrap">
          {formatMessage("jw-CLONE")} {formatMessage("jw-JOB")}
        </div>
      }
    >
      <button
        disabled={disabled}
        className={`${className} btn btn-outline-primary square-btn`}
        onClick={() =>
          history.push({
            pathname: `/admin/${yearId}/job/create`,
            state: { jobToClone },
          })
        }
      >
        <Icon iconRelativePath="monade/clone" />{" "}
        {extended ? (
          <span className="ml-1">{`${formatMessage("jw-CLONE")} ${formatMessage(
            "jw-JOB",
          )}`}</span>
        ) : (
          ""
        )}
      </button>{" "}
    </Tooltip>
  )
})
