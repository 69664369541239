import { useRef } from "react"

export default function CalendarSubrow({
  rowModel,
  subrowModel,
  mainCellComponentFactory,
  slotCellComponentsFactory
}) {
  const rowRef = useRef(null)

  return (
    <tr ref={rowRef} className="calendar-subrow">
      <td>{mainCellComponentFactory(rowModel, subrowModel, rowRef)}</td>
      {slotCellComponentsFactory(rowModel, subrowModel).map(
        (slotCellComponent, index) => (
          <td
            key={index}
            style={{ height: "inherit", verticalAlign: "top", position: "relative" }}
          >
            {slotCellComponent}
          </td>
        )
      )}
    </tr>
  )
}
