export const cleanObject = (object) => {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === "object") {
      cleanObject(v)
    }
    if (v === null || v === undefined) {
      if (Array.isArray(object)) {
        object.splice(k, 1)
      } else {
        delete object[k]
      }
    }
  })
  return object
}
