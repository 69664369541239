import React, { Fragment, useEffect } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import queryString from "query-string"
import ExternalReview from "./review"
import moment from "moment"
import PageTitle from "../../../Layout/AppMain/PageTitle"
import { PeriodDropDown } from "./../Components/periodDropDown"
import { setSelectedPeriod } from "../../../reducers/Period"
import { formatMessage } from "devextreme/localization"

export default function Externals(props) {
  let selectedPeriod = useSelector((state) => state.Period.selectedPeriod)

  const dispatch = useDispatch()

  useEffect(() => {
    const parsed = queryString.parse(props.location.search)
    if (parsed.period) {
      dispatch(
        setSelectedPeriod({
          _id: parsed.period,
          label: moment(parsed.period, "YYYYMM").format("MMMM YY"),
          calendarStartDate: parsed.period.calendarStartDate,
          calendarEndDate: parsed.period.calendarEndDate
        })
      )
    }
  }, [dispatch, props.location.search])

  return (
    <Fragment>
      <PageTitle
        heading={formatMessage("Externals")}
        subheading=""
        icon="pe-7s-way text-success"
        actions={
          <Fragment>
            <PeriodDropDown review="anyReview" />
          </Fragment>
        }
      />
      <ExternalReview currentPeriod={selectedPeriod} />
    </Fragment>
  )
}
