import { include } from "underscore"
import { apiService } from "./APIServices"

import axios from "axios"

export const getUsers = async (bipRoles, includeTeam, includeAreaShortcode, include_roles) => {
  if (bipRoles)
    return axios.get(
      `${apiService.user}?${bipRoles.map((role) => `bip_roles=${role}`).join("&")}`
    )
  else
    return axios.get(apiService.user, {
      params: {
        include_team: includeTeam ? includeTeam : false,
        include_area_shortcode: includeAreaShortcode ? includeAreaShortcode : false,
        include_roles: include_roles ? include_roles : false
      }
    })
}

export const getUser = async (id) => {
  return axios.get(`${apiService.user}/${id}`)
}

export const getAllUsers = async (
  onlyEnabled = false,
  leavingDate_gte,
  joiningDate_lte
) => {
  let url = apiService.user + `?include_roles=true&is_enabled=${onlyEnabled}`

  if (leavingDate_gte) url = url + `&leavingDate_gte=${leavingDate_gte}`
  if (joiningDate_lte) url = url + `&joiningDate_lte=${joiningDate_lte}`

  return axios.get(url)
}

export const createUser = async (user) => {
  return axios.post(apiService.user, user)
}

export const updateUser = async (user) => {
  return axios.put(apiService.user + `/${user._id}`, user)
}

export const addLevel = async (userId, data) => {
  return axios.post(apiService.user + `/${userId}/levels`, data)
}

export const getLevels = async (userId) => {
  return axios.get(apiService.user + `/${userId}/levels`)
}

export const updateLevel = async (userId, levelId, data) => {
  return axios.put(apiService.user + `/${userId}/levels/${levelId}`, data)
}

export const removeLevel = async (userId, levelId) => {
  return axios.delete(apiService.user + `/${userId}/levels/${levelId}`)
}
