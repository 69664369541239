import moment from "moment"
import queryString from "query-string"
import React, { Fragment, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import PageTitle from "../../../Layout/AppMain/PageTitle"
import ThirdReview from "./review"
import { PeriodDropDown } from "./../Components/periodDropDown"
import { setSelectedPeriod } from "../../../reducers/Period"
import { useDispatch } from "react-redux"
import { formatMessage } from "devextreme/localization"
import { setAllJobs } from "../../../reducers/Jobs"
import { getJobsThumb } from "../../../services"
import { Button } from "reactstrap"
import { sendInChargeReviews, closeAllReviews } from "../Utils/tsFlowConfig"
import { Tooltip } from "@material-ui/core"
import { ExternalGuideRefs } from "../../../Common/guide/guideExternalRefs.js"
import { useAppAbility } from "../../../Context/ability.js"

export default function TLReview(props) {
  let selectedPeriod = useSelector((state) => state.Period.selectedPeriod)
  const dispatch = useDispatch()
  const config = useSelector((state) => state.Auth.config)
  const user = useSelector((state) => state.Auth.user)

  const [openAlert, setOpenAlert] = useState(false)
  const [titleAlert, setTitleAlert] = useState("")
  const [messageAlert, setMessageAlert] = useState("")
  const [isAlert, setIsAlert] = useState(false)
  const [statusToSend, setStatusToSend] = useState(null)
  const [sendBulkError, setSendBulkError] = useState(false)
  const [assignedTs, setAssignedTs] = useState([])

  const { can } = useAppAbility()
  const canManageAll = can("manage", "CloseAllTimesheets")

  const updateJobThumb = () => {
    getJobsThumb().then(({ data }) => {
      dispatch(
        setAllJobs({
          jobs: data,
          wbsTypes: config.wbsTypes,
          selectedPeriod: selectedPeriod
        })
      )
    })
  }

  useEffect(() => {
    updateJobThumb()
  }, [])

  React.useEffect(() => {
    const parsed = queryString.parse(props.location.search)
    if (parsed.period) {
      dispatch(
        setSelectedPeriod({
          _id: parsed.period,
          label: moment(parsed.period, "YYYYMM").format("MMMM YY"),
          calendarStartDate: parsed.period.calendarStartDate,
          calendarEndDate: parsed.period.calendarEndDate
        })
      )
    }
  }, [dispatch, props.location.search])

  return (
    <Fragment>
      <PageTitle
        heading={formatMessage("Review")}
        subheading=""
        icon="pe-7s-way text-success"
        timesheetStatus={""}
        actions={
          <Fragment>
            <PeriodDropDown review="anyReview" />

            <Tooltip title={formatMessage("send-all-review-hint")} arrow={true}>
              <Button
                id="sendAllButton"
                color="dark"
                onClick={() => {
                  const { isAlert, sendText, alertText } = sendInChargeReviews({
                    containInvalidJobs: sendBulkError,
                    noReviewsToAprove: !sendBulkError && assignedTs.length === 0,
                    selectedPeriod: selectedPeriod
                  })

                  setStatusToSend("all")
                  setMessageAlert(alertText)
                  setTitleAlert(sendText)
                  setIsAlert(isAlert)
                  setOpenAlert(true)
                }}
              >
                {formatMessage("send-all-review").toUpperCase()}
              </Button>
            </Tooltip>

            {canManageAll ? (
              <Tooltip title={formatMessage("close-all-review-hint")} arrow={true}>
                <Button
                  className="mx-2"
                  id="closeAllButton"
                  color="warning"
                  onClick={() => {
                    const { isAlert, sendText, alertText } = closeAllReviews({
                      selectedPeriod: selectedPeriod
                    })
                    setStatusToSend("closeAll")
                    setMessageAlert(alertText)
                    setTitleAlert(sendText)
                    setIsAlert(isAlert)
                    setOpenAlert(true)
                  }}
                >
                  {formatMessage("close-all-review").toUpperCase()}
                </Button>
              </Tooltip>
            ) : null}
          </Fragment>
        }
        guideRef={ExternalGuideRefs.REVIEW}
      />
      <ThirdReview
        currentPeriod={selectedPeriod}
        updateJobThumb={updateJobThumb}
        openAlert={openAlert}
        setOpenAlert={setOpenAlert}
        titleAlert={titleAlert}
        setTitleAlert={setTitleAlert}
        messageAlert={messageAlert}
        setMessageAlert={setMessageAlert}
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        statusToSend={statusToSend}
        setStatusToSend={setStatusToSend}
        sendBulkError={sendBulkError}
        setSendBulkError={setSendBulkError}
        assignedTs={assignedTs}
        setAssignedTs={setAssignedTs}
      />
    </Fragment>
  )
}
