import { formatMessage } from "devextreme/localization"
import moment from "moment"
import {
  getClosedReview,
  getJOReview,
  saveFirstReviewRow,
  savePpaRow,
  savePreReviewRow,
  saveThirdReviewRow,
  saveCompletedReviewRow
} from "../../../services"

export const flowConfig = {
  original: {
    label: "Timesheet"
  },
  preReview: {
    label: "Pre Review",
    status: {
      started: {
        isEditable: true
      },
      skipped: {
        isEditable: false
      }
    },
    saveRow: savePreReviewRow
  },
  firstReview: {
    label: "Job Review",
    status: {
      completed: {
        isEditable: false,
        badgeStyle: "badge badge-success",
        label: "completed"
      },
      started: {
        isEditable: true,
        badgeStyle: "badge badge-warning",
        label: "started"
      },
      skipped: {
        isEditable: false,
        badgeStyle: "badge badge-success",
        label: "started"
      }
    },
    saveRow: saveFirstReviewRow,
    getRows: getJOReview
  },
  thirdReview: {
    label: "Third Review",
    status: {
      started: {
        isEditable: true
      },
      skipped: {
        isEditable: false
      }
    },
    saveRow: saveThirdReviewRow
  },
  completed: {
    label: "Completed",
    status: {
      started: {
        isEditable: true
      }
    },
    saveRow: saveCompletedReviewRow
  },
  ppa: {
    label: "PPA",
    saveRow: savePpaRow
  },
  closed: {
    label: "Closed",
    getRows: getClosedReview
  },
  onHold: {
    label: "On Hold"
  }
}

export const isEditable = ({ review, status }) => {
  return flowConfig[review].status[status].isEditable
}

export const getBadgeStyle = ({ review, status }) => {
  return flowConfig[review].status[status].badgeStyle
}

// only for first review
export const getRows = ({ review, params }) => {
  return flowConfig[review].getRows(params)
}

export const saveRow = ({ review, params }) => {
  return flowConfig[review].saveRow(params)
}

export const getJobsBy = ({ review, wbsTypes }) => {
  return flowConfig[review].getJobs(wbsTypes)
}

export const buildConfigForAnyReviewBy = ({
  bipRole,
  userMail,
  review,
  reviewOwner,
  status,
  locked,
  can
}) => {
  // if the ts is close it can't be edited
  if (review === "closed")
    return {
      text: `${flowConfig[review].label}`,
      class: "success",
      editable: false,
      sendable: false,
      ppa: true
    }

  // if the ts is completed it can't be edited and ppa cannot be done
  if (review === "completed")
    return {
      text: `${flowConfig[review].label}`,
      class: "success",
      editable: false,
      sendable: false,
      ppa: false
    }

  // if the ts is close it can't be edited
  if (review === "onHold")
    return {
      text: `${flowConfig[review].label}`,
      class: "dark",
      editable: false,
      sendable: false
    }

  // if the ts is in first review or is locked by another user it can't be edited
  if (review === "firstReview" || (locked && reviewOwner !== userMail))
    return {
      text: `waiting for ${flowConfig[review].label}`,
      class: "dark",
      editable: false,
      sendable: false
    }

  // if the ts is locked, but it's the same user it can be edited
  if (locked && reviewOwner === userMail)
    return {
      text: `${flowConfig[review].label}`,
      class: "warning",
      editable: true,
      sendable: true
    }

  // if the ts is not locked
  if (!locked && reviewOwner !== userMail) {
    const canEdit = can("edit", review)
    const successStyle = "grey"
    return {
      text: canEdit
        ? `${flowConfig[review].label}`
        : `waiting for ${flowConfig[review].label}`,
      class: canEdit ? successStyle : "dark",
      editable: canEdit,
      sendable: true
    }
  }

  if (!locked && reviewOwner === userMail) {
    return {
      text: `${flowConfig[review].label}`,
      class: "warning",
      editable: true,
      sendable: true
    }
  }
  return {
    text: `${flowConfig[review].label}`,
    class: "warning",
    editable: true,
    sendable: true
  }
}

export const sendAnyReview = ({
  tempHours,
  review,
  unitIsHours,
  totalHours,
  workingDays,
  containInvalidJobs,
  selectedPeriod
}) => {
  if (containInvalidJobs) {
    return {
      isAlert: true,
      error: true,
      sendText: "",
      alertText: formatMessage("warning-ts-compiling-disabled-job-body")
    }
  }

  // if temp hours is greater then 0, the ts can't be sent by setting error: true
  if (review === "thirdReview" && tempHours > 0) {
    return {
      isAlert: true,
      alertText: formatMessage(
        "review-excess-after-pre-review-error",
        unitIsHours
          ? formatMessage("Hours").toLowerCase()
          : formatMessage("Days").toLowerCase()
      ),
      error: true,
      sendText: ""
    }
  }

  if (
    selectedPeriod._id ===
    moment()
      .add(1, "month")
      .format("YYYYMM")
  ) {
    return {
      isAlert: true,
      error: false,
      sendText: "",
      alertText: formatMessage("future-review-send-confirm")
    }
  }

  // if temp hours is less then 0, the ts can be sent by setting error: false
  if (review === "thirdReview" && tempHours < 0) {
    return {
      isAlert: true,
      alertText: formatMessage("warning-ts-compiling-send-anyway"),
      error: false,
      sendText: formatMessage(
        "review-missing-after-pre-review-warning",
        unitIsHours
          ? formatMessage("Hours").toLowerCase()
          : formatMessage("Days").toLowerCase()
      )
    }
  }

  if (review === "ppa") {
    return {
      isAlert: false,
      alertText: ``,
      error: false,
      sendText: formatMessage("review-send-confirm")
    }
  }

  // if total hours is different from working days, the ts can be sent
  if (totalHours / 8 !== workingDays) {
    const alertText =
      totalHours / 8 > workingDays
        ? unitIsHours
          ? formatMessage("warning-ts-compiling-too-many-hours")
          : formatMessage("warning-ts-compiling-too-many-days")
        : unitIsHours
        ? formatMessage("warning-ts-compiling-not-enough-hours")
        : formatMessage("warning-ts-compiling-not-enough-days")

    return {
      isAlert: true,
      alertText: formatMessage("warning-ts-compiling-send-anyway"),
      error: false,
      sendText: alertText
    }
  }

  // it's all good
  return {
    isAlert: false,
    alertText: "",
    error: false,
    sendText: formatMessage("review-send-confirm")
  }
}

export const sendOriginalTimesheetText = ({
  totalDays,
  workingDays,
  unitIsHours,
  containInvalidJobs,
  selectedPeriod
}) => {
  const unit = unitIsHours ? "hours" : "days"

  if (containInvalidJobs) {
    return {
      title: formatMessage("warning-ts-compiling-disabled-job-title"),
      message: formatMessage("warning-ts-compiling-disabled-job-body"),
      action: "",
      isAlert: true
    }
  }

  if (!totalDays) {
    // it's impossible that a timsheet has 0 hours compiled because of the validation rules, but we will keep it.
    return {
      title:
        unitIsHours === true
          ? formatMessage("warning-ts-compiling-sending-not-enough-hours")
          : formatMessage("warning-ts-compiling-sending-not-enough-days"),
      message:
        unitIsHours === true
          ? formatMessage("warning-ts-compiling-sending-greater-then-0-hours")
          : formatMessage("warning-ts-compiling-sending-greater-then-0-days"),
      action: "",
      isAlert: true
    }
  }

  if (
    selectedPeriod._id ===
    moment()
      .add(1, "month")
      .format("YYYYMM")
  ) {
    return {
      title: formatMessage("warning-ts-compiling-future-period"),
      message: formatMessage("warning-ts-compiling-sending-confirmation-anyway"),
      action: formatMessage("SEND"),
      isAlert: true
    }
  }

  if (totalDays !== workingDays) {
    return {
      title:
        totalDays < workingDays
          ? unitIsHours === true
            ? formatMessage("warning-ts-compiling-not-enough-hours")
            : formatMessage("warning-ts-compiling-not-enough-days")
          : unitIsHours === true
          ? formatMessage("warning-ts-compiling-too-many-hours")
          : formatMessage("warning-ts-compiling-too-many-days"),
      message: formatMessage("warning-ts-compiling-sending-confirmation-anyway"),
      action: formatMessage("SEND-ANYWAY"),
      isAlert: true
    }
  }

  return {
    title: formatMessage("warning-ts-compiling-sending-confirmation"),
    message: formatMessage("warning-ts-compiling-sending-confirmation-info"),
    action: formatMessage("SEND"),
    isAlert: false
  }
}

export const sendInChargeReviews = ({
  containInvalidJobs,
  noReviewsToAprove,
  selectedPeriod
}) => {
  if (containInvalidJobs) {
    return {
      isAlert: true,
      alertText: formatMessage("warning-approve-all-reviews-disabled-job"),
      error: false
    }
  } else if (noReviewsToAprove) {
    return {
      isAlert: true,
      alertText: formatMessage("error-approve-all-reviews-missing-reviews"),
      error: true
    }
  }

  if (
    selectedPeriod._id ===
    moment()
      .add(1, "month")
      .format("YYYYMM")
  ) {
    return {
      alertText: formatMessage("warning-approve-all-future-reviews"),
      isAlert: true,
      error: false
    }
  }

  return {
    alertText: formatMessage("warning-approve-all-reviews"),
    isAlert: true,
    error: false
  }
}

export const closeAllReviews = ({
  selectedPeriod
}) => {
  if (
    selectedPeriod._id ===
    moment()
      .add(1, "month")
      .format("YYYYMM")
  ) {
    return {
      alertText: formatMessage("warning-close-all-future-reviews"),
      isAlert: true,
      error: false
    }
  }

  return {
    alertText: formatMessage("warning-close-all-reviews"),
    isAlert: true,
    error: false
  }
}

export const reviewList = Object.keys(flowConfig)
