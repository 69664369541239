import { formatMessage } from "devextreme/localization"
import "./TeamSlotCell.scss"

export function TeamSlotCell({ slot }) {
  const chargeable = Math.round(slot.chargeablePercent * 1e3) / 10
  const unchargeable = Math.round(slot.notChargeablePercent * 1e3) / 10
  const free = Math.round(slot.freePercent * 1e3) / 10

  return (
    <div className="team-slot-cell">
      <p className="text-muted mb-1">
        {formatMessage("preallocation-teams-medium-time")}
      </p>
      <div className="time-bars">
        <div className="bar chargeable-bar" style={{ height: `${Math.max(chargeable, 0)}%` }}>
          {chargeable}%
        </div>
        <div className="bar unchargeable-bar" style={{ height: `${Math.max(unchargeable, 0)}%` }}>
          {unchargeable}%
        </div>
        <div className="bar free-bar" style={{ height: `${Math.max(free, 0)}%` }}>
          {free}%
        </div>
      </div>
    </div>
  )
}
