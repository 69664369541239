import React, { Fragment, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import _ from "underscore"
import { getAllOffices, getAllOrganizations, getUsers } from "../../../services"
import PageTitle from "./../../../Layout/AppMain/PageTitle"
import renderExportButton from "./Main/Components/ExportXLSButton"
import UsersList from "./Main/HCList"
import getColumns from "./Main/Utils/GetColumns"
import "./Styles/searchBoxPadding.css"

export default function Headcount({ canManageUser }) {
  const config = useSelector((state) => state.Auth.config)
  const [organizations, setOrganizations] = useState([])
  const [levels, setLevels] = useState([])
  const [bipRoles, setBipRoles] = useState([])
  const [offices, setOffices] = useState([])
  const [userNames, setUserNames] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [competences, setCompetences] = useState([])
  const [contractTypes, setContractTypes] = useState([])
  const [globalCompetences, setGlobalCompetences] = useState([])
  const [circleValues, setCircleValues] = useState([])
  const [genderValues, setGenderValues] = useState([])

  useEffect(() => {
    if (config) {
      setLevels(_.sortBy(config.levels, "name"))
      setBipRoles(config.bipRoles.sort())
      setCompetences(config.competences.sort())
      setSuppliers(config.suppliers.sort())
      setContractTypes(config.contractType.sort())
      setGlobalCompetences(config.globalCompetences.sort())
      setCircleValues(config.circleValues.sort())
      setGenderValues(config.genderValues)
    }
  }, [config])
  useEffect(() => {
    getAllOffices().then(({ data }) => {
      setOffices(
        data.map((j) => ({
          id: j._id,
          name: j.name,
          code: j.code
        }))
      )
    })
  }, [])
  useEffect(() => {
    getAllOrganizations().then(({ data }) => {
      setOrganizations(
        data.map((j) => ({
          id: j._id,
          name: j.name,
          owner: j.owner,
          isDeleted: j.isDeleted
        }))
      )
    })
  }, [])
  useEffect(() => {
    getUsers().then(({ data }) => {
      setUserNames(
        data
          .sort(function(a, b) {
            var A = a.firstName.toUpperCase()
            var B = b.firstName.toUpperCase()
            return A < B ? -1 : A > B ? 1 : 0
          })
          .map((user) => ({
            id: user._id,
            name: `${user.firstName} ${user.lastName}`
          }))
      )
    })
  }, [])

  const dataGridRef = useRef(null)
  const columns = getColumns(
    organizations,
    userNames,
    offices,
    competences,
    levels,
    suppliers,
    contractTypes,
    globalCompetences,
    circleValues,
    genderValues
  )

  return (
    <Fragment>
      <PageTitle
        heading="Headcount"
        icon="pe-7s-graph text-success"
        actions={renderExportButton(columns, dataGridRef)}
      ></PageTitle>
      <UsersList
        genderValues={genderValues}
        organizations={organizations}
        offices={offices}
        bipRoles={bipRoles}
        levels={levels}
        userNames={userNames}
        competences={competences}
        dataGridRef={dataGridRef}
        columns={columns}
        suppliers={suppliers}
        contractTypes={contractTypes}
        canManageUser={canManageUser}
        globalCompetences={globalCompetences}
        circleValues={circleValues}
      />
    </Fragment>
  )
}
