import { apiService } from "./APIServices"

import axios from "axios"

/* params: {
     startDate, *
     aggregation, *
     showFreeTime, *
     colNumber,
     order,
     search,
     teamId
} */
export const getAllTeams = async (params) => {
  return axios.get(apiService.team, { params })
}

export const getTeam = async (params) => {
  return axios.get(`${apiService.team}/details`, { params })
}
