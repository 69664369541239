import { DataGrid } from "devextreme-react"
import {
  Column,
  Editing,
  LoadPanel,
  Lookup,
  Scrolling
} from "devextreme-react/data-grid"
import {
  CustomRule,
  Form,
  GroupItem,
  Item,
  Label,
  SimpleItem
} from "devextreme-react/form"
import { formatMessage } from "devextreme/localization"
import moment from "moment"
import { Fragment, useEffect, useRef, useState } from "react"
import { Button } from "reactstrap"
import { addLevel, removeLevel, updateLevel } from "../../../../../services"
import AddLevelPopup from "../Components/AddLevelPopup"
import MultiSwitchComponent from "../Components/MultiSwitchComponent"
import RemoveLevelPopup from "../Components/RemoveLevelPopuo"
import { calcExtJobTitle } from "../Utils/CalcExtJobTitle"
import { cleanObject } from "../Utils/CleanObject"
import clone from "../Utils/Clone"
import {
  parseErrors,
  showSuccessToast,
  showToastError
} from "../Utils/ErrorHandling"
import { FormValidator } from "../Utils/FormValidator"
import { formatToIsoUtc } from "../../../../../Utils/formatToIsoUtc"
import isExternal from "../Utils/IsExternal"
import isUserWithExternalPromotion from "../Utils/IsUserWithExternalPromotion"
import { roundSecondDecimal } from "../Utils/RoundSecondDecimal"
import sortByStartDate from "../Utils/SortByStartDate"
import { parseUserLevelDate } from "../Utils/ParseUserLevelDate"
import { parseUserDates } from "../Utils/ParseUserDates"

export default function LevelsForm({
  isSelectingExternalLevel,
  userLevels,
  setUserLevels,
  updateUserAndHandleResponse,
  ...props
}) {
  const [newLevel, setNewLevel] = useState({
    startDate: null,
    cost: null,
    code: null,
    name: null,
    isExternal: null,
    isPromotion: false
  })
  const [showAddLevelPopup, setShowAddLevelPopup] = useState(false)
  const [problemInAdding, setProblemInAdding] = useState({
    description: "",
    solution: null,
    isBlocking: false,
    isProblem: false
  })
  const [showRemoveLevelPopup, setShowRemoveLevelPopup] = useState(false)
  const [problemInRemoving, setProblemInRemoving] = useState({
    description: "",
    solution: null,
    isBlocking: false,
    isProblem: false
  })
  const [errors, setErrors] = useState([])
  const [spinner, setSpinner] = useState(false)
  const [idOfLevelToRemove, setIdOfLevelToRemove] = useState(null)

  const formRef = useRef(null)
  const costColumnRef = useRef(null)
  const dataGridRef = useRef(null)

  const validator = new FormValidator()

  const rule = (fieldName) => (
    <CustomRule
      validationCallback={validator.isValid(fieldName, errors)}
      message={validator.getErrorMessage(fieldName, errors)}
    ></CustomRule>
  )

  const addDisplayPropertyToALevel = (l) => {
    return {
      ...l,
      display: `${l.name} - ${l.code}`
    }
  }

  const getDropDownLevels = props.levels
    .filter((l) => {
      return l.active
    })
    .filter((l) => {
      return newLevel.isExternal ? l.isExternal : !l.isExternal
    })
    .filter((l) => {
      if (l.isExternal) return true
      return userLevels?.find((userLevel) => {
        return userLevel.code === l.code
      })
        ? false
        : true
    })
    .map(addDisplayPropertyToALevel)

  const dataGridLevels = props.levels.map(addDisplayPropertyToALevel)

  const dataGridInternalLevels = dataGridLevels.filter((l) => {
    return !l.isExternal
  })

  const dataGridExternalLevels = dataGridLevels.filter((l) => {
    return !!l.isExternal
  })

  const solveProblemsAndAddLevel = () => {
    problemInAdding.solution(onAddLevel)
  }

  const onAddLevel = () => {
    if (!formRef.current.instance.validate().isValid) {
      return
    }

    let levelToAdd = clone(newLevel)

    levelToAdd.cost /= 8

    levelToAdd.startDate = formatToIsoUtc(levelToAdd.startDate)

    levelToAdd.extJobTitle = null
    levelToAdd.isPromotion = null
    levelToAdd.isExternal = null
    cleanObject(levelToAdd)

    setErrors([])
    setSpinner(true)

    addLevel(props.user._id, levelToAdd)
      .then(({ data }) => {
        data.levels.forEach((level) => {
          level.cost *= 8
        })
        let parsedLevels = []
        data.levels.forEach((level) => {
          parsedLevels.push(parseUserLevelDate(level))
        })
        setUserLevels(sortByStartDate(parsedLevels))
        if (data.currentLevel) {
          props.setUser((prevUser) => ({
            ...prevUser,
            currentLevel: parseUserLevelDate({
              ...data.currentLevel,
              cost: data.currentLevel.cost * 8,
              extJobTitle: calcExtJobTitle(
                data.currentLevel?.code,
                prevUser.competence,
                props.levels
              )
            }),
            __v: data.__v
          }))
        }
        resetFormToStep(0)
        setErrors([])
        setSpinner(false)
        formRef.current.instance.resetValues()
        showSuccessToast(formatMessage("hc-success-add-level"))
      })
      .catch((err) => {
        setSpinner(false)
        if (!err.response) {
          console.error(err)
          return
        }
        const parsedErrors = parseErrors(err.response)

        setErrors(parsedErrors)

        formRef.current.instance.validate()

        const errorsWithoutFields = parsedErrors.filter((e) => !e.field)
        if (errorsWithoutFields) {
          errorsWithoutFields.forEach((e) => {
            showToastError(err.response, e.message)
          })
        } else {
          showToastError(err.response)
        }
      })
  }

  function onRowUpdating(e) {
    let code = e.oldData.code
    if (e.newData?.code) {
      code = e.newData.code
    }
    let referenceLevel = props.levels.find((l) => {
      return l.code === code
    })
    let cost = referenceLevel.cost
    if (referenceLevel.isExternal) {
      if (e.newData?.cost) {
        cost = e.newData.cost
      } else {
        cost = e.oldData.cost
      }
    }
    let startDate = new Date(e.oldData.startDate)
    if (e.newData?.startDate) {
      startDate = new Date(e.newData.startDate)
    }
    let updatedLevel = {
      ...referenceLevel,
      _id: e.oldData._id,
      code: code,
      cost: cost / 8,
      startDate: formatToIsoUtc(startDate)
    }
    updatedLevel.extJobTitle = null
    cleanObject(updatedLevel)

    const isCanceled = async () => {
      try {
        const resp = await updateLevel(
          props.user._id,
          updatedLevel._id,
          updatedLevel
        )
        if (resp) {
          resp.data.levels.forEach((level) => {
            level.cost *= 8
          })
          let parsedUserLevels = []
          resp.data.levels.forEach((level) => {
            parsedUserLevels.push(parseUserLevelDate(level))
          })
          setUserLevels(sortByStartDate(parsedUserLevels))
          let updatedUser = resp.data
          if (resp.data.currentLevel) {
            updatedUser.currentLevel.extJobTitle = calcExtJobTitle(
              resp.data.currentLevel?.code,
              updatedUser.competence,
              props.levels
            )
            updatedUser.currentLevel.cost *= 8
          }
          props.setUser(parseUserDates(updatedUser))
          showSuccessToast(formatMessage("hc-success-edited-level"))
          return false
        } else {
          return true
        }
      } catch (error) {
        if (error.response) {
          showToastError(error)
        } else {
          console.error(error)
        }
        return true
      }
    }
    e.cancel = isCanceled()
  }

  const levelBefore = (levelToCheck) => {
    let found = null
    userLevels.forEach((l) => {
      if (new Date(l.startDate) < new Date(levelToCheck.startDate)) {
        found = l
      }
    })
    return found
  }

  const isLevelAPromotion = (level) => {
    if (new Date(level.startDate) > new Date()) {
      return true
    }
  }

  const isLastExternalPromotion = (level) => {
    if (!isLevelAPromotion(level) || !isExternal(level.code, props.levels)) {
      return false
    }
    return !isUserWithExternalPromotion(
      userLevels.filter((l) => {
        return l.startDate !== level.startDate
      }),
      props.levels
    )
  }

  const solution_disableAndEmptyFields = (callback) => {
    let updatedUser = clone(props.user)
    updatedUser = {
      ...updatedUser,
      contract: null,
      contractType: null,
      skills: null,
      contractEnd: null,
      orderEnd: null,
      xTechAccountable: null,
      supplier: null,
      equivalentBipLevel: null,
      enabled: false
    }
    updateUserAndHandleResponse(updatedUser, (resp) => {
      props.setUser((prev) => ({
        ...prev,
        contract: resp.data.contract,
        contractType: resp.data.contractType,
        skills: resp.data.skills,
        contractEnd: resp.data.contractEnd,
        orderEnd: resp.data.orderEnd,
        xTechAccountable: resp.data.xTechAccountable,
        supplier: resp.data.supplier,
        equivalentBipLevel: resp.data.equivalentBipLevel,
        enabled: resp.data.enabled
      }))
      callback()
    })
  }

  const solution_emptyFields = (callback) => {
    let updatedUser = clone(props.user)
    updatedUser = {
      ...updatedUser,
      contract: null,
      contractType: null,
      skills: null,
      contractEnd: null,
      orderEnd: null,
      xTechAccountable: null,
      supplier: null,
      equivalentBipLevel: null
    }
    updateUserAndHandleResponse(updatedUser, (resp) => {
      props.setUser((prev) => ({
        ...prev,
        contract: resp.data.contract,
        contractType: resp.data.contractType,
        skills: resp.data.skills,
        contractEnd: resp.data.contractEnd,
        orderEnd: resp.data.orderEnd,
        xTechAccountable: resp.data.xTechAccountable,
        supplier: resp.data.supplier,
        equivalentBipLevel: resp.data.equivalentBipLevel
      }))
      callback()
    })
  }

  const solution_disable = (callback) => {
    let updatedUser = clone(props.user)
    updatedUser.enabled = false

    updateUserAndHandleResponse(updatedUser, (resp) => {
      props.setUser((prev) => ({
        ...prev,
        enabled: resp.data.enabled
      }))
      callback()
    })
  }

  const solution_do_nothing = (callback) => {
    callback()
  }

  const checkProblemInRemoving = (levelToRemove) => {
    let problem = {
      description: formatMessage("hc-are-you-sure-to-delete-this-level"),
      solution: solution_do_nothing,
      isBlocking: false,
      isProblem: true
    }
    //se è una promozione ed è l'ultima promozione esterna e l'utente è interno
    if (
      isLastExternalPromotion(levelToRemove) &&
      !isExternal(props.user.currentLevel?.code, props.levels)
    ) {
      //    se l'utente è abilitato -> l'utente va disabilitato e i campi per esterni svuotati
      if (props.user.enabled) {
        problem = {
          description:
            formatMessage("hc-before-deleting-this-level") +
            ", " +
            formatMessage("hc-empty-fields-and-disable"),
          solution: solution_disableAndEmptyFields,
          isBlocking: false,
          isProblem: true
        }
      }
      //    se l'utente non è abilitato -> i campi per gli esterni vanno svuotati
      else {
        problem = {
          description:
            formatMessage("hc-before-deleting-this-level") +
            ", " +
            formatMessage("hc-empty-fields"),
          solution: solution_emptyFields,
          isBlocking: false,
          isProblem: true
        }
      }
    }

    //se è un livello corrente e ci sono livelli passati
    if (isCurrent(levelToRemove) && levelBefore(levelToRemove)) {
      problem = {
        description:
          formatMessage("hc-before-deleting-this-level") +
          ", " +
          formatMessage("hc-disable-user"),
        solution: solution_disable,
        isBlocking: false,
        isProblem: true
      }
      //  se il livello da cancellare è interno e l'utente è abilitato e senza promozioni esterne
      //   e il livello che sostituirà è esterno: -> l'utente va disabilitato e salvato
      if (
        !isExternal(levelToRemove.code, props.levels) &&
        props.user.enabled &&
        !isUserWithExternalPromotion(userLevels, props.levels) &&
        isExternal(levelBefore(levelToRemove).code, props.levels)
      ) {
        problem = {
          description:
            formatMessage("hc-before-deleting-this-level") +
            ", " +
            formatMessage("hc-disable-user"),
          solution: solution_disable,
          isBlocking: false,
          isProblem: true
        }
      }
      //  se il livello da cancellare è esterno
      if (isExternal(levelToRemove.code, props.levels)) {
        //    se l'utente è abilitato e senza promozioni esterne e il livello che sostituirà è interno:
        //      -> l'utente va disabilitato e i campi solo per esterni svuotati
        if (
          props.user.enabled &&
          !isUserWithExternalPromotion(userLevels, props.levels) &&
          !isExternal(levelBefore(levelToRemove).code, props.levels)
        ) {
          problem = {
            description:
              formatMessage("hc-before-deleting-this-level") +
              ", " +
              formatMessage("hc-empty-fields-and-disable"),
            solution: solution_disableAndEmptyFields,
            isBlocking: false,
            isProblem: true
          }
        }
        //    se l'utente è disabilitato e il livello che sostituirà è interno
        if (
          !props.user.enabled &&
          !isExternal(levelBefore(levelToRemove).code, props.levels)
        ) {
          if (!isUserWithExternalPromotion(userLevels, props.levels)) {
            //se non ha promozioni esterne future:
            //      -> vengono svuotati i campi e basta
            problem = {
              description:
                formatMessage("hc-before-deleting-this-level") +
                ", " +
                formatMessage("hc-empty-fields"),
              solution: solution_emptyFields,
              isBlocking: false,
              isProblem: true
            }
          } else {
            let problem = {
              description: formatMessage("hc-are-you-sure-to-delete-this-level"),
              solution: solution_do_nothing,
              isBlocking: false,
              isProblem: true
            }
          }
        }
      }
    }

    if (
      !props.user.enabled &&
      isUserWithExternalPromotion(userLevels, props.levels) &&
      levelBefore(levelToRemove)
    ) {
      problem = {
        description: formatMessage("hc-are-you-sure-to-delete-this-level"),
        solution: solution_do_nothing,
        isBlocking: false,
        isProblem: true
      }
    }

    //se stiamo eliminando il livello corrente ma non ci sono livelli precedenti e l'utente è abilitato
    if (
      isCurrent(levelToRemove) &&
      !levelBefore(levelToRemove) &&
      props.user.enabled
    ) {
      if (isExternal(levelToRemove.code, props.levels)) {
        // se l'utente è esterno
        // se l'utente ha promozioni future esterne -> disabilitiamo (perchè il backend vuole così)
        if (isUserWithExternalPromotion(userLevels, props.levels)) {
          problem = {
            description:
              formatMessage("hc-before-deleting-this-level") +
              ", " +
              formatMessage("hc-disable-user"),
            solution: solution_disable,
            isBlocking: false,
            isProblem: true
          }
        }
        // se l'utente no ha promozioni future esterne -> disabilita e svuota
        else {
          problem = {
            description:
              formatMessage("hc-before-deleting-this-level") +
              ", " +
              formatMessage("hc-empty-fields-and-disable"),
            solution: solution_disableAndEmptyFields,
            isBlocking: false,
            isProblem: true
          }
        }
      } else {
        //se l'utente è interno -> disabilitiamo e basta
        problem = {
          description:
            formatMessage("hc-before-deleting-this-level") +
            ", " +
            formatMessage("hc-disable-user"),
          solution: solution_disable,
          isBlocking: false,
          isProblem: true
        }
      }
    }

    //in tutti gli altri casi non ci sono problemi

    return problem
  }

  const solveProblemsAndRemoveLevel = () => {
    problemInRemoving.solution(removeLevelAndHandleResponse)
  }

  const removeLevelAndHandleResponse = async (lvlId = idOfLevelToRemove) => {
    try {
      const resp = await removeLevel(props.user._id, lvlId)
      showSuccessToast(formatMessage("hc-success-deleted-level"))
      resp.data.levels.forEach((level) => {
        level.cost *= 8
      })
      let parsedLevels = []
      resp.data.levels.forEach((level) => {
        parsedLevels.push(parseUserLevelDate(level))
      })
      setUserLevels(sortByStartDate(parsedLevels))
      let updatedUser = resp.data
      if (resp.data.currentLevel) {
        updatedUser.currentLevel.extJobTitle = calcExtJobTitle(
          resp.data.currentLevel?.code,
          updatedUser.competence,
          props.levels
        )
        updatedUser.currentLevel.cost *= 8
      }
      let parsedAndUpdatedUser = parseUserDates(updatedUser)
      props.setUser(parsedAndUpdatedUser)
    } catch (error) {
      showToastError(error.response)
      console.error(error)
    }
  }

  async function onRowRemoving(e) {
    e.cancel = true
    setIdOfLevelToRemove(e.data._id)

    await new Promise((r) => setTimeout(r))

    const problem = checkProblemInRemoving(e.data)
    setProblemInRemoving(problem)

    if (problem.isProblem) {
      setShowRemoveLevelPopup(true)
    } else {
      removeLevelAndHandleResponse(e.data._id)
    }
  }

  const onRowEditorPreparing = (e) => {
    const isExternalLevel = isExternal(e.row.data.code, props.levels)
    if (e.dataField === "cost") {
      e.editorOptions.disabled = !isExternalLevel
    }
    if (e.dataField === "code") {
      if (isExternalLevel) {
        e.editorOptions.dataSource = dataGridExternalLevels
      } else {
        e.editorOptions.dataSource = dataGridInternalLevels.filter((l) => {
          return userLevels.find((userLevel) => userLevel.code === l.code)
            ? false
            : true
        })
      }
    }
  }

  function setCellValue(newData, value) {
    let column = this
    column.defaultSetCellValue(newData, value)
  }

  const renderNameCodeCell = (cellInfo) => {
    return (
      <>
        <span>
          {cellInfo.data.name} - {cellInfo.data.code}
        </span>
        {isCurrent(cellInfo.data) ? (
          <span className="ml-2 p-1 border border-success text-success">
            {formatMessage("hc-current-level-label")}
          </span>
        ) : (
          ""
        )}
        {isPromotion(cellInfo.data) ? (
          <span className="ml-2 p-1 border border-warning text-warning">
            {formatMessage("hc-promotion-level-label")}
          </span>
        ) : (
          ""
        )}
      </>
    )
  }

  const datesAreOnSameDay = (first, second) => {
    let firstDate = new Date(first)
    let secondDate = new Date(second)
    return (
      firstDate.getFullYear() === secondDate.getFullYear() &&
      firstDate.getMonth() === secondDate.getMonth() &&
      firstDate.getDate() === secondDate.getDate()
    )
  }

  const isCurrent = (level) => {
    if (
      level.code === props.user.currentLevel?.code &&
      datesAreOnSameDay(level.startDate, props.user.currentLevel?.startDate)
    ) {
      return true
    }
    return false
  }

  const isPromotion = (level) => {
    if (isCurrent(level)) return false
    if (new Date() < new Date(level.startDate)) {
      return true
    }
    return false
  }

  let [formStatus, setFormStatus] = useState({ step: 0 })

  const nextStep = () => {
    setFormStatus((prev) => ({
      ...prev,
      step: prev.step + 1
    }))
  }

  const resetFormToStep = (stepNumber) => {
    let newLevelToSet = {}
    if (stepNumber <= 3) {
      newLevelToSet.cost = props.getLevelByCode(newLevel?.code)?.cost || null
    }
    if (stepNumber <= 2) {
      newLevelToSet.code = null
      newLevelToSet.name = null
      newLevelToSet.cost = null
    }
    if (stepNumber <= 1) {
      newLevelToSet.startDate = null
      newLevelToSet.isPromotion = null
    }
    if (stepNumber <= 0) {
      newLevelToSet.isExternal = null
    }
    setNewLevel((prev) => ({
      ...prev,
      ...newLevelToSet
    }))
    setFormStatus((prev) => ({
      ...prev,
      step: stepNumber
    }))
  }

  const setNoProblemInAdding = () => {
    setProblemInAdding({
      description: "",
      solution: null,
      isBlocking: false,
      isProblem: false
    })
  }

  const checkProblemInAdding = () => {
    //form is not complete:
    if (newLevel.cost === null || newLevel.cost === undefined) {
      setNoProblemInAdding()
      return
    }

    //the start date is already taken by another level -> not possible
    for (const userLevel of userLevels) {
      let startDate = moment(userLevel.startDate)
      let newLevelStartDate = moment(newLevel.startDate)
      if (startDate.format("DD-MM-YYY") === newLevelStartDate.format("DD-MM-YYY")) {
        setProblemInAdding({
          description:
            formatMessage("hc-error-level-in-same-day") +
            ": " +
            userLevel.code +
            " - " +
            userLevel.name +
            " " +
            startDate.format("DD-MM-YYYY"),
          solution: "",
          isBlocking: true,
          isProblem: true
        })
        return
      }
    }

    //external and another level with the same code and same cost already exists -> not possible
    if (newLevel.isExternal) {
      for (const userLevel of userLevels) {
        if (
          userLevel.code === newLevel.code &&
          roundSecondDecimal(userLevel.cost) === roundSecondDecimal(newLevel.cost)
        ) {
          setProblemInAdding({
            description:
              formatMessage("hc-error-same-code-cost") +
              ": " +
              userLevel.code +
              " - " +
              userLevel.name +
              " " +
              formatMessage("hc-with-cost") +
              " €" +
              roundSecondDecimal(userLevel.cost),
            solution: "",
            isBlocking: true,
            isProblem: true
          })
          return
        }
      }
    }

    if (
      new Date(newLevel.startDate) < new Date(props.user.currentLevel?.startDate)
    ) {
      setNoProblemInAdding()
      return
    }

    //if user is disabled and external
    if (
      !props.user.enabled &&
      isExternal(props.user.currentLevel?.code, props.levels) &&
      !isExternal(newLevel.code, props.levels)
    ) {
      //change his current level to internal -> Tutti i campi solo per esterni vengono svuotati
      if (!newLevel.isPromotion) {
        if (isUserWithExternalPromotion(userLevels, props.levels)) {
          setNoProblemInAdding()
          return
        } else {
          setProblemInAdding({
            description:
              formatMessage("hc-before-adding-this-level") +
              ", " +
              formatMessage("hc-empty-fields-and-disable"),
            solution: solution_emptyFields,
            isBlocking: false,
            isProblem: true
          })
          return
        }
      }
      //set an internal promotion
      else {
        setProblemInAdding({
          description: formatMessage("hc-warning-job-will-empty-fields"),
          solution: solution_do_nothing,
          isBlocking: false,
          isProblem: true
        })
        return
      }
    }

    //if user is disabled in any other case -> no problems
    if (!props.user.enabled) {
      setNoProblemInAdding()
      return
    }

    //if user is enabled
    //  if level is promotion
    if (newLevel.isPromotion) {
      if (!isExternal(props.user.currentLevel?.code, props.levels)) {
        if (
          newLevel.isExternal &&
          !isUserWithExternalPromotion(userLevels, props.levels)
        ) {
          //    interno -> esterno: Utente viene disabilitato.
          //                        Per riabilitarlo vanno aggiunti i campi della sezione "External Users".
          setProblemInAdding({
            description:
              formatMessage("hc-before-adding-this-level") +
              ", " +
              formatMessage("hc-disable-user"),
            solution: solution_disable,
            isBlocking: false,
            isProblem: true
          })
          return
        } else {
          //    interno -> interno: No problems
          setNoProblemInAdding()
          return
        }
      } else {
        if (!isExternal(newLevel.code, props.levels)) {
          //    esterno -> interno: Avviso che in futuro il job svuoterà i campi
          setProblemInAdding({
            description: formatMessage("hc-warning-job-will-empty-fields"),
            solution: solution_do_nothing,
            isBlocking: false,
            isProblem: true
          })
          return
        }
        //    esterno -> esterno: No problems
        setNoProblemInAdding()
        return
      }
    }

    //if user is enabled
    //  if level is current
    if (isExternal(props.user.currentLevel?.code, props.levels)) {
      if (newLevel.isExternal) {
        //    esterno -> esterno: No problems
        setNoProblemInAdding()
        return
      } else {
        //    esterno -> interno: Se ci sono promozioni future esterne i campi non vengono svuotati e l'utente riamne abilitato.
        if (isUserWithExternalPromotion(userLevels, props.levels)) {
          setNoProblemInAdding()
          return
        } else {
          //    esterno -> interno: Se NON ci sono promozioni future esterne i campi della
          //                        sezione "External Users" vengono svuotati.
          setProblemInAdding({
            description:
              formatMessage("hc-before-adding-this-level") +
              ", " +
              formatMessage("hc-empty-fields-and-disable"),
            solution: solution_disableAndEmptyFields,
            isBlocking: false,
            isProblem: true
          })
          return
        }
      }
    } else {
      if (
        newLevel.isExternal &&
        !isUserWithExternalPromotion(userLevels, props.levels)
      ) {
        //    interno -> esterno: Utente viene disabilitato.
        //                        Per riabilitarlo vanno aggiunti i campi della sezione "External Users".
        setProblemInAdding({
          description:
            formatMessage("hc-before-adding-this-level") +
            ", " +
            formatMessage("hc-disable-user"),
          solution: solution_disable,
          isBlocking: false,
          isProblem: true
        })
        return
      } else {
        //    interno -> interno: No problems.
        setNoProblemInAdding()
        return
      }
    }
  }

  useEffect(checkProblemInAdding, [
    newLevel,
    formStatus,
    userLevels,
    props.user.enabled,
    props.levels,
    props.user.currentLevel?.code,
    props.user.currentLevel?.startDate
  ])

  const getNewLevelIsExternalIdValue = () => {
    if (newLevel.isExternal === null || newLevel.isExternal === undefined) {
      return null
    }
    return newLevel.isExternal ? 1 : 2
  }

  return (
    <Fragment>
      <h6 className="pt-4">{formatMessage("hc-new-level")}</h6>
      <p className="text-danger">
        {problemInAdding.isProblem && problemInAdding.isBlocking ? (
          <>
            <span className="text-danger ml-2 lnr lnr-warning"></span>{" "}
            {problemInAdding.description}
          </>
        ) : (
          ""
        )}
      </p>
      <div className="table-responsive pb-3 d-flex flex-row">
        <div className="pr-5 dx-first-row dx-first-col dx-last-col dx-last-row dx-field-item dx-col-0 dx-field-item-optional dx-flex-layout dx-label-v-align">
          <label
            className="dx-field-item-label dx-field-item-label-location-top"
            htmlFor="multi-switch-component"
          >
            <span className="dx-field-item-label-content">
              <span className="dx-field-item-label-text invisible">label</span>
            </span>
          </label>
          <div
            id="multi-switch-component"
            className="dx-field-item-content dx-field-item-content-location-bottom"
          >
            <MultiSwitchComponent
              items={[
                {
                  id: 1,
                  text: formatMessage("hc-External"),
                  activeClass: "btn-primary",
                  nonActiveClass: "btn-outline-primary",
                  customStyle: { padding: "0.92rem 1rem" }
                },
                {
                  id: 2,
                  text: formatMessage("hc-Internal"),
                  activeClass: "btn-primary",
                  nonActiveClass: "btn-outline-primary",
                  customStyle: { padding: "0.92rem 1rem" }
                }
              ]}
              onValueChanged={(id) => {
                if (formStatus.step !== 0) {
                  resetFormToStep(1)
                } else {
                  nextStep()
                }
                setErrors([])
                setNewLevel((prevLevel) => ({
                  ...prevLevel,
                  isExternal: id === 1
                }))
              }}
              value={getNewLevelIsExternalIdValue()}
              disabled={false}
              size={"lg"}
            />
          </div>
        </div>
        <Form
          ref={formRef}
          labelLocation="top"
          formData={newLevel}
          validationGroup="levelsGroup"
        >
          <GroupItem colCount={20}>
            <GroupItem colSpan={4}>
              <SimpleItem
                dataField="startDate"
                editorType="dxDateBox"
                editorOptions={{
                  readOnly: formStatus.step < 1,
                  onValueChanged: (e) => {
                    setFormStatus((prev) => ({
                      ...prev
                    }))
                    setNewLevel((prev) => ({
                      ...prev,
                      isPromotion: new Date(e.value) > new Date()
                    }))
                    setErrors([])
                    if (e.value !== null) {
                      if (formStatus.step !== 1) {
                        resetFormToStep(2)
                      } else {
                        nextStep()
                      }
                    }
                  }
                }}
              >
                <Label
                  showColon={false}
                  text={formatMessage("hc-field-start-date")}
                />
                {rule("startDate")}
              </SimpleItem>
            </GroupItem>
            <GroupItem colSpan={8}>
              <SimpleItem
                dataField="code"
                editorType="dxLookup"
                editorOptions={{
                  dataSource: getDropDownLevels,
                  readOnly: formStatus.step < 2,
                  onValueChanged: (e) => {
                    setErrors([])
                    const level = props.getLevelByCode(e.value)
                    if (!level) {
                      return
                    }
                    setNewLevel((prevLevel) => ({
                      ...prevLevel,
                      code: e.value,
                      name: level.name,
                      cost: level.cost
                    }))
                    if (e.value !== null) {
                      if (formStatus.step !== 2) {
                        resetFormToStep(3)
                      } else {
                        nextStep()
                      }
                    }
                  },
                  displayExpr: "display",
                  valueExpr: "code",
                  placeholder: formatMessage("hc-placeholder-level"),
                  searchEnabled: true,
                  showCancelButton: false,
                  dropDownOptions: {
                    title: formatMessage("hc-field-levels"),
                    closeOnOutsideClick: true
                  }
                }}
              >
                <Label
                  showColon={false}
                  text={formatMessage("hc-field-name-code")}
                />
                {rule("code")}
                {rule("currentLevel")}
              </SimpleItem>
            </GroupItem>
            <GroupItem colSpan={4}>
              <SimpleItem
                dataField="cost"
                editorType="dxNumberBox"
                editorOptions={{
                  readOnly: formStatus.step < 3 || !newLevel.isExternal,
                  format: { currency: "EUR", type: "currency", precision: 3 },
                  onValueChanged: (e) => {
                    setErrors([])
                    setNewLevel((prevLevel) => ({
                      ...prevLevel,
                      cost: e.value
                    }))
                    if (e.value !== null) {
                      if (formStatus.step !== 3) {
                        resetFormToStep(4)
                      } else {
                        nextStep()
                      }
                    }
                  }
                }}
              >
                <Label showColon={false} text={formatMessage("hc-field-cost")} />
                {rule("cost")}
              </SimpleItem>
            </GroupItem>
            <Item>
              <Button
                style={{ marginTop: "42px", padding: "15px" }}
                color="primary"
                onClick={() => {
                  if (!problemInAdding.isProblem) {
                    onAddLevel()
                    setShowAddLevelPopup(false)
                  } else {
                    setShowAddLevelPopup(true)
                  }
                }}
                disabled={
                  newLevel.cost === null ||
                  newLevel.cost === undefined ||
                  problemInAdding.isBlocking
                }
              >
                <span className="dx-button-text text-nowrap text-truncate">
                  {spinner
                    ? formatMessage("hc-button-saving")
                    : formatMessage("hc-add") + " "}
                  {newLevel.isExternal && newLevel.isPromotion
                    ? formatMessage("hc-external-promotion")
                    : ""}
                  {!newLevel.isExternal && newLevel.isPromotion
                    ? formatMessage("hc-internal-promotion")
                    : ""}
                  {newLevel.isExternal && !newLevel.isPromotion
                    ? formatMessage("hc-external-level")
                    : ""}
                  {!newLevel.isExternal && !newLevel.isPromotion
                    ? formatMessage("hc-internal-level")
                    : ""}
                </span>
                {problemInAdding.isProblem && !problemInAdding.isBlocking ? (
                  <span className="text-danger ml-2 lnr lnr-warning"></span>
                ) : (
                  ""
                )}
              </Button>
            </Item>
          </GroupItem>
        </Form>
      </div>

      <AddLevelPopup
        showAddLevelPopup={showAddLevelPopup}
        setShowAddLevelPopup={setShowAddLevelPopup}
        solveProblemsAndAddLevel={solveProblemsAndAddLevel}
        problems={problemInAdding}
      />

      <RemoveLevelPopup
        showRemoveLevelPopup={showRemoveLevelPopup}
        setShowRemoveLevelPopup={setShowRemoveLevelPopup}
        solveProblemsAndRemoveLevel={solveProblemsAndRemoveLevel}
        problems={problemInRemoving}
      />

      <div className="pr-4 pt-5" style={{ minWidth: "450" }}>
        <h6 className="pt-4">{formatMessage("hc-header-user-levels-history")}</h6>
        <DataGrid
          width={"auto"}
          dataSource={userLevels}
          showRowLines
          showColumnLines={false}
          showBorders={false}
          allowColumnResizing={true}
          allowColumnReordering={true}
          onEditorPreparing={onRowEditorPreparing}
          onRowUpdating={onRowUpdating}
          onRowRemoving={onRowRemoving}
          ref={dataGridRef}
          colSpan={10}
        >
          <Editing
            mode="row"
            allowDeleting={true}
            allowUpdating={true}
            useIcons={true}
            texts={{ confirmDeleteMessage: "" }}
          />

          <Column
            colCount={4}
            dataField="code"
            caption={formatMessage("hc-field-name-code")}
            setCellValue={setCellValue}
            cellRender={renderNameCodeCell}
          >
            <Lookup
              dataSource={dataGridLevels}
              valueExpr="code"
              displayExpr="display"
            />
          </Column>

          <Column
            colCount={3}
            dataField="startDate"
            caption={formatMessage("hc-field-start-date")}
            dataType="date"
            format="dd-MM-yyyy"
          />

          <Column
            colCount={1}
            dataField="cost"
            caption={formatMessage("hc-field-cost")}
            ref={costColumnRef}
            dataType="number"
            customizeText={(cellInfo) => {
              return "€" + (cellInfo.value ? roundSecondDecimal(cellInfo.value) : 0)
            }}
          />

          <Column
            allowReordering={false}
            allowResizing={false}
            colCount={1}
            type="buttons"
          >
            <Button name="edit" icon="edit" />
            <Button name="delete" icon="delete" />
          </Column>
          <Scrolling mode="virtual" />
          <LoadPanel enabled={spinner} />
        </DataGrid>
      </div>
    </Fragment>
  )
}
