import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Paper from "@material-ui/core/Paper"
import Slide from "@material-ui/core/Slide"
import React, { useEffect } from "react"
import Draggable from "react-draggable"
import { Alert } from "reactstrap"
import { useState } from "react"
import { formatMessage } from "devextreme/localization"

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function AlertDialog({
  handleSend,
  open,
  closeAlert,
  alertText,
  error,
  isAlert
}) {
  const [disabled, setDisabled] = useState(false)

  return (
    <div>
      <Dialog
        open={open}
        onClose={closeAlert}
        onExited={() => setDisabled(false)}
        TransitionComponent={Transition}
        aria-labelledby="draggable-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperComponent={PaperComponent}
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {(isAlert || alertText?.isAlert) && (
            <Alert
              color="danger"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              {formatMessage("ATTENTION")}
            </Alert>
          )}
          {alertText.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            color={error ? "error" : "initial"}
          >
            {alertText.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAlert} color="primary">
            {formatMessage("Cancel")}
          </Button>
          {alertText.action ? (
            <Button
              disabled={disabled}
              onClick={() => {
                setDisabled(true)
                handleSend()
              }}
              color="primary"
            >
              {alertText.action}
            </Button>
          ) : (
            <div />
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}
