import cx from "classnames"
import React, { Fragment } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import AppMain from "../../Layout/AppMain"
import { setAllJobs } from "../../reducers/Jobs"
import { getJobsThumb } from "../../services/JobService"
import { initGlobalize } from "../../Common/localization/localization"

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      closedSmallerSidebar: false,
      loading: false,
      locale: props.locale
    }
    initGlobalize(this.state.locale)
  }

  componentDidMount() {
    getJobsThumb().then(({ data }) => {
      this.props.setAllJobs({
        jobs: data,
        wbsTypes: this.props.config.wbsTypes,
        selectedPeriod: this.props.config.selectedPeriod
      })
    })
  }

  render() {
    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu
    } = this.props

    return (
      <Fragment>
        <div
          className={cx(
            "app-container app-theme-" + colorScheme,
            { "fixed-header": enableFixedHeader },
            { "fixed-sidebar": enableFixedSidebar },
            { "fixed-footer": enableFixedFooter },
            { "closed-sidebar": enableClosedSidebar },
            { "closed-sidebar-mobile": closedSmallerSidebar },
            { "sidebar-mobile-open": enableMobileMenu }
          )}
        >
          <AppMain />
        </div>
      </Fragment>
    )
  }
}

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
  user: state.Auth,
  config: state.Auth.config,
  locale: state.Locale,
  setSelectedPeriod: state.Period.selectedPeriod
})

const mapDispatchToProps = (dispatch) => ({
  setAllJobs: (jobs) => dispatch(setAllJobs(jobs))
})

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Index))
