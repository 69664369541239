import { apiService } from "./APIServices"

import axios from "axios"

/* params: {
  "startDate": "2023-03-20T09:06:33.598Z",
  "endDate": "2023-03-20T09:06:33.598Z",
  "jobId": "string",
  "allocationPercentage": 0
} */
export const createAllocation = async (body) => {
  return axios.post(`${apiService.allocation}`, body)
}

export const updateAllocation = async (query, body) => {
  return axios.put(`${apiService.allocation}/single-update`, body, { params: query })
}

export const updateMultipleAllocation = async (userId, body) => {
  return axios.put(`${apiService.allocation}/multiple-update`, body, {
    params: { userId }
  })
}

export const deleteAllocation = async (params) => {
  return axios.delete(`${apiService.allocation}`, { params })
}
