import {
  faLock,
  faPaperPlane,
  faPlus,
  faUserEdit
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Avatar from "@material-ui/core/Avatar"
import Chip from "@material-ui/core/Chip"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import { DataGrid } from "devextreme-react"
import { Button as DevextremeButton } from "devextreme-react/button"
import { Template } from "devextreme-react/core/template"
import {
  Button,
  Column,
  Editing,
  Export,
  Grouping,
  GroupItem,
  GroupPanel,
  Item,
  Lookup,
  MasterDetail,
  Paging,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Summary,
  Toolbar,
  ColumnChooser
} from "devextreme-react/data-grid"
import { CustomRule } from "devextreme-react/validator"
import { formatMessage } from "devextreme/localization"
import moment from "moment"
import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { useSelector } from "react-redux"
import { find, first, sortBy } from "underscore"
import localization from "../../../../config/localization.json"
import { useAppAbility } from "../../../../Context/ability"
import { round } from "../../../../Utils/utils"
import { MasterTemplate } from "../../Components/masterTemplate"
import { buildConfigForAnyReviewBy, sendAnyReview } from "../../Utils/tsFlowConfig"
import { ToolbarFilter } from "../filterToolbar"
import { InternalReviewCustomStore } from "./datasource"
import "./../style/dx.review.table.custom.css"
import { getTeamsChatUrl } from "../../Utils/utils.js"

let tsId = null
let exported = false

const theme = createMuiTheme({
  palette: {
    primary: { main: "#0c2340" },
    secondary: { main: "rgba(12,35,64,0.7)" }
  }
})

export const JobTypePercentage = ({ hours, config }) => {
  const { wbsTypes } = config
  // const typesNumber = hours.length
  let sortedTypes = sortBy(hours, (h) => -Number(h.percentage))
  // let excidingTypes = []
  // const displayWidth = window.innerWidth

  /*   const cutTypes = (index) => {
    let percent = 0
    let tooltip = ""
    excidingTypes = sortedTypes.slice(index, sortedTypes.length)
    excidingTypes.forEach((et) => {
      percent += et.percentage
      tooltip = tooltip.concat(
        find(wbsTypes, (jt) => String(jt.code) === String(et.type))?.name,
        " - ",
        et.percentage + "% \n"
      )
    })
    sortedTypes = sortedTypes.slice(0, index)
    percent > 0 && sortedTypes.push({ percentage: percent, tooltip: tooltip })
  }

  if (displayWidth < 1400 && typesNumber > 2) {
    cutTypes(2)
  } else if (displayWidth < 1600 && typesNumber >= 2) {
    cutTypes(3)
  } else if (displayWidth < 1800 && typesNumber > 4) {
    cutTypes(4)
  } else if (displayWidth < 2000 && typesNumber > 5) {
    cutTypes(5)
  } else if (typesNumber > 6) {
    cutTypes(6)
  }
 */
  return (
    <ThemeProvider theme={theme}>
      {sortedTypes.map((i, index) => {
        if (i.percentage > 0) {
          const percentage = i.percentage
            .toLocaleString(localization.lang)
            .substring(0, 4)
          const wbsType = find(wbsTypes, (jt) => String(jt.code) === String(i.type))

          const jobTypeName = wbsType?.name
          const jobTypeAcronym = wbsType?.acronym

          return (
            <Chip
              key={index}
              title={i.tooltip ? i.tooltip : jobTypeName}
              size="small"
              avatar={<Avatar style={{ width: 40 }}>{percentage}%</Avatar>}
              style={{ marginLeft: 30 }}
              label={jobTypeAcronym || "Others"}
              color="secondary"
              variant="outlined"
            />
          )
        }
      })}
    </ThemeProvider>
  )
}

const SendTimesheet = React.memo(
  ({
    allRows,
    tempH,
    unitIsHours,
    setSendError,
    setMessageAlert,
    setTitleAlert,
    setIsAlert,
    setTsId,
    setStatusToSend,
    setOpenAlert,
    invalidTimesheets,
    currentPeriod
  }) => {
    return (
      <div
        className="font-icon-wrapper-preter"
        data-toggle="tooltip"
        title={formatMessage("send-review-command")}
        style={{ marginLeft: 20 }}
        onClick={() => {
          const totalHours =
            round(
              allRows.length &&
                allRows.reduce(
                  (accumulator, currentValue) =>
                    (parseFloat(accumulator) || 0) +
                    (parseFloat(currentValue.timesheetRow.currentHours) || 0),
                  0
                )
            ) || 0

          const { error, alertText, sendText, isAlert } = sendAnyReview({
            tempHours: tempH,
            review: first(allRows).statusDetails.code,
            unitIsHours,
            totalHours,
            workingDays: first(allRows).workingDays,
            containInvalidJobs:
              invalidTimesheets?.find((t) => t === first(allRows)._id) != null,
            selectedPeriod: currentPeriod
          })

          setSendError(error)
          setMessageAlert(alertText)
          setTitleAlert(sendText)
          setIsAlert(isAlert)
          setTsId(first(allRows)._id)
          setStatusToSend(first(allRows).statusDetails.code)
          setOpenAlert(true)
        }}
      >
        <FontAwesomeIcon icon={faPaperPlane} size="1x" />
      </div>
    )
  }
)

const AddRow = React.memo(({ addRow }) => {
  return (
    <div
      className="font-icon-wrapper-preter"
      data-toggle="tooltip"
      title={formatMessage("dxDataGrid-editingAddRow")}
      onClick={addRow}
    >
      <FontAwesomeIcon icon={faPlus} size="1x" />
    </div>
  )
})

const ReopenTimesheet = React.memo(
  ({
    setOpenAlert,
    setTitleAlert,
    setMessageAlert,
    setStatusToSend,
    tsId,
    setTsId,
    setIsAlert,
    setSendError
  }) => {
    return (
      <div
        className="font-icon-wrapper-preter"
        data-toggle="tooltip"
        title={formatMessage("reopen-ts-hint")}
        style={{ marginLeft: 20 }}
        onClick={() => {
          setTsId(tsId)
          setStatusToSend("ppa-open")
          setTitleAlert(formatMessage("warning-ts-ppa-title"))
          setMessageAlert(formatMessage("warning-ts-ppa-message"))
          setOpenAlert(true)
          setIsAlert(false)
          setSendError(false)
        }}
      >
        <FontAwesomeIcon icon={faLock} size="1x" />
      </div>
    )
  }
)

const getRowEditDetails = ({ currentStatus, timesheetRow }) => {
  switch (currentStatus) {
    case "preReview":
      return false
    case "firstReview":
      return false
    case "thirdReview":
      if (timesheetRow.firstReview?.notes)
        return "FIRST REVIEW: " + timesheetRow.firstReview.notes
      break
    default:
      return ""
  }
}

export const ReviewTable = React.memo(
  ({
    currentPeriod,
    jobs,
    unitIsHours,
    setTsId,
    setOpenAlert,
    setDataToUpdate,
    setStatusToSend,
    setMessageAlert,
    setAddRow,
    setSendError,
    setTitleAlert,
    setIsAlert,
    invalidJobs,
    invalidTimesheets,
    setInvalidTimesheets,
    forceUpdate,
    setDs,
    dgRef,
    sendBulkError,
    setSendBulkError,
    assignedTs,
    setAssignedTs,
    invalidWbsCodes,
    setDataLoaded
  }) => {
    const user = useSelector((state) => state.Auth.user)
    const config = useSelector((state) => state.Auth.config)
    const [toReviewFilter, setToReviewFilter] = useState(true)
    const [statusFilter, setStatusFilter] = useState(undefined)
    const { can } = useAppAbility()
    const canManageReview = can("manage", "Review")
    const canManagePPA = can("manage", "PPA")
    const canManageAll = can("manage", "all")
    const canManageCompleted = can("manage", "Completed")
    const [dataSource, setDataSource] = useState(undefined)

    useEffect(() => {
      setDs(dgRef)
    }, [setDs])

    useEffect(() => {
      setDataSource({
        reshapeOnPush: true,
        store: InternalReviewCustomStore({
          toReviewFilter: toReviewFilter,
          statusFilter: statusFilter,
          currentPeriod: currentPeriod,
          jobs: jobs,
          config: config,
          setDataLoaded: setDataLoaded
        })
      })
    }, [toReviewFilter, statusFilter, currentPeriod, config])

    const allowManageReview = (status) => {
      if (status && status.row) {
        return (
          (!["closed", "onHold"].includes(status?.row?.data?.currentStatus) &&
            (canManageReview || canManageAll)) ||
          (status?.row?.data?.currentStatus === "ppa" && canManagePPA) ||
          (status?.row?.data?.currentStatus === "completed" && canManageCompleted)
        )
      }
      return canManageReview || canManagePPA || canManageAll
    }

    const rowStatusSanitized = (row) => {
      return buildConfigForAnyReviewBy({
        bipRole: user.bipRole,
        userMail: user.email,
        review: row.currentStatus,
        reviewOwner: row.statusDetails.owner,
        locked: row.statusDetails.locked,
        can: can
      })
    }

    const renderGroupButtons = (e) => {
      let allRows = e.data.items || e.data.collapsedItems
      const timesheetDetails = first(allRows)
      const addRow = () => {
        tsId = timesheetDetails._id
        setDataToUpdate({ allRows, jobs })
        setAddRow(true)
      }

      let tempH = (allRows.length && round(timesheetDetails?.tempHours)) || 0

      const status = (allRows.length && rowStatusSanitized(timesheetDetails)) || {}

      return (
        <div
          className="d-flex justify-content-center"
          style={{ alignItems: "center" }}
        >
          {((status.editable && canManageReview) ||
            (status.text === "PPA" && canManagePPA) ||
            (status.text === "Completed" && canManageCompleted) ||
            // superadmins can approve any review
            (status.text !== "Closed" &&
              status.text !== "On Hold" &&
              status.text.toLowerCase() !== "waiting for job review" &&
              canManageAll)) && <AddRow addRow={addRow} />}
          {((status.sendable && canManageReview) ||
            (status.text === "PPA" && canManagePPA) ||
            // superadmins can approve any review
            (status.text !== "Completed" &&
              status.text !== "Closed" &&
              status.text !== "On Hold" &&
              status.text.toLowerCase() !== "waiting for job review" &&
              canManageAll)) && (
            <SendTimesheet
              tempH={tempH}
              allRows={allRows}
              unitIsHours={unitIsHours}
              setSendError={setSendError}
              setMessageAlert={setMessageAlert}
              setTitleAlert={setTitleAlert}
              setIsAlert={setIsAlert}
              setTsId={setTsId}
              setStatusToSend={setStatusToSend}
              setOpenAlert={setOpenAlert}
              invalidTimesheets={invalidTimesheets}
              currentPeriod={currentPeriod}
            />
          )}
          {status.ppa && canManagePPA && (
            <ReopenTimesheet
              setMessageAlert={setMessageAlert}
              setTitleAlert={setTitleAlert}
              setOpenAlert={setOpenAlert}
              setStatusToSend={setStatusToSend}
              tsId={timesheetDetails._id}
              setTsId={setTsId}
              setIsAlert={setIsAlert}
              setSendError={setSendError}
            />
          )}
        </div>
      )
    }

    const renderHoursSummary = (e) => {
      let allRows = e.data.items || e.data.collapsedItems
      const summaryDays = unitIsHours
        ? round(e.data.aggregates[1]) / 8
        : round(e.data.aggregates[1])
      const timesheetDetails = first(allRows)
      const workingDays = timesheetDetails.workingDays
      const isDifferentFromWorkingDays = workingDays !== summaryDays

      const getTooltipTitle = () => {
        switch (unitIsHours) {
          case true:
            if (workingDays > summaryDays)
              return formatMessage("hours-less-than-working-hours")
            else if (workingDays < summaryDays)
              return formatMessage("hours-more-than-working-hours")
            break
          default:
            if (workingDays > summaryDays)
              return formatMessage("days-less-than-working-days")
            else if (workingDays < summaryDays)
              return formatMessage("days-more-than-working-days")
            break
        }
      }

      if (isDifferentFromWorkingDays) {
        return (
          <Tooltip id="hourTooltip" title={getTooltipTitle()}>
            <span>
              <div
                style={{
                  alignItems: "center",
                  alignContent: "center",
                  color: "red"
                }}
              >
                {e.data.aggregates[1].toLocaleString(localization.lang)}
              </div>
            </span>
          </Tooltip>
        )
      } else {
        return (
          <div style={{ alignItems: "center", alignContent: "center" }}>
            {e.data.aggregates[1].toLocaleString(localization.lang)}
          </div>
        )
      }
    }

    const onCellPrepared = (e) => {
      // remove any tooltip bugged on the page [XMAP-247]
      document.getElementById("hourTooltip")?.remove()

      if (e.rowType === "group" && e.column.caption === "Act") {
        ReactDOM.render(renderGroupButtons(e), e.cellElement)
      }
      if (e.rowType === "header" && e.column.caption === "Act") {
        e.cellElement.innerText = ""
      }
      if (
        e.rowType === "group" &&
        e.column.caption === formatMessage("Description")
      ) {
        e.cellElement.innerText = ""
      }
      if (
        e.rowType === "group" &&
        (e.column.caption === formatMessage("Days") ||
          e.column.caption === formatMessage("Hours"))
      ) {
        ReactDOM.render(renderHoursSummary(e), e.cellElement)
      }
      if (e.rowType === "group" && e.column.caption === formatMessage("Notes")) {
        let status = rowStatusSanitized(
          (e.data?.items && e.data.items[0]) ||
            (e.data?.collapsedItems && e.data.collapsedItems[0])
        )
        const badgeElement = document.createElement("div")
        badgeElement.innerHTML = status.text
        badgeElement.classList.add(`badge`, `badge-${status.class}`)
        e.cellElement.appendChild(badgeElement)
      }
    }

    const RenderListItem = (data) => {
      let allRows = data.data.items || data.data.collapsedItems
      const timesheetDetails = first(allRows)
      const tempHours = timesheetDetails?.tempHours || 0
      const tsOwnerEmail = timesheetDetails.owner.email

      return (
        <div
          className="d-flex justify-content-between"
          style={{ height: 32, alignItems: "center" }}
        >
          <div>
            <a
              title={formatMessage("tooltip-open-teams-chat")}
              href={getTeamsChatUrl(tsOwnerEmail)}
              target="_blank"
              rel="noreferrer"
            >
              {data.text}
            </a>

            {round(tempHours) !== 0 ? (
              <Tooltip
                title={
                  tempHours > 0
                    ? `${
                        unitIsHours ? formatMessage("Hours") : formatMessage("Days")
                      } ${formatMessage("review-excess-after-first-review-tooltip")}`
                    : formatMessage(
                        "review-missing-after-pre-review-tooltip",
                        unitIsHours
                          ? formatMessage("Hours").toLowerCase()
                          : formatMessage("Days").toLowerCase()
                      )
                }
              >
                <div
                  className={`badge badge-danger`}
                  style={{ marginLeft: 30, fontSize: 15 }}
                >
                  {tempHours > 0 ? "+" : ""}
                  {unitIsHours
                    ? tempHours.toLocaleString(localization.lang)
                    : (tempHours / 8).toLocaleString(localization.lang)}
                </div>
              </Tooltip>
            ) : null}
            {timesheetDetails?.hours && (
              <JobTypePercentage hours={timesheetDetails?.hours} config={config} />
            )}
          </div>
        </div>
      )
    }

    const isTimesheetRowOnHold = (row) => {
      return (
        row?.rowType === "data" &&
        row.data.currentStatus === "onHold" &&
        invalidWbsCodes?.find(
          (wbs) => wbs === row.data?.timesheetRow?.onHold?.wbs
        ) != null
      )
    }

    const renderJobCell = (data) => {
      const rowDetails = getRowEditDetails(data.data)

      const invalidRow =
        invalidJobs?.includes(data.value) &&
        !["closed", "onHold", "firstReview"].includes(data.data.currentStatus) &&
        data.data.timesheetRow.currentHours > 0 &&
        data.data.timesheetRow.currentDays > 0

      return (
        <div>
          <div
            style={{
              display: "inline-block",
              fontWeight:
                rowDetails || isTimesheetRowOnHold(data) ? "bold" : "normal",
              ...(invalidRow && { opacity: 0.6 })
            }}
          >
            {data.displayValue}
            {invalidRow && (
              <span
                className="text-danger ml-2 lnr lnr-warning"
                title={formatMessage("job-disabled-tooltip")}
              ></span>
            )}
          </div>
          {rowDetails && (
            <div
              style={{ marginLeft: 20, display: "inline-block" }}
              className="font-icon-wrapper-currentjob"
              data-toggle="tooltip"
              title={rowDetails}
            >
              <FontAwesomeIcon icon={faUserEdit} size="1x" color="grey" />
            </div>
          )}
        </div>
      )
    }

    const onExported = (e) => {
      e.component.columnOption(0, "groupIndex", 0)
    }

    const onExporting = (e) => {
      e.component.columnOption(0, "groupIndex", null)

      if (exported) return

      e.component.refresh().done(function() {
        exported = true
        e.component.exportToExcel()

        exported = false
      })

      e.cancel = true
    }

    const onDataErrorOccurred = (e) => {
      console.log(e)
      if (e?.error?.response?.status === 423) {
        e.error.message = formatMessage("review-timesheet-old-data")
        dgRef.current.instance.cancelEditData()
        return
      }
      if (e?.error?.response?.status === 412) {
        e.error.message = formatMessage("review-timesheet-old-data")
        dgRef.current.instance.cancelEditData()
        return
      }
      e.error.message = formatMessage("review-general-error")
    }

    const onEditingStart = (e) => {
      let { editable } = rowStatusSanitized(e.data)
      if (e.data.currentStatus === "completed" && canManageCompleted) editable = true
      if (e.data.currentStatus !== "closed" && canManageAll) editable = true
      e.cancel = !editable
    }

    const onEditorPreparing = (e) => {
      if (e.dataField === "timesheetRow.currentCode") {
        if (e.value != null && !e.lookup.dataSource.find((j) => j.id === e.value)) {
          e.lookup.dataSource.push(jobs._array.find((j) => j.id === e.value))
        }
        e.editorOptions.onContentReady = function(ev) {
          setTimeout(function() {
            if (ev.component.content())
              ev.component.content().parentNode.style.width = "400px"
          })
        }
      }
    }

    const onRowPrepared = (e) => {
      if (isTimesheetRowOnHold(e)) {
        e.rowElement.style.fontWeight = "bold"
        e.rowElement.style.color = "#D21312"
      }
    }

    return (
      <div className="review-table">
        <DataGrid
          dataSource={dataSource}
          showBorders={true}
          onExporting={onExporting}
          onDataErrorOccurred={onDataErrorOccurred}
          onExported={onExported}
          onRowPrepared={onRowPrepared}
          onEditingStart={onEditingStart}
          onCellPrepared={onCellPrepared}
          onRowUpdated={() => {
            forceUpdate()
          }}
          onRowRemoved={() => {
            forceUpdate()
          }}
          loadPanel={{
            enabled: true,
            indicatorSrc: `data:image/svg+xml;utf8,${require("./../../../../assets/rolling.svg")}`
          }}
          onEditorPreparing={onEditorPreparing}
          allowColumnResizing
          ref={dgRef}
        >
          <Paging enabled={false} />
          <Export
            texts={{
              exportAll: formatMessage("dxDataGrid-exportCurrentView")
            }}
            enabled={true}
            fileName={"review_" + moment().format("YYYYMMDD-HH:mm")}
          />
          <Editing
            mode="cell"
            allowUpdating={allowManageReview}
            allowDeleting={allowManageReview}
          />
          <GroupPanel />
          <SearchPanel visible={true} />
          <ColumnChooser enabled={true} mode="select" height="800" />
          <Grouping autoExpandAll={true} />
          <Paging enabled={false} />
          <Export
            texts={{
              exportAll: formatMessage("dxDataGrid-exportCurrentView")
            }}
            enabled={true}
            fileName={"review_" + moment().format("YYYYMMDD-HH:mm")}
          />
          <GroupPanel />
          <SearchPanel visible={true} />
          <Grouping autoExpandAll={true} />
          <Column
            dataField={"owner.displayName"}
            caption={"Owner"}
            allowEditing={false}
            groupIndex={0}
            groupCellTemplate={"groupRowTemplate"}
            allowHiding={false}
          />
          <Column
            dataField={"timesheetRow.currentCode"}
            cellRender={renderJobCell}
            caption={"Job"}
            allowEditing={true}
            allowHiding={false}
            calculateDisplayValue={(row) => {
              // This code is used to verify if the timesheet row has to be considered invalid due to a disabled/deleted job
              const isInvalid = invalidTimesheets.find((i) => i === row._id) != null

              if (
                !["closed", "onHold", "firstReview"].includes(row.currentStatus) &&
                invalidJobs?.includes(row.timesheetRow.currentCode) &&
                !isInvalid &&
                row.timesheetRow.currentHours > 0 &&
                row.timesheetRow.currentDays > 0
              ) {
                invalidTimesheets.push(row._id)
                setInvalidTimesheets(invalidTimesheets)
              }

              // if there is an invalid timesheet assigned to user, raise an error on bulk send of in charge review
              if (
                row.statusDetails.owner === user.email &&
                !["closed", "onHold", "firstReview"].includes(row.currentStatus)
              ) {
                assignedTs.push(row._id)
                setAssignedTs(assignedTs)

                if (!sendBulkError && isInvalid) {
                  setSendBulkError(true)
                }
              }

              return row.timesheetRow.currentLabel
            }}
          >
            <Lookup
              dataSource={jobs._array.filter((j) => j.disabled === false)}
              displayExpr={(option) =>
                option.isTempPlaced
                  ? `${option.wbs}${
                      option.wbsAliasCode ? ` - ${option.wbsAliasCode}` : ""
                    } (${config.wbsTempCode}) - ${option.description}`
                  : option.suggestionLabel
              }
              valueExpr="id"
            />
            <RequiredRule />
          </Column>
          <Column
            dataField={"wbsAliasCode"}
            caption={"WBS Alias Code"}
            allowEditing={false}
            showInColumnChooser={true}
            allowHiding={true}
          />
          <Column
            dataField={"timesheetRow.currentDescription"}
            allowEditing={true}
            caption={formatMessage("Description")}
            allowHiding={false}
            minWidth={40}
            editorOptions={{ maxLength: 120 }}
          >
            <CustomRule
              validationCallback={(e) => {
                if (
                  e.data.timesheetRow?.currentCode &&
                  jobs._array.find((i) => i.id === e.data.timesheetRow?.currentCode)
                    .isDescriptionMandatory
                ) {
                  const descr = e.value.replace(/ /g, "")
                  return descr !== "" && descr !== null && descr !== undefined
                } else {
                  return true
                }
              }}
              message={formatMessage("job-mandatory-description")}
            />
          </Column>
          <Column
            dataField={
              unitIsHours ? "timesheetRow.currentHours" : "timesheetRow.currentDays"
            }
            caption={unitIsHours ? formatMessage("Hours") : formatMessage("Days")}
            alignment={"center"}
            allowEditing={true}
            allowHiding={false}
            width={"auto"}
            dataType={"number"}
            format={{}} // mandatory for applying localization
            editorOptions={{
              showSpinButtons: true,
              format: { type: "fixedPoint", precision: 2 },
              min: 0
            }}
          >
            <CustomRule
              validationCallback={(e) => {
                return e.value >= 0
              }}
              message={
                unitIsHours === true
                  ? formatMessage(
                      "warning-ts-compiling-sending-greater-then-0-hours"
                    )
                  : formatMessage("warning-ts-compiling-sending-greater-then-0-days")
              }
            />
          </Column>
          <Column
            dataField={"timesheetRow.currentNotes"}
            caption={formatMessage("Notes")}
            alignment={"center"}
            allowEditing={true}
            allowHiding={false}
            minWidth={170}
            editorOptions={{ maxLength: 120 }}
          >
            <CustomRule
              validationCallback={(e) => {
                const currentStatus = e.data.currentStatus
                if (
                  currentStatus !== "preReview" &&
                  currentStatus !== "thirdReview" &&
                  currentStatus !== "ppa"
                ) {
                  return (
                    e.data?.timesheetRow?.currentNotes !== "" &&
                    e.data?.timesheetRow?.currentNotes !== null &&
                    e.data?.timesheetRow?.currentNotes !== undefined
                  )
                } else {
                  return true
                }
              }}
              message={formatMessage("review-mandatory-notes")}
            />
          </Column>
          <Column type="buttons" width={100} caption={"Act"} allowHiding={false}>
            <Button
              name="delete"
              icon="clearformat"
              hint={formatMessage("delete-review-command")}
              visible={(e) => {
                const rowDataSanitized = rowStatusSanitized(e.row.data)
                return (
                  (rowDataSanitized.editable && canManageReview) ||
                  (rowDataSanitized.text === "PPA" && canManagePPA) ||
                  (rowDataSanitized.text === "Completed" && canManageCompleted) ||
                  // superadmins can manage any review
                  (rowDataSanitized.text !== "Closed" &&
                    rowDataSanitized.text !== "On Hold" &&
                    rowDataSanitized.text.toLowerCase() !==
                      "waiting for job review" &&
                    canManageAll)
                )
              }}
            />
          </Column>
          <Summary>
            <GroupItem
              column="timesheetRow.currentDescription"
              summaryType="max"
              displayFormat={"{0}"}
              showInGroupFooter={false}
              name="currentDescription"
              alignByColumn={true}
              skipEmptyValues={false}
            />
            {unitIsHours ? (
              <GroupItem column={`timesheetRow.currentHours`} summaryType="sum" />
            ) : (
              <GroupItem column={`timesheetRow.currentDays`} summaryType="sum" />
            )}
            <GroupItem column="timesheetRow.currentNotes" summaryType="max" />
            <Template name={"groupRowTemplate"} render={RenderListItem} />
            {/* GroupItem mandatory to create the element algined which will be modified in renderListItem */}
            <GroupItem
              column="Act"
              summaryType="max"
              displayFormat={"{0}"}
              showInGroupFooter={false}
              name="currentAct"
              alignByColumn={true}
            />
          </Summary>
          <MasterDetail
            enabled={true}
            component={(props) => (
              <MasterTemplate
                unitIsHours={unitIsHours}
                data={props.data}
                invalidWbsCodes={invalidWbsCodes}
              />
            )}
          />
          <Scrolling
            mode="virtual"
            rowRenderingMode="virtual"
            preloadEnabled={true}
          />
          <Paging defaultPageSize={100000} />
          <Template
            name={"filterStatus"}
            render={() => (
              <ToolbarFilter
                toReviewFilter={toReviewFilter}
                setToReviewFilter={setToReviewFilter}
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
                theme={theme}
              />
            )}
          />
          <Toolbar>
            <Item location="before" name="searchPanel" />
            <Item location="before" template="filterStatus" name="filterStatus" />
            <Item location="after" name="exportButton" />
            <Item location="after" name="refreshButton" widget={"dxButton"}>
              <DevextremeButton
                hint={formatMessage("dxDataGrid-commandRefresh")}
                icon="refresh"
                onClick={() => forceUpdate()}
              />
            </Item>
            <Item location="after" name="columnChooserButton" />
          </Toolbar>
        </DataGrid>
      </div>
    )
  }
)
