import { useEffect, useState } from "react"
import HeaderCell from "../components/cell/HeaderCell"

export function useTable({
  tableColumns,
  tableData,
  getFilterValue,
  setFilterValue,
  orderBy,
  setOrderBy,
  sortingOrder,
  setSortingOrder,
  initialExpand,
  setInitialExpand,
  pinnedUntil,
  setPinnedUntil,
}) {
  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([])
  const [headers, setHeaders] = useState([])

  const isFunction = (reference) => {
    return typeof reference === "function"
  }

  const buildColumns = () => {
    return tableColumns
  }

  const buildHeaders = () => {
    const lastPinnedIndex = tableColumns.findIndex(
      (column) => column.id === pinnedUntil,
    )

    return tableColumns?.map((column, index) => {
      const content = isFunction(column.Header) ? <column.Header /> : column.Header
      const isPinned = index <= lastPinnedIndex
      const onSortClick = () => {
        if (
          typeof setOrderBy !== "function" ||
          typeof setSortingOrder !== "function"
        ) {
          return
        }

        if (orderBy !== column.id) {
          setOrderBy(column.id)
          setSortingOrder("DESC")
          return
        }

        setSortingOrder((prev) => (prev === "ASC" ? "DESC" : "ASC"))
      }
      const onPinClick = () =>
        setPinnedUntil(pinnedUntil !== column.id ? column.id : null)

      return {
        id: column.id,
        content: (
          <HeaderCell
            isSortable={column.sortable}
            sortingOrder={orderBy === column.id ? sortingOrder : ""}
            onSortClick={() => onSortClick()}
            isPinned={isPinned}
            onPinClick={() => onPinClick()}
          >
            {content}
          </HeaderCell>
        ),
        filter: column.Filter ? (
          <column.Filter
            value={getFilterValue ? getFilterValue(column.id) : ""}
            setValue={(value) => {
              setFilterValue(column.id, value)
            }}
            initialExpand={initialExpand}
            setInitialExpand={setInitialExpand}
          />
        ) : (
          ""
        ),
        isPinned,
      }
    })
  }

  const buildRows = () => {
    const lastPinnedIndex = tableColumns.findIndex(
      (column) => column.id === pinnedUntil,
    )

    return tableData?.map((row) => {
      return {
        info: row,
        cells: tableColumns.map((column, index) => {
          const isPinned = index <= lastPinnedIndex

          const info = isFunction(column.accessor)
            ? column.accessor(row)
            : row[column.accessor]
          return {
            id: column.id,
            isPinned,
            info,
            renderCell: ({ isWbsActive, canManage, onChange, error, ...props }) => {
              return isFunction(column.Cell) ? (
                <column.Cell
                  {...props}
                  info={info}
                  row={row}
                  onChange={async (val, field) => {
                    onChange(val, field || column.id)
                  }}
                  error={error}
                  canManage={canManage}
                  isWbsActive={isWbsActive}
                />
              ) : (
                <span>{info}</span>
              )
            },
          }
        }),
      }
    })
  }

  useEffect(() => {
    setRows(buildRows())
    setHeaders(buildHeaders())
    setColumns(buildColumns())
  }, [tableColumns, tableData, pinnedUntil])

  return { headers, rows, columns }
}
