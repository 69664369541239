import { Fragment, useEffect, useState } from "react"
import { useAppAbility } from "../../../../../Context/ability"
import { getErrorByField } from "../../../Headcount/Main/Utils/ErrorHandling"
import { useEditYear } from "../../hooks/useEditYear"
import { useTable } from "../../hooks/useTable"
import Chevron from "../Chevron"
import { jobColumns } from "../column/jobColumns"
import JobRow from "./JobRow"
import { subject } from "@casl/ability"

const ghostCells = () => (
  <>
    <td className="no-right-border"></td>
    <td className="no-right-border"></td>
    <td className="no-right-border"></td>
    <td className="no-right-border"></td>
    <td className="no-right-border"></td>
    <td className="no-right-border"></td>
  </>
)

export default function YearRow({
  cells,
  info,
  initialExpand = false,
  isWbsActive,
  isChargeable,
  columnOffsets,
  lastPinnedIndex,
}) {
  const { can } = useAppAbility()

  const canManageFiscalYear = can("manage", subject("FiscalYear", info))

  const [editedYear, setEditedYear] = useState(info)
  const [rowEl, setRowEl] = useState(null)

  useEffect(() => {
    setEditedYear(info)
    resetErrors()
  }, [info])

  const { mutation, errors, resetErrors } = useEditYear()

  function onYearRowChanged(val, field) {
    const payload = { ...editedYear, [field]: val }
    setEditedYear(payload)

    mutation.mutate({ year: payload, isChargeable })
  }

  const [isExpanded, setIsExpanded] = useState(initialExpand)
  useEffect(() => {
    setIsExpanded(initialExpand)
  }, [initialExpand])

  // Job rows
  const [jobsTableData, setJobsTableData] = useState(editedYear.jobs || [])
  useEffect(() => {
    setJobsTableData(editedYear.jobs || [])
  }, [editedYear.jobs])
  const { rows: jobRows } = useTable({
    tableColumns: jobColumns,
    tableData: jobsTableData || [],
  })

  useEffect(() => {
    if (!rowEl || !columnOffsets) {
      return
    }

    let timeoutId = setTimeout(() => {
      for (let index = 0; index < Array.from(rowEl.children).length; index++) {
        if (lastPinnedIndex != null && index <= lastPinnedIndex) {
          rowEl.children[index].className += " position-sticky overflow-none"
          rowEl.children[index].style.left = columnOffsets
            ? columnOffsets[index] + "px"
            : 0
          rowEl.children[index].style.zIndex = "1000"
        } else {
          rowEl.children[index].className = rowEl.children[index].className.replace(
            " position-sticky overflow-none",
            ""
          )
          rowEl.children[index].style.left = null
          rowEl.children[index].style.zIndex = null
        }
      }
    }, 0)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [rowEl, lastPinnedIndex, columnOffsets])

  return (
    <>
      <tr
        ref={setRowEl}
        className={`year-row ${errors && errors?.length > 0 ? "error-row" : ""}`}
      >
        {cells.map((cell, index) =>
          cell.id === "fiscalYear" ? (
            <Fragment key={`${cell.id}-${editedYear._id}-${index}`}>
              <td
                className={`text-center text-nowrap px-3 no-right-border year-row-${cell.id}`}
                key={`${cell.id}-${editedYear._id}`}
              >
                <div className="d-flex flex-row justify-content-start align-items-center">
                  <Chevron
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                    className="mr-2 ml-5"
                    disabled={!editedYear.jobs?.length}
                  />
                  <span className={`text-nowrap font-weight-bold`}>
                    <div>{editedYear.year}</div>
                  </span>
                </div>
              </td>
              {ghostCells()}
            </Fragment>
          ) : (
            <td
              className={`text-center text-nowrap px-3 year-row-${cell.id} ${
                index >= 2 && !isChargeable ? "hide-cell" : ""
              }`}
              key={`${cell.id}-${editedYear._id}-${index}`}
            >
              <cell.renderCell
                isWbsActive={isWbsActive}
                onChange={(val, field) => onYearRowChanged(val, field)}
                error={getErrorByField(errors, cell.id)}
                canManage={canManageFiscalYear}
              />
            </td>
          )
        )}
      </tr>
      {isExpanded &&
        jobRows.map(({ info, cells }, index) => (
          <JobRow
            cells={cells}
            info={info}
            key={index}
            isChargeable={isChargeable}
            columnOffsets={columnOffsets}
            lastPinnedIndex={lastPinnedIndex}
          />
        ))}
    </>
  )
}
