import { exportDataGrid } from "devextreme/excel_exporter"
import { Workbook } from "exceljs"
import saveAs from "file-saver"
import moment from "moment"
import React from "react"
import { Button } from "reactstrap"
import { formatMessage } from "devextreme/localization"

export default function renderExportButton(columns, dataGridRef) {
  return (
    <div>
      <Button
        className="mr-3"
        color="primary"
        width={100}
        height={40}
        onClick={() => {
          const dataGrid = dataGridRef.current.instance

          const previousVisible = {}
          columns.forEach((c) => {
            previousVisible[c.field] = dataGrid.columnOption(c.field, "visible")
          })

          dataGrid.beginUpdate()
          columns.forEach((c) => {
            dataGrid.columnOption(c.field, "visible", true)
          })

          const workbook = new Workbook()
          const worksheet = workbook.addWorksheet("Main sheet")
          exportDataGrid({
            component: dataGrid,
            worksheet: worksheet
          }).then(function() {
            workbook.xlsx.writeBuffer().then(function(buffer) {
              saveAs(
                new Blob([buffer], { type: "application/octet-stream" }),
                "HC_" + moment().format("YYYYMMDD") + ".xlsx"
              )

              columns.forEach((c) => {
                dataGrid.columnOption(c.field, "visible", previousVisible[c.field])
              })
              dataGrid.endUpdate()
            })
          })
        }}
      >
        <span className="dx-button-text">
          {formatMessage("data-export").toUpperCase()}
        </span>
      </Button>
    </div>
  )
}
