import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import { DateBox } from "devextreme-react"
import { CheckBox } from "devextreme-react/check-box"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Draggable from "react-draggable"
import { createPeriod } from "../../../services/index"
import { Bounce, toast } from "react-toastify"
import { formatMessage } from "devextreme/localization"
import { formatToIsoUtc } from "../../../Utils/formatToIsoUtc"

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

export const AddRow = ({ togglePopUp, setTogglePopUp, offices }) => {
  const [name, setName] = useState("")
  const [startDate, setStartDate] = useState(
    moment(new Date())
      .startOf("month")
      .add(20, "d")
      .toDate()
  )
  const [endDate, setEndDate] = useState(
    moment(new Date())
      .endOf("month")
      .format("YYYY-MM-DD hh:mm")
  )
  const [workingDays, setWorkingDays] = useState([])
  const [workingDaysCheckBox, setWorkingDaysCheckBox] = useState(false)
  const [enableSave, setEnableSave] = useState(false)

  useEffect(() => {
    setName(moment(startDate).format("MMMM YYYY"))
    if (startDate < endDate && !workingDaysCheckBox && workingDays.length > 0) {
      setEnableSave(true)
    } else {
      setEnableSave(false)
    }
    if (workingDaysCheckBox) {
      for (let office of offices) {
        const days = workingDays.find(
          (workingDay) => workingDay.officeId === office.id
        )?.days

        if (days === undefined || days <= 0) {
          setEnableSave(false)
          return
        }
      }
      setEnableSave(true)
    } else {
      setEnableSave(workingDays.length > 0)
    }
  }, [name, startDate, endDate, workingDays, workingDaysCheckBox, offices])

  useEffect(() => {
    setEndDate(
      moment(startDate)
        .add(3, "d")
        .toDate()
    )
  }, [startDate])

  const onInsert = async (data) => {
    try {
      // console.log(data)
      data.startDate = formatToIsoUtc(new Date(data.startDate))
      data.endDate = formatToIsoUtc(new Date(data.endDate))

      data._id = moment(data.startDate).format("YYYYMM")
      data.calendarStartDate = moment(data.startDate)
        .startOf("month")
        .format("YYYY-MM-DD")
      data.calendarEndDate = moment(data.endDate)
        .endOf("month")
        .format("YYYY-MM-DD")
      console.log(data)
      await createPeriod(data)
      setTogglePopUp(false)
    } catch (e) {
      console.log("Error on adding", e.toString())
      toast(
        e.response.status === 400
          ? formatMessage("error-period-existing-entry")
          : formatMessage("error-updating-period"),
        {
          transition: Bounce,
          closeButton: true,
          autoClose: 3000,
          position: "bottom-center",
          type: "error"
        }
      )
      return
    }
  }

  const render = () => {
    if (setTogglePopUp) {
      return (
        <Dialog
          open={togglePopUp}
          disableBackdropClick
          onClose={() => setTogglePopUp(false)}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            {formatMessage("add-period-title")}
          </DialogTitle>
          <DialogContent>
            <DateBox
              defaultValue={startDate}
              max={moment(endDate)
                .subtract(1, "d")
                .toDate()}
              onValueChanged={(item) => {
                // console.log(item.value)
                setStartDate(item.value)
              }}
              displayFormat="dd/MM/yyyy"
              type="date"
              style={{ width: "100%", marginTop: 20 }}
            />
            <DateBox
              defaultValue={endDate}
              min={moment(startDate)
                .add(1, "d")
                .toDate()}
              value={endDate}
              onValueChanged={(item) => {
                // console.log(item.value)
                setEndDate(item.value)
              }}
              displayFormat="dd/MM/yyyy"
              type="date"
              style={{ width: "100%", marginTop: 20 }}
            />
            <TextField
              id="name"
              label={formatMessage("name")}
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
              variant="outlined"
              style={{ width: "100%", marginTop: 20 }}
            />

            <div className="c-card" id="wd" style={{ marginTop: 20 }}>
              <label className="dx-field-label" for="wd">
                {formatMessage("working-days-label")}
              </label>
              <div
                className="dx-field"
                style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
              >
                <TextField
                  style={{
                    width: "50%",
                    visibility: workingDaysCheckBox ? "hidden" : "visible"
                  }}
                  required
                  id="workingDays"
                  type="number"
                  min={0}
                  value={workingDays.length > 0 ? workingDays[0].days : undefined}
                  disabled={workingDaysCheckBox}
                  onChange={(item) => {
                    const n = Number(item.target.value)
                    setWorkingDays(
                      offices.map((office) => {
                        return {
                          officeId: office.id,
                          days: n
                        }
                      })
                    )
                  }}
                  variant="outlined"
                />
                <div
                  style={{
                    width: "50%",
                    alignSelf: "center",
                    marginLeft: "70px",
                    display: "inline-flex"
                  }}
                >
                  <div
                    className="dx-field-label"
                    style={{ width: "60%", paddingRight: 0 }}
                  >
                    {formatMessage("office-based-label")}
                  </div>
                  <CheckBox
                    style={{ width: "20%", padding: "8px 8px 9px 0" }}
                    onValueChanged={(item) => {
                      console.log(item.value)
                      setWorkingDaysCheckBox(item.value)
                    }}
                    value={workingDaysCheckBox}
                  />
                </div>
              </div>
              {workingDaysCheckBox &&
                offices.map((office) => {
                  return (
                    <TextField
                      style={{ width: "50%", padding: "6px 4px 6px 4px" }}
                      required
                      id="workingDays"
                      type="number"
                      label={office.code}
                      min={0}
                      onChange={(item) => {
                        const n = Number(item.target.value)

                        const index = workingDays.findIndex(
                          (workingDay) => workingDay.officeId === office.id
                        )

                        if (index > -1) {
                          workingDays[index].days = n
                        } else {
                          workingDays.push({ officeId: office.id, days: n })
                        }

                        setWorkingDays([...workingDays])
                      }}
                      value={
                        workingDays.find(
                          (workingDay) => workingDay.officeId === office.id
                        )?.days
                      }
                      variant="outlined"
                    />
                  )
                })}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setTogglePopUp(false)} color="primary">
              {formatMessage("Cancel")}
            </Button>
            <Button
              disabled={!enableSave}
              onClick={() =>
                onInsert({
                  name: name,
                  startDate: startDate,
                  endDate: endDate,
                  workingDays: workingDays
                })
              }
              color="primary"
            >
              {formatMessage("dxDataGrid-editingSaveRowChanges")}
            </Button>
          </DialogActions>
        </Dialog>
      )
    } else {
      return <div />
    }
  }

  return <div>{render()}</div>
}
