import { Fragment } from "react"
import { Route } from "react-router-dom"
import AppFooter from "../../Layout/AppFooter/"
import AppHeader from "../../Layout/AppHeader/"
import AppSidebar from "../../Layout/AppSidebar/"
import { useAppAbility } from "./../../Context/ability"
import Headcount from "./Headcount/"
import HeadcountCreate from "./Headcount/create"
import HeadcountEdit from "./Headcount/edit"
import HeadcountShow from "./Headcount/show"
import Iam from "./Iam/"
import JobAndWbs from "./JobAndWbs"
import JobCreate from "./JobAndWbs/Job/create"
import JobEdit from "./JobAndWbs/Job/edit"
import WbsCreate from "./JobAndWbs/Wbs/create"
import WbsEdit from "./JobAndWbs/Wbs/edit"
import YearCreate from "./JobAndWbs/Year/create"
import YearEdit from "./JobAndWbs/Year/edit"
import Organization from "./Organization/"
import Period from "./Period/"

const Admin = ({ match }) => {
  const { can } = useAppAbility()

  const canReadJobAdmin = can("read", "JobAdmin")
  const canReadUserAdmin = can("read", "UserAdmin")
  const canReadPeriodAdmin = can("read", "PeriodAdmin")
  const canReadOrganizationAdmin = can("read", "OrganizationAdmin")
  const canReadIamAdmin = can("read", "IamAdmin")
  const canManageUser = can("manage", "User")
  const canReadUser = can("read", "User")

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            {canReadIamAdmin && <Route path={`${match.url}/iam`} component={Iam} />}
            {canReadUserAdmin && (canManageUser || canReadUser) && (
              <Route
                exact
                path={`${match.url}/headcount`}
                render={() => <Headcount canManageUser={canManageUser} />}
              />
            )}
            {canReadUserAdmin && canManageUser && (
              <Route
                exact
                path={`${match.url}/headcount/create`}
                component={HeadcountCreate}
              />
            )}
            {canReadUserAdmin && (canManageUser || canReadUser) && (
              <Route
                exact
                path={`${match.url}/headcount/edit/:id`}
                component={HeadcountEdit}
              />
            )}
            {canReadUserAdmin && (canManageUser || canReadUser) && (
              <Route
                exact
                path={`${match.url}/headcount/show/:id`}
                component={HeadcountShow}
              />
            )}
            {canReadPeriodAdmin && (
              <Route path={`${match.url}/period`} component={Period} />
            )}
            {canReadOrganizationAdmin && (
              <Route path={`${match.url}/organization`} component={Organization} />
            )}

            {/* {canReadJobAdmin && <Route path={`${match.url}/job`} component={Job} />} */}

            {canReadJobAdmin && (
              <Route path={`${match.url}/job-and-wbs`} component={JobAndWbs} />
            )}
            {canReadJobAdmin && (
              <Route path={`${match.url}/wbs/create`} component={WbsCreate} />
            )}
            {canReadJobAdmin && (
              <Route path={`${match.url}/wbs/edit/:id`} component={WbsEdit} />
            )}
            {canReadJobAdmin && (
              <Route
                path={`${match.url}/:wbsId/year/create`}
                component={YearCreate}
              />
            )}
            {canReadJobAdmin && (
              <Route
                path={`${match.url}/:wbsId/year/edit/:id`}
                component={YearEdit}
              />
            )}
            {canReadJobAdmin && (
              <Route
                path={`${match.url}/:yearId/job/create`}
                component={JobCreate}
              />
            )}
            {canReadJobAdmin && (
              <Route
                path={`${match.url}/:yearId/job/edit/:shortCode`}
                component={JobEdit}
              />
            )}
          </div>
          <AppFooter />
        </div>
      </div>
    </Fragment>
  )
}

export default Admin
