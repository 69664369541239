import { formatMessage } from "devextreme/localization"
import { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import Tooltip from "./common/Tooltip"
import Icon from "./Icon"

export default withRouter(function CloneWbsButton({
  history,
  className,
  wbs,
  extended = false,
  disabled,
}) {
  const getWbsWithoutCodeAndId = () => {
    const newWbs = { ...wbs }
    delete newWbs._id
    delete newWbs.code
    delete newWbs.isFinal
    delete newWbs.isTempPlaced
    delete newWbs.isActive
    return newWbs
  }

  const [wbsToClone, setWbsToClone] = useState(getWbsWithoutCodeAndId())

  useEffect(() => {
    setWbsToClone(getWbsWithoutCodeAndId())
  }, [wbs])

  return (
    <Tooltip
      disabled={extended}
      content={
        <div className="text-nowrap">
          {formatMessage("jw-CLONE")} {formatMessage("jw-WBS")}
        </div>
      }
    >
      <button
        disabled={disabled}
        className={`${className} btn btn-outline-primary square-btn`}
        onClick={() =>
          history.push({
            pathname: `/admin/wbs/create`,
            state: { wbsToClone },
          })
        }
      >
        <Icon iconRelativePath="monade/clone" />{" "}
        {extended ? (
          <span className="ml-1">{`${formatMessage("jw-CLONE")} ${formatMessage(
            "jw-WBS",
          )}`}</span>
        ) : (
          ""
        )}
      </button>
    </Tooltip>
  )
})
