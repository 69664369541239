import "react-app-polyfill/stable"
import "devextreme/dist/css/dx.common.css"
import "devextreme/dist/css/dx.light.css"
import React, { useEffect } from "react"
import { unregister } from "./registerServiceWorker"
import LoadPanel from "devextreme-react/load-panel"
import "bootstrap/dist/css/bootstrap.min.css"
import { HashRouter } from "react-router-dom"
import "./assets/base.css"
import Main from "./Pages/Index"
import Unauthorized from "./Pages/Index/unauthorized"
import ServerError from "./Pages/Index/serverError"
import TC from "./Pages/Index/tc"
import configureStore from "./config/configureStore"
import { AuthProvider, useAuth } from "./Context/auth"
import { AbilityContext } from "./Context/ability"
import { Provider } from "react-redux"
import axios from "axios"
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react"
import { indexOf } from "underscore"
import createAuthRefreshInterceptor from "axios-auth-refresh"
import { InteractionType } from "@azure/msal-browser"
import { msalInstance } from "./index"
import { loginGraphRequest, loginRequest } from "./authConfig"
import "./style/dx.material.bip-scheme.css"
import "./style/dx.generic.compact.css"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
const store = configureStore()

const setAuthToken = (token) => {
  axios.defaults.params = {}
  axios.defaults.headers["authorization"] = `Bearer ${token}`
}

const setGrahToken = (token) => {
  sessionStorage.setItem("graphBearer", JSON.stringify(token))
}

const ErrorComponent = ({ error }) => {
  console.log(JSON.stringify(error.errorCode || error))
  return <h5>An error occurred during authentication</h5>
}

const InProgressComponent = ({ inProgress }) => {
  return (
    <LoadPanel
      visible={true}
      indicatorSrc={`data:image/svg+xml;utf8,${require("./assets/rolling.svg")}`}
    />
  )
}

function AppContent({ userEmail, Component }) {
  const { user, loading, error, config } = useAuth(userEmail)

  if (loading) {
    return (
      <LoadPanel
        visible={true}
        indicatorSrc={`data:image/svg+xml;utf8,${require("./assets/rolling.svg")}`}
      />
    )
  }

  if (
    error ||
    (config?.maintenance?.enabled &&
      indexOf(config?.maintenance?.userWhiteList, user.email) === -1)
  ) {
    return error === 403 ? (
      <Unauthorized />
    ) : (
      <ServerError textMessage={config?.maintenance?.text || null} />
    )
  }

  const IsUserRegister = ({ userData }) => {
    if (!userData.lastTermsAndConditions) {
      return <TC />
    } else {
      return (
        <AbilityContext.Provider value={userData.capabilities}>
          <Component />
        </AbilityContext.Provider>
      )
    }
  }
  if (user) {
    return <IsUserRegister userData={user} />
  } else {
    return (
      <LoadPanel
        visible={true}
        indicatorSrc={`data:image/svg+xml;utf8,${require("./assets/rolling.svg")}`}
      />
    )
  }
}

/** Vengono richiesti 2 token:
 *  - graph token: viene usato per chiamare la graph api e caricare la foto dell'utente
 *  - login token: viene usato per chiamare il backend e viene validato dall'api gateway
 *
 * prima veniva usato solo il graph token e validato nel Backend l'id_token. Purtroppo questo aveva problemi di allineamento della
 * expiring date. L'access_token fornito nella chiamata Graph non può essere validato normalmente, poiché nell'header viene specificato
 * l'algoritmo RS256 ma per la signature viene usato l'algoritmo HS256
 *
 * L'unica soluzione è stata quella di richiedere un secondo token da poter validare nello user scope
 */

createAuthRefreshInterceptor(axios, (failedRequest) => {
  const account = msalInstance.getActiveAccount()

  msalInstance
    .acquireTokenSilent({ ...loginGraphRequest, account: account })
    .then((response) => setGrahToken(response.accessToken))
    .catch(async (e) => {
      console.log("Error while acquireTokenSilent", JSON.stringify(e))
      return msalInstance
        .acquireTokenByRefreshToken({
          ...loginGraphRequest,
          account: account
        })
        .then((response) => {
          console.log("RESPONSE REDIRECT INTERCEPTOR", response)
          setGrahToken(response.accessToken)
          return (failedRequest.response.config.headers[
            "authorization"
          ] = `Bearer ${response.accessToken}`)
        })
        .finally(() => Promise.resolve())
    })
    .finally(() => Promise.resolve())

  return msalInstance
    .acquireTokenByRefreshToken({
      ...loginRequest,
      account: account
    })
    .then((response) => {
      console.log("RESPONSE INTERCEPTOR", response)
      setAuthToken(response.accessToken)
      return (failedRequest.response.config.headers[
        "authorization"
      ] = `Bearer ${response.accessToken}`)
    })
    .catch(async (e) => {
      console.log("Error while acquireTokenSilent", JSON.stringify(e))
      return msalInstance
        .acquireTokenRedirect({
          ...loginRequest,
          account: account
        })
        .then((response) => {
          console.log("RESPONSE REDIRECT INTERCEPTOR", response)
          setAuthToken(response.accessToken)
          // setGrahToken(response.accessToken)
          return (failedRequest.response.config.headers[
            "authorization"
          ] = `Bearer ${response.accessToken}`)
        })
        .finally(() => Promise.resolve())
    })
    .finally(() => Promise.resolve())
})

const App = () => {
  const [apiData, setApiData] = React.useState(null)
  const account = msalInstance.getActiveAccount()

  useEffect(() => {
    ;(async () => {
      const responseAuth = await msalInstance
        .acquireTokenSilent({
          ...loginRequest,
          account: account
        })
        .catch(async (e) => {
          console.log("ERROR ACQUIRING TOKEN", JSON.stringify(e))
          const response = await msalInstance.acquireTokenRedirect({
            ...loginRequest,
            account: account
          })
          setAuthToken(response.accessToken)
          setApiData(response)
        })
      const responseGraph = await msalInstance
        .acquireTokenSilent({
          ...loginGraphRequest,
          account: account
        })
        .catch(async (e) => {
          console.log("ERROR ACQUIRING TOKEN", JSON.stringify(e))
          const response = await msalInstance.acquireTokenRedirect({
            ...loginGraphRequest,
            account: account
          })
          setGrahToken(response.accessToken)
          setApiData(response)
        })
      setAuthToken(responseAuth.accessToken)
      setGrahToken(responseGraph.accessToken)
      setApiData(responseGraph)
    })()
  }, [])

  if (apiData) {
    return (
      <AuthProvider>
        <AppContent userEmail={account.username} Component={Main} />
      </AuthProvider>
    )
  }

  return <InProgressComponent />
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

export default ({ pca }) => {
  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <MsalProvider instance={pca}>
          <Provider store={store}>
            <HashRouter>
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                errorComponent={ErrorComponent}
                loadingComponent={InProgressComponent}
              >
                <App />
              </MsalAuthenticationTemplate>
            </HashRouter>
          </Provider>
        </MsalProvider>
      </QueryClientProvider>
    </React.Fragment>
  )
}

unregister()

// registerServiceWorker();
