import { useRef } from "react"
import Icon from "../Icon"
import Tooltip from "../Tooltip"

export default function FieldErrorIcon({ className, message, iconClassName }) {
  const calcTextWidth = useRef(null)

  if (!message || message === "") {
    return ""
  }
  const getTooltipMessage = () => {
    // split the message into lines of max 30 characters or max 6 words
    // and return a div for each line
    return (
      <div>
        {message
          .split(/(?<=\s)(?=\S)/)
          .reduce(
            (acc, word) => {
              const lastLine = acc[acc.length - 1]
              if (lastLine.length + word.length > 30) {
                acc.push(word)
              } else {
                acc[acc.length - 1] = lastLine + word
              }
              return acc
            },
            [""]
          )
          .map((line, index) => {
            return (
              <div style={{ whiteSpace: "nowrap" }} key={index}>
                {line}
              </div>
            )
          })}
      </div>
    )
  }

  return (
    <div className={className}>
      <div
        ref={calcTextWidth}
        style={{
          position: "absolute",
          visibility: "hidden",
          height: "auto",
          width: "auto",
          whiteSpace: "nowrap"
        }}
      >
        {message}
      </div>
      <Tooltip content={message}>
        <span>
          <Icon
            svgStyles={{ width: "16px", height: "16px" }}
            className={`text-danger ${iconClassName}`}
            iconRelativePath={"monade/exclamation-circle"}
          />
        </span>
      </Tooltip>
    </div>
  )
}
