import { useMutation, useQueryClient } from "@tanstack/react-query"
import { formatMessage } from "devextreme/localization"
import { useEffect, useState } from "react"
import { createJob } from "../../../../services"
import {
  showSuccessToast,
  showToastError
} from "../../Headcount/Main/Utils/ErrorHandling"
import { parseJobData } from "../utils/parseJobData"
import { parseJobToData } from "../utils/parseJobToData"

export function useCreateJob(jobToClone, wbs, year, onSuccess) {
  const [errors, setErrors] = useState([])

  const [newJob, setNewJob] = useState(
    jobToClone
      ? jobToClone
      : {
          fiscalYear: year?._id ? year._id : null,
          startDate: new Date(),
          endDate: new Date(),
          accountable: wbs?.accountable ? wbs.accountable : null,
          adaMonetized: false,
          technologyProducts: [],
          technologyProviders: [],
          digitalAssets: [],
          jobSpecialties: []
        }
  )

  useEffect(() => {
    setNewJob((prev) => ({
      ...prev,
      fiscalYear: year?._id ? year._id : null,
      accountable: wbs?.accountable ? wbs.accountable : null
    }))
  }, [year, wbs])

  const onValueChanged = (field, value) => {
    let newData = null

    if (!field) {
      // update more than one field
      newData = { ...newJob, ...value?.fieldsToAdd }
      value?.fieldsToDelete?.forEach((fieldToDelete) => {
        delete newData[fieldToDelete]
      })
    } else {
      newData = { ...newJob, [field]: value }
    }

    setNewJob(newData)
  }

  const queryClient = useQueryClient()

  const mutation = useMutation(
    async ({ job, isChargeable }) => {
      const payload = parseJobToData(job, isChargeable)
      return await createJob(payload)
    },
    {
      onSuccess: (data) => {
        setErrors([])
        const parsedJob = parseJobData(data.data)
        queryClient.invalidateQueries(["GET", "wbs"])
        queryClient.invalidateQueries(["GET", "wbs/", wbs._id])
        queryClient.invalidateQueries(["GET", "year/", year._id])
        queryClient.invalidateQueries(["GET", "job", "job-suggestions"])
        queryClient.setQueryData(["GET", "job/", parsedJob.shortCode], parsedJob)
        showSuccessToast(formatMessage("jw-Successfully created Job"))
        if (onSuccess) {
          onSuccess(parsedJob)
        }
      },
      onError: (err) => {
        if (err.response.status === 400) {
          setErrors(err.response.data)
          showToastError(err.response)
        } else {
          setErrors([])
          showToastError(err.response)
        }
      }
    }
  )

  return {
    mutation,
    onValueChanged,
    newJob,
    setNewJob,
    errors
  }
}
