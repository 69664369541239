import { useEffect, useState } from "react"
import moment from "moment"
import { parseResourcesFiltersToParams } from "../utils/parseResourcesFiltersToParams"
import { getAllUsers, getConfig } from "../../../../services"
import { getAllResources, getResource } from "../services/ResourcesService"
import { createAllocation as createResourceAllocation } from "../services/AllocationService"
import { parseResourcesFiltersToCreateAllocationParams } from "../utils/parseResourcesFiltersToCreateAllocationParams"
import { showToastError } from "../../../Admin/Headcount/Main/Utils/ErrorHandling"
import { parseSingleResourceFiltersToParams } from "../utils/parseSingleResourceFiltersToParams"
import { getWorkingDays } from "../services/WorkdayService"

export const useAddResource = () => {
  const DEFAULT_FILTERS = {
    startDate: moment()
      .startOf("day")
      .toDate(),
    endDate: moment()
      .add(1, "month")
      .startOf("day")
      .toDate(),
    startDateTolerance: 0,
    endDateTolerance: 0,
    timeAllocationPercentage: 0,
    level: null,
    jobTitle: null,
    teamLeader: null,
    competence: null,
    userType: null,
    search: ""
  }
  const DEFAULT_RESOURCE_FILTERS = {
    aggregation: "WEEKLY",
    startDate: moment()
      .startOf("day")
      .toDate()
  }

  const [projects, setProjects] = useState([])
  const [levels, setLevels] = useState([])
  const [jobTitles, setJobTitles] = useState([])
  const [teamLeaders, setTeamLeaders] = useState([])
  const [filters, setFilters] = useState(DEFAULT_FILTERS)
  const [workingDays, setWorkingDays] = useState(null)
  const [resources, setResources] = useState([])
  const [isLoadingResources, setIsLoadingResources] = useState(false)
  const [loadResourcesError, setLoadResourcesError] = useState(null)
  const [resourcesNoTolerance, setResourcesNoTolerance] = useState([])
  const [isLoadingResourcesNoTolerance, setIsLoadingResourcesNoTolerance] = useState(
    false
  )
  const [loadResourcesNoToleranceError, setLoadResourcesNoToleranceError] = useState(
    null
  )
  const [isCreatingAllocation, setIsCreatingAllocation] = useState(false)
  const [createAllocationsErrors, setCreateAllocationsErrors] = useState(null)
  const [paginationMeta, setPaginationMeta] = useState({})
  const [paginationNoToleranceMeta, setPaginationNoToleranceMeta] = useState({})

  useEffect(() => {
    if (filters.startDate && filters.endDate) {
      getWorkingDays(filters.startDate, filters.endDate)
        .then(({ data: { workdaysCount } }) => {
          setWorkingDays(workdaysCount)
        })
        .catch(() => setWorkingDays(0))
    } else {
      setWorkingDays(0)
    }
  }, [filters.startDate, filters.endDate])

  useEffect(() => {
    const TEAM_LEADER_ROLES = ["DTL", "TL", "AM", "COEL"]
    const loadCharacteristicsItems = async () => {
      let {
        data: { bipRoles, levels }
      } = await getConfig()
      let { data: users } = await getAllUsers(true)
      const teamLeaders = users.filter((user) =>
        TEAM_LEADER_ROLES.includes(user.bipRole)
      )
      setLevels(levels)
      setJobTitles(bipRoles)
      setTeamLeaders(teamLeaders)
    }

    loadCharacteristicsItems()
  }, [])

  const loadResources = async (filters) => {
    setIsLoadingResources(true)
    setLoadResourcesError(null)

    try {
      const params = parseResourcesFiltersToParams(filters)
      let {
        data: { data: resources, ...meta }
      } = await getAllResources(params)
      setResources(resources)
      setPaginationMeta(meta)
      setIsLoadingResources(false)
    } catch (e) {
      setLoadResourcesError(e)
      setIsLoadingResources(false)
    }
  }

  const loadResourcesNoTolerance = async (filters) => {
    setIsLoadingResourcesNoTolerance(true)
    setLoadResourcesNoToleranceError(null)

    try {
      const params = {
        ...parseResourcesFiltersToParams(filters),
        toleranceStartDate: 0,
        toleranceEndDate: 0
      }
      let {
        data: { data: resourcesNoTolerance, ...meta }
      } = await getAllResources(params)
      setResourcesNoTolerance(resourcesNoTolerance)
      setPaginationNoToleranceMeta(meta)
      setIsLoadingResourcesNoTolerance(false)
    } catch (e) {
      setLoadResourcesNoToleranceError(e)
      setIsLoadingResourcesNoTolerance(false)
    }
  }

  const createAllocation = async (filters) => {
    setIsCreatingAllocation(true)
    setCreateAllocationsErrors([])

    try {
      const params = parseResourcesFiltersToCreateAllocationParams(filters)
      await createResourceAllocation(params)
      setIsCreatingAllocation(false)
    } catch (e) {
      if (e.response.status === 400) {
        setCreateAllocationsErrors(e.response.data)
        showToastError(e.response)
      }
      throw e
      setIsCreatingAllocation(false)
    }
  }

  const loadResource = async (resource, filters, resourceFilters) => {
    setResource({
      ...resource,
      isLoadingDetails: true,
      loadDetailsError: null,
      filters: resourceFilters
    })

    try {
      const params = parseSingleResourceFiltersToParams(filters, resourceFilters)
      const { data: details } = await getResource(resource.userId, params)
      setResource({
        ...resource,
        loadDetailsError: null,
        isLoadingDetails: false,
        details,
        filters: resourceFilters
      })
    } catch (e) {
      setResource({
        ...resource,
        isLoadingDetails: false,
        loadDetailsError: e,
        filters: resourceFilters
      })
    }
  }

  const setResource = (resource) => {
    if (resources.length > 0) {
      setResources((current) =>
        current.map((r) => (r.userId !== resource.userId ? r : resource))
      )
    }

    if (resourcesNoTolerance.length > 0) {
      setResourcesNoTolerance((current) =>
        current.map((r) => (r.userId !== resource.userId ? r : resource))
      )
    }
  }
  const setIsResourceOpen = (resource, isOpen, filters) => {
    const newResource = { ...resource, isOpen }

    loadResource(newResource, filters, {
      ...DEFAULT_RESOURCE_FILTERS,
      startDate: filters.startDate
    })
  }

  const toggleIsCalendarShown = (resource) => {
    setIsResourceOpen(resource, !resource.isOpen, filters)
  }

  const loadMoreResources = async (filters) => {
    try {
      if (!paginationMeta.hasNextPage) {
        return
      }
      setLoadResourcesError(null)
      const params = parseResourcesFiltersToParams(filters)
      params.page = paginationMeta.page + 1
      const {
        data: { data: newResources, ...meta }
      } = await getAllResources(params)
      setResources([...resources, ...newResources])
      setPaginationMeta(meta)
    } catch (error) {
      setLoadResourcesError(error)
    }
  }

  const loadMoreResourcesNoTolerance = async (filters) => {
    try {
      if (!paginationNoToleranceMeta.hasNextPage) {
        return
      }
      setLoadResourcesNoToleranceError(null)
      const params = {
        ...parseResourcesFiltersToParams(filters),
        toleranceStartDate: 0,
        toleranceEndDate: 0
      }
      params.page = paginationNoToleranceMeta.page + 1
      const {
        data: { data: newResources, ...meta }
      } = await getAllResources(params)
      setResourcesNoTolerance([...resourcesNoTolerance, ...newResources])
      setPaginationNoToleranceMeta(meta)
    } catch (error) {
      setLoadResourcesNoToleranceError(error)
    }
  }

  return {
    filters,
    setFilters,
    DEFAULT_FILTERS,
    workingDays,
    resources,
    isLoadingResources,
    loadResourcesError,
    loadResources,
    loadMoreResources,
    loadMoreResourcesNoTolerance,
    projects,
    levels,
    jobTitles,
    teamLeaders,
    resourcesNoTolerance,
    isLoadingResourcesNoTolerance,
    loadResourcesNoToleranceError,
    loadResourcesNoTolerance,
    createAllocation,
    isCreatingAllocation,
    createAllocationsErrors,
    toggleIsCalendarShown,
    loadResource,
    paginationMeta,
    paginationNoToleranceMeta
  }
}
