import React, { Fragment } from "react"
import { Redirect, Route } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import Menu from "../../Pages/Main"
import Preter from "../../Pages/Preter"
import Admin from "../../Pages/Admin"
import Preallocation from "../../Pages/Preallocation"
import PerformanceMgmt from "../../Pages/PerformanceMgmt"

const AppMain = () => {
  return (
    <Fragment>
      <Route path="/main" component={Menu} />
      <Route path="/admin" component={Admin} />
      <Route path="/preallocation" component={Preallocation} />
      <Route path="/preter" component={Preter} />
      <Route path="/performance-mgmt" component={PerformanceMgmt} />
      <Route exact path="/" render={() => <Redirect to="/main/profile" />} />
      <ToastContainer />
    </Fragment>
  )
}

export default AppMain
