import { Lookup } from "devextreme-react/data-grid"
import { formatMessage } from "devextreme/localization"
import { roundSecondDecimal } from "./RoundSecondDecimal"

export default function getColumns(
  organizations,
  userNames,
  offices,
  competences,
  levels,
  suppliers,
  contractTypes,
  globalCompetences,
  circleValues,
  genderValues
) {
  const getExtJobTitles = () => {
    let extJobTitles = []
    levels.forEach((l) => {
      if (l.extJobTitle) {
        for (const property in l.extJobTitle) {
          let extJobTitle = l.extJobTitle[property]
          if (!extJobTitles.includes(extJobTitle)) {
            extJobTitles.push(extJobTitle)
          }
        }
      }
    })
    return extJobTitles.sort()
  }
  return [
    //default (visible: true)
    {
      title: formatMessage("hc-field-enabled"),
      field: "enabled",
      visible: true,
      value: false,
      dataType: "boolean"
    },
    {
      title: formatMessage("hc-field-created-at"),
      field: "created_at",
      visible: true,
      dataType: "date",
      format: "dd-MM-yyyy"
    },
    {
      title: formatMessage("hc-field-lastname"),
      field: "lastName",
      visible: true
    },
    {
      title: formatMessage("hc-field-firstname"),
      field: "firstName",
      visible: true
    },
    {
      title: formatMessage("hc-field-email"),
      field: "email",
      visible: true
    },
    {
      title: formatMessage("hc-field-level-name"),
      field: "currentLevel.name",
      visible: true
    },
    {
      title: formatMessage("hc-field-level-ext-job-title"),
      field: "currentLevel.extJobTitle",
      visible: true,
      lookup: <Lookup dataSource={getExtJobTitles()} />
    },
    {
      title: formatMessage("hc-field-ext-id"),
      field: "extId",
      visible: true
    },
    //others (visible: false)
    {
      title: formatMessage("gender"),
      field: "gender",
      visible: false
      // lookup: <Lookup dataSource={genderValues} />
    },
    {
      title: formatMessage("hc-field-name"),
      field: "name",
      visible: false
    },
    {
      title: formatMessage("hc-field-sial-code"),
      field: "currentLevel.sialCode",
      visible: false
    },
    {
      title: formatMessage("hc-field-bip-role"),
      field: "bipRole",
      visible: false
    },
    {
      title: formatMessage("hc-field-office"),
      field: "office",
      visible: false,
      lookup: (
        <Lookup
          dataSource={offices.map((office) => {
            return {
              display: office.name + " - " + office.code,
              value: office.id
            }
          })}
          displayExpr="display"
          valueExpr="value"
        />
      )
    },
    {
      title: formatMessage("hc-field-area"),
      field: "area",
      visible: false
    },
    {
      title: formatMessage("hc-field-competence"),
      field: "competence",
      visible: false,
      lookup: <Lookup dataSource={competences} />
    },
    {
      title: formatMessage("hc-field-level-code"),
      field: "currentLevel.code",
      visible: false,
      lookup: <Lookup dataSource={levels} displayExpr="code" valueExpr="code" />
    },
    {
      title: formatMessage("hc-field-level-cost"),
      field: "currentLevel.cost",
      visible: false,
      dataType: "number",
      customizeText: (cellInfo) => {
        return "€" + (cellInfo.value ? roundSecondDecimal(cellInfo.value) : 0)
      },
      format: { currency: "EUR", type: "currency", precision: 2 }
    },
    {
      title: formatMessage("hc-field-level-starting-date"),
      field: "currentLevel.startDate",
      visible: false,
      dataType: "date",
      format: "dd-MM-yyyy"
    },
    {
      title: formatMessage("hc-field-hiring-date"),
      field: "joiningDate",
      visible: false,
      dataType: "date",
      format: "dd-MM-yyyy"
    },
    {
      title: formatMessage("hc-field-leaving-date"),
      field: "leavingDate",
      visible: false,
      dataType: "date",
      format: "dd-MM-yyyy"
    },
    {
      title: formatMessage("hc-field-total-worked-years"),
      field: "totWorkedYears",
      visible: false,
      dataType: "number",
      format: { type: "fixedPoint", precision: 2 }
    },
    {
      title: formatMessage("hc-field-worked-years-before-bip"),
      field: "workedYearsBeforeBip",
      visible: false,
      dataType: "number",
      format: { type: "fixedPoint", precision: 2 }
    },
    {
      title: formatMessage("hc-field-worked-years-in-bip"),
      field: "workedYearsInBip",
      visible: false,
      dataType: "number",
      format: { type: "fixedPoint", precision: 2 }
    },
    {
      title: formatMessage("hc-field-organization"),
      field: "organization",
      visible: false,
      lookup: <Lookup dataSource={organizations} displayExpr="name" valueExpr="id" />
    },
    {
      title: formatMessage("hc-field-updated-at"),
      field: "updated_at",
      visible: false,
      dataType: "date",
      format: "dd-MM-yyyy"
    },
    {
      title: formatMessage("hc-field-review-reader"),
      field: "reviewReader",
      visible: false,
      lookup: <Lookup dataSource={userNames} displayExpr="name" valueExpr="id" />
    },
    {
      title: formatMessage("hc-field-chargeability"),
      field: "chargeability",
      visible: false,
      dataType: "number"
    },
    {
      title: formatMessage("hc-field-notes"),
      field: "notes",
      visible: false
    },
    {
      title: formatMessage("hc-field-tags"),
      field: "tags",
      visible: false
    },
    {
      title: formatMessage("hc-field-contract"),
      field: "contract",
      visible: false
    },
    {
      title: formatMessage("hc-field-contract-type"),
      field: "contractType",
      visible: false,
      lookup: (
        <Lookup
          dataSource={contractTypes.map((contractType) => {
            return { display: contractType, value: contractType }
          })}
          displayExpr="display"
          valueExpr="value"
        />
      )
    },
    {
      title: formatMessage("hc-field-skills"),
      field: "skills",
      visible: false
    },
    {
      title: formatMessage("hc-field-contract-end"),
      field: "contractEnd",
      visible: false,
      dataType: "date",
      format: "dd-MM-yyyy"
    },
    {
      title: formatMessage("hc-field-order-end"),
      field: "orderEnd",
      visible: false,
      dataType: "date",
      format: "dd-MM-yyyy"
    },
    {
      title: formatMessage("hc-field-xtech-accountable"),
      field: "xTechAccountable",
      visible: false,
      lookup: <Lookup dataSource={userNames} displayExpr="name" valueExpr="id" />
    },
    {
      title: formatMessage("hc-field-supplier"),
      field: "supplier",
      visible: false,
      lookup: (
        <Lookup
          dataSource={suppliers.map((role) => {
            return { display: role, value: role }
          })}
          displayExpr="display"
          valueExpr="value"
        />
      )
    },
    {
      title: formatMessage("hc-field-equivalent-bip-level"),
      field: "equivalentBipLevel",
      visible: false,
      lookup: (
        <Lookup
          dataSource={levels
            .filter((l) => {
              return !l.isExternal
            })
            .map((l) => {
              return {
                displayName: l.name + " - " + l.code,
                code: l.code
              }
            })}
          displayExpr="displayName"
          valueExpr="code"
        />
      )
    },
    {
      title: "Team",
      field: "team",
      visible: false
    },
    {
      title: formatMessage("hc-field-team-account"),
      field: "isTeam",
      visible: false
    },
    {
      title: formatMessage("hc-field-global-competence"),
      field: "globalCompetence",
      visible: false,
      lookup: (
        <Lookup
          dataSource={globalCompetences.map((comp) => {
            if (comp === "Other")
              return { display: formatMessage("areas-other"), value: comp }
            else return { display: comp, value: comp }
          })}
          displayExpr="display"
          valueExpr="value"
        />
      )
    },
    {
      title: formatMessage("hc-field-circle"),
      field: "circle",
      visible: false,
      lookup: <Lookup dataSource={circleValues} />
    },
    {
      title: formatMessage("hc-field-area-code"),
      field: "areaShortcode",
      visible: false,
      dataType: "string"
    }
  ]
}
