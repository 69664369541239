import isExternal from "./IsExternal"

export default function isUserWithExternalPromotion(
  userLevels,
  levels
) {
  let found = false
  userLevels?.forEach((l) => {
    if ((new Date(l.startDate) > new Date()) && isExternal(l.code, levels)) {
      found =  true
    }
  })
  return found;
}
