import { useState } from "react"
import { getAllTeams, getTeam } from "../services/TeamsService"
import { parseProjectsFiltersToParams } from "../utils/parseProjectsFiltersToParams"
import { PREALLOCATION_PALETTE } from "../utils/preallocationPalette"

export const useTeams = () => {
  const [teams, setTeams] = useState([])
  const [isLoading, setIsLoadingTeams] = useState(false)
  const [loadError, setLoadTeamsError] = useState(null)
  const [paginationMeta, setPaginationMeta] = useState({})

  const loadTeams = async (filters) => {
    setIsLoadingTeams(true)
    setLoadTeamsError(null)

    try {
      const openedTeamsIds = teams.filter((team) => team.isOpen).map((t) => t.id)
      const pinned = teams.filter((team) => team.isPinned)
      const pinnedTeamsIds = pinned.map((p) => p.id)
      const params = parseProjectsFiltersToParams(filters)
      if (paginationMeta.take) {
        params.take = paginationMeta.take
      }
      let {
        data: { data: rawTeams, ...meta }
      } = await getAllTeams(params)
      const newTeams = mapTeamsAddDetails(rawTeams, pinnedTeamsIds).filter(
        (t) => !pinnedTeamsIds.includes(t.id)
      )
      const showedTeams = [...pinned, ...newTeams]
      setTeams(showedTeams)
      setPaginationMeta(meta)

      const teamIdsToOpen = showedTeams
        .filter((p) => openedTeamsIds.includes(p.id))
        .map((p) => p.id)
      await loadTeamDetails(teamIdsToOpen, filters)

      setIsLoadingTeams(false)
    } catch (e) {
      setLoadTeamsError(e)
      setIsLoadingTeams(false)
    }
  }

  const mapTeamsAddDetails = (teams, pinnedTeamsIds = []) =>
    teams.map((team, index) => ({
      ...team,
      color: PREALLOCATION_PALETTE[index % PREALLOCATION_PALETTE.length],
      isOpen: false,
      isPinned: pinnedTeamsIds.includes(team.id),
      details: null,
      isLoadingDetails: false,
      loadDetailsError: null
    }))

  const loadTeamDetails = async (teamIds, filters) => {
    if (!teamIds.length) {
      return
    }

    updateTeams(teamIds, () => ({
      isLoadingDetails: true,
      loadDetailsError: null,
      isOpen: true
    }))

    try {
      const params = parseProjectsFiltersToParams(filters)
      const { data: details } = await getTeam({ ...params, teamIds: teamIds })
      updateTeams(teamIds, (p) => ({
        isLoadingDetails: false,
        details: details.find((d) => d.id === p.id) || []
      }))
    } catch (e) {
      updateTeams(teamIds, () => ({
        isLoadingDetails: false,
        loadDetailsError: e
      }))
    }
  }

  const updateTeams = (teamIds, fn) => {
    setTeams((t) => t.map((t) => (teamIds.includes(t.id) ? { ...t, ...fn(t) } : t)))
  }

  const setTeam = (team) => {
    setTeams((p) => p.map((p) => (p.id !== team.id ? p : team)))
  }
  const setIsTeamOpen = (team, isOpen, filters) => {
    const newTeam = { ...team, isOpen }
    if (isOpen) {
      loadTeamDetails([newTeam.id], filters)
    } else {
      setTeam(newTeam)
    }
  }
  const setIsTeamPinned = (team, isPinned) => setTeam({ ...team, isPinned })

  const loadMore = async (filters) => {
    try {
      if (!paginationMeta.hasNextPage) {
        return
      }
      setLoadTeamsError(null)
      const params = parseProjectsFiltersToParams(filters)
      params.page = paginationMeta.page + 1
      if (paginationMeta.take) {
        params.take = paginationMeta.take
      }
      let {
        data: { data: rawTeams, ...meta }
      } = await getAllTeams(params)
      const newTeams = mapTeamsAddDetails(rawTeams)
      setTeams([...teams, ...newTeams])
      setPaginationMeta(meta)
    } catch (e) {
      setLoadTeamsError(e)
    }
  }

  const loadPage = async (filters, page, pageSize) => {
    try {
      if (page < 0) return
      page = page || 0
      pageSize = pageSize || paginationMeta.take
      const currentPage = (paginationMeta.page || 0) - 1
      if (
        !paginationMeta.page ||
        (currentPage === page && paginationMeta.take === pageSize)
      ) {
        return
      }
      setLoadTeamsError(null)
      const params = parseProjectsFiltersToParams(filters)
      params.page = page + 1
      params.take = pageSize
      let {
        data: { data: rawTeams, ...meta }
      } = await getAllTeams(params)
      const pinned = teams.filter((t) => t.isPinned)
      const newTeams = mapTeamsAddDetails(rawTeams).filter(
        (t) => !pinned.some((pin) => pin.id === t.id)
      )
      setTeams([...pinned, ...newTeams])
      setPaginationMeta(meta)
    } catch (e) {
      setLoadTeamsError(e)
    }
  }

  return {
    teams,
    isLoading,
    loadError,
    loadTeams,
    loadMore,
    loadTeamDetails,
    setIsTeamOpen,
    setIsTeamPinned,
    paginationMeta,
    loadPage
  }
}
