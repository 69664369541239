import { useMemo, useState } from "react"
import "./ResourceSlotCell.scss"
import ResourceActivity from "../components/ResourceActivity"
import { formatPercentage } from "../../../../Utils/formatPercentage"

export default function ResourceSlotCell({
  slot,
  maxRatio,
  showTotalBar = true,
  selected,
  selectable,
  onActivityClick,
  showFreeTime
}) {
  const [activitiesEl, setActivitiesEl] = useState(null)

  const total = useMemo(() => {
    return (
      slot.activities.reduce(
        (sum, activity) => sum + Math.round(activity.percent * 1e2) / 1e2,
        0
      ) * 100
    )
  }, [slot])

  const freePercent = useMemo(() => {
    return 1 - total / 100
  }, [total])

  const totalHeight = useMemo(() => {
    if (!activitiesEl) {
      return 0
    }

    const { height } = activitiesEl.getBoundingClientRect()

    return height
  }, [activitiesEl])

  const minAllocationHeight = 25 //this value controls the size of everything else, and represents the height in pixels of the shortest job

  const fullHeight = minAllocationHeight * 10

  const calcScaledHeight = (percentage, allocationRatio) => {
    const ratioToScale = 1 + maxRatio - allocationRatio
    return percentage * fullHeight * ratioToScale < minAllocationHeight
      ? minAllocationHeight
      : percentage * fullHeight * ratioToScale
  }

  return (
    <div className="resource-slot-cell">
      {!showFreeTime && showTotalBar && slot.activities.length > 0 && (
        <div
          className={`resource-slot-cell-total ${
            total.toFixed(0) > 100 ? "total-overflow" : ""
          } ${totalHeight < 30 ? "resource-slot-cell-total-sm" : ""}`}
          style={{ height: totalHeight + "px" }}
        >
          <span>{formatPercentage(total, 0)}</span>
        </div>
      )}
      <div ref={setActivitiesEl} className="resource-slot-cell-activities">
        {slot.activities.map((activity, index) => {
          return (
            <ResourceActivity
              key={index}
              className={showFreeTime && "shadowed-activity"}
              activity={activity}
              height={calcScaledHeight(activity.percent, slot.ratio || 1)}
              onClick={() => !activity?.readOnly && onActivityClick(activity)}
              selectable={selectable(activity)}
              selected={selected(activity)}
            />
          )
        })}
        {showFreeTime && total < 100 && (
          <ResourceActivity
            key="free"
            className="highlighted-activity"
            activity={{ name: "Free", percent: freePercent }}
            height={calcScaledHeight(freePercent, slot.ratio || 1)}
            onClick={() => onActivityClick({ percent: freePercent })}
          />
        )}
      </div>
    </div>
  )
}
