import { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"

export function useSelectJobSpecialties() {
  const config = useSelector((state) => state.Auth.config)
  useEffect(() => {
    setJobSpecialtiesValues(config.jobSpecialtiesValues || [])
  }, [config])

  const [jobSpecialtiesValues, setJobSpecialtiesValues] = useState(
    config?.jobSpecialtiesValues || [],
  )

  const getJobSpecialtiesValuesAsOptions = () =>
    jobSpecialtiesValues.map((v) => ({ id: v, cell: v }))

  const [jobSpecialtiesValueOptions, setJobSpecialtiesValueOptions] = useState(
    getJobSpecialtiesValuesAsOptions(),
  )

  useEffect(() => {
    setJobSpecialtiesValueOptions(getJobSpecialtiesValuesAsOptions())
  }, [jobSpecialtiesValues])

  return {
    jobSpecialtiesValues,
    jobSpecialtiesValueOptions,
  }
}
