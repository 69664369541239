import axios from "axios"
import { apiService } from "./APIServices"

export const getAllRoles = () => {
  return axios.get(apiService.role)
}

export const saveRoles = (roles, userId ) => {
  return axios.put(apiService.user + "/" + userId + "/roles", { roles })
}
