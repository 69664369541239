import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { getUsers } from "../../../../../services"
import { parseBipUserData } from "../../utils/parseBipUserData"

export function useSelectAccountableUsers() {
  const bipRoles = ["AM", "COEL", "DAM"]
  const { isLoading, isError, isSuccess, data, error } = useQuery(
    ["GET", "user", { bipRoles }],
    async () => {
      return await getUsers(bipRoles).then((data) => {
        return data.data.map((accountableUser) => parseBipUserData(accountableUser))
      })
    },
    { staleTime: 1000 * 60 * 2 }
  )

  const parseAccountableUserId = (accountableUserId) => {
    if (isError) {
      return "error"
    }

    if (isLoading) {
      return "-"
    }

    return (
      data.find((accountableUser) => accountableUser.id === accountableUserId) || "-"
    )
  }

  const getAccountableUsersAsOptions = () => {
    if (!isSuccess) {
      return []
    }
    return data.map((u) => ({ id: u.id, cell: u.fullName }))
  }
  const [accountableUserOptions, setAccountableUserOptions] = useState(
    getAccountableUsersAsOptions()
  )
  useEffect(() => {
    setAccountableUserOptions(getAccountableUsersAsOptions())
  }, [data])

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    parseAccountableUserId,
    accountableUserOptions,
  }
}
