import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

export function useSelectForceDC() {
  const config = useSelector((state) => state.Auth.config)

  useEffect(() => {
    if (config.forceDCValues) {
      setValues(config.forceDCValues)
    }
  }, [config])

  const [values, setValues] = useState(config?.forceDCValues || [])
  const getValuesAsOption = () => values.map((v) => ({ id: v.code, cell: v.name }))

  const [forceDCOptions, setValueOptions] = useState(getValuesAsOption())
  useEffect(() => {
    setValueOptions(getValuesAsOption())
  }, [values])

  return { values, forceDCOptions }
}
