import { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"

export function useSelectSubType() {
  const config = useSelector((state) => state.Auth.config)
  useEffect(() => {
    if (config.wbsSubTypes) {
      setSubTypes(config.wbsSubTypes)
    }
  }, [config])

  const [subTypes, setSubTypes] = useState(config?.wbsSubTypes || [])
  const parseSubType = (subTypeCode) => {
    return subTypes.find((subType) => subType.code === subTypeCode)?.name || "-"
  }

  const getSubTypesAsOption = () =>
    subTypes.map((t) => ({ id: t.code, cell: t.name }))
  const [subTypeOptions, setSubTypeOptions] = useState(getSubTypesAsOption())
  useEffect(() => {
    setSubTypeOptions(getSubTypesAsOption())
  }, [subTypes])

  return { subTypes, parseSubType, subTypeOptions }
}
