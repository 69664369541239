import { formatMessage } from "devextreme/localization"
import Tooltip from "./common/Tooltip"
import Icon from "./Icon"

export default function ClearFiltersButton({ resetAllFilters }) {
  return (
    <Tooltip
      content={
        <div className="text-nowrap">{formatMessage("jw-Clear filters")}</div>
      }
    >
      <button
        className={`btn btn-outline-secondary ml-2`}
        style={{ backgroundColor: "white" }}
        onClick={resetAllFilters}
      >
        <Icon
          svgStyles={{ width: "16px", height: "16px" }}
          iconRelativePath="monade/clear-filter"
        />
      </button>
    </Tooltip>
  )
}
