export default Object.freeze({
  Roles: {
    INTERNAL_SUPPORTER: "Internal Supporter",
    EXTERNAL_SUPPORTER: "External Supporter",
  },
  Permissions: {
    MANAGE_WBS: {
      action: "manage",
      subject: "Wbs"
    }
  },
  JobSpecialties: {
    MARTECH: "Martech",
    DATWAVE: "Datwave",
    GENERATIVE: "Generative"
  }
})
