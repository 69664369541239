import Icon from "./Icon"
import "./Buttons.scss"
import "./InputDefaults.scss"

export default function Chevron({
  className,
  style,
  isExpanded,
  setIsExpanded,
  disabled = false,
}) {
  const toggle = () => {
    setIsExpanded((prev) => {
      return !prev
    })
  }
  const svgStyles = { width: "15px" }
  return (
    <button
      disabled={disabled}
      className={`${className} ${disabled ? "disabled" : ""} square-btn btn ${
        !disabled ? "btn-outline-primary" : "btn-outline-secondary"
      }`}
      onClick={() => toggle()}
      style={style}
    >
      <Icon
        svgStyles={svgStyles}
        iconRelativePath={`monade/arrow-${!isExpanded || disabled ? "down" : "up"}`}
      />
    </button>
  )
}
