import { roundSecondDecimal } from "./RoundSecondDecimal"

export const calcTotWorkedYears = (workedYearsBeforeBip, workedYearsInBip) => {
  let totWorkedYears = 0
  if (workedYearsBeforeBip) {
    totWorkedYears = workedYearsBeforeBip
  }
  if (workedYearsInBip) {
    totWorkedYears += workedYearsInBip
  }
  totWorkedYears = roundSecondDecimal(totWorkedYears)

  return totWorkedYears
}
