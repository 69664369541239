import {
  CustomRule,
  Form,
  GroupItem,
  Item,
  Label,
  SimpleItem
} from "devextreme-react/form"
import { formatMessage } from "devextreme/localization"
import { Fragment, useEffect } from "react"
import { calcChargeability } from "../Utils/CalcChargeability"
import { calcExtJobTitle } from "../Utils/CalcExtJobTitle"
import FormGroupTitle from "../Components/FormGroupTitle"
import { FormValidator } from "../Utils/FormValidator"
import clone from "../Utils/Clone"
import _ from "underscore"
import Checkbox from "../../../JobAndWbs/components/input/Checkbox"
import { useState } from "react"

export default function InformationsForm({ setErrors, genderValues, ...props }) {
  const errors = props.errors ?? []

  useEffect(() => {
    props.validateForms()
  }, [])

  const validator = new FormValidator()

  function configureForm(ref) {
    validator.validationGroup = ref
    props.registerForm("informations", ref)
  }

  const rule = (fieldName) => (
    <CustomRule
      validationCallback={validator.isValid(fieldName, errors)}
      message={validator.getErrorMessage(fieldName, errors)}
    ></CustomRule>
  )

  const isOrganizationDisabled = (organization) => {
    return organization.isDeleted || !organization.owner
  }

  const [isTeam, setIsTeam] = useState(props.user.isTeam)

  useEffect(() => {
    setIsTeam(props.user.isTeam)
  }, [props.user.isTeam])

  const handleCheckboxChange = (event) => {
    setIsTeam(event.target.checked)
  }

  return (
    <Fragment>
      <div className="form-base" style={props.container}>
        <Form
          ref={configureForm}
          labelLocation="top"
          formData={props.user}
          validationGroup="userInformationsForm"
        >
          <GroupItem cssClass="with-separator pb-4" colCount={12}>
            <GroupItem colSpan={3}>
              <Item>
                <FormGroupTitle
                  title={formatMessage("hc-header-basic-info")}
                  required={true}
                  requiredForEnabling={true}
                />
              </Item>
            </GroupItem>
            <GroupItem colCount={2} colSpan={9}>
              <SimpleItem
                dataField="firstName"
                editorOptions={{
                  maxLength: 50,
                  onValueChanged: (e) => {
                    setErrors([])
                    props.setUser((prevUser) => ({
                      ...prevUser,
                      name: e.value.trim() + " " + props.user.lastName.trim(),
                      firstName: e.value
                    }))
                  }
                }}
              >
                <Label
                  showColon={false}
                  text={formatMessage("hc-field-firstname") + " *"}
                />
                {rule("firstName")}
              </SimpleItem>
              <SimpleItem
                dataField="lastName"
                editorOptions={{
                  maxLength: 50,
                  onValueChanged: (e) => {
                    setErrors([])
                    props.setUser((prevUser) => ({
                      ...prevUser,
                      name: props.user.firstName.trim() + " " + e.value.trim(),
                      lastName: e.value
                    }))
                  }
                }}
              >
                <Label
                  showColon={false}
                  text={formatMessage("hc-field-lastname") + " *"}
                />
                {rule("lastName")}
              </SimpleItem>
              <SimpleItem dataField="email">
                <Label
                  showColon={false}
                  text={
                    isTeam
                      ? formatMessage("hc-field-email-optional")
                      : formatMessage("hc-field-email") + " *"
                  }
                />
                {rule("email")}
                <CustomRule
                  validationCallback={(e) => {
                    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/
                    return regex.test(e.value)
                  }}
                  message={formatMessage("hc-error-invalid-mail-format")}
                />
              </SimpleItem>

              <SimpleItem dataField="name" editorOptions={{ readOnly: true }}>
                <Label showColon={false} text={formatMessage("hc-field-name")} />
              </SimpleItem>

              <SimpleItem
                dataField="extId"
                editorOptions={{
                  maxLength: 10,
                  inputAttr: { style: "text-transform: uppercase" }
                }}
              >
                <Label
                  showColon={false}
                  text={formatMessage("hc-field-ext-id") + " *"}
                />
                {rule("extId")}
              </SimpleItem>
              <SimpleItem
                dataField="joiningDate"
                editorType="dxDateBox"
                editorOptions={{
                  onValueChanged: () => {
                    setErrors([])
                    props.setUser((prev) => ({
                      ...prev,
                      chargeability: calcChargeability(props.user)
                    }))
                  }
                }}
              >
                <Label
                  showColon={false}
                  text={formatMessage("hc-field-hiring-date") + " ■"}
                />
                {rule("joiningDate")}
              </SimpleItem>
              <SimpleItem
                dataField="leavingDate"
                editorType="dxDateBox"
                editorOptions={{
                  onValueChanged: () => {
                    setErrors([])
                    props.setUser((prev) => ({
                      ...prev,
                      chargeability: calcChargeability(props.user)
                    }))
                  }
                }}
              >
                <Label
                  showColon={false}
                  text={formatMessage("hc-field-leaving-date")}
                />
                {rule("leavingDate")}
              </SimpleItem>
              <SimpleItem
                dataField="gender"
                editorType="dxLookup"
                editorOptions={{
                  dataSource: [
                    { display: formatMessage("hc-placeholder-gender"), value: null },
                    ...genderValues.map((gender) => {
                      return { display: gender, value: gender }
                    })
                  ],
                  displayExpr: "display",
                  valueExpr: "value",
                  onValueChanged: (e) => {
                    setErrors([])
                    props.setUser((prevUser) => ({ ...prevUser, gender: e.value }))
                  },
                  value: props.user.gender,
                  placeholder: formatMessage("hc-placeholder-gender"),
                  dropDownOptions: {
                    title: formatMessage("gender"),
                    closeOnOutsideClick: true
                  },
                  searchEnabled: false,
                  showCancelButton: false
                }}
              >
                <Label showColon={false} text={formatMessage("gender")} />
                {rule("gender")}
              </SimpleItem>

              <SimpleItem>
                <Checkbox
                  label={formatMessage("hc-create-team-account")}
                  value={props.user.isTeam}
                  onChange={(event) => {
                    handleCheckboxChange(event)
                    props.setUser((prevUser) => ({
                      ...prevUser,
                      isTeam: event.target.checked
                    }))
                  }}
                ></Checkbox>
              </SimpleItem>
            </GroupItem>
          </GroupItem>
          <GroupItem className="group" colCount={12}>
            <GroupItem colSpan={3}>
              <Item>
                <FormGroupTitle
                  title={formatMessage("hc-header-organizational-info")}
                  required={false}
                  requiredForEnabling={true}
                />
              </Item>
            </GroupItem>
            <GroupItem colCount={2} colSpan={9}>
              <SimpleItem
                dataField="organization"
                editorType="dxLookup"
                editorOptions={{
                  dataSource: [
                    {
                      name: formatMessage("hc-placeholder-organization"),
                      id: null
                    },
                    ...props.organizations
                      .filter((organization) => {
                        if (!props.user.organization && organization.isDeleted) {
                          return false
                        }
                        return props.user.organization === organization.id
                          ? true
                          : !organization.isDeleted
                      })
                      .map((organization) => {
                        return {
                          disabled: isOrganizationDisabled(organization),
                          ...organization
                        }
                      })
                  ],
                  onValueChanged: (e) => {
                    setErrors([])
                    props.setUser((prevUser) => ({
                      ...prevUser,
                      organization: e.value
                    }))
                  },
                  displayExpr: "name",
                  valueExpr: "id",
                  value: props.user.organization,
                  placeholder: formatMessage("hc-placeholder-organization"),
                  dropDownOptions: {
                    title: formatMessage("hc-field-organizations"),
                    closeOnOutsideClick: true
                  },
                  searchEnabled: true,
                  showCancelButton: false
                }}
              >
                <Label
                  showColon={false}
                  text={formatMessage("hc-field-organization") + " ■"}
                />
                {rule("organization")}
              </SimpleItem>

              <SimpleItem
                dataField="bipRole"
                editorType="dxLookup"
                editorOptions={{
                  dataSource: [
                    { display: formatMessage("hc-placeholder-role"), value: null },
                    ...props.bipRoles.map((role) => {
                      return { display: role, value: role }
                    })
                  ],
                  displayExpr: "display",
                  valueExpr: "value",
                  onValueChanged: (e) => {
                    setErrors([])
                    props.setUser((prevUser) => ({ ...prevUser, bipRole: e.value }))
                  },
                  value: props.user.bipRole,
                  placeholder: formatMessage("hc-placeholder-role"),
                  dropDownOptions: {
                    title: formatMessage("hc-field-roles"),
                    closeOnOutsideClick: true
                  },
                  searchEnabled: false,
                  showCancelButton: false
                }}
              >
                <Label
                  showColon={false}
                  text={formatMessage("hc-field-bip-role") + " ■"}
                />
                {rule("bipRole")}
              </SimpleItem>
              <SimpleItem
                dataField="office"
                editorType="dxLookup"
                editorOptions={{
                  dataSource: [
                    { display: formatMessage("hc-placeholder-office"), value: null },
                    ...props.offices.map((office) => {
                      return {
                        display: office.name + " - " + office.code,
                        value: office.id
                      }
                    })
                  ],
                  displayExpr: "display",
                  valueExpr: "value",
                  onValueChanged: (e) => {
                    setErrors([])
                    props.setUser((prevUser) => ({ ...prevUser, office: e.value }))
                  },
                  value: props.user.office,
                  placeholder: formatMessage("hc-placeholder-office"),
                  dropDownOptions: {
                    title: formatMessage("hc-field-offices"),
                    closeOnOutsideClick: true
                  },
                  searchEnabled: false,
                  showCancelButton: false
                }}
              >
                <Label
                  showColon={false}
                  text={formatMessage("hc-field-office") + " ■"}
                />
                {rule("office")}
              </SimpleItem>
              <SimpleItem
                dataField="competence"
                editorType="dxLookup"
                editorOptions={{
                  dataSource: [
                    {
                      display: formatMessage("hc-placeholder-competence"),
                      value: null
                    },
                    ...props.competences.map((role) => {
                      return { display: role, value: role }
                    })
                  ],
                  displayExpr: "display",
                  valueExpr: "value",
                  onValueChanged: (e) => {
                    setErrors([])
                    let newUser = clone(props.user)
                    newUser.competence = e.value
                    if (newUser.currentLevel) {
                      newUser.currentLevel.extJobTitle = calcExtJobTitle(
                        newUser.currentLevel.code,
                        newUser.competence,
                        props.levels
                      )
                    }
                    props.setUser(newUser)
                  },
                  value: props.user.competence,
                  placeholder: formatMessage("hc-placeholder-competence"),
                  dropDownOptions: {
                    title: formatMessage("hc-field-competences"),
                    closeOnOutsideClick: true
                  },
                  searchEnabled: false,
                  showCancelButton: false
                }}
              >
                <Label
                  showColon={false}
                  text={formatMessage("hc-field-competence")}
                />
                {rule("competence")}
              </SimpleItem>
              <SimpleItem
                dataField="globalCompetence"
                editorType="dxLookup"
                editorOptions={{
                  dataSource: [
                    {
                      display: formatMessage("hc-placeholder-global-competence"),
                      value: null
                    },
                    ...props.globalCompetences.map((comp) => {
                      if (comp === "Other")
                        return {
                          display: formatMessage("global-competence-other"),
                          value: comp
                        }
                      else return { display: comp, value: comp }
                    })
                  ],
                  displayExpr: "display",
                  valueExpr: "value",
                  onValueChanged: (e) => {
                    setErrors([])
                    props.setUser((prevUser) => ({
                      ...prevUser,
                      globalCompetence: e.value
                    }))
                  },
                  value: props.user.globalCompetence,
                  placeholder: formatMessage("hc-placeholder-global-competence"),
                  dropDownOptions: {
                    title: formatMessage("hc-field-global-competences"),
                    closeOnOutsideClick: true
                  },
                  searchEnabled: false,
                  showCancelButton: false
                }}
              >
                <Label
                  showColon={false}
                  text={formatMessage("hc-field-global-competence") + " ■"}
                />
                {rule("globalCompetence")}
              </SimpleItem>
              <SimpleItem
                dataField="circle"
                editorType="dxTagBox"
                editorOptions={{
                  searchEnabled: false,
                  dataSource: [
                    ...props.circleValues.map((value) => {
                      return { display: value, value: value }
                    })
                  ],
                  displayExpr: "display",
                  valueExpr: "value",
                  value: props.user.circle,
                  onValueChanged: (e) => {
                    // this condition avoids setState loop on page initial render, somehow Devextreme doesn't recognize changes on array
                    if (!_.isEqual(props.user.circle, e.value)) {
                      setErrors([])
                      props.setUser((prevUser) => ({
                        ...prevUser,
                        circle: e.value
                      }))
                    }
                  }
                }}
              >
                <Label showColon={false} text={formatMessage("hc-field-circle")} />
                {rule("circle")}
              </SimpleItem>
            </GroupItem>
          </GroupItem>
        </Form>
      </div>
    </Fragment>
  )
}
