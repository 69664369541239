import {LabelWithError} from "./LabelWithError"
import "./ToggleButtonGroup.scss"
import "./InputDefaults.scss"

export default function ToggleButtonGroup({
  className,
  options,
  value,
  onChange,
  label,
  inputClassName,
  wrapperStyle,
  error = "",
  readOnly = false,
  disabled = false
}) {
  const randomId = Math.random().toString()

  return (
    <div
      style={wrapperStyle}
      className={`${className} btn-group-wrapper ${readOnly ? "readonly" : disabled ? "disabled" : "cursor"}`}
    >
      {label ? (
        <LabelWithError
          disabled={disabled}
          readOnly={readOnly}
          label={label}
          error={error}
          labelClassName={`mb-1`}
          htmlFor={randomId}
        />
      ) : (
        ""
      )}
      <div id={randomId} className="btn-group">
        {
          options.map(option => (
            <button
              type="button"
              key={option.id}
              className={ `btn ${value === option.id ? 'btn-primary' : 'btn-outline-primary'}` }
              onClick={() => onChange(option.id)}
            >
              { option.label.toUpperCase() }
            </button>
          ))
        }
      </div>
    </div>
  )
}
