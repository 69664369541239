import { useEffect, useRef, useState } from "react"
import { formatPercentage } from "../../../../../Utils/formatPercentage"
import { LabelWithError } from "../LabelWithError"
import "./PercentageInput.scss"
import "../InputDefaults.scss"

export default function PercentageInput({
  className,
  inputClassName,
  inputStyles,
  value,
  onChange,
  placeholder = "",
  label,
  readOnly = false,
  disabled = false,
  prefocus = false,
  error = "",
}) {
  const [parsedAmount, setParsedAmount] = useState(formatPercentage(value))
  useEffect(() => {
    setParsedAmount(formatPercentage(value))
  }, [value])

  const [isEditing, setIsEditing] = useState(false)

  const inputRef = useRef(null)
  const randomId = Math.random().toString()
  const containerRef = useRef(null)

  useEffect(() => {
    if (prefocus) {
      focus()
    }
  }, [])

  useEffect(() => {
    if (isEditing) {
      focus()
    }
  }, [isEditing])

  const focus = (e) => {
    inputRef.current?.focus()
  }

  const valueChanged = (event) => {
    if (event.target.value > 100 || event.target.value < 0) {
      return
    }
    if (onChange) {
      onChange(event)
    }
  }

  return (
    <div
      ref={containerRef}
      className={`${
        className ? className : ""
      } percentage-input-container d-flex flex-column ${
        readOnly ? "readonly" : disabled ? "disabled" : "cursor"
      }`}
    >
      {label ? (
        <LabelWithError
          label={label}
          error={error}
          htmlFor={randomId}
          labelClassName={`mb-2`}
          disabled={disabled}
          readOnly={readOnly}
        />
      ) : (
        ""
      )}
      {isEditing && !readOnly && !disabled ? (
        <input
          className={`w-100 ${inputClassName} ${
            error !== "" ? "border-danger" : ""
          } ${readOnly ? "readonly" : disabled ? "disabled" : "cursor"}`}
          id={randomId}
          style={inputStyles}
          placeholder={placeholder}
          value={value}
          onChange={valueChanged}
          type="number"
          min={0}
          max={100}
          step={1}
          disabled={disabled || readOnly}
          onBlur={() => {
            setIsEditing(false)
          }}
        />
      ) : (
        <input
          onFocus={() => {
            setIsEditing(true)
          }}
          className={`w-100 ${inputClassName} ${
            readOnly ? "readonly" : disabled ? "disabled" : "cursor"
          } ${error !== "" ? "border-danger" : ""}`}
          id={randomId}
          style={inputStyles}
          placeholder={placeholder}
          value={parsedAmount}
          onChange={() => {}}
          disabled={disabled || readOnly}
        />
      )}
    </div>
  )
}
