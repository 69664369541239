export function calcBiggestPercentageRatio(slots) {
  let maxRatio = 1
  slots.forEach((slot) => {
    let graphicalPercentage = 0
    let realPercentage = 0
    slot.activities.forEach((job) => {
      if (job.percent < 0.1) {
        graphicalPercentage += 0.1
      } else {
        graphicalPercentage += job.percent
      }
      realPercentage += job.percent
    })

    if (realPercentage === 0) {
      realPercentage = 1
    } else if (realPercentage < 0.1) {
      realPercentage = 0.1
    }

    slot.ratio = graphicalPercentage / realPercentage
    if (slot.ratio > maxRatio) {
      maxRatio = slot.ratio
    }
  })
  return maxRatio
}
