import { useMutation, useQueryClient } from "@tanstack/react-query"
import { formatMessage } from "devextreme/localization"
import { useState } from "react"
import { withRouter } from "react-router-dom"
import { deleteJob } from "../../../../services"
import {
  showSuccessToast,
  showToastError,
} from "../../Headcount/Main/Utils/ErrorHandling"
import ConfirmModal from "./common/ConfirmModal"
import { Spinner } from "./Spinner"

export default withRouter(function DeleteJobButton({ job, disabled, history }) {
  const [isOpen, setIsOpen] = useState(false)

  const queryClient = useQueryClient()

  const mutation = useMutation(
    async () => {
      return await deleteJob(job._id)
    },
    {
      onSuccess: (e) => {
        queryClient.invalidateQueries(["GET", "wbs"])
        history.push("/admin/Job-and-wbs")
        showSuccessToast(formatMessage("jw-Job deleted successfully"))
      },
      onError: (e) => {
        if (e.response) {
          showToastError(e.response)
        } else {
          showToastError(null, e.message)
        }
      },
    },
  )
  return (
    <>
      <ConfirmModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onConfirm={() => {
          setIsOpen(false)
          mutation.mutate(job.shortCode)
        }}
        onCancel={() => {
          setIsOpen(false)
        }}
        title={formatMessage("jw-Confirmation")}
        body={formatMessage("jw-Are you sure?")}
        confirmButtonText={formatMessage("jw-Delete")}
        confirmButtonClassName={"btn-danger"}
        cancelButtonText={formatMessage("jw-Cancel")}
      />
      <button
        disabled={disabled && mutation.isLoading}
        onClick={() => {
          setIsOpen(true)
        }}
        className="btn btn-outline-danger"
      >
        {mutation.isLoading && <Spinner className={"mx-1"} />}{" "}
        {formatMessage("jw-DELETE")} {formatMessage("jw-JOB")}
      </button>
    </>
  )
})
