import { useProjectsView } from "../hooks/useProjectsView"
import { useEffect } from "react"
import Filters from "./Filters"
import { SidePanel } from "../components/SidePanel"
import { AllocationForm } from "../EditAllocation/AllocationForm"
import { EditMultipleAllocationsForm } from "../EditAllocation/EditMultipleAllocationsForm"
import moment from "moment"
import { useAllocations } from "../hooks/useAllocations"
import { OpaqueOverlay } from "../components/OpaqueOverlay"
import { useExcludeOverlay } from "../hooks/useExcludeOverlay"
import { useDebounce } from "../hooks/useDebounce"
import ProjectsCalendar from "./ProjectsCalendar"
import { formatMessage } from "devextreme/localization"
import { useConfirm } from "../components/ConfirmModal"
import Paginator from "../components/Paginator"
import { useAppAbility } from "../../../../Context/ability"

export default function ProjectsView() {
  const {
    filters,
    setFilters,
    areAllResourcesShown,
    projects,
    isLoadingProjects,
    loadProjectsError,
    loadProjects,
    loadPage,
    paginationMeta,
    toggleIsOpen,
    toggleIsPinned,
    toggleAreAllResourcesShown,
  } = useProjectsView()

  const {
    createAllocation,
    updateAllocation,
    updateMultipleAllocation,
    deleteMultipleAllocations,
    errors: allocationErrors,
    resetErrors: resetAllocationErrors,
    editingAllocation,
    editAllocation,
    editMultiple,
    isEditingMultiple,
    toggleAllocation,
    selectedAllocations,
    projects: projectList,
    loadAllocationData,
    loadProjects: loadProjectList,
  } = useAllocations()

  const confirmModal = useConfirm()

  const { overlayBoundingRect, updateExcludeRef, clearOverlayBoundingRect } =
    useExcludeOverlay()

  const { debounce } = useDebounce(300)

  useEffect(() => {
    loadProjects(filters)
    loadAllocationData()
  }, [])

  useEffect(() => {
    const slotCount = projects[0]?.timeSlotsNumber
    const startDate =
      projects[0]?.projectTimeSlots[0]?.startDate || filters.startDate
    const endDate =
      slotCount &&
      moment(startDate)
        .add(slotCount, filters.aggregation === "WEEKLY" ? "week" : "month")
        .subtract(1, "day")
        .toDate()
    loadProjectList(startDate, endDate)
  }, [projects])

  const { can } = useAppAbility()
  const canShowDetails = can("read", "AllocationDetails")
  const canViewProjectDetails = can("read", "JobAdmin")

  const onFilterChange = (filter, reload = true) => {
    const changedFilterKeys = Object.keys(filter)

    if (
      !changedFilterKeys.some(
        (key) => JSON.stringify(filter[key]) !== JSON.stringify(filters[key])
      )
    ) {
      return
    }

    const newFilters = {
      ...filters,
      ...filter,
    }

    setFilters(newFilters)

    if (reload) debounce(async () => await loadProjects(newFilters))
  }

  const onPreviousClick = () => {
    onFilterChange({
      startDate: moment(filters.startDate)
        .subtract(1, filters.aggregation === "WEEKLY" ? "week" : "month")
        .toDate(),
    })
  }

  const onNextClick = () => {
    onFilterChange({
      startDate: moment(filters.startDate)
        .add(1, filters.aggregation === "WEEKLY" ? "week" : "month")
        .toDate(),
    })
  }

  const onRemoveResource = async (project, resource) => {
    if (
      await confirmModal.confirm({
        title: formatMessage("preallocation-confirm-delete-title"),
        body: formatMessage(
          "preallocation-confirm-delete-resource",
          resource.name || resource.fullName
        ),
        confirmButtonText: formatMessage("preallocation-delete"),
        confirmButtonClassName: "btn-danger",
      })
    ) {
      await deleteMultipleAllocations([
        {
          jobId: project.id,
          userId: resource.id,
          startDate: project.jobStartDate,
          endDate: project.jobEndDate,
        },
      ])
      loadProjects(filters)
    }
  }

  const onAddAllocation = (project, resource) => {
    resetAllocationErrors()
    const initAllocation = {
      startDate: project.jobStartDate,
      endDate: project.jobEndDate,
      specificStartDate: project.jobStartDate,
      specificEndDate: project.jobEndDate,
    }

    editAllocation({
      project,
      resource,
      initAllocation,
      minDate: project?.jobStartDate || filters.startDate,
      maxDate:
        project?.jobEndDate ||
        (filters.aggregation === "WEEKLY"
          ? moment(filters.startDate).add(6, "week").toDate()
          : moment(filters.startDate).add(6, "month").toDate()),
    })
  }

  const onEditAllocation = (project, resource, rawAllocation, slot) => {
    const allocation = {
      ...rawAllocation,
      startDate: slot.startDate,
      endDate: slot.endDate,
    }
    if (isEditingMultiple) {
      toggleAllocation({
        ...allocation,
        projectId: project.id,
        resourceId: resource.id,
      })
      return
    }
    resetAllocationErrors()
    editAllocation({
      project,
      resource,
      allocation,
      minDate: project?.jobStartDate || filters.startDate,
      maxDate:
        project?.jobEndDate ||
        (filters.aggregation === "WEEKLY"
          ? moment(filters.startDate).add(6, "week").toDate()
          : moment(filters.startDate).add(6, "month").toDate()),
    })
  }

  const onEditMultipleAllocations = (project, resource, rowRef) => {
    if (isEditingMultiple) {
      return
    }
    clearOverlayBoundingRect(null)
    editMultiple({ project, resource })
    updateExcludeRef(rowRef)
    rowRef?.scrollIntoView({ behavior: "smooth", block: "center" })
  }

  const onSaveAllocation = async ({ isNew, ...allocation }) => {
    if (isNew) {
      await createAllocation(allocation)
    } else {
      const oldAllocation = {
        userId: editingAllocation.resource.id,
        jobId: editingAllocation.project.id,
        startDate: editingAllocation.allocation.specificStartDate,
        endDate: editingAllocation.allocation.specificEndDate,
      }
      await updateAllocation(oldAllocation, allocation)
    }
    editAllocation(null)
    loadProjects(filters)
  }

  const onSaveMultipleAllocations = async (editData) => {
    try {
      const allocations = {
        ...editData,
        selectedAllocations: selectedAllocations.map((a) => ({
          jobId: a.id,
          startDate: a.specificStartDate,
          endDate: a.specificEndDate,
        })),
      }
      await updateMultipleAllocation(editData.userId, allocations)
      editAllocation(null)
      loadProjects(filters)
    } catch (error) {}
  }

  const onDeleteMultipleAllocations = async () => {
    try {
      if (
        selectedAllocations?.length > 0 &&
        (await confirmModal.confirm({
          title: formatMessage("preallocation-confirm-delete-title"),
          body: formatMessage("preallocation-confirm-delete-multiple-resource"),
          confirmButtonText: formatMessage("preallocation-delete"),
          confirmButtonClassName: "btn-danger",
        }))
      ) {
        await deleteMultipleAllocations(
          selectedAllocations.map((a) => ({
            jobId: a.id,
            userId: a.resourceId,
            startDate: a.specificStartDate,
            endDate: a.specificEndDate,
          }))
        )
        editAllocation(null)
        loadProjects(filters)
      }
    } catch (error) {}
  }

  return (
    <>
      <SidePanel
        show={editingAllocation || isEditingMultiple}
        onClose={() => editAllocation(null)}
        overlay={
          <OpaqueOverlay exclude={isEditingMultiple && overlayBoundingRect} />
        }
        collapsible={isEditingMultiple}
      >
        {isEditingMultiple ? (
          <EditMultipleAllocationsForm
            {...editingAllocation}
            selected={selectedAllocations}
            onCancel={() => editAllocation(null)}
            onSave={onSaveMultipleAllocations}
            onDelete={onDeleteMultipleAllocations}
            errors={allocationErrors}
          />
        ) : (
          <AllocationForm
            {...editingAllocation}
            onCancel={() => editAllocation(null)}
            onSave={onSaveAllocation}
            errors={allocationErrors}
          />
        )}
      </SidePanel>
      <Filters
        className="w-100 mb-4 pb-2"
        filters={filters}
        onFiltersChange={onFilterChange}
        areAllResourcesShown={areAllResourcesShown}
        onToggleShowAll={toggleAreAllResourcesShown}
        projects={projectList}
        showShowDetails={canShowDetails}
        isLoading={isLoadingProjects || projects.some((p) => p.isLoadingDetails)}
        searchPlaceholder={formatMessage(
          "preallocation-filters-projects-search-placeholder"
        )}
      />
      <ProjectsCalendar
        projects={projects}
        filters={filters}
        selectedAllocations={selectedAllocations}
        isEditingMultiple={isEditingMultiple}
        editingAllocation={editingAllocation}
        isLoadingProjects={isLoadingProjects}
        loadProjectsError={loadProjectsError}
        canViewProjectDetails={canViewProjectDetails}
        onReload={() => loadProjects(filters)}
        toggleIsOpen={toggleIsOpen}
        toggleIsPinned={toggleIsPinned}
        onAddAllocation={onAddAllocation}
        onEditAllocation={onEditAllocation}
        onEditMultipleAllocations={onEditMultipleAllocations}
        onRemoveResource={onRemoveResource}
        onPreviousClick={onPreviousClick}
        onNextClick={onNextClick}
      />
      <Paginator
        className="mt-4"
        currentPage={paginationMeta.page - 1}
        lastPage={paginationMeta.pageCount - 1}
        onPageChange={(page) => loadPage(filters, page)}
        pageSize={paginationMeta.take}
        setPageSize={(pageSize) => loadPage(filters, 0, pageSize)}
        totalCount={paginationMeta.totalCount}
        amountCurrentlyVisualizing={projects.length}
      />
    </>
  )
}
