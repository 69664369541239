import LinearProgress from "@material-ui/core/LinearProgress"
import ArrayStore from "devextreme/data/array_store"
import { formatMessage } from "devextreme/localization"
import React, { Fragment, useEffect, useReducer, useState } from "react"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import { useSelector } from "react-redux"
import { Bounce, toast } from "react-toastify"
import { Card, CardBody, CardTitle, Col, Form, Row } from "reactstrap"
import ToggleSwitch from "../../../assets/components/switch/ToggleSwitch"
import { useAppAbility } from "../../../Context/ability"
import {
  getAllWbs,
  reopenTimesheet,
  sendFirstReview,
  sendInChargeReviews,
  sendPpa,
  sendPreReview,
  sendThirdReview,
  closeReviews
} from "../../../services"
import AlertDialog from "../Utils/AlertDialog"
import { AddRow } from "./addRow"
import ExportAllButton, { exportReviewModes } from "./components/exportReviewButton"
import { ReviewTable } from "./table/reviewTable"

export default function ThirdReview({
  currentPeriod,
  updateJobThumb,
  openAlert,
  setOpenAlert,
  titleAlert,
  setTitleAlert,
  messageAlert,
  setMessageAlert,
  isAlert,
  setIsAlert,
  statusToSend,
  setStatusToSend,
  sendBulkError,
  setSendBulkError,
  assignedTs,
  setAssignedTs
}) {
  const jobs = useSelector(
    (state) =>
      new ArrayStore({
        data: state.Jobs.forReview,
        key: "_id"
      })
  )
  const config = useSelector((state) => state.Auth.config)
  const [unitIsHours, setUnitIsHours] = useState(true)
  const [jobId, setJobId] = useState(null)
  const [tsId, setTsId] = useState(true)
  const [ds, setDs] = useState(null) // data source
  const [dataToUpdate, setDataToUpdate] = useState(null)
  const [addRow, setAddRow] = useState(false)
  const [sendError, setSendError] = useState("")
  const [invalidJobs, setInvalidJobs] = useState([])
  const [invalidTimesheets, setInvalidTimesheets] = useState([]) // list of timesheets that contain invalid jobs
  const [update, forceUpdate] = useReducer((x) => x + 1, 0) // necessary to force component update
  const [invalidWbsCodes, setInvalidWbsCodes] = useState([]) // codes of tmp or non-final wbs
  const [dataLoaded, setDataLoaded] = useState(true)

  const { can } = useAppAbility()

  let dgRef = React.useRef(null)

  useEffect(() => {
    getAllWbs().then(({ data }) => {
      data = data
        .filter((wbs) => !wbs.isFinal || wbs.isTempPlaced)
        .map((wbs) => wbs.code)
      data.push(config.wbsTempCode)
      setInvalidWbsCodes(data)
    })
    updateJobThumb()
    dgRef?.current?.instance?.refresh()
  }, [update, currentPeriod])

  useEffect(() => {
    setSendBulkError(false)
    setInvalidTimesheets([])
    setAssignedTs([])
    setInvalidJobs(jobs._array.filter((j) => j.disabled).map((j) => j.id))
  }, [jobs._array])

  useEffect(() => {
    let unitCache = localStorage.getItem("@timesheetThirdReviewUnitIsHours")
    if (unitCache === "false") setUnitIsHours(false)
  }, [setUnitIsHours])

  const onToogleSwitch = () => {
    setUnitIsHours(!unitIsHours)
    localStorage.setItem("@timesheetThirdReviewUnitIsHours", !unitIsHours)
  }

  const actionsPostSend = () => {
    forceUpdate()
    ds.current.instance.refresh()
    setOpenAlert(false)
  }

  const errorPostSend = (error) => {
    if (error.response && error.response.status === 423) {
      toast(formatMessage("review-timesheet-old-data"), {
        transition: Bounce,
        closeButton: true,
        autoClose: 3000,
        position: "bottom-center",
        type: "error"
      })
    } else if (error.response && error.response.status === 412) {
      toast(formatMessage("review-timesheet-old-data"), {
        transition: Bounce,
        closeButton: true,
        autoClose: 3000,
        position: "bottom-center",
        type: "error"
      })
    } else {
      toast("There was an error on the request", {
        transition: Bounce,
        closeButton: true,
        autoClose: 3000,
        position: "bottom-center",
        type: "error"
      })
    }
    actionsPostSend()
  }

  const sendTsPreReview = () => {
    sendPreReview({ tsId })
      .then((r) => {
        actionsPostSend()
      })
      .catch((error) => {
        errorPostSend(error)
      })
  }

  const sendTsFirstReview = () => {
    sendFirstReview({ tsId, jobId })
      .then((r) => {
        actionsPostSend()
      })
      .catch((error) => {
        errorPostSend(error)
      })
  }

  const sendTsThirdReview = () => {
    sendThirdReview({ tsId })
      .then((r) => {
        actionsPostSend()
      })
      .catch((error) => {
        errorPostSend(error)
      })
  }

  const sendPpaReview = () => {
    sendPpa({ tsId })
      .then((r) => {
        actionsPostSend()
      })
      .catch((error) => {
        errorPostSend(error)
      })
  }

  const reopenTimesheetAfterClosed = () => {
    reopenTimesheet({ tsId })
      .then((r) => {
        actionsPostSend()
      })
      .catch((error) => {
        errorPostSend(error)
      })
  }

  const sendAllInChargeReviews = () => {
    sendInChargeReviews({ periodId: currentPeriod._id })
      .then((r) => {
        actionsPostSend()
      })
      .catch((error) => {
        errorPostSend(error)
      })
  }

  const closeAllReviews = () => {
    closeReviews({ periodId: currentPeriod._id })
      .then((r) => {
        actionsPostSend()
      })
      .catch((error) => {
        errorPostSend(error)
      })
  }

  const handleSend = () => {
    try {
      switch (statusToSend) {
        case "preReview":
          return sendTsPreReview()
        case "firstReview":
          return sendTsFirstReview()
        case "thirdReview":
          return sendTsThirdReview()
        case "ppa-open":
          return reopenTimesheetAfterClosed()
        case "ppa":
          return sendPpaReview()
        case "all":
          return sendAllInChargeReviews()
        case "closeAll":
          return closeAllReviews()
        default:
          return null
      }
    } catch (error) {
      if (error.response && error.response.status === 423) {
        console.log("ERROR: ", error)
        actionsPostSend()
      }
    }
  }

  const alertText = () => {
    switch (statusToSend) {
      case "preReview":
        return {
          title: titleAlert,
          message: messageAlert,
          action: sendError ? "" : formatMessage("SEND")
        }
      case "firstReview":
        return {
          title: titleAlert,
          message: messageAlert,
          action: formatMessage("SEND")
        }
      case "thirdReview":
        return {
          title: titleAlert,
          message: messageAlert,
          action: sendError ? "" : formatMessage("SEND")
        }
      case "ppa-open":
        return {
          title: titleAlert,
          message: messageAlert,
          action: sendError ? "" : formatMessage("REOPEN")
        }
      case "ppa":
        return {
          title: titleAlert,
          message: messageAlert,
          action: sendError ? "" : formatMessage("SEND")
        }
      case "all":
        return {
          title: titleAlert,
          message: messageAlert,
          action: assignedTs.length === 0 ? "" : formatMessage("SEND")
        }
      case "closeAll":
        return {
          title: titleAlert,
          message: messageAlert,
          action: formatMessage("SEND")
        }
      default:
        return { title: "UNKOWN STATUS", message: "", action: "" }
    }
  }

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="main-card mb-3">
          <CardBody>
            <Form>
              <Row form>
                <Col md={12}>
                  <div>
                    <CardTitle>
                      <div className="d-flex justify-content-between">
                        {can("manage", "Report") && (
                          <div
                            style={{
                              display: "flex",
                              columnGap: "0.3rem"
                            }}
                          >
                            <ExportAllButton
                              mode={exportReviewModes.ALL_PERIODS}
                              periodId={currentPeriod}
                            />

                            <ExportAllButton
                              mode={exportReviewModes.CURRENT_PERIOD}
                              periodId={currentPeriod._id}
                            />
                          </div>
                        )}
                        <div />
                        <ToggleSwitch
                          id="thirdReview"
                          currentValue={!unitIsHours}
                          onChange={onToogleSwitch}
                          disabled={!dataLoaded}
                        />
                      </div>
                    </CardTitle>
                  </div>
                </Col>
              </Row>
              <Row form style={{ marginTop: "1.5%" }}>
                <Col md={12}>
                  {jobs._array.length > 0 ? (
                    <div>
                      <AddRow
                        addRow={addRow}
                        setAddRow={setAddRow}
                        dataToUpdate={dataToUpdate}
                        unitIsHours={unitIsHours}
                        forceUpdate={forceUpdate}
                      />
                      <AlertDialog
                        open={openAlert}
                        alertText={alertText()}
                        handleSend={handleSend}
                        error={sendError}
                        isAlert={isAlert}
                        closeAlert={() => {
                          setOpenAlert(false)
                        }}
                      />
                      <ReviewTable
                        currentPeriod={currentPeriod}
                        jobs={jobs}
                        unitIsHours={unitIsHours}
                        setOpenAlert={setOpenAlert}
                        setTsId={setTsId}
                        setJobId={setJobId}
                        setDs={setDs}
                        setDataToUpdate={setDataToUpdate}
                        setStatusToSend={setStatusToSend}
                        setAddRow={setAddRow}
                        setSendError={setSendError}
                        setTitleAlert={setTitleAlert}
                        setMessageAlert={setMessageAlert}
                        setIsAlert={setIsAlert}
                        updateJobThumb={updateJobThumb}
                        invalidJobs={invalidJobs}
                        invalidTimesheets={invalidTimesheets}
                        setInvalidTimesheets={setInvalidTimesheets}
                        forceUpdate={forceUpdate}
                        dgRef={dgRef}
                        sendBulkError={sendBulkError}
                        setSendBulkError={setSendBulkError}
                        assignedTs={assignedTs}
                        setAssignedTs={setAssignedTs}
                        invalidWbsCodes={invalidWbsCodes}
                        setDataLoaded={setDataLoaded}
                      />
                    </div>
                  ) : (
                    <LinearProgress />
                  )}
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
