import React from "react"
import { useSelector } from "react-redux"
import ReactDOM from "react-dom"
import CustomStore from "devextreme/data/custom_store"
import DataGrid, {
  Column,
  Editing,
  Export,
  Grouping,
  GroupItem,
  GroupPanel,
  MasterDetail,
  Paging,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Summary
} from "devextreme-react/data-grid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserEdit } from "@fortawesome/free-solid-svg-icons"
import { Template } from "devextreme-react/core/template"
import { CustomRule } from "devextreme-react/validator"
import Tooltip from "@material-ui/core/Tooltip"
import moment from "moment"
import { MasterTemplate } from "./../Components/masterTemplate"
import { first, isEmpty } from "underscore"
import { getExternalReview } from "../../../services"
import { round } from "../../../Utils/utils"
import localization from "../../../config/localization.json"
import { JobTypePercentage } from "../Review/table/reviewTable"
import { buildConfigForAnyReviewBy } from "../Utils/tsFlowConfig"
import { formatMessage } from "devextreme/localization"
import { useAppAbility } from "../../../Context/ability.js"

let exported = false

const getRowEditDetails = ({ currentStatus, timesheetRow }) => {
  switch (currentStatus) {
    case "preReview":
      return false
    case "firstReview":
      return false
    case "thirdReview":
      if (timesheetRow.firstReview?.notes)
        return "FIRST REVIEW: " + timesheetRow.firstReview.notes
      break
    default:
      return ""
  }
}

export const ExternalReviewTable = React.memo(
  ({ currentPeriod, unitIsHours, invalidWbsCodes }) => {
    let _datagrid = React.useRef(null)
    const config = useSelector((state) => state.Auth.config)
    const user = useSelector((state) => state.Auth.user)
    const jobs = useSelector((state) => state.Jobs.forReview)
    const { can } = useAppAbility()

    const dataSource = {
      reshapeOnPush: true,
      store: new CustomStore({
        load: async function() {
          return getExternalReview({
            period: currentPeriod._id
          })
            .then(async ({ data }) => {
              data.forEach((row) => {
                let currentStatus = row.statusDetails.code

                if (!row.timesheetRow[currentStatus]) {
                  currentStatus = row.currentStatus
                }

                row.timesheetRow.currentTsRowId = row.timesheetRow[currentStatus]._id

                const rowJob = jobs.find(
                  (j) => j.id === row.timesheetRow[currentStatus].code
                )

                if (rowJob?.isTempPlaced) {
                  row.timesheetRow.currentLabel = `${config.wbsTempCode} - ${row.timesheetRow[currentStatus].activity}`
                } else {
                  row.timesheetRow.currentLabel =
                    row.timesheetRow[currentStatus].wbs +
                    " - " +
                    row.timesheetRow[currentStatus].activity
                }

                row.timesheetRow.currentCode = row.timesheetRow[currentStatus].code
                row.timesheetRow.currentHours = row.timesheetRow[currentStatus].hours
                row.timesheetRow.currentDays = row.timesheetRow[currentStatus].days
                row.timesheetRow.currentDescription =
                  row.timesheetRow[currentStatus].description || ""
                row.timesheetRow.currentNotes = row.timesheetRow[currentStatus].notes
              })
              return {
                data: !isEmpty(data) ? data : []
              }
            })
            .catch((e) => {
              throw e
            })
        },
        update: async (before, dataToUpdate) => {},
        insert: async (data) => {},
        remove: (data) => {}
      })
    }

    const rowStatusSanitized = (row) =>
      buildConfigForAnyReviewBy({
        bipRole: user.bipRole,
        userMail: user.email,
        review: row.currentStatus,
        reviewOwner: row.statusDetails.owner,
        locked: row.statusDetails.locked,
        can: can
      })

    const onExported = (e) => {
      e.component.columnOption(0, "groupIndex", 0)
    }

    const onExporting = (e) => {
      e.component.columnOption(0, "groupIndex", null)

      if (exported) return

      e.component.refresh().done(function() {
        exported = true
        e.component.exportToExcel()

        exported = false
      })

      e.cancel = true
    }

    const onDataErrorOccurred = (e) => {
      console.log(e)
      if (e?.error?.response?.status === 423) {
        e.error.message = formatMessage("review-timesheet-old-data")
        _datagrid.current.instance.cancelEditData()
        return
      }
      if (e?.error?.response?.status === 412) {
        e.error.message = formatMessage("review-timesheet-old-data")
        _datagrid.current.instance.cancelEditData()
        return
      }
      e.error.message = formatMessage("review-general-error")
    }

    const renderJobCell = (data) => {
      const rowDetails = getRowEditDetails(data.data)

      return (
        <div>
          <div
            style={{
              display: "inline-block",
              fontWeight:
                rowDetails || isTimesheetRowOnHold(data) ? "bold" : "normal"
            }}
          >
            {data.displayValue}
          </div>
          {rowDetails && (
            <div
              style={{ marginLeft: 20, display: "inline-block" }}
              className="font-icon-wrapper-currentjob"
              data-toggle="tooltip"
              title={rowDetails}
            >
              <FontAwesomeIcon icon={faUserEdit} size="1x" color="grey" />
            </div>
          )}
        </div>
      )
    }

    const renderHoursSummary = (e) => {
      let allRows = e.data.items || e.data.collapsedItems
      const summaryDays = unitIsHours
        ? round(e.data.aggregates[1]) / 8
        : round(e.data.aggregates[2])
      const timesheetDetails = first(allRows)
      const workingDays = timesheetDetails.workingDays
      const isDifferentFromWorkingDays = workingDays !== summaryDays

      const getTooltipTitle = () => {
        switch (unitIsHours) {
          case true && workingDays > summaryDays:
            return formatMessage("hours-less-than-working-hours")
          case true && workingDays < summaryDays:
            return formatMessage("hours-more-than-working-hours")
          case false && workingDays > summaryDays:
            return formatMessage("days-less-than-working-hours")
          case false && workingDays < summaryDays:
            return formatMessage("days-more-than-working-hours")
        }
      }

      if (isDifferentFromWorkingDays) {
        return (
          <Tooltip title={getTooltipTitle()}>
            <span>
              <div
                style={{
                  alignItems: "center",
                  alignContent: "center",
                  color: "red"
                }}
              >
                {unitIsHours
                  ? e.data.aggregates[1].toLocaleString(localization.lang)
                  : e.data.aggregates[2].toLocaleString(localization.lang)}
              </div>
            </span>
          </Tooltip>
        )
      } else {
        return (
          <div style={{ alignItems: "center", alignContent: "center" }}>
            {unitIsHours
              ? e.data.aggregates[1].toLocaleString(localization.lang)
              : e.data.aggregates[2].toLocaleString(localization.lang)}
          </div>
        )
      }
    }

    const onCellPrepared = (e) => {
      if (e.rowType === "header" && e.column.caption === "Act") {
        e.cellElement.innerText = ""
      }
      if (
        e.rowType === "group" &&
        e.column.caption === formatMessage("Description")
      ) {
        e.cellElement.innerText = ""
      }
      if (
        e.rowType === "group" &&
        (e.column.caption === formatMessage("Days") ||
          e.column.caption === formatMessage("Hours"))
      ) {
        ReactDOM.render(renderHoursSummary(e), e.cellElement)
      }
      if (e.rowType === "group" && e.column.caption === formatMessage("Notes")) {
        let status = rowStatusSanitized(
          (e.data?.items && e.data.items[0]) ||
            (e.data?.collapsedItems && e.data.collapsedItems[0])
        )
        const badgeElement = document.createElement("div")
        badgeElement.innerHTML = status.text
        badgeElement.classList.add(`badge`, `badge-${status.class}`)
        e.cellElement.appendChild(badgeElement)
      }
    }

    const RenderListItem = (data) => {
      let allRows = data.data.items || data.data.collapsedItems
      const timesheetDetails = first(allRows)
      const tempHours = timesheetDetails?.tempHours || 0
      return (
        <div
          className="d-flex justify-content-between"
          style={{ height: 32, alignItems: "center" }}
        >
          <div>
            {
              // remove the email domain to get the format firstName.lastName
              data.text.split("@")[0]
            }
            {round(tempHours) !== 0 ? (
              <Tooltip
                title={
                  tempHours > 0
                    ? `${
                        unitIsHours ? formatMessage("Hours") : formatMessage("Days")
                      } ${formatMessage("review-excess-after-first-review-tooltip")}`
                    : formatMessage(
                        "review-missing-after-pre-review-tooltip",
                        unitIsHours
                          ? formatMessage("Hours").toLowerCase()
                          : formatMessage("Days").toLowerCase()
                      )
                }
              >
                <div
                  className={`badge badge-danger`}
                  style={{ marginLeft: 30, fontSize: 15 }}
                >
                  {tempHours > 0 ? "+" : ""}
                  {unitIsHours
                    ? tempHours.toLocaleString(localization.lang)
                    : (tempHours / 8).toLocaleString(localization.lang)}
                </div>
              </Tooltip>
            ) : null}
            {timesheetDetails?.hours && (
              <JobTypePercentage hours={timesheetDetails?.hours} config={config} />
            )}
          </div>
        </div>
      )
    }

    const isTimesheetRowOnHold = (row) => {
      return (
        row?.rowType === "data" &&
        row.data.currentStatus === "onHold" &&
        invalidWbsCodes?.find(
          (wbs) => wbs === row.data?.timesheetRow?.onHold?.wbs
        ) != null
      )
    }
    const onRowPrepared = (e) => {
      if (isTimesheetRowOnHold(e)) {
        e.rowElement.style.fontWeight = "bold"
        e.rowElement.style.color = "#D21312"
      }
    }

    return (
      <DataGrid
        dataSource={dataSource}
        showBorders={true}
        onExporting={onExporting}
        onDataErrorOccurred={onDataErrorOccurred}
        onExported={onExported}
        onCellPrepared={onCellPrepared}
        onRowPrepared={onRowPrepared}
        //onToolbarPreparing={onToolbarPreparing}
        loadPanel={{
          enabled: true,
          indicatorSrc: `data:image/svg+xml;utf8,${require("./../../../assets/rolling.svg")}`
        }}
        allowColumnResizing
        ref={_datagrid}
      >
        <Paging enabled={false} />
        <Export
          texts={{ exportAll: formatMessage("dxDataGrid-exportCurrentView") }}
          enabled={true}
          fileName={"review_" + moment().format("YYYYMMDD-HH:mm")}
        />
        <Editing mode="cell" allowUpdating={false} allowDeleting={false} />
        <GroupPanel />
        <SearchPanel visible={true} />
        <Grouping autoExpandAll={true} />
        <Column
          dataField={"owner.displayName"}
          caption={"Owner"}
          allowEditing={false}
          groupIndex={0}
          groupCellTemplate={"groupRowTemplate"}
          allowHiding={false}
        />
        <Column
          dataField={"timesheetRow.currentCode"}
          cellRender={renderJobCell}
          caption={"Job"}
          allowEditing={false}
          allowHiding={false}
          width={"auto"}
          calculateDisplayValue={"timesheetRow.currentLabel"}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField={"timesheetRow.currentDescription"}
          allowEditing={false}
          caption={formatMessage("Description")}
          allowHiding={false}
          minWidth={40}
        ></Column>
        <Column
          dataField={
            unitIsHours ? "timesheetRow.currentHours" : "timesheetRow.currentDays"
          }
          caption={unitIsHours ? formatMessage("Hours") : formatMessage("Days")}
          alignment={"center"}
          allowEditing={false}
          allowHiding={false}
          width={"auto"}
          dataType={"number"}
          format={{}} // mandatory for applying localization
          editorOptions={{
            showSpinButtons: true,
            format: { type: "fixedPoint", precision: 2 }
          }}
        >
          <CustomRule
            validationCallback={(e) => {
              return e.value >= 0
            }}
            message={formatMessage(
              "value-greater-than-0",
              unitIsHours ? "Hours" : "Days"
            )}
          />
        </Column>
        <Column
          dataField={"timesheetRow.currentNotes"}
          caption={formatMessage("Notes")}
          alignment={"center"}
          allowEditing={false}
          allowHiding={false}
          minWidth={170}
        >
          <CustomRule
            validationCallback={(e) => {
              const currentStatus = e.data.currentStatus
              if (
                currentStatus !== "preReview" &&
                currentStatus !== "thirdReview" &&
                currentStatus !== "ppa"
              ) {
                return (
                  e.data?.timesheetRow?.currentNotes !== "" &&
                  e.data?.timesheetRow?.currentNotes !== null &&
                  e.data?.timesheetRow?.currentNotes !== undefined
                )
              } else {
                return true
              }
            }}
            message={formatMessage("review-mandatory-notes")}
          />
        </Column>
        <Summary>
          <GroupItem
            column="timesheetRow.currentDescription"
            summaryType="max"
            displayFormat={"{0}"}
            showInGroupFooter={false}
            name="currentDescription"
            alignByColumn={true}
          />
          <GroupItem column="timesheetRow.currentHours" summaryType="sum" />
          <GroupItem column="timesheetRow.currentDays" summaryType="sum" />
          <GroupItem column="timesheetRow.currentNotes" summaryType="max" />
          <Template name={"groupRowTemplate"} render={RenderListItem} />
          {/* GroupItem mandatory to create the element algined which will be modified in renderListItem */}
          <GroupItem
            column="Act"
            summaryType="max"
            displayFormat={"{0}"}
            showInGroupFooter={false}
            name="currentAct"
            alignByColumn={true}
          />
        </Summary>
        <MasterDetail
          enabled={true}
          component={(props) => (
            <MasterTemplate
              unitIsHours={unitIsHours}
              data={props.data}
              invalidWbsCodes={invalidWbsCodes}
            />
          )}
        />
        <Scrolling mode="virtual" rowRenderingMode="virtual" preloadEnabled={true} />
        <Paging defaultPageSize={100000} />
      </DataGrid>
    )
  }
)
