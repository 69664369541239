import ButtonGroup from "./ButtonGroup";
import Icon from "./Icon";
import {useMemo} from "react";
import moment from "moment";
import {formatDateInterval} from "../utils/formatDateInterval";

export default function DateNav({
  from,
  to,
  onPreviousClick,
  onNextClick,
}) {
  const formattedFromTo = useMemo(() => formatDateInterval(from, to), [from, to]);

  return (
    <div className="d-flex align-items-center">
      <ButtonGroup>
        <div
          className="date-arrow-outer"
          onClick={() => onPreviousClick()}
        >
          <Icon
            svgStyles={{ width: "16px", height: "12px" }}
            iconRelativePath="monade/preallocation/left-arrow"
          />
        </div>
        <div className="date-arrow-outer" onClick={() => onNextClick()}>
          <Icon
            svgStyles={{ width: "16px", height: "12px" }}
            iconRelativePath="monade/preallocation/right-arrow"
          />
        </div>
      </ButtonGroup>
      <div className="ml-3">{formattedFromTo}</div>
    </div>
  )
}
