import { useQuery } from "@tanstack/react-query"
import { getWbsSuggestions } from "../../../../../services"
import { parseBipUserData } from "../../utils/parseBipUserData"
import { parseAreaData } from "../../utils/parseAreaData"
import { useEffect, useState } from "react"

export function useSelectWbsSuggestions(isEditingOrCreating, areaId) {
  const { isLoading, isError, isSuccess, data, error, refetch } = useQuery(
    ["GET", "wbs", "wbs-suggestions", isEditingOrCreating, areaId],
    async () => {
      return await getWbsSuggestions(isEditingOrCreating, areaId)
    }
  )

  const getSuggestionsAsOptions = () => {
    if (!isSuccess) {
      return {}
    }
    const suggestionsAsOptions = {}
    Object.keys(data.data).forEach((suggestionKey) => {
      if (suggestionKey === "accountables") {
        suggestionsAsOptions[suggestionKey] = data.data[suggestionKey].map(
          (accountableUser) => {
            const u = parseBipUserData(accountableUser)
            return { id: u.id, cell: u.fullName }
          }
        )
      }
      else if (suggestionKey === "areas") {
        suggestionsAsOptions[suggestionKey] = data.data[suggestionKey].map(
          (area) => {
            const areaData = parseAreaData(area)
            return { id: areaData.id, cell: areaData.name, owner: areaData.owner, deputies: areaData.deputies, internalSupporters: areaData.internalSupporters, externalSupporters: areaData.externalSupporters }
          }
        )
      }
    })
    return suggestionsAsOptions
  }

  const [suggestionsOptions, setSuggestionsOptions] = useState(
    getSuggestionsAsOptions()
  )
  useEffect(() => {
    setSuggestionsOptions(getSuggestionsAsOptions())
  }, [data])

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    refetchSuggestions: refetch,
    suggestionsOptions,
  }
}
