import React, { Fragment } from "react"
import AppHeader from "../../Layout/AppHeader"
import { Route } from "react-router-dom"
import AppFooter from "../../Layout/AppFooter"
import AppSidebar from "../../Layout/AppSidebar"
import Appraisal from "./Appraisal"
import { useAppAbility } from "../../Context/ability"

const PerformanceMgmt = ({ match }) => {
  const { can } = useAppAbility()

  const canReadAppraisal = can("read", "Appraisal")

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            {canReadAppraisal && (
              <Route path={`${match.url}/appraisal`} component={Appraisal} />
            )}
          </div>
          <AppFooter />
        </div>
      </div>
    </Fragment>
  )
}

export default PerformanceMgmt
