import { LoadPanel } from "devextreme-react"
import { formatMessage } from "devextreme/localization"
import React, { Fragment, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Button } from "reactstrap"
import _ from "underscore"
import { formatToIsoUtc } from "../../../Utils/formatToIsoUtc"
import {
  getAllOffices,
  getAllOrganizations,
  getUser,
  getUsers,
  updateUser,
} from "../../../services"
import ConfirmModal from "../JobAndWbs/components/common/ConfirmModal"
import PageTitle from "./../../../Layout/AppMain/PageTitle"
import HCEdit from "./Main/HCEdit"
import { calcChargeability } from "./Main/Utils/CalcChargeability"
import { calcExtJobTitle } from "./Main/Utils/CalcExtJobTitle"
import { calcTotWorkedYears } from "./Main/Utils/CalcTotWorkedYears"
import clone from "./Main/Utils/Clone"
import ConfirmExitPopup from "./Main/Utils/ConfirmExitPopup"
import {
  parseErrors,
  showSuccessToast,
  showToastError,
} from "./Main/Utils/ErrorHandling"
import isExternal from "./Main/Utils/IsExternal"
import { parseUserDates } from "./Main/Utils/ParseUserDates"
import "./Styles/edit.css"
import "./Styles/searchBoxPadding.css"

export default function HeadcountEdit(props) {
  const [oldUserLeavingDate, setOldUserLeavingDate] = useState(null)
  const [user, setUser] = useState({})
  const [errors, setErrors] = useState([])
  const forms = {}

  const config = useSelector((state) => state.Auth.config)
  const [organizations, setOrganizations] = useState([])
  const [levels, setLevels] = useState([])
  const [bipRoles, setBipRoles] = useState([])
  const [offices, setOffices] = useState([])
  const [userNames, setUserNames] = useState([])
  const [competences, setCompetences] = useState([])
  const [contractTypes, setContractTypes] = useState([])
  const [adbS, setAdbS] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [spinner, setSpinner] = useState(false)
  const [globalCompetences, setGlobalCompetences] = useState([])
  const [circleValues, setCircleValues] = useState([])
  const [confirmExitPopup, setConfirmExitPopup] = useState(false)
  const [genderValues, setGenderValues] = useState([])
  const [tags, setTags] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalBody, setModalBody] = useState("")

  useEffect(() => {
    if (!isExternal(user.currentLevel?.code, config.levels)) {
      user.reviewReader = null
      setUser(user)
    }
  }, [user.currentLevel?.code])

  useEffect(() => {
    if (config) {
      setLevels(_.sortBy(config.levels, "name"))
      setBipRoles(config.bipRoles.sort())
      setCompetences(config.competences.sort())
      setContractTypes(config.contractType.sort())
      setAdbS(config.adbS.sort())
      setSuppliers(config.suppliers.sort())
      setGlobalCompetences(config.globalCompetences.sort())
      setCircleValues(config.circleValues.sort())
      setGenderValues(config.genderValues)
      setTags(config.tags)
    }
  }, [config])

  useEffect(() => {
    getAllOffices().then(({ data }) => {
      setOffices(
        data.map((j) => ({
          id: j._id,
          name: j.name,
          code: j.code,
        }))
      )
    })
  }, [])

  useEffect(() => {
    getAllOrganizations().then(({ data }) => {
      setOrganizations(
        data.map((j) => ({
          id: j._id,
          name: j.name,
          owner: j.owner,
          isDeleted: j.isDeleted,
        }))
      )
    })
  }, [])
  useEffect(() => {
    setSpinner(true)
    getUser(props.match.params.id)
      .then((resp) => {
        const user = resp.data
        if (!user?.workedYearsBeforeBip) user.workedYearsBeforeBip = 0
        if (!user?.workedYearsInBip) user.workedYearsInBip = 0
        user.totWorkedYears = calcTotWorkedYears(
          user.workedYearsBeforeBip,
          user.workedYearsInBip
        )
        user.chargeability = calcChargeability(user)
        if (user.currentLevel) {
          user.currentLevel.extJobTitle = calcExtJobTitle(
            user.currentLevel?.code,
            user.competence,
            levels
          )

          user.currentLevel.cost *= 8
        }

        let parsedUser = parseUserDates(user)

        setUser(parsedUser)
        setOldUserLeavingDate(parsedUser.leavingDate)
      })
      .finally(() => setSpinner(false))
  }, [props.match.params.id])

  useEffect(() => {
    getUsers().then(({ data }) => {
      setUserNames(
        data
          .sort(function (a, b) {
            var A = a.firstName.toUpperCase()
            var B = b.firstName.toUpperCase()
            return A < B ? -1 : A > B ? 1 : 0
          })
          .map((user) => ({
            id: user._id,
            name: `${user.firstName} ${user.lastName}`,
          }))
      )
    })
  }, [])

  const getLevelByCode = (code) => {
    return levels.find((l) => {
      return l.code === code
    })
  }

  const onCancel = () => {
    props.history.push("/admin/headcount")
  }

  const openModal = () => setIsModalOpen(true)

  const saveOrOpenModal = () => {
    if (user.leavingDate !== null && user.leavingDate !== oldUserLeavingDate) {
      setModalBody(formatMessage("hc-edit-warning-leavingdate-effect-allocation"))
      openModal()
      return
    }

    onSave()
  }

  const onSave = () => {
    updateUserAndHandleResponse(user)
  }

  const updateUserAndHandleResponse = (
    data,
    callbackSuccessFunction,
    callbackErrorFunction
  ) => {
    setSpinner(true)
    setErrors([])

    let updatedUser = clone(data)

    updatedUser.extId = updatedUser.extId.toUpperCase()
    if (updatedUser.currentLevel) {
      updatedUser.currentLevel.cost /= 8
      updatedUser.currentLevel.startDate = formatToIsoUtc(
        updatedUser.currentLevel.startDate
      )
    }

    updatedUser.contractEnd = formatToIsoUtc(updatedUser.contractEnd)
    updatedUser.joiningDate = formatToIsoUtc(updatedUser.joiningDate)
    updatedUser.orderEnd = formatToIsoUtc(updatedUser.orderEnd)
    updatedUser.leavingDate = formatToIsoUtc(updatedUser.leavingDate)

    updateUser(updatedUser)
      .then((resp) => {
        setSpinner(false)
        showSuccessToast(formatMessage("hc-success-saved-user"))
        let user = resp.data
        if (user.currentLevel) {
          user.currentLevel.cost *= 8
        }

        let parsedUser = parseUserDates(user)
        setUser(parsedUser)

        if (callbackSuccessFunction) {
          callbackSuccessFunction(resp)
        }
      })
      .catch((err) => {
        setSpinner(false)
        if (!err.response) {
          console.error(err)
          return
        }
        const parsedErrors = parseErrors(err.response)
        setErrors(parsedErrors)

        setTimeout(() => {
          validateForms()
        })
        showToastError(err.response)
        if (callbackErrorFunction) {
          callbackErrorFunction()
        }
      })
  }

  function validateForms() {
    Object.values(forms).forEach((form) => {
      try {
        form?.instance?.validate()
      } catch (e) {
        if (!e.__id === "E0110") {
          console.error(e)
        }
      }
    })
  }

  function renderButtons() {
    return (
      <div>
        <Button
          outline
          className="mr-2"
          color="primary"
          d="button"
          width={100}
          height={40}
          onClick={onCancel}
        >
          <span className="dx-button-text">
            {formatMessage("hc-button-cancel").toUpperCase()}
          </span>
        </Button>
        <ConfirmModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          onConfirm={() => {
            setIsModalOpen(false)
            onSave()
          }}
          onCancel={() => {
            setIsModalOpen(false)
          }}
          title={formatMessage("jw-Confirmation")}
          body={modalBody}
          confirmButtonText={formatMessage("jw-Save")}
          cancelButtonText={formatMessage("jw-Cancel")}
        />
        <Button
          className="mr-3"
          color="primary"
          width={100}
          height={40}
          onClick={saveOrOpenModal}
        >
          <span className="dx-button-text">
            {formatMessage("hc-button-save").toUpperCase()}
          </span>
        </Button>
      </div>
    )
  }

  return (
    <Fragment>
      <ConfirmExitPopup
        confirmExitPopup={confirmExitPopup}
        setConfirmExitPopup={setConfirmExitPopup}
        history={props.history}
        levels={levels}
        user={user}
      />
      <PageTitle
        heading={formatMessage("hc-edit-title")}
        icon="pe-7s-graph text-success"
        actions={renderButtons()}
      ></PageTitle>
      <LoadPanel
        visible={spinner}
        indicatorSrc={`data:image/svg+xml;utf8,${require("../../../assets/rolling.svg")}`}
      />
      <HCEdit
        updateUserAndHandleResponse={updateUserAndHandleResponse}
        forms={forms}
        errors={errors}
        setErrors={setErrors}
        spinner={spinner}
        setSpinner={setSpinner}
        user={user}
        setUser={setUser}
        organizations={organizations}
        levels={levels}
        bipRoles={bipRoles}
        offices={offices}
        userNames={userNames}
        competences={competences}
        contractTypes={contractTypes}
        adbS={adbS}
        suppliers={suppliers}
        getLevelByCode={getLevelByCode}
        validateForms={validateForms}
        globalCompetences={globalCompetences}
        circleValues={circleValues}
        genderValues={genderValues}
        tags={tags}
      />
    </Fragment>
  )
}
