import {useEffect, useMemo, useState} from "react";
import ResourceCalendar from "./ResourceCalendar";
import "./ResourceCardBody.scss";
import DateNav from "../components/DateNav";
import moment from "moment";
import {formatMessage} from "devextreme/localization";
import ToggleButtonGroup from "../components/input/ToggleButtonGroup";
import DatePickerInput from "../components/input/DatePickerInput";

export default function ResourceCardBody({
  resource,
  projectId,
  onResourceFiltersChange
}) {
  const aggregationOptions = [
    {
      id: 'WEEKLY',
      label: formatMessage('preallocation-filters-aggregation-weeks')
    },
    {
      id: 'MONTHLY',
      label: formatMessage('preallocation-filters-aggregation-months')
    }
  ];

  const [dateNavFrom, setDateNavFrom] = useState(null)
  const [dateNavTo, setDateNavTo] = useState(null)

  const slotDates = useMemo(() => {
    if (!resource?.details?.resourceTimeSlots) {
      return []
    }

    return resource.details.resourceTimeSlots.map(({ startDate, endDate }) => ({
      startDate,
      endDate
    }))
  });

  useEffect(() => {
    if (slotDates.length === 0) {
      setDateNavFrom(null);
      setDateNavTo(null);
      return;
    }

    setDateNavFrom(slotDates[0].startDate);
    setDateNavTo(slotDates[slotDates.length - 1].endDate);
  }, [slotDates]);

  const onFilterChange = (filter) => {
    const changedFilterKeys = Object.keys(filter)

    if (
      !changedFilterKeys.some(
        (key) => JSON.stringify(filter[key]) !== JSON.stringify(resource.filters[key])
      )
    ) {
      return
    }

    const newFilters = {
      ...resource.filters,
      ...filter
    }

    onResourceFiltersChange(resource, newFilters)
  }

  const onPreviousClick = () => {
    onFilterChange({
      startDate: moment(resource.filters.startDate)
        .subtract(1, resource.filters.aggregation === "WEEKLY" ? "week" : "month")
        .toDate()
    })
  }

  const onNextClick = () => {
    onFilterChange({
      startDate: moment(resource.filters.startDate)
        .add(1, resource.filters.aggregation === "WEEKLY" ? "week" : "month")
        .toDate()
    })
  }

  return (
    <div className='resource-card-body'>
      {
        resource.details && (
          <>
            <div className='resource-card-body-heading'>
              <DateNav
                from={dateNavFrom}
                to={dateNavTo}
                onPreviousClick={() => onPreviousClick()}
                onNextClick={() => onNextClick()}
              />
              <ToggleButtonGroup
                options={aggregationOptions}
                value={resource.filters.aggregation}
                onChange={aggregation => onFilterChange({aggregation})}
                className='mr-3'
              />
              <DatePickerInput
                value={resource.filters.startDate}
                onChange={startDate => onFilterChange({startDate})}
                style={{ width: '154px' }}
              />
            </div>
            <ResourceCalendar
              resource={resource}
              slotDates={slotDates}
              projectId={projectId}
              onReloadResource={() => onResourceFiltersChange(resource, resource.filters)}
            />
          </>
        )
      }
    </div>
  );
}
