import DropdownSelect from "./input/DropdownSelect"
import "./Filter.scss"
import { useSelectTypes } from "../hooks/selectHooks/useSelectTypes"
import { formatMessage } from "devextreme/localization"

export default function TypeFilter({ value, setValue, className }) {
  const { typeOptions } = useSelectTypes()

  return (
    <DropdownSelect
      className={`${className} filter-select`}
      inputStyles={{ minWidth: "150px" }}
      innerLabel={
        <span className="text-muted pr-1">{formatMessage("jw-type")}:</span>
      }
      value={value || "all"}
      options={typeOptions}
      placeholder={formatMessage("jw-all")}
      onSelected={(option) => {
        setValue(option?.id || null)
      }}
    />
  )
}
