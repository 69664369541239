import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

export function useSelectFinalCustomer() {
  const config = useSelector((state) => state.Auth.config)
  useEffect(() => {
    setCustomers(config.customers || [])
  }, [config])

  const [customers, setCustomers] = useState(config?.customers || [])

  const getCustomersAsOptions = () =>
    customers.map((customer) => ({ id: customer, cell: customer }))

  const [finalCustomerOptions, setCustomerOptions] = useState(
    getCustomersAsOptions(),
  )

  useEffect(() => {
    setCustomerOptions(getCustomersAsOptions())
  }, [customers])

  return { customers, finalCustomerOptions }
}
