import { useEffect, useState } from "react"
import Calendar from "../components/Calendar"
import TeamCell from "./TeamCell"
import ResourceSlotCell from "./ResourceSlotCell"
import ResourceCell from "./ResourceCell"
import DateNav from "../components/DateNav"
import { formatDateInterval } from "../utils/formatDateInterval"
import "./ResourcesCalendar.scss"
import { calcBiggestPercentageRatio } from "../utils/calcBiggestPercentageRatio"
import { TeamSlotCell } from "./TeamSlotCell"
import moment from "moment"

export default function ResourcesCalendar({
  teams,
  filters,
  selectedAllocations,
  isEditingMultiple,
  editingAllocation,
  isLoading,
  loadError,
  onReload,
  toggleIsOpen,
  toggleIsPinned,
  onAddAllocation,
  onEditAllocation,
  onEditMultipleAllocations,
  onRemoveResource,
  onPreviousClick,
  onNextClick
}) {
  const [dateNavFrom, setDateNavFrom] = useState(null)
  const [dateNavTo, setDateNavTo] = useState(null)
  const [formattedSlotHeaders, setFormattedSlotHeaders] = useState([])
  useEffect(() => {
    const startDate = moment(filters.startDate).toDate()
    const endDate = moment(startDate)
      .add(6, filters.aggregation === "WEEKLY" ? "week" : "month")
      .add(-1, "day")
      .toDate()
    setDateNavFrom(startDate)
    setDateNavTo(endDate)
    setFormattedSlotHeaders(
      new Array(6).fill(null).map((_, i) => {
        const date = moment(startDate).add(
          i,
          filters.aggregation === "WEEKLY" ? "week" : "month"
        )
        return formatDateInterval(
          date.toDate(),
          date
            .add(1, filters.aggregation === "WEEKLY" ? "week" : "month")
            .add(-1, "day")
            .toDate()
        )
      })
    )
    if (!filters?.aggregation || !teams || teams.length === 0) {
      return
    }

    const slotDates = teams[0].teamTimeSlots.map(({ startDate, endDate }) => ({
      startDate,
      endDate
    }))

    if (slotDates.length === 0) {
      return
    }

    setDateNavFrom(slotDates[0].startDate)
    setDateNavTo(slotDates[slotDates.length - 1].endDate)
    setFormattedSlotHeaders(
      slotDates.map(({ startDate, endDate }) =>
        formatDateInterval(startDate, endDate)
      )
    )
  }, [teams, filters])

  const headerMainCellComponentFactory = () => (
    <DateNav
      from={dateNavFrom}
      to={dateNavTo}
      onPreviousClick={onPreviousClick}
      onNextClick={onNextClick}
    />
  )

  const headerSlotCellComponentsFactory = () =>
    formattedSlotHeaders.map((slotHeaders, index) => (
      <td key={index} className="slot-header">
        {slotHeaders}
      </td>
    ))

  const mainCellComponentFactory = (team) => (
    <TeamCell
      team={team}
      filters={filters}
      onToggleIsOpen={() => toggleIsOpen(team)}
      onToggleIsPinned={() => toggleIsPinned(team)}
    />
  )

  const slotCellComponentsFactory = (team) =>
    team.teamTimeSlots.map((slot) => <TeamSlotCell slot={slot} />)

  const subrowMainCellComponentFactory = (team, resource, rowRef) => (
    <ResourceCell
      team={team}
      resource={resource}
      filters={filters}
      onAddAllocation={onAddAllocation}
      onEditMultipleAllocations={(team, resource) =>
        onEditMultipleAllocations(team, resource, rowRef.current)
      }
      onRemove={onRemoveResource}
    />
  )

  const subrowSlotCellComponentsFactory = (team, resource) => {
    return resource.userTimeSlots.map((slot, index) => (
      <ResourceSlotCell
        slot={slot}
        resource={resource}
        maxRatio={calcBiggestPercentageRatio(resource.userTimeSlots)}
        showFreeTime={filters.showFreeTime}
        onActivityClick={(activity) =>
          onEditAllocation(team, resource, activity, team.teamTimeSlots[index])
        }
        selected={(activity) => {
          const allocation = {
            ...activity,
            teamId: team.id,
            resourceId: resource.id,
            startDate: team.teamTimeSlots[index].startDate,
            endDate: team.teamTimeSlots[index].endDate
          }
          return selectedAllocations
            .map((e) => JSON.stringify(e, Object.keys(e).sort()))
            .includes(JSON.stringify(allocation, Object.keys(allocation).sort()))
        }}
        selectable={(activity) =>
          isEditingMultiple &&
          !activity.readOnly &&
          editingAllocation?.team?.id === team.id &&
          editingAllocation?.resource?.id === resource.id
        }
      />
    ))
  }

  return (
    <Calendar
      className="resources-calendar"
      rowModels={teams}
      getSubrowModelsFn={(team) => (team.isOpen ? team.details?.users || [] : [])}
      headerMainCellComponentFactory={headerMainCellComponentFactory}
      headerSlotCellComponentsFactory={headerSlotCellComponentsFactory}
      mainCellComponentFactory={mainCellComponentFactory}
      slotCellComponentsFactory={slotCellComponentsFactory}
      subrowMainCellComponentFactory={subrowMainCellComponentFactory}
      subrowSlotCellComponentsFactory={subrowSlotCellComponentsFactory}
      headerSpacing={20}
      isLoading={isLoading}
      error={loadError}
      onReload={onReload}
    />
  )
}
