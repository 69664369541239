import React, { Fragment, useEffect, useState } from "react"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import { useSelector } from "react-redux"
import { Card, CardBody, Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import PageTitle from "./../../../Layout/AppMain/PageTitle"
import { formatMessage } from "devextreme/localization"
import { getOffice } from "../../../services"

export default function MenuLayouts() {
  const user = useSelector((state) => state.Auth.user)

  const [office, setOffice] = useState([])

  useEffect(() => {
    getOffice(user.office).then(({ data }) => {
      setOffice({ id: data._id, name: data.name, code: data.code })
    })
  }, [])

  const officeName = office.name + " - " + office.code



  return (
    <Fragment>
      <PageTitle
        heading={formatMessage("Profile")}
        subheading=""
        icon="pe-7s-study text-success"
      />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="main-card mb-3">
          <CardBody>
            <div className="w-25 pb-3">
              <h5>{formatMessage("hc-basic-informations")}</h5>
            </div>
            <Form>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label showColon={false} for="exampleEmail11">
                      Email
                    </Label>
                    <Input plaintext readOnly>
                      {user.email}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label showColon={false} for="exampleEmail11">
                      {formatMessage("role")}
                    </Label>
                    <Input plaintext readOnly>
                      {user.bipRole}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label showColon={false} for="exampleEmail11">
                      {formatMessage("name")}
                    </Label>
                    <Input plaintext readOnly>
                      {user.firstName}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label showColon={false} for="exampleEmail11">
                      {formatMessage("lastName")}
                    </Label>
                    <Input plaintext readOnly>
                      {user.lastName}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label showColon={false} for="exampleEmail11">
                      {formatMessage("level")}
                    </Label>
                    <Input plaintext readOnly>
                      {user.currentLevel.name}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label showColon={false} for="exampleEmail11">
                      {formatMessage("office")}
                    </Label>
                    <Input plaintext readOnly>
                      {officeName}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
        <Card className="main-card mb-3">
          <CardBody>
            <div className="w-25 pb-3">
              <h5>{formatMessage("hc-organizational-informations")}</h5>
            </div>
            <Form>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label showColon={false} for="exampleEmail11">
                      {formatMessage("hc-field-area")}
                    </Label>
                    <Input plaintext readOnly>
                      {user.area}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label showColon={false} for="exampleEmail11">
                      {formatMessage("hc-field-competence")}
                    </Label>
                    <Input plaintext readOnly>
                      {user.competence}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label showColon={false} for="exampleEmail11">
                      {formatMessage("hc-field-global-competence")}
                    </Label>
                    <Input plaintext readOnly>
                      {user.globalCompetence === "Other"
                        ? formatMessage("global-competence-other")
                        : user.globalCompetence}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label showColon={false} for="exampleEmail11">
                      {formatMessage("line-manager")}
                    </Label>
                    <Input plaintext readOnly>
                      {user.lineManager}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label showColon={false} for="exampleEmail11">
                      {formatMessage("hc-field-organization")}
                    </Label>
                    <Input plaintext readOnly>
                      {user.organization?.name}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
