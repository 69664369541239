import axios from "axios"
import { apiService } from "./APIServices"

export const getPeriodsForUser = ({ limit, sortDirection }) => {
  // limit parameter is in months

  return axios.get(apiService.period + "/user", {
    params: { limit, sort_direction: sortDirection }
  })
}

export const getPeriodsForReview = ({ limit, sortDirection }) => {
  // limit parameter is in months

  return axios.get(apiService.period + "/review", {
    params: { limit, sort_direction: sortDirection }
  })
}

export const getAllPeriod = () => {
  return axios.get(apiService.period)
}

export const getPeriodById = ({ periodId }) => {
  return axios.get(`${apiService.period}`, { params: { period_id: periodId } })
}

export const createPeriod = async (period) => {
  return axios.post(apiService.period, period)
}

export const updatePeriod = async (period) => {
  return axios.put(apiService.period + `/${period._id}`, period)
}

export const deletePeriod = (periodId) => {
  return axios.delete(apiService.period + `/${periodId}`)
}
