import React, { useEffect, useRef, useState } from "react"
import "./Icon.scss"

export default function Icon({
  iconRelativePath,
  spin,
  svgStyles,
  spanContainerStyles,
  className,
  onClick
}) {
  const spanRef = useRef(null)
  const [svg, setSvg] = useState(null)

  const getSvg = async () => {
    const importedSVG = await import(
      `!!raw-loader!../../../../assets/components/icons/${iconRelativePath}.svg`
    )
    return importedSVG.default
  }

  useEffect(() => {
    let isMounted = true

    getSvg().then((svg) => {
      if (isMounted) {
        setSvg(svg)
      }
    })
    return () => {
      isMounted = false
    }
  }, [iconRelativePath])

  useEffect(() => {
    if (svgStyles && spanRef.current?.querySelector("svg")) {
      spanRef.current?.querySelector("svg").setAttribute(
        "style",
        Object.entries(svgStyles)
          .map(([k, v]) => `${k}:${v}`)
          .join(";")
      )
    }
  }, [svg, svgStyles])

  return (
    <span
      onClick={onClick}
      style={spanContainerStyles}
      ref={spanRef}
      className={`icon ${spin ? "spin" : ""} ${className ? className : ""}`}
      dangerouslySetInnerHTML={{ __html: svg || "" }}
    />
  )
}
