export const parseAreaData = (area) => {
  return {
    id: area._id,
    name: area.name,
    owner: area.owner,
    deputies: area.deputies,
    internalSupporters: area.internalSupporters,
    externalSupporters: area.externalSupporters
  }
}
