import { useEffect, useRef, useState } from "react"
import { usePopper } from "react-popper"
import { formatMessage } from "devextreme/localization"
import Icon from "../Icon"
import "./InputDefaults.scss"
import { LabelWithError } from "./LabelWithError"
import TextInput from "./TextInput"
import "./DropdownSelect.scss"
import { useOnClickOutside } from "../../hooks/useOnClickOutside"

export default function DropdownSelect({
  className,
  style,
  inputStyles,
  inputClassName,
  options, // {id: string, cell: string or jsx, disabled: boolean, searchFunction: (searchText) => {return boolean}}
  value,
  onSelected,
  placeholder = formatMessage("jw-Select an option..."),
  label,
  innerLabel,
  disabled = false,
  readOnly = false,
  searchable = true,
  error = "",
  enableSeparator = false
}) {
  const [showOptions, setShowOptions] = useState(false)
  const [searchedOptions, setSearchedOptions] = useState(options)
  const [searchText, setSearchText] = useState("")
  const [inputEl, setInputEl] = useState(null)
  const dropdownSelectContainerRef = useRef(null)

  useEffect(() => {
    if (searchText === "") {
      setSearchedOptions(options)
      return
    }

    const newSearchedOptions = options?.filter((option) => {
      if (option.searchFunction) {
        return option.searchFunction(searchText)
      }
      if (typeof option.cell === "string") {
        return option.cell.toLowerCase().includes(searchText.toLowerCase())
      }

      return false
    })

    setSearchedOptions(newSearchedOptions)
  }, [searchText, options])

  useOnClickOutside(dropdownSelectContainerRef, () => {
    setShowOptions(false)
  })

  const trigger = () => {
    setShowOptions((prev) => !prev)
    if (popperEl != null) popper.update()
  }

  const getCurrentOption = () =>
    value ? options?.find((option) => option.id === value) : null

  const [selected, setSelected] = useState(getCurrentOption())
  useEffect(() => {
    setSelected(getCurrentOption())
  }, [value, options])

  const [popperEl, setPopperEl] = useState(null)
  const popper = usePopper(inputEl, popperEl, {
    placement: "bottom-start",
    strategy: "fixed"
  })

  const isOptionSelected = (option) => {
    return option.id === value
  }

  const selectOption = (option) => {
    trigger()
    if (onSelected) {
      onSelected(option)
    }
  }

  const deselectOption = () => {
    selectOption(null)
  }

  const svgStyles = { width: "12px", height: "12px", margin: "0 0 0 8px" }

  return (
    <div
      className={`${className} preallocation-dropdown-select-container d-flex flex-column ${
        readOnly ? "readonly" : disabled ? "disabled" : "cursor"
      }`}
      style={style}
      ref={dropdownSelectContainerRef}
    >
      {label ? (
        <LabelWithError
          disabled={disabled}
          readOnly={readOnly}
          onClick={trigger}
          label={label}
          error={error}
          labelClassName={`mb-1`}
        />
      ) : (
        ""
      )}
      {disabled || readOnly ? (
        <div
          ref={setInputEl}
          className={`text-nowrap overflow-hidden input d-flex flex-row justify-content-between ${
            readOnly ? "readonly" : disabled ? "disabled" : ""
          } ${inputClassName} ${error !== "" ? "border-danger" : ""}`}
          style={inputStyles}
          onClick={trigger}
        >
          <>
            {innerLabel}
            {selected ? selected.cell : placeholder}
          </>
        </div>
      ) : (
        <>
          <div
            tabIndex={0}
            ref={setInputEl}
            onKeyDown={(e) =>
              ["Enter", " "].indexOf(e.key) !== -1 && trigger() && e.preventDefault()
            }
            className={`text-nowrap overflow-hidden input d-flex flex-row justify-content-between ${inputClassName} ${
              error !== "" ? "border-danger" : ""
            }`}
            style={inputStyles}
            onClick={trigger}
          >
            <span>
              {innerLabel}
              {selected ? selected.cell : placeholder}
            </span>
            <Icon
              svgStyles={svgStyles}
              iconRelativePath={
                showOptions ? "monade/arrow-up" : "monade/arrow-down"
              }
            />
          </div>
          <div
            ref={setPopperEl}
            className={`options`}
            style={{
              ...popper.styles.popper,
              ...{
                minWidth: `${inputEl?.offsetWidth || 0}px`,
                maxWidth: `${1.5 * (inputEl?.current?.offsetWidth || 0)}px`,
                width: `auto`,
                visibility: showOptions ? "visible" : "collapse"
              }
            }}
            {...popper.attributes.popper}
          >
            {searchable ? (
              <TextInput
                value={searchText}
                placeholder={formatMessage("jw-Search...")}
                onChange={(value) => {
                  setSearchText(value)
                }}
              />
            ) : (
              ""
            )}
            {searchedOptions?.map((option, index) => (
              <div
                key={option.id}
                className={`text-nowrap option ${
                  selected?.id === option.id
                    ? "selected"
                    : option.disabled
                    ? "disabled"
                    : "enabled"
                } `}
                onClick={() =>
                  option.disabled
                    ? null
                    : isOptionSelected(option)
                    ? deselectOption(option)
                    : selectOption(option)
                }
              >
                {option.cell.split("\n").map((line, i) => (
                  <div key={i}>{line}</div>
                ))}
                {enableSeparator && index < searchedOptions.length - 1 && (
                  <div className="option-separator"></div>
                )}
              </div>
            ))}
            {searchedOptions?.length === 0 ? (
              <div className="option disabled">
                {formatMessage("jw-No options to show.")}
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </div>
  )
}
