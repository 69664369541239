import { useEffect, useState } from "react"
import Calendar from "../components/Calendar"
import ProjectCell from "./ProjectCell"
import ProjectSlotCell from "./ProjectSlotCell"
import ResourceCell from "./ResourceCell"
import ResourceSlotCell from "./ResourceSlotCell"
import DateNav from "../components/DateNav"
import { formatDateInterval } from "../utils/formatDateInterval"
import "./ProjectsCalendar.scss"
import { calcBiggestPercentageRatio } from "../utils/calcBiggestPercentageRatio"
import moment from "moment"

export default function ProjectsCalendar({
  projects,
  filters,
  selectedAllocations,
  isEditingMultiple,
  editingAllocation,
  isLoadingProjects,
  loadProjectsError,
  canViewProjectDetails,
  onReload,
  toggleIsOpen,
  toggleIsPinned,
  onAddAllocation,
  onEditAllocation,
  onEditMultipleAllocations,
  onRemoveResource,
  onPreviousClick,
  onNextClick
}) {
  const [dateNavFrom, setDateNavFrom] = useState(null)
  const [dateNavTo, setDateNavTo] = useState(null)
  const [formattedSlotHeaders, setFormattedSlotHeaders] = useState([])

  useEffect(() => {
    const startDate = moment(filters.startDate).toDate()
    const endDate = moment(startDate)
      .add(6, filters.aggregation === "WEEKLY" ? "week" : "month")
      .add(-1, "day")
      .toDate()
    setDateNavFrom(startDate)
    setDateNavTo(endDate)
    setFormattedSlotHeaders(
      new Array(6).fill(null).map((_, i) => {
        const date = moment(startDate).add(
          i,
          filters.aggregation === "WEEKLY" ? "week" : "month"
        )
        return formatDateInterval(
          date.toDate(),
          date
            .add(1, filters.aggregation === "WEEKLY" ? "week" : "month")
            .add(-1, "day")
            .toDate()
        )
      })
    )
    if (!filters?.aggregation || !projects || projects.length === 0) {
      return
    }

    const slotDates = projects[0].projectTimeSlots.map(({ startDate, endDate }) => ({
      startDate,
      endDate
    }))

    if (slotDates.length === 0) {
      return
    }

    setDateNavFrom(slotDates[0].startDate)
    setDateNavTo(slotDates[slotDates.length - 1].endDate)
    setFormattedSlotHeaders(
      slotDates.map(({ startDate, endDate }) =>
        formatDateInterval(startDate, endDate)
      )
    )
  }, [projects, filters])

  const headerMainCellComponentFactory = () => (
    <DateNav
      from={dateNavFrom}
      to={dateNavTo}
      onPreviousClick={onPreviousClick}
      onNextClick={onNextClick}
    />
  )

  const headerSlotCellComponentsFactory = () =>
    formattedSlotHeaders.map((slotHeaders, index) => (
      <td key={index} className="slot-header">
        {slotHeaders}
      </td>
    ))

  const mainCellComponentFactory = (project) => (
    <ProjectCell
      project={project}
      filters={filters}
      onToggleIsOpen={() => toggleIsOpen(project)}
      onToggleIsPinned={() => toggleIsPinned(project)}
      onAddResourceLight={() => onAddAllocation(project)}
      canViewProjectDetails={canViewProjectDetails}
    />
  )

  const slotCellComponentsFactory = (project) =>
    project.projectTimeSlots.map((slot) => <ProjectSlotCell slot={slot} />)

  const subrowMainCellComponentFactory = (project, resource, rowRef) => (
    <ResourceCell
      project={project}
      resource={resource}
      filters={filters}
      onAddAllocation={onAddAllocation}
      onEditMultipleAllocations={(project, resource) =>
        onEditMultipleAllocations(project, resource, rowRef.current)
      }
      onRemove={onRemoveResource}
    />
  )

  const subrowSlotCellComponentsFactory = (project, resource, maxRatio) =>
    resource.userTimeSlots.map((slot, index) => (
      <ResourceSlotCell
        slot={slot}
        resource={resource}
        maxRatio={calcBiggestPercentageRatio(resource.userTimeSlots)}
        highlightedActivityId={project.id}
        onActivityClick={(activity) =>
          onEditAllocation(
            project,
            resource,
            activity,
            project.projectTimeSlots[index]
          )
        }
        selected={(activity) => {
          const allocation = {
            ...activity,
            projectId: project.id,
            resourceId: resource.id,
            startDate: project.projectTimeSlots[index].startDate,
            endDate: project.projectTimeSlots[index].endDate
          }
          return selectedAllocations
            .map((e) => JSON.stringify(e, Object.keys(e).sort()))
            .includes(JSON.stringify(allocation, Object.keys(allocation).sort()))
        }}
        selectable={(activity) =>
          isEditingMultiple &&
          !activity.readOnly &&
          editingAllocation?.project?.id === project.id &&
          editingAllocation?.resource?.id === resource.id
        }
      />
    ))

  return (
    <Calendar
      className="projects-calendar"
      rowModels={projects}
      getSubrowModelsFn={(project) =>
        project.isOpen ? project.details?.users || [] : []
      }
      headerMainCellComponentFactory={headerMainCellComponentFactory}
      headerSlotCellComponentsFactory={headerSlotCellComponentsFactory}
      mainCellComponentFactory={mainCellComponentFactory}
      slotCellComponentsFactory={slotCellComponentsFactory}
      subrowMainCellComponentFactory={subrowMainCellComponentFactory}
      subrowSlotCellComponentsFactory={subrowSlotCellComponentsFactory}
      headerSpacing={20}
      isLoading={isLoadingProjects}
      error={loadProjectsError}
      onReload={onReload}
    />
  )
}
