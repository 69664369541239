import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import React, { useEffect, useState } from "react"
import Draggable from "react-draggable"
import { updateOrganization } from "../../../services/index"
import { Bounce, toast } from "react-toastify"
import { formatMessage } from "devextreme/localization"
import { Checkbox as MaterialUICheckbox } from "@material-ui/core"
import Checkbox from "../JobAndWbs/components/input/Checkbox"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons"

const icon = <FontAwesomeIcon icon={faSquare} />
const checkedIcon = <FontAwesomeIcon icon={faCheckSquare} />

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

export const EditRow = ({
  togglePopUp,
  setTogglePopUp,
  types,
  data,
  teamLeaders,
  areaManagers,
  internalSupportersList,
  externalSupportersList,
  organizationList
}) => {
  const [id, setId] = useState(data !== null ? data._id : "")
  const [name, setName] = useState(data !== null ? data.name : "")
  const [type, setType] = useState(data !== null ? data.type : "")
  const [owner, setOwner] = useState(
    data !== null && data.owner !== null ? data.owner.name : ""
  )
  const [parent, setParent] = useState(data !== null ? data.parent : "")
  const [possibleOwners, setPossibleOwners] = useState(teamLeaders)
  const [possibleInternalSupporters, setPossibleInternalSupporters] = useState([])
  const [possibleExternalSupporters, setPossibleExternalSupporters] = useState([])
  const [enableSave, setEnableSave] = useState(false)
  const [isCompetence, setIsCompetence] = useState(
    data !== null && data.isCompetence !== null ? data.isCompetence : null
  )
  const [shortcode, setShortcode] = useState(
    data !== null && data.shortcode !== null ? data.shortcode : ""
  )
  const [deputies, setDeputies] = useState([])
  const [internalSupporters, setInternalSupporters] = useState([])
  const [externalSupporters, setExternalSupporters] = useState([])
  const [initialType, setInitialType] = useState([])

  useEffect(() => {
    setId(data !== null ? data._id : "")
    setName(data !== null ? data.name : "")
    setType(data !== null ? data.type : "")
    setOwner(data !== null && data.owner !== null ? data.owner : "")
    setParent(data !== null && data.parent !== null ? data.parent : "")
    setIsCompetence(
      data !== null && data.isCompetence !== null ? data.isCompetence : null
    )
    setShortcode(data !== null && data.shortcode !== null ? data.shortcode : "")
    setDeputies(data !== null && data.deputies != null ? data.deputies : [])
    setInternalSupporters(
      data !== null && data.internalSupporters != null ? data.internalSupporters : []
    )
    setExternalSupporters(
      data !== null && data.externalSupporters != null ? data.externalSupporters : []
    )
    setInitialType(data !== null ? data.type : "")
  }, [data])

  // load different user according to the UO type
  useEffect(() => {
    switch (type) {
      case "Team":
        setPossibleOwners(teamLeaders)
        setIsCompetence(undefined)
        setDeputies(undefined)
        setShortcode(undefined)
        break
      case "Area":
        setPossibleOwners(areaManagers)
        setPossibleInternalSupporters(internalSupportersList)
        setPossibleExternalSupporters(externalSupportersList)
        break
      default:
      // code block
    }
  }, [type, data, teamLeaders, areaManagers])

  useEffect(() => {
    setEnableSave(name !== undefined && name !== "")
  }, [name])

  useEffect(() => {
    // do not allow saving if type is area and the shortCode is missing
    if (type === types[1]) setEnableSave(shortcode !== undefined && shortcode !== "")
    // console.log(type, initialType)
  }, [type, shortcode])

  useEffect(() => {
    if (data !== null && type !== null && type !== "" && type !== data.type)
      setOwner(null)
  }, [type])

  const isArea = () => type === types[1]

  const onInsert = async (data) => {
    try {
      // console.log(data)
      await updateOrganization(data)
      setTogglePopUp(false)
    } catch (e) {
      console.log("Error on adding", e.toString())

      let errorResp = e.response
      let errorMessage = ""

      if (errorResp?.status === 409) {
        if (errorResp.data.errors) {
          const paths = []
          e.response.data.errors.forEach((error) => paths.push(error.path))
          errorMessage =
            "Organization already exists: verify " +
            paths.join(", ") +
            " to be unique"
        } else {
          errorMessage = formatMessage(errorResp.data)
        }
      } else if (errorResp?.status === 400) {
        if (e.response.data.errors) {
          errorMessage = formatMessage("invalid-input-data")
        } else {
          errorMessage = errorResp.data.message
        }
      } else {
        errorMessage = formatMessage("error-updating-organization")
      }

      toast(errorMessage, {
        transition: Bounce,
        closeButton: true,
        autoClose: 3000,
        position: "bottom-center",
        type: "error"
      })
      return
    }
  }

  const render = () => {
    if (setTogglePopUp) {
      return (
        <Dialog
          open={togglePopUp}
          disableBackdropClick
          onClose={() => setTogglePopUp(false)}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            {formatMessage("edit-organization-title")}
          </DialogTitle>
          <DialogContent>
            <TextField
              id="name"
              label={formatMessage("name")}
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
              variant="outlined"
              style={{ marginTop: 20 }}
              inputProps={{ maxLength: 20 }}
              fullWidth
            />
            <Autocomplete
              id="combo-box-type"
              options={types}
              getOptionLabel={(option) => option}
              defaultValue={type}
              onChange={(item, value) => {
                // console.log(value)
                setType(value)
              }}
              disableClearable={true}
              renderInput={(params) => (
                <TextField {...params} label={type} variant="outlined" />
              )}
              style={{ marginTop: 20 }}
            />
            <Autocomplete
              id="combo-box-owner"
              options={possibleOwners}
              getOptionLabel={(option) => option.name ?? ""}
              getOptionSelected={(option, value) => option?.id === value?.id}
              defaultValue={owner}
              key={owner?.id}
              value={owner}
              onChange={(item, value) => {
                // console.log(value)
                setOwner(value)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={formatMessage("organization-owner")}
                  variant="outlined"
                />
              )}
              style={{ marginTop: 20 }}
            />
            <Autocomplete
              id="combo-box-org"
              options={organizationList}
              getOptionLabel={(option) => option.name ?? ""}
              getOptionSelected={(option, value) => option?.id === value?.id}
              defaultValue={parent}
              key={parent?.id}
              value={parent}
              onChange={(item, value) => {
                setParent(value)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Parent Organization"}
                  variant="outlined"
                />
              )}
              style={{ marginTop: 20 }}
            />
            {isArea() && (
              <>
                <TextField
                  id="shortcode"
                  label={"Shortcode"}
                  value={shortcode}
                  onChange={(e) => {
                    setShortcode(e.target.value)
                  }}
                  variant="outlined"
                  style={{ width: "100%", marginTop: 20 }}
                  inputProps={{
                    readOnly: initialType !== "Team"
                  }}
                />
                <Checkbox
                  disabled={false}
                  readOnly={false}
                  value={isCompetence}
                  onChange={(event) => {
                    setIsCompetence(event.target.checked)
                  }}
                  label={"Competence Area"}
                  style={{
                    width: "100%",
                    marginTop: 20
                  }}
                />
              </>
            )}
            {type === "Area" && (
              <Autocomplete
                id="combo-box-deputies"
                options={possibleOwners}
                multiple={true}
                disableCloseOnSelect
                getOptionLabel={(option) => option?.name ?? ""}
                getOptionSelected={(option, value) => option?.id === value?.id}
                defaultValue={deputies}
                value={deputies}
                onChange={(event, value) => {
                  setDeputies(value)
                }}
                renderInput={(params) => (
                  <TextField {...params} label={"Deputies"} variant="outlined" />
                )}
                renderOption={(option, state) => {
                  return (
                    <li style={{ width: "auto" }}>
                      <MaterialUICheckbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={state.selected}
                      />
                      {option?.name}
                    </li>
                  )
                }}
                fullWidth
                style={{ marginTop: 20 }}
              />
            )}
            {type === "Area" && (
              <Autocomplete
                id="combo-box-internal-supporters"
                options={possibleInternalSupporters.filter(
                  (supporter) => !internalSupporters.includes(supporter)
                )}
                multiple={true}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name ?? ""}
                getOptionSelected={(option, value) => option?.id === value?.id}
                defaultValue={internalSupporters}
                value={internalSupporters}
                onChange={(event, value) => {
                  setInternalSupporters(value ?? [])
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Internal Supporters"}
                    variant="outlined"
                  />
                )}
                renderOption={(option, state) => {
                  return (
                    <li style={{ width: "auto" }}>
                      <MaterialUICheckbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={state.selected}
                      />
                      {option.name}
                    </li>
                  )
                }}
                fullWidth
                style={{ marginTop: 20 }}
              />
            )}
            {type === "Area" && (
              <Autocomplete
                id="combo-box-external-supporters"
                options={possibleExternalSupporters.filter(
                  (supporter) => !externalSupporters.includes(supporter)
                )}
                multiple={true}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name ?? ""}
                getOptionSelected={(option, value) => option?.id === value?.id}
                defaultValue={externalSupporters}
                value={externalSupporters}
                onChange={(event, value) => {
                  setExternalSupporters(value ?? [])
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"External Supporters"}
                    variant="outlined"
                  />
                )}
                renderOption={(option, state) => {
                  return (
                    <li style={{ width: "auto" }}>
                      <MaterialUICheckbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={state.selected}
                      />
                      {option.name}
                    </li>
                  )
                }}
                fullWidth
                style={{ marginTop: 20 }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setTogglePopUp(false)
                setId(data !== null ? data._id : "")
                setName(data !== null ? data.name : "")
                setType(data !== null ? data.type : "")
                setOwner(data !== null && data.owner !== null ? data.owner : "")
                setIsCompetence(data !== null ? data.isCompetence : null)
                setShortcode(data !== null ? data.shortcode : "")
                setOwner(data !== null && data.owner != null ? data.owner : "")
                setDeputies(
                  data !== null && data.deputies != null ? data.deputies : []
                )
                setInternalSupporters(
                  data !== null && data.internalSupporters != null
                    ? data.internalSupporters
                    : []
                )
                setExternalSupporters(
                  data !== null && data.internalSupporters != null
                    ? data.internalSupporters
                    : []
                )
              }}
              color="primary"
            >
              {formatMessage("Cancel")}
            </Button>
            <Button
              disabled={!enableSave}
              onClick={() => {
                let org = {
                  _id: id,
                  name: name,
                  type: type,
                  owner: owner !== null ? owner.id : null,
                  parent: parent !== null ? parent.id : null
                }

                if (isArea()) {
                  org = {
                    ...org,
                    isCompetence: isCompetence,
                    deputies:
                      deputies != null ? deputies.map((deputy) => deputy.id) : [],
                    internalSupporters:
                      internalSupporters != null
                        ? internalSupporters.map((supporter) => supporter.id)
                        : [],
                    externalSupporters:
                      externalSupporters != null
                        ? externalSupporters.map((supporter) => supporter.id)
                        : []
                  }
                }
                return onInsert(org)
              }}
              color="primary"
            >
              {formatMessage("dxDataGrid-editingSaveRowChanges")}
            </Button>
          </DialogActions>
        </Dialog>
      )
    } else {
      return <div />
    }
  }

  return <div>{render()}</div>
}
