import React from "react"
import errorImg from "./error_401.jpg"

export default function Unauthorized() {
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)"
      }}
    >
      <img style={{ height: "100%" }} src={errorImg} alt="" />
    </div>
  )
}
