import { formatMessage } from "devextreme/localization"
import { getErrorByField } from "../../Headcount/Main/Utils/ErrorHandling"
import Checkbox from "./input/Checkbox"
import NumericInput from "./input/NumericInput"
import PercentageInput from "./input/PercentageInput"
import PriceInput from "./input/PriceInput"
import DropdownSelect from "./input/DropdownSelect.js"
import { useSelectForceDC } from "../hooks/selectHooks/useSelectForceDC"

export default function YearDetailsForm({
  year,
  wbs,
  onValueChanged,
  isCreating = false,
  errors,
  canManage = false,
}) {
  const { forceDCOptions } = useSelectForceDC()

  const isChargeable = () => wbs.type === "1"

  return (
    <div className="d-flex flex-column">
      <h4 className="mb-4">
        {wbs.code} / {!year.year || year.year === "" ? "-" : year.year}
      </h4>
      <div className="w-100 d-flex flex-row align-items-end mb-4">
        <NumericInput
          readOnly={!canManage}
          label={formatMessage("jw-Year") + " *"}
          value={year.year}
          className="w-25"
          onChange={(event) => onValueChanged("year", event.target.value)}
          error={getErrorByField(errors, "year")}
        />
        {isChargeable() ? (
          <>
            {!isCreating ? (
              <div className="w-25 pl-3 d-flex flex-row justify-content-left align-items-center">
                <NumericInput
                  readOnly={true || !canManage}
                  label={formatMessage("jw-DC multiply") + " *"}
                  value={year.dcMultiply}
                  className="w-50"
                  onChange={(event) =>
                    onValueChanged("dcMultiply", event.target.value)
                  }
                  error={getErrorByField(errors, "dcMultiply")}
                />
                <Checkbox
                  readOnly={true}
                  className="w-50"
                  label={formatMessage("jw-IS DC")}
                  value={year.isDc}
                  onChange={(event) => onValueChanged("isDc", event.target.checked)}
                  error={getErrorByField(errors, "isDc")}
                />
              </div>
            ) : (
              ""
            )}
            <div
              className={`${
                !isCreating ? "pl-3" : "pl-3"
              } w-25 d-flex flex-row justify-content-center align-items-center`}
            >
              <DropdownSelect
                readOnly={!canManage}
                options={forceDCOptions}
                label={formatMessage("jw-force DC") + " *"}
                className="w-100"
                value={year.forceDc}
                searchable={false}
                onSelected={(option) => {
                  // console.log(option)
                  console.log(year)
                  onValueChanged("forceDc", option?.id || null)
                }}
                error={getErrorByField(errors, "forceDc")}
              />
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      {isChargeable() ? (
        <>
          <div className="d-flex flex-row mb-4">
            <PriceInput
              readOnly={!canManage}
              className="w-25"
              label={formatMessage("jw-Contract value") + " *"}
              value={year.contractValue}
              onChange={(val) => onValueChanged("contractValue", val)}
              error={getErrorByField(errors, "contractValue")}
            />
            <PriceInput
              readOnly={!canManage}
              className="w-25 pl-3"
              label={formatMessage("jw-STD total costs") + " *"}
              value={year.standardCostsTotal}
              onChange={(val) => onValueChanged("standardCostsTotal", val)}
              error={getErrorByField(errors, "standardCostsTotal")}
            />
            <PriceInput
              readOnly={!canManage}
              className="w-25 pl-3"
              label={formatMessage("jw-Exp & other costs")}
              value={year.otherCosts}
              onChange={(val) => onValueChanged("otherCosts", val)}
              error={getErrorByField(errors, "otherCosts")}
            />
          </div>
          {!isCreating ? (
            <>
              <div className="d-flex flex-row mb-4">
                <PriceInput
                  readOnly={true || !canManage}
                  className="w-25"
                  label={formatMessage("jw-Margin")}
                  value={year.targetMargin}
                  onChange={(val) => onValueChanged("targetMargin", val)}
                  error={getErrorByField(errors, "targetMargin")}
                />
                <PercentageInput
                  readOnly={true || !canManage}
                  className="w-25 pl-3"
                  label={formatMessage("jw-Margin %")}
                  value={year.targetMarginPerc}
                  onChange={(event) =>
                    onValueChanged(
                      "targetMarginPerc",
                      parseFloat(event.target.value),
                    )
                  }
                  error={getErrorByField(errors, "targetMarginPerc")}
                />
                <PriceInput
                  readOnly={true || !canManage}
                  className="w-25 pl-3"
                  label={formatMessage("jw-Managerial")}
                  value={year.revenueXtechManagerial}
                  onChange={(val) => onValueChanged("revenueXtechManagerial", val)}
                  error={getErrorByField(errors, "revenueXtechManagerial")}
                />
              </div>
              <div className="d-flex flex-row mb-4">
                <PriceInput
                  readOnly={true || !canManage}
                  className="w-25"
                  label={formatMessage("jw-Margin xTech")}
                  value={year.xTechMargin}
                  onChange={(val) => onValueChanged("xTechMargin", val)}
                  error={getErrorByField(errors, "xTechMargin")}
                />
                <PriceInput
                  readOnly={true || !canManage}
                  className="w-25 pl-3"
                  label={formatMessage("jw-STD cost xTech")}
                  value={year.standardCostsXtech}
                  onChange={(val) => onValueChanged("standardCostsXtech", val)}
                  error={getErrorByField(errors, "standardCostsXtech")}
                />
                <PercentageInput
                  readOnly={true || !canManage}
                  className="w-25 pl-3"
                  label={formatMessage("jw-STD effort xTech")}
                  value={year.xTechEffortPerc}
                  onChange={(event) =>
                    onValueChanged("xTechEffortPerc", parseFloat(event.target.value))
                  }
                  error={getErrorByField(errors, "xTechEffortPerc")}
                />
              </div>
              <div className="d-flex flex-row mb-4">
                <PriceInput
                  readOnly={true || !canManage}
                  className="w-25"
                  label={formatMessage("jw-RFP")}
                  value={year.rfpXTech}
                  onChange={(val) => onValueChanged("rfpXTech", val)}
                  error={getErrorByField(errors, "rfpXTech")}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </div>
  )
}
