import { formatMessage } from "devextreme/localization"
import React, { Fragment, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Button } from "reactstrap"
import _ from "underscore"
import {
  getLevels,
  getAllOrganizations,
  getUser,
  getUsers,
  getAllOffices
} from "../../../services"
import PageTitle from "./../../../Layout/AppMain/PageTitle"
import HCShow from "./Main/HCShow"
import { calcChargeability } from "./Main/Utils/CalcChargeability"
import { calcExtJobTitle } from "./Main/Utils/CalcExtJobTitle"
import { calcTotWorkedYears } from "./Main/Utils/CalcTotWorkedYears"
import clone from "./Main/Utils/Clone"
import { parseUserDates } from "./Main/Utils/ParseUserDates"
import { parseUserLevelDate } from "./Main/Utils/ParseUserLevelDate"
import sortByStartDate from "./Main/Utils/SortByStartDate"
import "./Styles/edit.css"
import "./Styles/searchBoxPadding.css"

export default function HeadcountShow(props) {
  const [user, setUser] = useState({})
  const config = useSelector((state) => state.Auth.config)
  const [offices, setOffices] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [levels, setLevels] = useState([])
  const [userNames, setUserNames] = useState([])
  const [spinner, setSpinner] = useState(false)
  const [userLevels, setUserLevels] = useState()

  useEffect(() => {
    if (config) {
      setLevels(_.sortBy(config.levels, "name"))
    }
  }, [config])

  useEffect(() => {
    getAllOffices().then(({ data }) => {
      setOffices(data.map((j) => ({ id: j._id, name: j.name, code: j.code })))
    })
  }, [])

  useEffect(() => {
    getAllOrganizations().then(({ data }) => {
      setOrganizations(
        data.map((j) => ({ id: j._id, name: j.name, owner: j.owner }))
      )
    })
  }, [])

  useEffect(() => {
    setSpinner(true)
    getUser(props.match.params.id)
      .then((resp) => {
        const user = resp.data
        user.workedYearsBeforeBip = user.workedYearsBeforeBip || 0
        user.workedYearsInBip = user.workedYearsInBip || 0
        user.totWorkedYears = calcTotWorkedYears(
          user.workedYearsBeforeBip,
          user.workedYearsInBip
        )
        user.chargeability = calcChargeability(user)
        if (user.currentLevel) {
          user.currentLevel.extJobTitle = calcExtJobTitle(
            user.currentLevel?.code,
            user.competence,
            levels
          )
          user.currentLevel.cost *= 8
        }
        let parsedUser = parseUserDates(user)
        setUser(clone(parsedUser))
      })
      .finally(() => setSpinner(false))
  }, [props.match.params.id])

  useEffect(() => {
    getUsers().then(({ data }) => {
      setUserNames(
        data
          .sort(function(a, b) {
            var A = a.firstName.toUpperCase()
            var B = b.firstName.toUpperCase()
            return A < B ? -1 : A > B ? 1 : 0
          })
          .map((user) => ({
            id: user._id,
            name: `${user.firstName} ${user.lastName}`
          }))
      )
    })
  }, [])

  useEffect(() => {
    fetchUserLevels()
  }, [props.match.params.id])

  const fetchUserLevels = () => {
    getLevels(props.match.params.id).then(({ data }) => {
      let userLvls = Array.isArray(data) ? data : []
      userLvls.forEach((lvl) => {
        lvl.cost *= 8
      })
      let parsedUserLevels = []
      userLvls.forEach((lvl) => {
        parsedUserLevels.push(parseUserLevelDate(lvl))
      })
      setUserLevels(sortByStartDate(parsedUserLevels))
    })
  }

  const onCancel = () => {
    props.history.push("/admin/headcount")
  }

  function renderButtons() {
    return (
      <div>
        <Button
          outline
          className="mr-2"
          color="primary"
          d="button"
          width={100}
          height={40}
          onClick={onCancel}
        >
          <span className="dx-button-text">
            {("< " + formatMessage("Back")).toUpperCase()}
          </span>
        </Button>
      </div>
    )
  }

  return (
    <Fragment>
      <PageTitle
        heading={formatMessage("hc-details-title")}
        icon="pe-7s-graph text-success"
        actions={renderButtons()}
      ></PageTitle>
      {spinner ? (
        <h5>LOADING</h5>
      ) : (
        <>
          <HCShow
            user={user}
            offices={offices}
            organizations={organizations}
            levels={levels}
            userNames={userNames}
            userLevels={userLevels}
          />
        </>
      )}
    </Fragment>
  )
}
