import DataGrid, {
  Button,
  Column,
  FilterRow,
  Item,
  SearchPanel,
  Toolbar,
  Export
} from "devextreme-react/data-grid"
import CustomStore from "devextreme/data/custom_store"
import { formatMessage } from "devextreme/localization"
import { React, useEffect, useRef, useState } from "react"
import { getMissingTimesheetByPeriod } from "../../../services"
import { Button as DevextremeButton } from "devextreme-react"
import { getTeamsChatUrl } from "../Utils/utils.js"
import { useSelector } from "react-redux"
import isExternal from "../../Admin/Headcount/Main/Utils/IsExternal.js"
import moment from "moment/moment.js"

const getDatasource = ({ periodId, setPeriodNotStarted, levels }) => {
  return new CustomStore({
    load: async function() {
      return getMissingTimesheetByPeriod({ period: periodId }).then((response) => {
        return response.data.map((user) => ({
          ...user,
          isExternal: isExternal(user.currentLevel.code, levels)
        }))
      })
    },
    onLoaded: (e) => {
      if (
        typeof e.data === "string" &&
        e.data?.toLowerCase() === "period not started"
      ) {
        setPeriodNotStarted(true)
        e.data = []
      } else {
        setPeriodNotStarted(false)
      }
    }
  })
}

export default function MissingTimesheetsTable(props) {
  const [datasource, setDatasource] = useState()
  const [periodNotStarted, setPeriodNotStarted] = useState(false)
  const config = useSelector((state) => state.Auth.config)

  const _datagrid = useRef(null)

  useEffect(() => {
    setDatasource(
      getDatasource({
        periodId: props.selectedPeriod._id,
        setPeriodNotStarted,
        levels: config?.levels
      })
    )
  }, [props.selectedPeriod._id])

  return (
    <DataGrid
      dataSource={datasource}
      showBorders={true}
      showRowLines
      ref={_datagrid}
      showColumnHeaders={true}
      noDataText={
        periodNotStarted
          ? formatMessage("period-not-started")
          : formatMessage("dxDataGrid-noDataText")
      }
    >
      <Export
        enabled={true}
        fileName={"missing_timesheets_" + props.selectedPeriod._id + "-" + moment().format("YYYYMMDD")}
      />
      <SearchPanel visible={true} width={240} />
      <FilterRow visible={true} />
      <Column
        allowFiltering={true}
        caption={formatMessage("firstName")}
        allowSearch={true}
        dataField={"firstName"}
      />
      <Column
        allowFiltering={true}
        caption={formatMessage("lastName")}
        allowSearch={true}
        dataField={"lastName"}
      />
      <Column
        caption={formatMessage("email")}
        allowFiltering={true}
        allowSearch={true}
        dataField={"email"}
        cellRender={(data) => {
          return (
            <a
              title={formatMessage("tooltip-open-teams-chat")}
              href={getTeamsChatUrl(data.data.email)}
              target="_blank"
              rel="noreferrer"
            >
              {data.data.email}
            </a>
          )
        }}
      />
      <Column
        caption={formatMessage("hc-External")}
        dataField={"isExternal"}
        allowFiltering={true}
        dataType={"boolean"}
      />
      <Column
        caption={formatMessage("hc-field-team-account")}
        dataField={"isTeam"}
        allowFiltering={true}
        dataType={"boolean"}
      />
      <Column type="buttons">
        <Button
          icon="fa fa-file-pen"
          onClick={(e) => {
            props.history.push({
              pathname: `/preter/management/missing-timesheet/${e.row.data._id}/${props.selectedPeriod._id}`
            })
          }}
        ></Button>
      </Column>
      <Toolbar>
        <Item name="refreshButton" location="after" widget={"searchPanel"}>
          <DevextremeButton
            icon="refresh"
            hint={formatMessage("dxDataGrid-commandRefresh")}
            onClick={() => _datagrid.current.instance.refresh()}
          />
        </Item>
        <Item name="exportButton" />
        <Item name="searchPanel" />
      </Toolbar>
    </DataGrid>
  )
}
