import {useMemo} from "react";
import Calendar from "../components/Calendar";
import {formatDateInterval} from "../utils/formatDateInterval";
import "./ResourceCalendar.scss";
import ResourceSlotCell from "../ProjectsView/ResourceSlotCell";
import {calcBiggestPercentageRatio} from "../utils/calcBiggestPercentageRatio";

export default function ResourceCalendar({
  resource,
  projectId,
  slotDates,
  onReloadResource
}) {
  const rowModels = useMemo(() => [resource], [resource]);
  const maxRatio = useMemo(() => calcBiggestPercentageRatio(resource.details.resourceTimeSlots), [resource.details.resourceTimeSlots]);
  const formattedSlotHeaders = useMemo(() => {
    if (!slotDates) {
      return []
    }
    return slotDates.map(({ startDate, endDate }) =>
      formatDateInterval(startDate, endDate)
    )
  }, [slotDates]);

  const headerSlotCellComponentsFactory = () =>
    formattedSlotHeaders.map((slotHeaders, index) => <td key={index}>{slotHeaders}</td>)

  const slotCellComponentsFactory = (resource) =>
    resource.details.resourceTimeSlots.map((slot, index) => (
      <ResourceSlotCell
        key={index}
        slot={slot}
        resource={resource}
        maxRatio={maxRatio}
        highlightedActivityId={projectId}
        showTotalBar={false}
        onActivityClick={() => {}}
        selectable={() => false}
        selected={() => false}
      />
    ))

  return (
    <Calendar
      className="resource-calendar"
      rowModels={rowModels}
      headerSlotCellComponentsFactory={headerSlotCellComponentsFactory}
      slotCellComponentsFactory={slotCellComponentsFactory}
      headerSpacing={5}
      isLoading={resource.isLoadingDetails}
      error={resource.loadDetailsError}
      onReload={onReloadResource}
      stickyHeader={false}
    />
  )
}
