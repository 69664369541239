import { locale, loadMessages } from "devextreme/localization"

import itMessages from "../../assets/dictionaries/it.json"
import enMessages from "../../assets/dictionaries/en.json"

export function getLocale() {
  const locale = sessionStorage.getItem("locale")
  return locale != null ? locale : "en"
}

export function setLocale(newLocale) {
  sessionStorage.setItem("locale", newLocale)
}

export function initGlobalize(currentLocale) {
  loadMessages(enMessages)
  loadMessages(itMessages)
  locale(currentLocale)
}
