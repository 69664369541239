import React, { Fragment } from "react"
import { Route } from "react-router-dom"
import Timesheet from "./Timesheet/"
import JOReview from "./JOReview/"
import Review from "./Review/"
import Externals from "./ExternalsReview/"

// Layout

import AppHeader from "../../Layout/AppHeader/"
import AppSidebar from "../../Layout/AppSidebar/"
import AppFooter from "../../Layout/AppFooter/"

import { useAppAbility } from "./../../Context/ability"
import MissingTimesheet from "./MissingTimesheet"
import CreateMissingTimesheet from "./MissingTimesheet/create/index"

const Preter = ({ match }) => {
  const { can } = useAppAbility()

  const canReadReview = can("read", "Review")
  const canReadPreTer = can("read", "PreTer")
  const canReadMissingTimesheet = ("manage", "MissingTimesheet")
  const isJobOwner = can("read", "JobReview")
  const canReadExternalReview = can("read", "ExternalReview")

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            {canReadPreTer && (
              <Route path={`${match.url}/timesheet`} component={Timesheet} />
            )}
            {isJobOwner && (
              <Route
                path={`${match.url}/management/jo-review`}
                component={JOReview}
              />
            )}
            {canReadReview && (
              <>
                <Route path={`${match.url}/management/review`} component={Review} />
              </>
            )}
            {canReadMissingTimesheet && (
              <>
                <Route
                  exact
                  path={`${match.url}/management/missing-timesheet/:userId/:periodId`}
                  component={CreateMissingTimesheet}
                />
                <Route
                  exact
                  path={`${match.url}/management/missing-timesheet`}
                  component={MissingTimesheet}
                />
              </>
            )}
            {canReadExternalReview && (
              <Route
                path={`${match.url}/management/externals-review`}
                component={Externals}
              />
            )}
          </div>
          <AppFooter />
        </div>
      </div>
    </Fragment>
  )
}

export default Preter
