import DropdownSelect from "./input/DropdownSelect"
import "./Filter.scss"
import { formatMessage } from "devextreme/localization"

export default function IsFinalFilter({ value, setValue, className }) {
  return (
    <DropdownSelect
      searchable={false}
      className={`${className} filter-select`}
      innerLabel={
        <span className="text-muted pr-1">{formatMessage("jw-final")}:</span>
      }
      value={value || "all"}
      options={[
        { id: "true", cell: formatMessage("jw-true") },
        { id: "false", cell: formatMessage("jw-false") },
      ]}
      placeholder={formatMessage("jw-all")}
      onSelected={(option) => {
        setValue(option?.id || null)
      }}
    />
  )
}
