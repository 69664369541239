import CustomStore from "devextreme/data/custom_store"
import {
  createOrganization,
  deleteOrganization,
  getOrganizations,
  updateOrganization,
} from "../../../services/index"
import merge from "deepmerge"

export const getOrganizationDatasource = (setOrganizationList) => {
  return {
    store: new CustomStore({
      load: async function () {
        return getOrganizations()
          .then(async ({ data }) => {
            if (data) {
              setOrganizationList(
                data.map((org) => {
                  if (org.isCompetence == null) {
                    org.isCompetence = false
                  }
                  return {
                    id: org._id,
                    name: org.name,
                  }
                })
              )
              return {
                data: data,
                totalCount: 10,
              }
            } else {
              setOrganizationList([])
              return {
                data: [],
                totalCount: 0,
              }
            }
          })
          .catch((e) => {
            throw e
          })
      },
      update: async (before, dataToUpdate) => {
        const objToSave = merge(before, dataToUpdate)
        console.log(objToSave)
        return updateOrganization(objToSave)
      },
      insert: async (data) => {
        return createOrganization(data)
      },
      remove: async (data) => {
        return deleteOrganization(data._id)
      },
    }),
  }
}
