import { useQuery } from "@tanstack/react-query"
import { getJob } from "../../../../services"
import { parseJobData } from "../utils/parseJobData"

export function useJobData(shortCode, includeDeputyAccountables = false, includeSupporters = false) {
  const { isLoading, isError, isSuccess, data, error } = useQuery(
    ["GET", "job/", shortCode],
    async () => {
      return await getJob(shortCode, includeDeputyAccountables, includeSupporters).then((data) => {
        return parseJobData(data.data[0])
      })
    }
  )

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
  }
}
