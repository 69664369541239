import PageTitle from "../../../Layout/AppMain/PageTitle"
import DataGrid, {
  Button,
  Column,
  Editing,
  FilterRow,
  Scrolling
} from "devextreme-react/data-grid"
import CustomStore from "devextreme/data/custom_store"
import { formatMessage } from "devextreme/localization"
import React, { Fragment, useState } from "react"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import { Card, CardBody, Col, Form as RSForm, Row } from "reactstrap"
import { each } from "underscore"
import { getAllUsers, saveRoles } from "../../../services"
import EditIam from "./edit"
import { Bounce, toast } from "react-toastify"

export default function Iam() {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [dataToEdit, setDataToEdit] = useState({})

  const dataSource = {
    store: new CustomStore({
      load: () => {
        return getAllUsers(true)
          .then(({ data }) => {
            if (data) {
              each(data, (ur) => {
                let rolesToDisplay = ""
                each(ur.roles, (r) => {
                  rolesToDisplay = rolesToDisplay.concat(r.name + " <br />")
                })
                ur.rolesToDisplay = rolesToDisplay
              })

              return {
                data: data
              }
            } else {
              return {
                data: [],
                totalCount: 0
              }
            }
          })
          .catch((e) => {
            throw Object.assign(new Error("Data Loading Error"), { code: 404 })
          })
      }
    })
  }

  let _datagrid = React.useRef(null)

  const onSaveRoles = async ({ rolesToSave, userId }) => {
    saveRoles(rolesToSave, userId)
      .then((data) => {
        if (!data) {
          setOpenDrawer(false)
        } else {
          setOpenDrawer(false)
          _datagrid.current.instance.refresh()
        }
      })
      .catch((error) => {
        toast(formatMessage("iam-role-error"), {
          transition: Bounce,
          closeButton: true,
          autoClose: 3000,
          position: "bottom-center",
          type: "error"
        })
      })
  }

  return (
    <Fragment>
      <PageTitle
        heading="IAM"
        subheading="Identity Access Management"
        icon="pe-7s-study text-success"
      />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="main-card mb-3">
          <CardBody>
            <RSForm>
              <Row form>
                <Col md={12}>
                  <div>
                    <div className="dx-swatch-dx-generic-compact">
                      <DataGrid
                        className={"dx-card wide-card"}
                        dataSource={dataSource}
                        showBorders={false}
                        columnAutoWidth={true}
                        columnHidingEnabled={true}
                        loadPanel={{ enabled: false }}
                        ref={_datagrid}
                      >
                        <Editing mode="cell" allowUpdating={true} />
                        <FilterRow visible={true} />
                        <Column
                          allowEditing={false}
                          dataField={"lastName"}
                          caption={formatMessage("lastName")}
                          hidingPriority={7}
                        />
                        <Column
                          allowEditing={false}
                          dataField={"firstName"}
                          caption={formatMessage("firstName")}
                          hidingPriority={7}
                        />
                        <Column
                          allowEditing={false}
                          dataField={"email"}
                          caption={"Email"}
                          hidingPriority={7}
                        />
                        <Column
                          allowEditing={false}
                          dataField={"rolesToDisplay"}
                          caption={formatMessage("role")}
                          encodeHtml={false}
                          hidingPriority={7}
                        />
                        <Column type="buttons" width={110}>
                          <Button
                            icon="edit"
                            onClick={(data) => {
                              setOpenDrawer(true)
                              setDataToEdit(data.row.data)
                            }}
                          />
                        </Column>
                        <Scrolling mode="virtual" />
                      </DataGrid>
                    </div>
                  </div>
                </Col>
              </Row>
            </RSForm>
          </CardBody>
        </Card>
      </ReactCSSTransitionGroup>
      <EditIam
        data={dataToEdit}
        open={openDrawer}
        close={() => setOpenDrawer(false)}
        onSaveRoles={onSaveRoles}
      />
    </Fragment>
  )
}
