import "./ProjectSlotCell.scss"
import { formatMessage } from "devextreme/localization"

export default function ProjectSlotCell({ slot }) {
  return (
    <div className="project-slot-cell d-flex flex-column">
      <div className="project-slot-cell-resources d-flex justify-content-center align-items-center">
        <div>
          <b>{slot.resourceCount}</b>
          <span>{formatMessage("preallocation-projects-resources-allocated")}</span>
        </div>
      </div>
      {/*<div className='project-slot-cell-stats flex-fill d-flex'>*/}
      {/*  <div className='flex-fill'>*/}
      {/*    <b>{ formatPercentage(slot.mediumTime || 0) }</b>*/}
      {/*    <div>{formatMessage('preallocation-projects-medium-time')}</div>*/}
      {/*  </div>*/}
      {/*  <div className='flex-fill'>*/}
      {/*    <b>{ slot.mediumPeriod || 0 } days</b>*/}
      {/*    <div>{formatMessage('preallocation-projects-medium-period')}</div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )
}
