import { faFilter } from "@fortawesome/free-solid-svg-icons"
import MenuItem from "@material-ui/core/MenuItem"
import { Select } from "@material-ui/core"
import { ThemeProvider } from "@material-ui/core/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Chip from "@material-ui/core/Chip"
import React from "react"
import ReactDom from "react-dom"
import { formatMessage } from "devextreme/localization"

const Toolbar = ({
  theme,
  toReviewFilter,
  setToReviewFilter,
  statusFilter,
  setStatusFilter
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Select
        labelId="select-filter"
        id="required"
        value={""}
        displayEmpty
        onChange={(e) => {
          switch (e.target.value) {
            case "assignedToMe":
              setToReviewFilter(true)
              if (["closed", "onHold", "firstReview"].includes(statusFilter))
                setStatusFilter(undefined)
              return
            case "preReview":
              setStatusFilter("preReview")
              return
            case "firstReview":
              setStatusFilter("firstReview")
              setToReviewFilter(false)
              return
            case "thirdReview":
              setStatusFilter("thirdReview")
              return
            case "completed":
              setStatusFilter("completed")
              setToReviewFilter(false)
              return
            case "closed":
              setStatusFilter("closed")
              setToReviewFilter(false)
              return
            case "onHold":
              setStatusFilter("onHold")
              setToReviewFilter(false)
              return
            case "ppa":
              setStatusFilter("ppa")
              return
            default:
              return
          }
        }}
      >
        <MenuItem value="" disabled>
          <FontAwesomeIcon icon={faFilter} size="1x" />
        </MenuItem>
        {!toReviewFilter && (
          <MenuItem value={"assignedToMe"}>
            {formatMessage("assigned-to-me-filter-label")}
          </MenuItem>
        )}
        {statusFilter !== "preReview" && (
          <MenuItem value={"preReview"}>Status: Pre Review</MenuItem>
        )}
        {statusFilter !== "firstReview" && (
          <MenuItem value={"firstReview"}>Status: Job Review</MenuItem>
        )}
        {statusFilter !== "thirdReview" && (
          <MenuItem value={"thirdReview"}>Status: Third Review</MenuItem>
        )}
        {statusFilter !== "completed" && (
          <MenuItem value={"completed"}>
            {formatMessage("Status")}: {formatMessage("completed-filter-label")}
          </MenuItem>
        )}
        {statusFilter !== "closed" && (
          <MenuItem value={"closed"}>
            {formatMessage("Status")}: {formatMessage("closed-filter-label")}
          </MenuItem>
        )}
        {statusFilter !== "onHold" && (
          <MenuItem value={"onHold"}>Status: On Hold</MenuItem>
        )}
        {statusFilter !== "ppa" && <MenuItem value={"ppa"}>Status: PPA</MenuItem>}
      </Select>

      {toReviewFilter && (
        <Chip
          style={{ marginLeft: 30 }}
          label={formatMessage("assigned-to-me-filter-label")}
          onDelete={() => {
            setToReviewFilter(false)
          }}
          color="primary"
          variant="outlined"
        />
      )}
      {statusFilter === "preReview" && (
        <Chip
          style={{ marginLeft: 30 }}
          label="Status: Pre Review"
          onDelete={() => {
            setStatusFilter(undefined)
          }}
          color="primary"
          variant="outlined"
        />
      )}
      {statusFilter === "firstReview" && (
        <Chip
          style={{ marginLeft: 30 }}
          label="Status: Job Review"
          onDelete={() => {
            setStatusFilter(undefined)
          }}
          color="primary"
          variant="outlined"
        />
      )}
      {statusFilter === "thirdReview" && (
        <Chip
          style={{ marginLeft: 30 }}
          label="Status: Third Review"
          onDelete={() => {
            setStatusFilter(undefined)
          }}
          color="primary"
          variant="outlined"
        />
      )}
      {statusFilter === "completed" && (
        <Chip
          style={{ marginLeft: 30 }}
          label={
            formatMessage("Status") + ":" + formatMessage("completed-filter-label")
          }
          onDelete={() => {
            setStatusFilter(undefined)
          }}
          color="primary"
          variant="outlined"
        />
      )}
      {statusFilter === "closed" && (
        <Chip
          style={{ marginLeft: 30 }}
          label={
            formatMessage("Status") + ":" + formatMessage("closed-filter-label")
          }
          onDelete={() => {
            setStatusFilter(undefined)
          }}
          color="primary"
          variant="outlined"
        />
      )}
      {statusFilter === "onHold" && (
        <Chip
          style={{ marginLeft: 30 }}
          label="Status: On Hold"
          onDelete={() => {
            setStatusFilter(undefined)
          }}
          color="primary"
          variant="outlined"
        />
      )}
      {statusFilter === "ppa" && (
        <Chip
          style={{ marginLeft: 30 }}
          label="Status: PPA"
          onDelete={() => {
            setStatusFilter(undefined)
          }}
          color="primary"
          variant="outlined"
        />
      )}
    </ThemeProvider>
  )
}

export const ToolbarFilter = React.memo(Toolbar)
