import AddYearButton from "../AddYearButton"
import CloneWbsButton from "../CloneWbsButton"
import DetailsWbsButton from "../DetailsWbsButton"

export default function WbsActionCell({ info, row, canManage = false }) {
  const isActive = () => Boolean(row.isActive)

  return (
    <span className="d-flex flex-row">
      {canManage && (
        <>
          <CloneWbsButton wbs={row} className="my-2 mr-1" />
          <AddYearButton wbs={row} className="my-2 mx-1" disabled={!isActive()} />
        </>
      )}
      <DetailsWbsButton wbsId={info} className="my-2 ml-1" canManage={canManage} />
    </span>
  )
}
