const baseUrl = `${document.env.BASE_URL}/api/${document.env.API_VER}/`
export const apiService = {
  user: baseUrl + "user",
  login: baseUrl + "user",
  timesheet: baseUrl + "timesheet",
  job: baseUrl + "job",
  period: baseUrl + "period",
  organization: baseUrl + "organization",
  query: baseUrl + "query",
  role: baseUrl + "role",
  config: baseUrl + "config",
  terms: baseUrl + "terms",
  wbs: baseUrl + "wbs",
  search: baseUrl + "search",
  adb: baseUrl + "adb",
  customer: baseUrl + "customer",
  me: baseUrl + "me",
  fiscalYear: baseUrl + "fiscal-year",
  office: baseUrl + "office",
  appraisal: baseUrl + "appraisal-goals"
}
