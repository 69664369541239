import { formatMessage } from "devextreme/localization"
import { useState } from "react"
import DatePicker from "react-datepicker"
import { useSelectBipUsers } from "../../hooks/selectHooks/useSelectBipUsers"
import { FieldErrorIcon } from "../FieldErrorIcon"
import "../input/DatePickerInput.scss"
import "./JobCodeCell.scss"
import Tooltip from "../common/Tooltip"

export default function JobCodeCell({
  info,
  onChange,
  startDateError,
  endDateError,
  canManage = false,
  width,
}) {
  const { name, description, startDate, endDate, jobOwner } = info
  const { parseBipUserId } = useSelectBipUsers()

  const [jobStartDate, setJobStartDate] = useState(
    startDate ? new Date(startDate) : null,
  )
  const [jobEndDate, setJobEndDate] = useState(endDate ? new Date(endDate) : null)

  function onDateChanged(newDate, field) {
    if (field === "startDate") {
      setJobStartDate(newDate)
    } else {
      setJobEndDate(newDate)
    }

    onChange(newDate, field)
  }

  const getContainerStyle = () => {
    let style = { position: "absolute", top: 0, bottom: 0, left: 0 }

    if (width > 0) {
      style.width = `calc(${width}px - 3rem)`
    } else {
      style.width = "800px"
    }

    return style
  }

  return (
    <div
      style={getContainerStyle()}
      className="ml-5 d-flex flex-row align-items-center py-2"
    >
      <div className="ml-5"></div>
      <div className="ml-4 flex-fill job-code-cell-grid">
        <b className="text-left mr-2">{formatMessage("jw-Start date")}</b>
        <div className="date-picker-input-container date-picker-input-container-little d-flex flex-row">
          <DatePicker
            portalId="root-portal"
            disabled={!canManage}
            className={`${startDateError !== "" ? "border-danger" : ""}`}
            selected={jobStartDate}
            onChange={(newDate) => onDateChanged(newDate, "startDate")}
          />
          <FieldErrorIcon className="pl-1 mb-2" message={startDateError} />
        </div>
        <b className="text-left pl-2 mx-2">{formatMessage("jw-Job owner")}</b>
        <span className="text-left">
          <Tooltip content={parseBipUserId(jobOwner)}>
            {parseBipUserId(jobOwner)}
          </Tooltip>
        </span>

        <b className="text-left mr-2">{formatMessage("jw-End date")}</b>
        <div className="date-picker-input-container date-picker-input-container-little d-flex flex-row">
          <DatePicker
            portalId="root-portal"
            disabled={!canManage}
            className={`${endDateError !== "" ? "border-danger" : ""}`}
            selected={jobEndDate}
            onChange={(newDate) => onDateChanged(newDate, "endDate")}
          />
          <FieldErrorIcon className="pl-1 mb-2" message={endDateError} />
        </div>
        <b className="text-left pl-2 mx-2">{formatMessage("jw-Description")}</b>
        <div className="d-inline-block text-left text-truncate">
          <Tooltip content={description}>{description}</Tooltip>
        </div>
      </div>
    </div>
  )
}
