import DropdownSelect from "./input/DropdownSelect"
import "./Filter.scss"
import { formatMessage } from "devextreme/localization"

export default function YearFilter({ yearFilter, setYearFilter, className }) {
  const currentYear = new Date().getFullYear()

  const years = ["all", currentYear + 1, currentYear, currentYear - 1].map((o) => {
    const value = o.toString()
    const label = value === "all" ? formatMessage("jw-all") : value
    return { id: value, cell: label }
  })

  return (
    <DropdownSelect
      searchable={false}
      className={`${className} filter-select font-weight-bold`}
      innerLabel={
        <span className="text-muted pr-1">{formatMessage("jw-year")}:</span>
      }
      value={yearFilter || "all"}
      placeholder={formatMessage("jw-all")}
      options={years}
      onSelected={(option) => {
        setYearFilter(option?.id || null)
      }}
    />
  )
}
