import { formatMessage } from "devextreme/localization"
import { createContext, useContext, useEffect, useRef, useState } from "react"
import { useOnClickOutside } from "../hooks/useOnClickOutside"
import Icon from "./Icon"
import "./ConfirmModal.scss"

export default function ConfirmModal({
  isOpen,
  setIsOpen,
  onAfterOpen,
  onAfterClose,
  className,
  height = "25vmin",
  width = "50vmin",
  onConfirm = () => {},
  onCancel = () => {},
  title = formatMessage("jw-Confirmation"),
  body = formatMessage("jw-Are you sure?"),
  confirmButtonText = formatMessage("jw-Confirm"),
  confirmButtonClassName,
  cancelButtonText = formatMessage("jw-Cancel"),
  cancelButtonClassName
}) {
  const containerRef = useRef(null)
  useOnClickOutside(containerRef, () => {
    setIsOpen(false)
  })

  useEffect(() => {
    if (isOpen && onAfterOpen) {
      onAfterOpen()
    }
    if (!isOpen && onAfterClose) {
      onAfterClose()
    }
  }, [isOpen])

  if (!isOpen) {
    return <></>
  }

  return (
    <div
      style={{ minHeight: height, width }}
      ref={containerRef}
      className={`base-modal ${className} d-flex flex-column`}
    >
      <div className="confirm-modal-header p-3">
        <span className="font-weight-bold">{title}</span>
        <button className="btn btn-outline-secondary" onClick={() => onCancel()}>
          <Icon
            iconRelativePath="monade/plus"
            svgStyles={{
              transform: "rotate(45deg)",
              width: "12px",
              height: "12px"
            }}
          />
        </button>
      </div>
      <div className="confirm-modal-body p-3 flex-fill">{body}</div>
      <div className="confirm-modal-actions p-3">
        <button
          className={`btn btn-outline-primary ${cancelButtonClassName}`}
          onClick={() => onCancel()}
        >
          {cancelButtonText}
        </button>
        <button
          className={`btn btn-primary ${confirmButtonClassName}`}
          onClick={() => onConfirm()}
        >
          {confirmButtonText}
        </button>
      </div>
    </div>
  )
}

const confirmContext = createContext({
  confirm: async ({
    title,
    body,
    confirmButtonText,
    cancelButtonText,
    confirmButtonClassName,
    cancelButtonClassName
  }) => null,
  isOpen: false
})

export const ConfirmProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const confirmResolver = useRef(null)
  const [confirmOpts, setConfirmOpts] = useState({})

  const confirm = (confirmOpts) => {
    if (confirmResolver.current) {
      confirmResolver.current.reject()
      confirmResolver.current = null
    }
    setIsOpen(true)
    setConfirmOpts(confirmOpts)
    return new Promise((resolve, reject) => {
      confirmResolver.current = { resolve, reject }
    })
  }

  const resolve = (val) => {
    setIsOpen(false)
    if (confirmResolver.current) {
      confirmResolver.current.resolve(val)
      confirmResolver.current = null
    }
  }

  return (
    <confirmContext.Provider
      value={{
        isOpen,
        confirm
      }}
    >
      {children}
      <ConfirmModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onConfirm={() => resolve(true)}
        onCancel={() => resolve(false)}
        {...confirmOpts}
      />
    </confirmContext.Provider>
  )
}

export const useConfirm = () => useContext(confirmContext)
