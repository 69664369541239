import { apiService } from "./APIServices"

import axios from "axios"

/* params: {
     startDate, *
     aggregation, *
     colNumber,
     order,
     search,
     teamId
} */
export const getAllProjects = async (params) => {
  return axios.get(apiService.project, { params })
}

export const getProject = async (params) => {
  return axios.get(`${apiService.project}/details`, { params })
}
