import { useMemo, useRef, useState } from "react"
import { getConfig, getJobs } from "../../../../services"
import { showToastError } from "../../../Admin/Headcount/Main/Utils/ErrorHandling"
import * as AllocationService from "../services/AllocationService"
import { useAppAbility } from "../../../../Context/ability"
import moment from "moment"
import { formatMessage } from "devextreme/localization"

const getErrorMessage = (status) =>
  [400, 401].includes(status)
    ? null
    : formatMessage("preallocation-allocation-save-error")

export const useAllocations = (lineManagerFilterProject) => {
  const [errors, setErrors] = useState(null)
  const [editingAllocation, setEditingAllocation] = useState(null)
  const [isEditingMultiple, setEditingMultiple] = useState(false)
  const [selectedAllocations, setSelectedAllocations] = useState([])
  const [projects, setProjects] = useState([])
  const [
    lineManagerAllowedAllocationJobs,
    setLineManagerAllowedAllocationJobs
  ] = useState([])

  const lastSearchedProjectDates = useRef({})

  const { can } = useAppAbility()
  const canManageProjects = can("manage", "ProjectAllocations")

  const lineManagerAllowedProjects = useMemo(
    () =>
      projects.filter((project) => {
        return lineManagerAllowedAllocationJobs.includes(project.id)
      }),
    [projects, lineManagerAllowedAllocationJobs]
  )

  const createAllocation = async (allocation) => {
    setErrors(null)
    try {
      return await AllocationService.createAllocation(allocation)
    } catch (err) {
      if (err.response) {
        setErrors(err.response?.data)
        showToastError(err.response, getErrorMessage(err.response.status))
      }
      throw err
    }
  }

  const updateAllocation = async (oldAllocation, newAllocation) => {
    setErrors(null)
    try {
      const oldAllocationParams = {
        userId: oldAllocation.userId,
        oldJobId: oldAllocation.jobId,
        oldStartDate: oldAllocation.startDate,
        oldEndDate: oldAllocation.endDate
      }
      const newAllocationParams = {
        jobId: newAllocation.jobId,
        startDate: newAllocation.startDate,
        endDate: newAllocation.endDate,
        allocationPercentage: newAllocation.allocationPercentage
      }
      return await AllocationService.updateAllocation(
        oldAllocationParams,
        newAllocationParams
      )
    } catch (err) {
      if (err.response) {
        setErrors(err.response?.data)
        showToastError(err.response, getErrorMessage(err.response.status))
      }
      throw err
    }
  }

  const updateMultipleAllocation = async (userId, allocations) => {
    setErrors(null)
    try {
      return await AllocationService.updateMultipleAllocation(userId, allocations)
    } catch (err) {
      if (err.response) {
        setErrors(err.response?.data)
        showToastError(err.response, getErrorMessage(err.response.status))
      }
      throw err
    }
  }

  const deleteMultipleAllocations = async (allocations) => {
    try {
      const promises = []
      for (let allocation of allocations) {
        const params = {
          userId: allocation.userId,
          oldJobId: allocation.jobId,
          oldStartDate: allocation.startDate,
          oldEndDate: allocation.endDate
        }
        promises.push(AllocationService.deleteAllocation(params))
      }
      await Promise.all(promises)
    } catch (err) {
      if (err.response) {
        showToastError(err.response, getErrorMessage(err.response.status))
      }
    }
  }

  const resetErrors = () => setErrors(null)

  const toggleAllocation = (allocation) => {
    const allocationStr = JSON.stringify(allocation, Object.keys(allocation).sort())
    const isAlreadySelected = selectedAllocations
      .map((e) => JSON.stringify(e, Object.keys(e).sort()))
      .includes(allocationStr)
    if (isAlreadySelected) {
      setSelectedAllocations(
        selectedAllocations.filter(
          (a) => JSON.stringify(a, Object.keys(a).sort()) !== allocationStr
        )
      )
    } else {
      setSelectedAllocations([...selectedAllocations, allocation])
    }
  }

  const editAllocation = (allocation) => {
    setEditingAllocation(allocation)
    setSelectedAllocations([])
    setEditingMultiple(false)
  }

  const editMultiple = (record) => {
    setSelectedAllocations([])
    setEditingMultiple(true)
    setEditingAllocation(record)
  }

  const loadProjects = async (startDate, endDate) => {
    if (
      lastSearchedProjectDates.current?.startDate ===
        moment(startDate).format("DD/MM/YYYY") &&
      lastSearchedProjectDates.current?.endDate ===
        moment(endDate).format("DD/MM/YYYY")
    ) {
      return
    }
    const params =
      canManageProjects || lineManagerFilterProject
        ? {}
        : {
            user_is_delivery_manager: true,
            user_is_accountable: true,
            user_is_deputy_accountable: true
          }
    if (startDate) {
      params.valid_from_date = moment(startDate).format("YYYY-MM-DD")
    }
    if (endDate) {
      params.valid_to_date = moment(endDate).format("YYYY-MM-DD")
    }
    params.populate_wbs = true
    const { data: projects } = await getJobs(params)
    setProjects(projects.map((p) => ({ ...p, id: p._id, name: p.description })))
    lastSearchedProjectDates.current = {
      startDate: moment(startDate).format("DD/MM/YYYY"),
      endDate: moment(endDate).format("DD/MM/YYYY")
    }
  }

  const loadConfig = async () => {
    const {
      data: { preallocation }
    } = await getConfig()
    const lineManagerAllowedAllocationJob =
      preallocation?.lineManagerAllowedAllocationJob
    setLineManagerAllowedAllocationJobs(lineManagerAllowedAllocationJob || [])
  }

  const loadAllocationData = () => {
    loadConfig()
  }

  return {
    createAllocation,
    updateAllocation,
    updateMultipleAllocation,
    deleteMultipleAllocations,
    errors,
    resetErrors,
    editingAllocation,
    editAllocation,
    editMultiple,
    toggleAllocation,
    isEditingMultiple,
    selectedAllocations,
    projects,
    loadAllocationData,
    loadProjects,
    lineManagerAllowedProjects
  }
}
