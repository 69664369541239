import { forwardRef } from "react"
import "./ResourceDetailsTooltip.scss"
import { formatMessage } from "devextreme/localization"
import moment from "moment"

function ResourceDetailsTooltip({ style, resource, isShown, ...attributes }, ref) {
  return (
    <div
      ref={ref}
      style={style}
      className={`resource-details-tooltip bg-primary text-white ${
        isShown ? "visible" : "hidden"
      }`}
      {...attributes}
    >
      <div>
        {formatMessage("preallocation-resource-details-email", resource.email)}
      </div>
      <div>{formatMessage("preallocation-resource-details-id", resource.extId)}</div>
      <div>
        {formatMessage(
          "preallocation-resource-details-team-leader",
          resource.teamLeader
        )}
      </div>
      <div>
        {formatMessage("preallocation-resource-details-area", resource.area)}
      </div>
      {resource.joiningDate && (
        <div>
          {formatMessage(
            "preallocation-resource-details-hiring-date",
            moment(resource.joiningDate).format("DD/MM/YYYY")
          )}
        </div>
      )}
      {resource.leavingDate && (
        <div>
          {formatMessage(
            "preallocation-resource-details-leaving-date",
            moment(resource.leavingDate).format("DD/MM/YYYY")
          )}
        </div>
      )}
    </div>
  )
}

export default forwardRef(ResourceDetailsTooltip)
