import React, { Fragment } from "react"
import { Route } from "react-router-dom"
import AppFooter from "../../Layout/AppFooter/"
// Layout
import AppHeader from "../../Layout/AppHeader/"
import AppSidebar from "../../Layout/AppSidebar/"
// Charts
import ProfileJS from "./Profile/"

const Charts = ({ match }) => (
  <Fragment>
    <AppHeader />
    <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
        <div className="app-main__inner">
          {/* Charts */}

          <Route path={`${match.url}/profile`} component={ProfileJS} />
        </div>
        <AppFooter />
      </div>
    </div>
  </Fragment>
)

export default Charts
