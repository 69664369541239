import { Fragment, useEffect, useState } from "react"
import { useAppAbility } from "../../../../../Context/ability"
import { getErrorByField } from "../../../Headcount/Main/Utils/ErrorHandling"
import { useEditJob } from "../../hooks/useEditJob"
import { subject } from "@casl/ability"

const nameGhostCells = () => (
  <>
    <td className="no-right-border"></td>
    <td className="no-right-border"></td>
    <td className="no-right-border"></td>
    <td className="no-right-border"></td>
    <td className="no-right-border"></td>
    <td className="no-right-border"></td>
    <td className="no-right-border"></td>
    <td className="no-right-border"></td>
    <td className="no-right-border"></td>
    <td className="no-right-border"></td>
    <td className="no-right-border"></td>
  </>
)

const stdCostXTechGhostCells = () => (
  <>
    <td></td>
    <td></td>
  </>
)

const marginXTechGhostCells = () => (
  <>
    <td></td>
  </>
)

export default function JobRow({
  cells,
  info,
  isChargeable,
  columnOffsets,
  lastPinnedIndex,
}) {
  const { can } = useAppAbility()
  const canManageJob = can("manage", subject("Job", info))
  const cellIdsWithGhostCells = ["name", "standardCostsXtech", "xTechMargin"]

  const [editedJob, setEditedJob] = useState(info)
  const [rowEl, setRowEl] = useState(null)

  useEffect(() => {
    setEditedJob(info)
    resetErrors()
  }, [info])

  useEffect(() => {
    if (!rowEl || !columnOffsets) {
      return
    }

    let timeoutId = setTimeout(() => {
      for (let index = 0; index < Array.from(rowEl.children).length; index++) {
        if (lastPinnedIndex != null && index <= lastPinnedIndex) {
          rowEl.children[index].className += " position-sticky overflow-none"
          rowEl.children[index].style.left = columnOffsets
            ? columnOffsets[index] + "px"
            : 0
          rowEl.children[index].style.zIndex = rowEl.children[
            index
          ].className.includes("has-ghosts")
            ? "1001"
            : "1000"
        } else {
          rowEl.children[index].className = rowEl.children[index].className.replace(
            " position-sticky overflow-none",
            ""
          )
          rowEl.children[index].style.left = null
          rowEl.children[index].style.zIndex = rowEl.children[
            index
          ].className.includes("has-ghosts")
            ? "1"
            : null
        }
      }
    }, 0)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [rowEl, lastPinnedIndex, columnOffsets])

  const { mutation, errors, resetErrors } = useEditJob()

  function onJobRowChanged(val, field) {
    const payload = { ...editedJob, [field]: val }
    setEditedJob(payload)

    mutation.mutate({ job: payload, isChargeable })
  }

  const getCellWidth = (cell, index) => {
    if (!columnOffsets || cell.id !== "name") {
      return null
    }

    const offsetStart = columnOffsets[1]
    const offsetEnd = columnOffsets[Math.min(lastPinnedIndex + 1, 13)]

    return offsetEnd - offsetStart
  }

  return (
    <tr
      ref={setRowEl}
      className={`job-row ${errors && errors?.length > 0 ? "error-row" : ""}`}
    >
      {cells.map((cell, index) => {
        return (
          <Fragment key={info._id + cell.id}>
            <td
              style={{
                overflow: "visible",
                position: "relative",
              }}
              className={`text-center text-nowrap px-3 job-row-${cell.id}
                ${cellIdsWithGhostCells.includes(cell.id) ? "has-ghosts" : ""}
                ${cell.id === "name" ? "no-right-border" : ""}
              `}
            >
              {(index >= 2 && isChargeable) || index < 2 ? (
                <cell.renderCell
                  info={info}
                  onChange={(val, field) => onJobRowChanged(val, field)}
                  startDateError={getErrorByField(errors, "startDate")}
                  endDateError={getErrorByField(errors, "endDate")}
                  error={getErrorByField(errors, cell.id)}
                  canManage={canManageJob}
                  width={getCellWidth(cell, index)}
                />
              ) : (
                <div />
              )}
            </td>
            {cell.id === "name" && nameGhostCells()}
            {cell.id === "standardCostsXtech" && stdCostXTechGhostCells()}
            {cell.id === "xTechMargin" && marginXTechGhostCells()}
          </Fragment>
        )
      })}
    </tr>
  )
}
