import { formatMessage } from "devextreme/localization"
import BooleanCell from "../cell/BooleanCell"
import PercentageCell from "../cell/PercentageCell"
import PriceCell from "../cell/PriceCell"
import PriceCellEdit from "../cell/PriceCellEdit"
import YearActionCell from "../cell/YearActionCell"
import DropdownSelect from "../input/DropdownSelect.js"
import { useSelectForceDC } from "../../hooks/selectHooks/useSelectForceDC"

export const yearColumns = [
  {
    id: "_id",
    Header: () => <span>{formatMessage("jw-Actions")}</span>,
    accessor: "_id",
    Cell: ({ info, row, canManage, isWbsActive }) => (
      <YearActionCell
        wbsId={row.wbs}
        yearId={row._id}
        canManage={canManage}
        isWbsActive={isWbsActive}
      />
    ),
  },
  {
    id: "fiscalYear",
    Header: () => <span>{formatMessage("jw-Year")}</span>,
    accessor: "fiscalYear",
  },
  {
    id: "contractValue",
    Header: () => <span>{formatMessage("jw-Contract value")}</span>,
    accessor: "contractValue",
    Cell: ({ info, row, onChange, error, canManage }) => {
      if (!canManage) {
        return <PriceCell info={info} />
      }
      return (
        <PriceCellEdit info={info} onChange={(val) => onChange(val)} error={error} />
      )
    },
  },
  {
    id: "standardCostsTotal",
    Header: () => <span>{formatMessage("jw-STD total costs")}</span>,
    accessor: "standardCostsTotal",
    Cell: ({ info, row, onChange, error, canManage }) => {
      if (!canManage) {
        return <PriceCell info={info} />
      }
      return (
        <PriceCellEdit info={info} onChange={(val) => onChange(val)} error={error} />
      )
    },
  },
  {
    id: "otherCosts",
    Header: () => <span>{formatMessage("jw-Exp & other costs")}</span>,
    accessor: "otherCosts",
    Cell: ({ info, row, onChange, error, canManage }) => {
      if (!canManage) {
        return <PriceCell info={info} />
      }
      return (
        <PriceCellEdit info={info} onChange={(val) => onChange(val)} error={error} />
      )
    },
  },
  {
    id: "targetMargin",
    Header: () => <span>{formatMessage("jw-Margin")}</span>,
    accessor: "targetMargin",
    Cell: ({ info }) => <PriceCell info={info} />,
  },
  {
    id: "targetMarginPerc",
    Header: () => <span>{formatMessage("jw-Margin %")}</span>,
    accessor: "targetMarginPerc",
    Cell: ({ info }) => <PercentageCell info={info} />,
  },
  {
    id: "revenueXtechManagerial",
    Header: () => <span>{formatMessage("jw-Managerial")}</span>,
    accessor: "revenueXtechManagerial",
    Cell: ({ info }) => <PriceCell info={info} />,
  },
  {
    id: "rfpXTech",
    Header: () => <span>{formatMessage("jw-RFP")}</span>,
    accessor: "rfpXTech",
    Cell: ({ info }) => <PriceCell info={info} />,
  },
  {
    id: "standardCostsXtech",
    Header: () => <span>{formatMessage("jw-STD cost xTech")}</span>,
    accessor: "standardCostsXtech",
    Cell: ({ info }) => <PriceCell info={info} />,
  },
  {
    id: "xTechEffortPerc",
    Header: () => <span>{formatMessage("jw-STD effort xTech")}</span>,
    accessor: "xTechEffortPerc",
    Cell: ({ info }) => <PercentageCell info={info} />,
  },
  {
    id: "isDc",
    Header: () => <span>{formatMessage("jw-IS DC")}</span>,
    accessor: "isDc",
    Cell: ({ info }) => <BooleanCell info={info} disabled={true} />,
  },
  {
    id: "xTechMargin",
    Header: () => <span>{formatMessage("jw-Margin xTech")}</span>,
    accessor: "xTechMargin",
    Cell: ({ info }) => <PriceCell info={info} />,
  },
  {
    id: "forceDc",
    Header: () => <span>{formatMessage("jw-Force DC")}</span>,
    accessor: "forceDc",
    Cell: ({ info, row, onChange, error, canManage }) => {
      const { forceDCOptions } = useSelectForceDC()

      return (
        <DropdownSelect
          readOnly={!canManage}
          options={forceDCOptions}
          searchable={false}
          value={info}
          onSelected={(option) => {
            // console.log(option)
            if (canManage) onChange(option?.id)
          }}
          error={error}
        />
      )
    },
  },
]
