import { useEffect, useRef } from "react"
import { LabelWithError } from "../LabelWithError"
import "./TextArea.scss"
import "../InputDefaults.scss"

export default function TextArea({
  className,
  inputClassName,
  inputStyles,
  value,
  onChange,
  placeholder = "",
  label,
  prefocus = false,
  error = "",
  disabled = false,
  readOnly = false,
}) {
  const inputRef = useRef(null)
  const randomId = Math.random().toString()

  useEffect(() => {
    if (prefocus) {
      focus()
    }
  }, [])

  const focus = (e) => {
    inputRef.current?.focus()
  }

  const valueChanged = (value) => {
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <div
      className={`${className} text-area-container d-flex flex-column ${
        readOnly ? "readonly" : disabled ? "disabled" : "cursor"
      }`}
    >
      {label ? (
        <LabelWithError
          disabled={disabled}
          readOnly={readOnly}
          label={label}
          error={error}
          htmlFor={randomId}
        />
      ) : (
        ""
      )}
      <textarea
        className={`w-100 ${inputClassName} ${error !== "" ? "border-danger" : ""} ${
          readOnly ? "readonly" : disabled ? "disabled" : "cursor"
        }`}
        id={randomId}
        style={inputStyles}
        placeholder={placeholder}
        value={value}
        onChange={valueChanged}
        type="area"
        disabled={disabled || readOnly}
      />
    </div>
  )
}
