export default function getRequiredForEnablingFields(isExternal) {
  let requiredForEnablingFields = [
    // "_id", -> commented out becouse is not a user set field
    "name",
    "email",
    "firstName",
    "lastName",
    "office",
    "bipRole",
    "extId",
    "currentLevel",
    "joiningDate",
    "organization",
    // "created_at", -> commented out becouse is not a user set field
    // "updated_at", -> commented out becouse is not a user set field
    "globalCompetence"
    //"chargeability" -> commented out becouse is not a user set field
  ]
  if (isExternal) {
    requiredForEnablingFields = requiredForEnablingFields.concat([
      "contract",
      "contractEnd",
      "orderEnd",
      "xTechAccountable",
      "supplier"
    ])
  }
  return requiredForEnablingFields
}
