
export const formatToIsoUtc = (date) => {
  if (!date) {
    return null
  }

  if (!(date instanceof Date)) {
    date = new Date(date)
  }

  //old code
  // let d = new Date(
  //   Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
  // )
  // console.log( d.toISOString())
  // return d

  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
  ).toISOString()
}
