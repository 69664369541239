import { useMutation, useQueryClient } from "@tanstack/react-query"
import { formatMessage } from "devextreme/localization"
import { useState } from "react"
import { updateWbs } from "../../../../services"
import {
  showSuccessToast,
  showToastError,
} from "../../Headcount/Main/Utils/ErrorHandling"
import { parseWbsData } from "../utils/parseWbsData"
import { parseWbsToData } from "../utils/parseWbsToData"

export function useEditWbs(onSuccess) {
  const [errors, setErrors] = useState([])
  const resetErrors = () => {
    setErrors([])
  }

  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (wbs) => {
      const payload = parseWbsToData(wbs)
      return await updateWbs(payload)
    },
    {
      onError: (err) => {
        if (err.response.status === 400) {
          showToastError(err.response)
          setErrors(err.response.data)
        } else {
          setErrors([{}])
          showToastError(err.response)
        }
      },
      onSuccess: (data) => {
        const parsedWbs = parseWbsData(data.data)
        queryClient.invalidateQueries(["GET", "wbs"])
        queryClient.invalidateQueries(["GET", "wbs/", parsedWbs._id])
        setErrors([])
        showSuccessToast(formatMessage("jw-Successfully saved WBS"))

        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )

  return {
    mutation,
    errors,
    resetErrors,
  }
}
