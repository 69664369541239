import { formatMessage } from "devextreme/localization"
import { useEffect, useRef } from "react"
import { useOnClickOutside } from "../../hooks/useOnClickOutside"
import Icon from "../Icon"
import "./ConfirmModal.scss"

export default function ConfirmModal({
  isOpen,
  setIsOpen,
  onAfterOpen,
  onAfterClose,
  className,
  height = "25vmin",
  width = "50vmin",
  onConfirm = () => {},
  onCancel = () => {},
  title = formatMessage("jw-Confirmation"),
  body = formatMessage("jw-Are you sure?"),
  confirmButtonText = formatMessage("jw-Confirm"),
  confirmButtonClassName,
  cancelButtonText = formatMessage("jw-Cancel"),
  cancelButtonClassName,
}) {
  const containerRef = useRef(null)
  useOnClickOutside(containerRef, () => {
    setIsOpen(false)
  })

  useEffect(() => {
    if (isOpen && onAfterOpen) {
      onAfterOpen()
    }
    if (!isOpen && onAfterClose) {
      onAfterClose()
    }
  }, [isOpen])

  if (!isOpen) {
    return <></>
  }

  return (
    <div
      style={{ minHeight: height, width }}
      ref={containerRef}
      className={`base-modal ${className} d-flex flex-column`}
    >
      <div className="confirm-modal-header p-3">
        <span className="font-weight-bold">{title}</span>
        <button className="btn btn-outline-secondary" onClick={() => onCancel()}>
          <Icon
            iconRelativePath="monade/plus"
            svgStyles={{
              transform: "rotate(45deg)",
              width: "12px",
              height: "12px",
            }}
          />
        </button>
      </div>
      <div className="confirm-modal-body p-3 flex-fill">{body}</div>
      <div className="confirm-modal-actions p-3">
        <button
          className={`btn btn-outline-primary ${cancelButtonClassName}`}
          onClick={() => onCancel()}
        >
          {cancelButtonText}
        </button>
        <button
          className={`btn btn-primary ${confirmButtonClassName}`}
          onClick={() => onConfirm()}
        >
          {confirmButtonText}
        </button>
      </div>
    </div>
  )
}
