import { useMutation, useQueryClient } from "@tanstack/react-query"
import { formatMessage } from "devextreme/localization"
import { useState } from "react"
import { updateJob } from "../../../../services"
import {
  showSuccessToast,
  showToastError,
} from "../../Headcount/Main/Utils/ErrorHandling"
import { parseJobData } from "../utils/parseJobData"
import { parseJobToData } from "../utils/parseJobToData"

export function useEditJob(onSuccess) {
  const [errors, setErrors] = useState([])
  const resetErrors = () => {
    setErrors([])
  }

  const queryClient = useQueryClient()

  const mutation = useMutation(
    async ({ job, isChargeable }) => {
      const payload = parseJobToData(job, isChargeable)
      return await updateJob(payload)
    },
    {
      onError: (err) => {
        if (err.response.status === 400) {
          setErrors(err.response.data)
          showToastError(err.response)
        } else {
          setErrors([{}])
          showToastError(err.response)
        }
      },
      onSuccess: (data) => {
        const parsedJob = parseJobData(data.data)
        queryClient.invalidateQueries(["GET", "wbs"])
        queryClient.invalidateQueries(["GET", "job"])
        queryClient.invalidateQueries(["GET", "job/", parsedJob.shortCode])
        setErrors([])
        showSuccessToast(formatMessage("jw-Successfully saved Job"))

        if (onSuccess) {
          onSuccess(parsedJob)
        }
      },
    },
  )

  return {
    mutation,
    errors,
    resetErrors,
  }
}
