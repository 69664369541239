import "bootstrap/dist/css/bootstrap.min.css"
import Popup from "devextreme-react/popup"
import { formatMessage } from "devextreme/localization"
import React from "react"
import { Button } from "reactstrap"
import "./../../../../../style/dx.material.bip-scheme.css"

export default function RemoveLevelPopup({
    showRemoveLevelPopup,
    setShowRemoveLevelPopup,
    solveProblemsAndRemoveLevel,
    problems
}) {
  const onConfirm = () => {
    solveProblemsAndRemoveLevel()
    setShowRemoveLevelPopup(false)
  }

  return (
    <Popup
      maxWidth={700}
      height={"auto"}
      showTitle={true}
      onHiding={() => {
        setShowRemoveLevelPopup(false)
      }}
      title={formatMessage("hc-are-you-sure-you-want-to-continue")}
      dragEnabled={true}
      closeOnOutsideClick={false}
      visible={showRemoveLevelPopup && problems.isProblem && !problems.isBlocking}
      contentRender={() => {
        return (
          <div>
            <div className="mb-4" style={{ fontSize: "18px" }}>
              {problems.description}
            </div>
            {problems.isBlocking && problems.solution !== null? (
              <div className="mb-4" style={{ fontSize: "18px" }}>
                <p className="font-weight-bold">Hint: </p>
                {problems.solution}
              </div>
            ) : (
              ""
            )}
            <div className="d-flex justify-content-between">
              <Button
                outline
                color="primary"
                d="button"
                width={100}
                height={40}
                onClick={() => {
                  setShowRemoveLevelPopup(false)
                }}
              >
                <span className="dx-button-text">
                  {formatMessage("hc-button-cancel")}
                </span>
              </Button>
              {problems.isBlocking ? (
                ""
              ) : (
                <Button
                  color="primary"
                  d="button"
                  width={100}
                  height={40}
                  onClick={onConfirm}
                >
                  <span className="dx-button-text">
                    Confirm
                  </span>
                </Button>
              )}
            </div>
          </div>
        )
      }}
    />
  )
}
