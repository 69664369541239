import { formatMessage } from "devextreme/localization"
import { useEffect, useRef, useState } from "react"
import { useOnClickOutside } from "../../hooks/useOnClickOutside"
import Icon from "../Icon"
import "./InputDefaults.scss"
import { LabelWithError } from "./LabelWithError"
import "./DropdownSelect.scss"
import TextInput from "./TextInput"

export default function DropdownMultipleSelect({
  className,
  inputStyles,
  inputClassName,
  options, // {id: string, cell: string or jsx, disabled: boolean, searchFunction: (searchText) => {return boolean}}
  onChange,
  placeholder = formatMessage("jw-Select an option..."),
  values, // ["id-1", "id-2", ...]
  label,
  style,
  searchable = true,
  disabled = false,
  readOnly = false,
  showOnlySelectedButton = false,
  showAddNew = false,
  error = ""
}) {
  const [_options, _setOptions] = useState(options)
  const [selections, setSelections] = useState([])
  const [showOptions, setShowOptions] = useState(false)
  const [optionsToShow, setOptionsToShow] = useState(_options)
  const [showOnlySelectedOptions, setShowOnlySelectedOptions] = useState(false)
  const [searchText, setSearchText] = useState("")
  const inputRef = useRef(null)
  const dropdownSelectContainerRef = useRef(null)

  useEffect(() => {
    _setOptions(options)
  }, [options])

  useEffect(() => {
    if (!values || values.length === 0) {
      setSelections([])
      return
    }
    setSelections(
      _options.filter((option) => {
        return values.find((valueId) => valueId === option.id)
      })
    )
  }, [values, _options])

  useEffect(() => {
    let newOptionsToShow = _options

    if (showOnlySelectedButton & showOnlySelectedOptions) {
      newOptionsToShow = _options.filter((option) =>
        selections.find((selection) => selection.id === option.id)
      )
    }

    if (searchable && searchText !== "") {
      newOptionsToShow = newOptionsToShow.filter((option) => {
        if (option.searchFunction) {
          return option.searchFunction(searchText)
        }
        if (typeof option.cell === "string") {
          return option.cell.toLowerCase().includes(searchText.toLowerCase())
        }

        return false
      })
    }

    if (
      showAddNew &&
      searchText !== "" &&
      !_options?.find((option) => option.id === searchText)
    ) {
      newOptionsToShow.push({
        id: searchText,
        cell: (
          <div>
            <Icon
              svgStyles={{ height: "12px", width: "12px" }}
              iconRelativePath="monade/plus"
            />
            <b className="pl-2">{formatMessage("jw-Create")}: </b>
            {searchText}
          </div>
        ),
        toCreate: true,
        actualText: searchText.toString()
      })
    }
    setOptionsToShow(newOptionsToShow)
  }, [
    searchText,
    showOnlySelectedOptions,
    _options,
    selections,
    searchable,
    showOnlySelectedButton
  ])

  useOnClickOutside(dropdownSelectContainerRef, () => {
    setShowOptions(false)
  })

  const trigger = (e) => {
    setShowOptions((prev) => !prev)
  }

  const isOptionSelected = (option) => {
    return selections.find((selection) => option.id === selection.id)
  }

  const selectOption = (option) => {
    if (option.toCreate) {
      setSearchText("")
      const optionToCreate = option
      optionToCreate.cell = optionToCreate.actualText
      delete optionToCreate.toCreate
      delete optionToCreate.actualText
      _setOptions((prev) => [...prev, optionToCreate])
    }
    if (onChange) {
      onChange([...selections, option])
    }
  }

  const deselectOption = (option) => {
    if (!selections.find((selection) => selection.id === option.id)) {
      return
    }

    if (onChange) {
      onChange(selections.filter((s) => s.id !== option.id))
    }
  }

  const svgStyles = { width: "12px", height: "12px" }

  return (
    <div
      className={`${className} preallocation-dropdown-select-container d-flex flex-column ${
        readOnly ? "readonly" : disabled ? "disabled" : "cursor"
      }`}
      ref={dropdownSelectContainerRef}
      style={style}
    >
      {label ? (
        <LabelWithError
          disabled={disabled}
          readOnly={readOnly}
          onClick={trigger}
          label={label}
          error={error}
          labelClassName={`mb-1`}
        />
      ) : (
        ""
      )}
      {disabled || readOnly ? (
        <div
          ref={inputRef}
          className={`input d-flex flex-row justify-content-between ${
            readOnly ? "readonly" : disabled ? "disabled" : ""
          } ${error !== "" ? "border-danger" : ""} ${inputClassName}`}
          style={inputStyles}
          onClick={trigger}
        >
          <div className="input-multi-select d-flex flex-row">
            {selections.length > 0
              ? selections.map((selection) => (
                  <div
                    key={selection.id}
                    onClick={(e) => {
                      e.stopPropagation()
                      deselectOption(selection)
                    }}
                    className="selected-option text-nowrap"
                  >
                    {selection.cell}
                  </div>
                ))
              : placeholder}
          </div>
        </div>
      ) : (
        <>
          <div
            tabIndex={0}
            ref={inputRef}
            onKeyDown={(e) =>
              ["Enter", " "].indexOf(e.key) !== -1 && trigger() && e.preventDefault()
            }
            className={`input d-flex flex-row justify-content-between ${
              error !== "" ? "border-danger" : ""
            } ${inputClassName}`}
            style={inputStyles}
            onClick={trigger}
          >
            <div className="input-multi-select d-flex flex-row">
              {selections.length > 0
                ? selections.map((selection) => (
                    <div
                      key={selection.id}
                      onClick={(e) => {
                        e.stopPropagation()
                        deselectOption(selection)
                      }}
                      className="selected-option text-nowrap"
                    >
                      {selection.cell}
                    </div>
                  ))
                : placeholder}
            </div>
            <Icon
              svgStyles={svgStyles}
              iconRelativePath={
                showOptions ? "monade/arrow-up" : "monade/arrow-down"
              }
            />
          </div>
          {showOptions ? (
            <div className="options-container">
              <div
                style={{ width: `${inputRef.current.offsetWidth}px` }}
                className="options mt-2"
              >
                {searchable ? (
                  <div className="filter-section">
                    <TextInput
                      placeholder={formatMessage("jw-Search...")}
                      value={searchText}
                      onChange={setSearchText}
                    />
                  </div>
                ) : (
                  ""
                )}
                {showOnlySelectedButton ? (
                  <div className="filter-section">
                    <div
                      onClick={() => {
                        setShowOnlySelectedOptions((prev) => !prev)
                      }}
                      className="filter-button btn btn-link"
                    >
                      {showOnlySelectedOptions
                        ? formatMessage("jw-only selected")
                        : formatMessage("jw-all options")}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {optionsToShow.map((option) => (
                  <div
                    key={option.id}
                    className={`option ${
                      isOptionSelected(option)
                        ? "selected"
                        : option.disabled
                        ? "disabled"
                        : "enabled"
                    } `}
                    onClick={() =>
                      option.disabled
                        ? null
                        : isOptionSelected(option)
                        ? deselectOption(option)
                        : selectOption(option)
                    }
                  >
                    {option.cell}
                  </div>
                ))}
                {optionsToShow.length === 0 ? (
                  <div className="option disabled">
                    {formatMessage("jw-No options to show.")}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  )
}
