export const parseWbsToData = (wbs) => {
  const parsedWbs = {
    _id: wbs._id,
    code: wbs.code,
    aliasCode: wbs.aliasCode,
    status: wbs.status,
    isActive: wbs.isActive,
    adb: wbs.adb,
    type: wbs.type,
    contractValue: wbs.contractValue,
    standardCostsTotal: wbs.standardCostsTotal,
    otherCosts: wbs.otherCosts,
    targetMargin: wbs.targetMargin,
    targetMarginPerc: wbs.targetMarginPerc / 100,
    revenueXtechManagerial: wbs.revenueXtechManagerial,
    rfpXTech: wbs.rfpXTech,
    standardCostsXtech: wbs.standardCostsXtech,
    xTechEffortPerc: wbs.xTechEffortPerc / 100,
    xTechMargin: wbs.xTechMargin,
    isFinal: wbs.isFinal,
    isTempPlaced: wbs.isTempPlaced,
    hasExternalSupport: wbs.hasExternalSupport,
    description: wbs.description,
    subType: wbs.subType,
    accountable: wbs.accountable,
    area: wbs.area,
    isAreaRequired: wbs.isAreaRequired,
    billToCustomer: wbs.billToCustomer,
    finalCustomer: wbs.finalCustomer
  }

  if (parsedWbs.type !== "1") {
    delete parsedWbs.contractValue
    delete parsedWbs.standardCostsTotal
    delete parsedWbs.otherCosts
    delete parsedWbs.targetMarginPerc
    delete parsedWbs.revenueXtechManagerial
    delete parsedWbs.xTechMargin
    delete parsedWbs.xTechEffortPerc
    delete parsedWbs.standardCostsXtech
    delete parsedWbs.standardCostsXtech
    delete parsedWbs.rfpXTech
    delete parsedWbs.subType
    delete parsedWbs.finalCustomer
    delete parsedWbs.billToCustomer
  }

  return parsedWbs
}
