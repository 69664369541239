import {formatDateNoTime} from "../../../../Utils/formatDateNoTime";

export const parseResourcesFiltersToCreateAllocationParams = (filters) => {
  let params = {
    startDate: formatDateNoTime(filters.startDate),
    endDate: formatDateNoTime(filters.endDate),
    jobId: filters.project,
  };

  return params;
}
