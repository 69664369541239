import { useQuery } from "@tanstack/react-query"
import { getWbs } from "../../../../services"
import { parseWbsData } from "../utils/parseWbsData"

export function useWbsData(wbsId) {
  const { isLoading, isError, isSuccess, data, error } = useQuery(
    ["GET", "wbs/", wbsId],
    async () => {
      return await getWbs(wbsId, true, true, true).then((data) => {
        return parseWbsData(data.data[0])
      })
    }
  )

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
  }
}
