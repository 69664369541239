import { formatMessage } from "devextreme/localization"
import { getErrorByField } from "../../Headcount/Main/Utils/ErrorHandling"
import PriceInput from "./input/PriceInput"

export default function JobAccountingDataForm({
  job,
  year,
  wbs,
  onValueChanged,
  errors,
  canManage = false
}) {
  const isDatwaveJob = () => job.jobSpecialties?.includes("Datwave")

  return (
    <div className="d-flex flex-column">
      <h6 className="mb-4">{formatMessage("jw-ACCOUNTING DATA")}</h6>
      <div className="d-flex flex-row mb-4">
        <PriceInput
          readOnly={!canManage}
          className="w-25"
          label={formatMessage("jw-STD cost xTech") + " *"}
          value={job.standardCostsXtech}
          onChange={(val) => onValueChanged("standardCostsXtech", val)}
          error={getErrorByField(errors, "standardCostsXtech")}
        />
        {isDatwaveJob() && (
          <PriceInput
            readOnly={!canManage}
            className="w-25 pl-3"
            label={formatMessage("jw-Datwave purchase order value")}
            value={job.datwavePurchaseOrderValue}
            onChange={(val) => onValueChanged("datwavePurchaseOrderValue", val)}
            error={getErrorByField(errors, "datwavePurchaseOrderValue")}
          />
        )}
      </div>
      <div className="d-flex flex-row mb-4">
        <PriceInput
          readOnly
          className="w-25"
          label={formatMessage("jw-Managerial")}
          value={job.revenueXtechManagerial}
          error={getErrorByField(errors, "revenueXtechManagerial")}
        />
        <PriceInput
          readOnly
          className="w-25 pl-3"
          label={formatMessage("jw-RFP")}
          value={job.rfpXTech}
          error={getErrorByField(errors, "rfpXTech")}
        />
        <PriceInput
          readOnly
          className="w-25 pl-3"
          value={job.xTechMargin}
          label={formatMessage("jw-Margin xTech")}
          error={getErrorByField(errors, "xTechMargin")}
        />
      </div>
    </div>
  )
}
